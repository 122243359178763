import React from "react";

const ClaimsLoo = () => {
    return (
        <svg
            style={{ width: "1.063rem", height: "1.125rem" }}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_4924_1033"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="22"
                height="22"
            >
                <rect width="22" height="22" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4924_1033)">
                <path
                    d="M13.2002 18.3331L11.9168 17.0497L14.3002 14.6664L11.9168 12.2831L13.2002 10.9997L15.5835 13.3831L17.9668 10.9997L19.2502 12.2831L16.8668 14.6664L19.2502 17.0497L17.9668 18.3331L15.5835 15.9497L13.2002 18.3331ZM15.0106 10.0831L11.7564 6.82891L13.0397 5.54557L14.9877 7.49349L18.8835 3.59766L20.1668 4.90391L15.0106 10.0831ZM1.8335 15.5831V13.7497H10.0835V15.5831H1.8335ZM1.8335 8.24974V6.41641H10.0835V8.24974H1.8335Z"
                    fill="#007BFF"
                />
            </g>
        </svg>
    );
};

export default ClaimsLoo;
