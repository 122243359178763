import { Box, CircularProgress, Slider, Typography } from "@mui/material";

const DamageDescriptionBox = ({ data, imageIndex }: any) => {
    console.log(data);
    return (
        <Box
            sx={{
                width: "100%",
                height: "91vh",
                display: "flex",
                flexDirection: "column",
                p: "4% 5%",
                overflowY: "scroll",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    height: "20%",
                    alignItems: "center",
                }}
            >
                <Box>
                    <Typography sx={{ color: "#007BFF", fontSize: "1rem" }}>
                        {data?.image_bytes_list?.other_details?.Vehicle_Model}
                    </Typography>
                    <Typography
                        sx={{ color: "#484848", fontSize: "0.875rem" }}
                    >{`Category: ${data?.image_bytes_list?.other_details?.Category}`}</Typography>
                    <Typography
                        sx={{ color: "#484848", fontSize: "0.875rem" }}
                    >{`Highest Part Confidence: ${data?.image_bytes_list?.other_details?.Confidence_Score}%`}</Typography>
                </Box>
                <Box
                    sx={{
                        height: "100px",
                        width: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transform: "rotateY(180deg)",
                    }}
                >
                    <CircularProgress
                        variant="determinate"
                        value={data?.image_bytes_list?.other_details?.Damage_Intensity}
                        sx={{
                            height: "150px",
                            width: "150px",
                            background: "#D9D9D9",
                            borderRadius: "50%",
                            color: "#E98B35",
                        }}
                        size={80}
                        thickness={7}
                    />

                    <Typography
                        sx={{
                            position: "absolute",
                            transform: "rotateY(180deg)",
                            mt: "100px",
                            width: "163px",
                        }}
                        variant="caption"
                    >
                        Highest Damage Confidence
                    </Typography>
                    <Box
                        sx={{
                            zIndex: "10",
                            position: "absolute",
                            background: "#fae683",
                            height: "55px",
                            width: "55px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "rotateY(180deg)",
                        }}
                    >
                        <Typography variant="caption" sx={{ color: "#E98B35" }}>
                            {data?.image_bytes_list?.other_details?.Damage_Intensity === "nan"
                                ? "100%"
                                : data?.image_bytes_list?.other_details?.Damage_Intensity}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", mt: "2%" }}>
                <Typography sx={{ fontFamily: "Gilroy-SemiBold" }}>Damage Report:</Typography>
                <Box sx={{ width: "100%", display: "flex" }}>
                    <Box sx={{ width: "50%" }} />
                    <Box sx={{ width: "50%", display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="caption">Minor</Typography>
                        <Typography variant="caption">Moderate</Typography>
                        <Typography variant="caption">Severe</Typography>
                    </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    {data?.image_bytes_list?.processed_report_summary?.map((item: any) => {
                        return (
                            <Box sx={{ width: "100%", display: "flex", mb: "0.8%" }}>
                                <Box sx={{ width: "50%" }}>{item?.["Car Part"]}</Box>
                                <Slider
                                    size="small"
                                    value={
                                        item?.["Damage Type"]?.includes("minor")
                                            ? 25
                                            : item?.["Damage Type"]?.includes("severe")
                                              ? 90
                                              : 50
                                    }
                                    sx={{
                                        color: item?.["Damage Type"]?.includes("minor")
                                            ? "#F9E000"
                                            : item?.["Damage Type"]?.includes("severe")
                                              ? "#CC3838"
                                              : "#E66036",
                                        width: "50%",
                                    }}
                                />
                            </Box>
                        );
                    })}
                </Box>
            </Box>
            <Box sx={{ display: "flex", width: "80%", justifyContent: "space-between", mt: "2%" }}>
                <Typography sx={{ fontFamily: "Gilroy-Semibold" }}>{`Estimated Cost:`}</Typography>
                <Typography sx={{ fontFamily: "Gilroy-Semibold", color: "#007BFF" }}>
                    ₹ {data?.image_bytes_list?.total_estimated_cost}
                </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: "2%" }}>
                <Box sx={{ width: "90%", display: "flex" }}>
                    <Typography variant="caption" sx={{ width: "33%", display: "flex", justifyContent: "center" }}>
                        Parts
                    </Typography>
                    <Typography variant="caption" sx={{ width: "33%", display: "flex", justifyContent: "center" }}>
                        Estimated Range
                    </Typography>
                    <Typography variant="caption" sx={{ width: "33%", display: "flex", justifyContent: "center" }}>
                        Average Cost
                    </Typography>
                </Box>
                <Box>
                    {data?.image_bytes_list?.processed_report_summary?.map((report: any) => {
                        return (
                            <Box
                                sx={{
                                    width: "90%",
                                    display: "flex",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "33%",
                                        border: "1px solid #ECECEC",
                                        p: 0.5,
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    {report?.["Car Part"]}
                                </Box>
                                <Box
                                    sx={{
                                        width: "33%",
                                        border: "1px solid #ECECEC",
                                        p: 0.5,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography>{report?.["Cost Range"]}</Typography>
                                    <Typography>{report?.["Damage Type"]}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: "33%",
                                        border: "1px solid #ECECEC",
                                        p: 0.5,
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    ₹ {report?.["Average Cost"]}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default DamageDescriptionBox;
