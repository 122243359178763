import React from "react";

const GoogleDocsIcon = () => {
    return (
        <svg width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.4516 28H1.90969C0.856953 28 0 27.143 0 26.0903V1.90969C0 0.856953 0.856407 0 1.90969 0H13.3618L20.3618 7V26.0903C20.3613 27.143 19.5048 28 18.4516 28Z"
                fill="#3086F6"
            />
            <path d="M13.3613 0L20.3613 7H13.3613V0Z" fill="#0C67D6" />
            <path
                d="M15.9093 12.4087H4.45117V10.8184H15.9033V12.4087H15.9093ZM15.9093 14.6372H4.45117V16.2275H15.9033V14.6372H15.9093ZM12.7281 18.4511H4.45117V20.0469H12.7221V18.4511H12.7281Z"
                fill="#FDFFFF"
            />
        </svg>
    );
};

export default GoogleDocsIcon;
