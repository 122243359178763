import React from "react";
import { Box } from "@mui/material";

interface ProgressBarProps {
    percentage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percentage }) => {
    const getColor = (percentage: number) => {
        if (percentage > 90) return "green";
        if (percentage > 70) return "yellowgreen";
        if (percentage > 50) return "orange";
        return "red";
    };

    return (
        <Box
            sx={{
                width: "100px",
                height: "10px",
                backgroundColor: "#e0e0df",
                borderRadius: "5px",
                overflow: "hidden",
                display: "inline-block",
                verticalAlign: "middle",
            }}
        >
            <Box
                sx={{
                    width: `${percentage}%`,
                    height: "100%",
                    backgroundColor: getColor(percentage),
                    transition: "width 0.2s ease-in",
                }}
            />
        </Box>
    );
};

export default ProgressBar;
