import React from "react";

const HRLogo = () => {
    return (
        <svg width= "25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 40" x="0px" y="0px">
            <g>
                <path d="M28.5,29a.5.5,0,0,0-1,0v.5H8.5V26a.5.5,0,0,0-1,0v4a.49971.49971,0,0,0,.5.5H28a.49971.49971,0,0,0,.5-.5Z" />
                <path d="M7.5,2V6a.5.5,0,0,0,1,0V2.5h15V6a.49971.49971,0,0,0,.5.5h3.5V25a.5.5,0,0,0,1,0V6a.49965.49965,0,0,0-.14648-.35352l-4-4A.49965.49965,0,0,0,24,1.5H8A.49971.49971,0,0,0,7.5,2Zm17,1.207L26.793,5.5H24.5Z" />
                <path d="M13,6.5A9.48962,9.48962,0,0,0,7.14862,23.46863l-3.093,4.23645a.5.5,0,0,0,.80762.58984l3.10437-4.25207A9.49473,9.49473,0,1,0,13,6.5ZM7.691,22.62756a5.478,5.478,0,0,1,10.61792,0,8.46251,8.46251,0,0,1-10.61792,0ZM10.5,15A2.5,2.5,0,1,1,13,17.5,2.50294,2.50294,0,0,1,10.5,15Zm8.62494,6.8786a6.51707,6.51707,0,0,0-4.09644-4.03754,3.5,3.5,0,1,0-4.057,0A6.51707,6.51707,0,0,0,6.87506,21.8786a8.5,8.5,0,1,1,12.24988,0Z" />
                <path d="M25,26.5H20a.5.5,0,0,0,0,1h5a.5.5,0,0,0,0-1Z" />
                <path d="M25,23.5H22a.5.5,0,0,0,0,1h3a.5.5,0,0,0,0-1Z" />
                <circle cx="28" cy="27" r="0.5" />
            </g>
        </svg>
    );
};

export default HRLogo;
