import { Box, Typography } from "@mui/material";

const NoAttackDetectedBox = ({ data }: any) => {
    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", mb: "2%" }}>
            <Typography variant="h5" sx={{ color: "#10AA50", mb: "1%" }}>
                Attack Not Detected!
            </Typography>
            {/* <Box sx={{ border: "1px solid #D9D9D9", borderRadius: "8px" }}>
                <Typography
                    sx={{ background: "#f0f9ff", p: 2, borderBottom: "1px solid #D9D9D9" }}
                >{`Technique: ${attack?.technique}`}</Typography>

                <Box sx={{ width: "100%", display: "flex", borderBottom: "1px solid #D9D9D9" }}>
                    <Box
                        sx={{
                            borderRight: "1px solid #D9D9D9",
                            width: "30%",
                            p: 2,
                        }}
                    >
                        Procedure Score
                    </Box>
                    <Box sx={{ p: 2, width: "70%" }}>{attack?.procedure_score}</Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", borderBottom: "1px solid #D9D9D9" }}>
                    <Box
                        sx={{
                            borderRight: "1px solid #D9D9D9",
                            width: "30%",
                            p: 2,
                        }}
                    >
                        Procedures
                    </Box>
                    <Box sx={{ p: 2, width: "70%" }}>{attack?.procedures}</Box>
                </Box>
            </Box> */}
        </Box>
    );
};

export default NoAttackDetectedBox;
