import React from "react";

const SendWhiteIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.1808 6.00536L4.14052 5.41338C4.4048 5.36059 4.4048 5.27466 4.14052 5.22187L1.1808 4.6299C1.00451 4.5947 0.832813 4.42285 0.797618 4.24671L0.205642 1.28699C0.152691 1.02256 0.308533 0.89969 0.553472 1.01273L9.58514 5.18113C9.74828 5.25643 9.74828 5.37882 9.58514 5.45413L0.553472 9.62253C0.308533 9.73557 0.152691 9.6127 0.205642 9.34826L0.797618 6.38854C0.832813 6.21241 1.00451 6.04056 1.1808 6.00536Z"
                fill="white"
            />
        </svg>
    );
};

export default SendWhiteIcon;
