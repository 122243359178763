import React, { useEffect, useRef } from "react";
import sofieBot from "../../assets/sofieBot.png";
import { Box } from "@mui/material";

type MessageType = {
    text: string;
    isReceived: boolean;
    withAvatar: boolean;
};

const Chats = ({ messages }: { messages: MessageType[] }) => {
    const lastMessageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    return (
        <Box
            sx={{
                pt: "1.5rem",
                height: "100%",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                    width: "0.1em",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "transparent",
                },
            }}
        >
            {messages.map((message, index) => (
                <Box
                    key={index}
                    ref={index === messages.length - 1 ? lastMessageRef : null}
                    sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
                >
                    <Box
                        sx={{
                            marginRight: message.isReceived ? "8px" : "auto",
                        }}
                    >
                        {message.withAvatar && (
                            <img
                                src={sofieBot}
                                alt="Avatar"
                                style={{ width: "fit-content", height: "40px", borderRadius: "50%" }}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            padding: "10px",
                            borderRadius: "10px",
                            backgroundColor: message.isReceived ? "transparent" : "#D2E8FF",
                            color: "black",
                            marginRight: message.isReceived ? "5rem" : "0px",
                            marginLeft: !message.isReceived ? "5rem" : "0px",
                        }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: message.text }} />
                    </Box>
                </Box>
            ))}
            <div ref={lastMessageRef} />
        </Box>
    );
};

export default Chats;
