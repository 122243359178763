import { Box, Button, Typography } from "@mui/material";
import { Api } from "../../apis";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/setting";

const GraphAnalysis = ({ processedData, fileList }: any) => {
    const [viewGraphs, setViewGraphs] = useState<boolean>(false);
    const [graphs, setGraphs] = useState<any>(null);

    const { contextStore, setContext } = useContext<any>(Context);
    const getGraphs = () => {
        if (fileList?.length > 0) {
            Api?.henkelGraphGeneration({ file_names: fileList })?.then((res: any) => {
                if (res?.status === 200) {
                    setGraphs(res?.data?.base64_images);
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Failed To generate graphs. Try again after some time",
                        },
                    });
                }
            });
        }
    };

    // console.log(graphs);

    useEffect(() => {
        getGraphs();
    }, []);

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                border: "1px solid #D9D9D9",
                borderRadius: "10px",
                p: "3vh 2vw",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <Box
                sx={{
                    width: viewGraphs ? "75%" : "100%",
                    mb: "2vh",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    paddingRight: "4px",
                    overflowY: "scroll",
                }}
            >
                <Typography variant="h5">Comparison</Typography>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", gap: 2 }}>
                    <Box sx={{ height: "100%", display: "flex", alignItems: "center", gap: 1 }}>
                        <Box sx={{ background: "rgba(204, 85, 85, 0.33)", height: "100%", width: "40px" }}></Box>
                        <Typography>Unavailable data</Typography>
                    </Box>
                    <Button
                        sx={{
                            border: "1px solid #007BFF",
                            borderRadius: "7px",
                            color: "#007BFF",
                            fontWeight: "600",
                            width: "100px",
                        }}
                        onClick={() => setViewGraphs(!viewGraphs)}
                    >
                        Visualise
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        color: "#007BFF",
                        height: "50px",
                    }}
                >
                    <Box
                        sx={{
                            width: "60%",
                            justifyContent: "space-evenly",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {fileList?.map((file: any) => {
                            return (
                                <Typography sx={{ width: "100%", marginLeft: 3 }}>{file?.substring(0, 4)}</Typography>
                            );
                        })}
                    </Box>
                </Box>
                <Box sx={{ width: "100%", maxHeight: "100%" }}>
                    {processedData &&
                        Object.entries(processedData?.[0]?.[1])?.map((data: any) => {
                            return (
                                <Box sx={{ width: "100%", mb: "2%" }}>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            background: "linear-gradient(to right, rgba(227, 244, 255, 1), #fff)",
                                            height: "35px",
                                            display: "flex",
                                            alignItems: "center",
                                            p: "0 1vw",
                                        }}
                                    >
                                        <Typography sx={{ color: "#007BFF" }}>{`${data?.[0]?.[0]}${data?.[0]
                                            ?.replaceAll("_", " ")
                                            .toLowerCase()
                                            .slice(1)}`}</Typography>
                                    </Box>
                                    {Object.entries(data?.[1])?.map((entries: any) => {
                                        return (
                                            <Box>
                                                <Box
                                                    sx={{
                                                        width: "100%",
                                                        height: "35px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        border: "1px solid #D9D9D9",
                                                    }}
                                                >
                                                    <Typography>{`${entries?.[0]?.[0].toUpperCase()}${entries[0]
                                                        ?.replaceAll("_", " ")
                                                        .toLowerCase()
                                                        .slice(1)}`}</Typography>
                                                </Box>

                                                {entries?.[1]?.map((entry: any, index: number) => {
                                                    return (
                                                        <Box
                                                            sx={{
                                                                width: "100%",
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    width: "40%",
                                                                    border: "1px solid #D9D9D9",
                                                                    p: 2,
                                                                }}
                                                            >
                                                                {entry?.type?.replaceAll("_", " ")}
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    width: "60%",
                                                                    display: "flex",
                                                                    justifyContent: "space-evenly",
                                                                }}
                                                            >
                                                                {Object.entries(processedData)?.map(
                                                                    (val: any, i: any) => {
                                                                        return (
                                                                            <Box
                                                                                sx={{
                                                                                    p: 2,
                                                                                    border: "1px solid #EDEDED",
                                                                                    width: "100%",
                                                                                    background:
                                                                                        processedData?.[i]?.[1][
                                                                                            data?.[0]
                                                                                        ]?.[entries?.[0]]?.[index]?.[
                                                                                            "value"
                                                                                        ]?.length > 0
                                                                                            ? null
                                                                                            : "rgba(204, 85, 85, 0.33)",
                                                                                }}
                                                                            >
                                                                                <Typography>
                                                                                    {
                                                                                        processedData?.[i]?.[1][
                                                                                            data?.[0]
                                                                                        ]?.[entries?.[0]]?.[index]?.[
                                                                                            "value"
                                                                                        ]
                                                                                    }
                                                                                </Typography>
                                                                            </Box>
                                                                        );
                                                                    }
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            );
                        })}
                </Box>
            </Box>
            {viewGraphs && (
                <Box sx={{ width: "24%", height: "100%", overflowY: "scroll" }}>
                    {graphs?.map((graph: any) => {
                        return <img style={{ width: "100%" }} alt="graph" src={`data:image/png;base64,${graph}`} />;
                    })}
                </Box>
            )}
        </Box>
    );
};

export default GraphAnalysis;
