import React from "react";

const ClaimReportIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.875 17.375H17.375V18.75H11.875V17.375ZM11.875 13.9375H20.125V15.3125H11.875V13.9375ZM11.875 20.8125H15.3125V22.1875H11.875V20.8125Z"
                fill="#484848"
            />
            <path
                d="M22.1875 8.4375H20.125V7.75C20.125 7.38533 19.9801 7.03559 19.7223 6.77773C19.4644 6.51987 19.1147 6.375 18.75 6.375H13.25C12.8853 6.375 12.5356 6.51987 12.2777 6.77773C12.0199 7.03559 11.875 7.38533 11.875 7.75V8.4375H9.8125C9.44783 8.4375 9.09809 8.58237 8.84023 8.84023C8.58237 9.09809 8.4375 9.44783 8.4375 9.8125V24.25C8.4375 24.6147 8.58237 24.9644 8.84023 25.2223C9.09809 25.4801 9.44783 25.625 9.8125 25.625H22.1875C22.5522 25.625 22.9019 25.4801 23.1598 25.2223C23.4176 24.9644 23.5625 24.6147 23.5625 24.25V9.8125C23.5625 9.44783 23.4176 9.09809 23.1598 8.84023C22.9019 8.58237 22.5522 8.4375 22.1875 8.4375ZM13.25 7.75H18.75V10.5H13.25V7.75ZM22.1875 24.25H9.8125V9.8125H11.875V11.875H20.125V9.8125H22.1875V24.25Z"
                fill="#484848"
            />
        </svg>
    );
};

export default ClaimReportIcon;
