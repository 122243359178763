import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import createStore from "./store";
import { Context } from "./context/setting";
import "./App.css";
import { useState } from "react";
import SnackbarAlert from "./screens/Snackbar/Snackbar";
import { createTheme, ThemeProvider } from "@mui/material";
import RouterConfig from "./navigation/RouterConfig";

const { store, persistor } = createStore();

const context = {
    chats: {},
    temparary: {
        modelType: "normal",
        model: "gpt-3.5-turbo",
        modelESG: "gpt-3.5-turbo",
        temperature: 0,
        temperatureESG: 0,
        top_k: 1,
        language: "English",
        persona: "Financial Analyst",
        memory: "0",
        memoryESG: "0",
        generator: "Engine-O",
        pixel: "1024x1024",
        cfg_scale: 10,
        steps: 50,
    },
    data_source: false,
    loading: true,
    workflow_settings: {
        all_nodes_connected: false,
    },
    pathology: {},
    isAzure: false,
    Language: "English",
    claims: {
        projects: [],
        activeProject: {},
        summary: {},
    },
    care: {},
    aurigo: {
        selected: [],
    },
    finance: { cfo: {} },
    damage_detection: { verify: { complete: 0, questions: 0 }, report: { imageIndex: 0 }, question: {} },
};

function App() {
    const [contextStore, setContext] = useState<any>(context);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
    };

    const theme = createTheme({
        typography: {
            fontFamily: ["Gilroy-Medium"].join(","),
        },
    });

    return (
        <Provider store={store}>
            <Context.Provider value={{ contextStore, setContext }}>
                <PersistGate persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <SnackbarAlert />
                        <RouterConfig />
                    </ThemeProvider>
                </PersistGate>
            </Context.Provider>
        </Provider>
    );
}

export default App;
