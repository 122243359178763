import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import LogisticsIcon from "../../components/Common/svgLogs/LogisticsIcon";
import ITIcon from "../../components/Common/svgLogs/ITIcon";
import BfsiIcon from "../../components/Common/svgLogs/BfsiIcon";
import FMCGIcon from "../../components/Common/svgLogs/FMCGIcon";
import ESGIcon from "../../components/Common/svgLogs/ESGIcon";
import HandIIcon from "../../components/Common/svgLogs/HandIIcon";
import SupplyChainIcon from "../../components/Common/svgLogs/SupplyChainIcon";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/setting";
import { Api } from "../../apis";
import { useAuth0 } from "@auth0/auth0-react";

const Card = ({ icon, heading, text }: any) => {
    const { user } = useAuth0();
    const [hovered, setHovered] = useState<boolean>(false);
    const navigate = useNavigate();
    const isSmallThan1560 = useMediaQuery("(max-width:1560px)");
    const { contextStore, setContext } = useContext<any>(Context);

    const postUserData = () => {
        Api?.uploadUserData({ name: user?.given_name, email: user?.email, subClaim: user?.sub })?.then((res: any) => {
            if (res?.status === 202) {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message: `User data saved in DB`,
                    },
                });
            }
        });
    };

    useEffect(() => {
        postUserData();
    }, []);

    return (
        <Box
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => {
                navigate("/sophie");
                setContext({
                    ...contextStore,
                    sector: heading,
                });
            }}
            sx={{
                width: "14vw",
                height: "20vh",
                minWidth: "180px",
                minHeight: "180px",
                border: !hovered ? "1px solid #D9D9D9" : null,
                borderRadius: "10px",
                maxWidth: "280px",
                maxHeight: "280px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                cursor: "pointer",
            }}
        >
            {!hovered ? (
                <>
                    {icon}
                    <Typography sx={{ fontSize: isSmallThan1560 ? "16px" : "20px" }}>{heading}</Typography>
                </>
            ) : (
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "linear-gradient(to bottom right, #eff7fe, #d7e3eb)",
                        borderRadius: "10px",
                    }}
                >
                    <Typography sx={{ color: "#007BFF", mb: "10px", fontSize: isSmallThan1560 ? "14px" : "18px" }}>
                        {heading}
                    </Typography>
                    <Typography sx={{ textAlign: "center", fontSize: isSmallThan1560 ? "13px" : "15px" }}>
                        {text}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

const Intro = () => {
    const data = [
        {
            heading: "Logistics",
            icon: <LogisticsIcon />,
            text: "Damage Detection, Video Analysis, Invoice Reconcilliation, Alert Generation, Damage Detection Video Analysis, Pdf Chatbot, Multiligual ",
        },
        {
            heading: "IT",
            icon: <ITIcon />,
            text: "PDF Chat,  Compare & Contrast , Similarity Search, Agentic Workflow, Audio analysis, Multilingual chatbot",
        },
        {
            heading: "BFSI",
            icon: <BfsiIcon />,
            text: "Invoice Reconcilliation, Anomaly Detection, Claims, Insurance Document Analysis, Predective Modelling, Signature Verification, Claims Chatbot, Audio Analysis, Data Fabric and Lakehouse",
        },
        {
            heading: "FMCG",
            icon: <FMCGIcon />,
            text: "Compare & Contrast , Graph Reading,   Package Expiry, Damage Detection, Video Analysis, Recommendation Chatbot , Audio Analysis",
        },
        {
            heading: "ESG",
            icon: <ESGIcon />,
            text: "Excel Document Chat, Alert Generation, Compare & Contrast, PDF chat, Report Generation",
        },
        {
            heading: "Health & Insurance",
            icon: <HandIIcon />,
            text: "PDF Chat,  C&C, Similarity Search, Agentic Workflow, Medical Report Analysis, Alert generation in pathology",
        },
        {
            heading: "Supply chain",
            icon: <SupplyChainIcon />,
            text: "Compare & contrast documents package expiry detection Summarize documents Multiple PDF Ingestion Variance analysis report Excel report generation",
        },
    ];

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    width: "85%",
                    height: "90%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        height: "10%",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h4" sx={{ color: "#007BFF" }}>
                        Welcome to Softsensor X
                    </Typography>
                    <Typography variant="h6">Automate data processing from your businesses with AI</Typography>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        height: "90%",
                        mt: "5vh",
                        display: "flex",
                        gap: "3vw",
                        flexWrap: "wrap",
                        justifyContent: "center",
                    }}
                >
                    {data?.map((section: any) => {
                        return <Card icon={section.icon} heading={section.heading} text={section.text} />;
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default Intro;
