import React, { useState } from "react";
import { Box, Typography, Button, Container, Grid } from "@mui/material";
import { ArrowRightAlt as ArrowRightAltIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ChatHomeBoxIcon from "../../components/Common/svgLogs/ChatX/ChatHomeBoxIcon";
import HomeStepOneIcon from "../../components/Common/svgLogs/ChatX/HomeStepOneIcon";
import HomeStepTwoIcon from "../../components/Common/svgLogs/ChatX/HomeStepTwoIcon";
import HomeStepThreeIcon from "../../components/Common/svgLogs/ChatX/HomeStepThreeIcon";
import SSXIcon from "../../components/Common/svgLogs/ChatX/SSXIcon";
import BlueArrowIcon from "../../components/Common/svgLogs/ChatX/RightArrowIcon";

const ChatStart = () => {
    const navigate = useNavigate();
    const [started, setStarted] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    const steps = [
        {
            title: "Upload Your Data",
            description:
                "To begin, upload your data from various formats like PDFs, Excel, Google Sheets, Google Docs, MongoDB, YouTube links, and more. Just click Upload and choose your data source.",
            Icon: HomeStepOneIcon,
        },
        {
            title: "Select Your Data Source",
            description:
                "Once you've uploaded your data, you can select the specific data source you'd like to use for your chat. This allows our chatbot to understand the format and content of your data.",
            Icon: HomeStepTwoIcon,
        },
        {
            title: "Start Chatting",
            description:
                "Now that your data source is selected, you're ready to start chatting with our chatbot. Your conversations will be informed by the data you've provided, making it a powerful tool for accessing and analyzing your information.",
            Icon: HomeStepThreeIcon,
        },
    ];

    const handleGetStarted = () => {
        setStarted(true);
    };

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            navigate("/chat");
        }
    };

    const handleSkip = () => {
        navigate("/chat");
    };

    if (!started) {
        return (
            <Box
                sx={{
                    bgcolor: "white",
                    p: 4,
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                }}
            >
                <ChatHomeBoxIcon />
                <Typography variant="body1" sx={{ mb: 2, color: "#666" }}>
                    You Don't have any Chats Created
                </Typography>
                <Typography sx={{ mb: 2, fontSize: '34px' }}>
                    Start Chatting with
                    <span style={{ marginLeft: "8px", display: "inline-flex", alignItems: "center" }}>
                        <SSXIcon />
                    </span>
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowRightAltIcon sx={{ color: "white" }} />}
                    onClick={handleGetStarted}
                    sx={{ mt: 2, background: "#007AFF",borderRadius: '10px' }}
                >
                    Get Started
                </Button>
            </Box>
        );
    }

    const StepIcon = steps[currentStep].Icon;

    return (
        <Container maxWidth="lg" sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
            <Grid container spacing={4} alignItems="flex-start">
                <Grid item xs={12} md={6} sx={{ mt: { xs: 4, md: 0 } }}>
                    <Box sx={{ position: "relative", pl: 2 }}>
                        <Box
                            sx={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                bottom: 0,
                                width: 4,
                                bgcolor: "#e0e0e0",
                            }}
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                    width: 4,
                                    bgcolor: "#2196f3",
                                    height: `${((currentStep + 1) / steps.length) * 100}%`,
                                    transition: "height 0.3s ease-in-out",
                                }}
                            />
                        </Box>
                        <Typography variant="h6" color="#007AFF" gutterBottom sx={{ ml: 1 }}>
                            STEP {currentStep + 1}
                        </Typography>
                        <Typography variant="h4" color="#007AFF" gutterBottom sx={{ ml: 1 }}>
                            {steps[currentStep].title}
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ ml: 1 }}>
                            {steps[currentStep].description}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", mt: 4, ml: 1 }}>
                            {currentStep < steps.length - 1 && (
                                <Box 
                                    onClick={handleSkip}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        mb: 4,
                                        '&:hover': {
                                            '& .MuiTypography-root': {
                                                color: '#1E88E5',
                                            },
                                            '& .arrow-icon': {
                                                transform: 'translateX(5px)',
                                            },
                                        },
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: '#2196f3',
                                            mr: 1,
                                            transition: 'color 0.3s',
                                        }}
                                    >
                                        Skip & Start Chat
                                    </Typography>
                                    <ArrowRightAltIcon
                                        className="arrow-icon"
                                        sx={{
                                            color: '#2196f3',
                                            transition: 'transform 0.3s',
                                        }}
                                    />
                                </Box>
                            )}
                            <BlueArrowIcon 
                                size={50} 
                                handleNext={handleNext}
                                currentStep={currentStep}
                                steps={steps}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <StepIcon />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ChatStart;