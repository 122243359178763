import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import ESGSearchIcon from "../../components/Common/svgLogs/ESGSearchIcon";
import SendWhiteIcon from "../../components/Common/svgLogs/SendWhiteIcon";
import { Context } from "../../context/setting";
import { KeyboardVoice, Mic, PauseCircle, PlayArrow } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import axios from "axios";

var a: any;

const InputFinance = ({ setChats, chats }: any) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [query, setQuery] = useState<string>("");

    useEffect(() => {
        setContext({
            ...contextStore,
            income_statement_chats: chats,
        });
    }, [chats]);

    const handleSend = async () => {
        const user_query = { user_query: query };
        const msgOut = {
            self: true,
            msg: query,
            load: false,
        };
        const msgLoad = {
            self: false,
            msg: "",
            load: true,
        };
        setChats((prev: any) => [...prev, msgOut, msgLoad]);
        setQuery("");
        const apiUrl = "https://struct-api-ssx.softsensor.ai/structured_data/scenario_analysis_chat_bot";

        try {
            const response = await axios.post(apiUrl, { user_query: query });
            // console.log('Response:', response.data);
            // Do something with the response, like displaying it to the user
            if (response.data.chat_response) {
                const msgIn = {
                    self: false,
                    msg: response.data.chat_response,
                    load: false,
                };

                if (response?.data?.updated_df) {
                    setContext({
                        ...contextStore,
                        income_statement_table: [JSON.parse(response?.data?.updated_df)],
                    });
                }
                setTimeout(() => {
                    setChats((prev: any) => [
                        ...prev.filter((msg: any) => !(msg.self === false && msg.msg === "")),
                        msgIn,
                    ]);
                }, 1500);
            }
        } catch (error) {
            console.error("Error:", error);
            // Handle any errors that occur during the request
        }
    };

    // useEffect(() => {
    //         const ev = new EventSource(`https://struct-api-ssx.softsensor.ai/structured_data/scenario_analysis_chat_bot`);
    //         ev.addEventListener("message", (sse: any) => {
    //             console.log(sse?.data);
    //             // if (JSON.parse(sse?.data).progress === 100) {
    //             //     ev.close();
    //             // }
    //         });

    // });

    return (
        <OutlinedInput
            value={query}
            onChange={(e: { target: { value: string }; preventDefault: any }) => {
                setQuery(e.target.value);
            }}
            onKeyDown={(e: any) => {
                if (e?.key === "Enter") {
                    setQuery(e.target.value);
                    handleSend();
                }
            }}
            endAdornment={
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {/* {contextStore?.activeSource === "excel" && (
                        <IconButton onClick={() => handleSend(query, "sql")}>
                            <ESGSearchIcon />
                        </IconButton>
                    )} */}
                    <IconButton
                        sx={{ background: "#007BFF", "&:hover": { background: "#007BFFaa" } }}
                        onClick={() => {
                            handleSend();
                        }}
                    >
                        <SendWhiteIcon />
                    </IconButton>
                </Box>
            }
            sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "80px",
                    border: "1px solid #E0E0E0",
                },
                "& .MuiOutlinedInput-input": {
                    padding: "10px 20px",
                },
                width: "70%",
            }}
            placeholder="Ask Me Anything"
        />
    );
};

export default InputFinance;
