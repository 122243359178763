const Car12 = () => {
    return (
        <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="140" height="140" transform="matrix(-1 0 0 1 140 0)" fill="white" />
            <path
                d="M86.575 7.99965C97.6709 10.9728 107.266 16.7521 114.8 24.3694L70.0108 69.8179L86.575 7.99965Z"
                fill="#BEDFF1"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M57.6749 76.2178C57.593 79.5449 56.6815 82.6373 54.5286 86.3909V77.5401L57.6749 76.2178ZM54.5297 62.327C56.5591 65.9639 57.722 69.6512 57.786 74.3006L54.5297 75.6745V62.327ZM53.6059 57.9021C53.1266 57.9201 52.7404 58.2939 52.7404 58.7496V89.9279C52.7281 90.7765 53.891 91.1143 54.3826 90.4027C58.9949 83.8608 59.5865 80.142 59.6168 74.2377C59.6449 68.3334 58.1542 63.399 54.3725 58.2591C54.1952 58.0245 53.9101 57.8898 53.6059 57.9021Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M57.2434 33.8155V36.1312C57.2434 37.0842 56.8393 38.4222 54.8637 39.8264V36.6105C54.8637 35.873 55.9335 34.1668 57.2434 33.8155ZM58.2772 30.6467C58.1201 30.6523 57.9618 30.6927 57.8204 30.7634C54.6336 32.4067 52.7411 34.535 52.7411 36.9248V41.7021C52.7411 42.4788 53.5538 42.9997 54.2553 42.6596C54.875 42.3677 55.453 42.0635 55.9615 41.7178C58.6274 39.9229 59.3593 37.6903 59.3593 36.1289V31.7029C59.366 31.1136 58.8733 30.6355 58.2772 30.6467Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M82.6383 33.8155V36.1312C82.6383 37.0842 83.0424 38.4222 85.018 39.8264V36.6105C85.018 35.873 83.9483 34.1668 82.6383 33.8155ZM81.6045 30.6467C81.7617 30.6523 81.9199 30.6927 82.0614 30.7634C85.2481 32.4067 87.1406 34.535 87.1406 36.9248V41.7021C87.1406 42.4788 86.3279 42.9997 85.6264 42.6596C85.0068 42.3677 84.4287 42.0635 83.9202 41.7178C81.2543 39.9229 80.5168 37.6903 80.5168 36.1289V31.7029C80.5157 31.1136 81.0085 30.6355 81.6045 30.6467Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M59.1733 107.246C58.612 107.251 57.8925 107.035 57.2381 106.596C56.5646 106.151 56.0853 105.578 55.8698 105.105C55.6532 104.637 55.6891 104.374 55.7699 104.252C55.8519 104.13 56.0674 103.988 56.5826 104.006C57.0978 104.016 57.805 104.234 58.4774 104.678C59.1553 105.123 59.6346 105.697 59.8569 106.169C60.0724 106.636 60.0264 106.9 59.9433 107.022C59.8793 107.118 59.7985 107.234 59.1733 107.246ZM59.207 109.368C60.2531 109.35 61.2129 108.952 61.7213 108.182C62.305 107.287 62.1939 106.183 61.7797 105.276C61.3588 104.364 60.6213 103.546 59.6571 102.902C58.6861 102.265 57.64 101.914 56.6398 101.886C55.6397 101.855 54.5823 102.19 53.9986 103.089C53.4071 103.99 53.5182 105.088 53.9403 105.995C54.3612 106.901 55.0975 107.725 56.0674 108.362C57.0204 108.994 58.1024 109.385 59.207 109.368Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M69.9256 26.5492C64.5702 26.5492 59.7525 27.7828 56.3794 29.6877C53.0018 31.588 51.1711 34.044 51.1711 36.5629V50.2561C51.1711 50.7051 50.932 51.1272 50.5335 51.3551L47.9843 52.8278C47.4635 53.1275 47.2356 53.8795 47.459 54.2724C47.7273 54.7293 48.2122 54.8112 49.0361 54.5059L49.4346 54.3543C50.2697 54.0266 51.1711 54.6406 51.1711 55.5408V103.339C51.1711 105.01 51.7963 107.338 54.3982 109.355C57.0013 111.371 61.7067 113.096 69.9256 113.096C78.1411 113.096 82.8522 111.371 85.453 109.355C88.056 107.338 88.6757 105.01 88.6757 103.339V55.5408C88.6757 54.6395 89.5815 54.0266 90.4178 54.3543L90.814 54.5059C91.6323 54.8112 92.1239 54.7293 92.3866 54.2724C92.61 53.8807 92.381 53.1275 91.8658 52.8278L89.311 51.3551C88.9204 51.1272 88.6745 50.7051 88.6745 50.2561V36.5629C88.6745 34.044 86.8505 31.588 83.4707 29.6877C80.092 27.7817 75.281 26.5492 69.9256 26.5492ZM69.9256 24C75.6671 24 80.8586 25.2853 84.7234 27.4662C88.5881 29.6405 91.2304 32.8329 91.2304 36.5629V49.5119L93.1297 50.6243C94.8011 51.5885 95.3567 53.5473 94.7786 55.1423C94.4913 55.9371 93.8492 56.6813 92.9478 57.0315C92.445 57.2245 91.813 56.9956 91.2293 56.9372V103.339C91.2293 105.69 90.2292 108.876 87.0189 111.371C83.8041 113.863 78.4958 115.64 69.9245 115.64C61.3487 115.64 56.046 113.863 52.8301 111.371C49.6153 108.876 48.6219 105.69 48.6219 103.339V56.9372C48.0303 56.9956 47.4051 57.2245 46.8977 57.0315C45.9964 56.6813 45.3532 55.9371 45.067 55.1423C44.4878 53.5473 45.049 51.5874 46.7148 50.6243L48.6219 49.5119V36.5629C48.6219 32.8329 51.2653 29.6405 55.1233 27.4662C58.9881 25.2853 64.1841 24 69.9256 24Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M69.9266 49.5822C65.8408 49.5946 59.8882 50.9797 57.0236 53.2067L61.6662 61.9352C64.8103 61.4267 67.9847 61.2224 69.9199 61.2224C71.8551 61.2224 75.0295 61.4256 78.1758 61.9352L82.8173 53.2067C79.9527 50.9797 74.0147 49.6002 69.9266 49.5822ZM85.0106 52.0135C85.3507 52.3413 85.4327 52.8498 85.216 53.2651L79.7439 63.5482C79.5205 63.9647 79.0536 64.1858 78.5911 64.0994C75.3303 63.4898 71.9449 63.345 69.921 63.345C67.8927 63.345 64.5072 63.4898 61.2453 64.0994C60.7839 64.1858 60.3159 63.9647 60.1003 63.5482L54.6282 53.2651C54.4048 52.8509 54.4935 52.3413 54.8314 52.0135C58.3336 48.664 65.1729 47.4775 69.921 47.4775C74.668 47.4775 81.5141 48.664 85.0106 52.0135Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M83.3088 104.007C82.742 103.995 82.0213 104.217 81.368 104.649C80.6957 105.094 80.222 105.673 79.9997 106.14C79.7831 106.615 79.819 106.872 79.8998 106.994C79.9818 107.116 80.1995 107.255 80.7125 107.245C81.2277 107.227 81.9416 107.016 82.6129 106.572C83.2853 106.129 83.7702 105.556 83.9868 105.081C84.2023 104.609 84.1563 104.35 84.0732 104.228C84.0149 104.129 83.9284 104.013 83.3088 104.007ZM83.3369 101.884C84.383 101.894 85.3428 102.293 85.8513 103.065C86.4361 103.964 86.3306 105.071 85.9096 105.976C85.4887 106.882 84.7568 107.706 83.787 108.343C82.8172 108.986 81.7699 109.337 80.7698 109.367C79.7696 109.395 78.7122 109.063 78.1285 108.156C77.537 107.254 77.6481 106.162 78.0702 105.256C78.4911 104.344 79.2275 103.524 80.1984 102.882C81.1503 102.259 82.2324 101.866 83.3369 101.884Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M78.7621 86.7373C75.7448 87.3738 72.8567 87.8542 69.9337 87.8542C67.0107 87.8542 64.1113 87.3738 61.1008 86.7373L56.9431 94.4073C57.5459 95.5399 59.2061 96.5703 61.7025 97.1596C64.3639 97.7849 67.5439 97.9903 69.9281 97.9903C72.3078 97.9903 75.4878 97.7849 78.1481 97.1596C80.6445 96.5703 82.3181 95.5399 82.9198 94.4073L78.7621 86.7373ZM79.3334 84.5327C79.7431 84.5204 80.118 84.7438 80.3111 85.094L85.1816 94.0975C85.3208 94.359 85.3443 94.6812 85.24 94.9629C84.3206 97.4054 81.5683 98.5335 78.6442 99.2239C75.7156 99.9142 72.4301 100.107 69.9281 100.107C67.4261 100.107 64.1405 99.9142 61.212 99.2239C58.2834 98.5335 55.5288 97.4054 54.6173 94.9629C54.5107 94.6756 54.5354 94.3601 54.6813 94.0975L59.5518 85.094C59.7797 84.6731 60.2646 84.4508 60.7327 84.5563C63.9598 85.2803 66.9344 85.7316 69.9157 85.7652C72.9094 85.7304 75.8795 85.2859 79.119 84.5563C79.1886 84.544 79.2649 84.5327 79.3334 84.5327Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M82.2061 76.2178C82.288 79.5449 83.1995 82.6373 85.3524 86.3909V77.5401L82.2061 76.2178ZM85.3513 62.327C83.3218 65.9639 82.1589 69.6512 82.0949 74.3006L85.3513 75.6745V62.327ZM86.2751 57.9021C86.7544 57.9201 87.1405 58.2939 87.1405 58.7496V89.9279C87.1529 90.7765 85.99 91.1143 85.4983 90.4027C80.8838 83.8608 80.2945 80.142 80.2642 74.2377C80.2361 68.3334 81.7268 63.399 85.5084 58.2591C85.6858 58.0245 85.9709 57.8898 86.2751 57.9021Z"
                fill="black"
            />
            <circle cx="70" cy="70" r="61" stroke="#3386D3" stroke-opacity="0.35" stroke-width="6" />
        </svg>
    );
};

export default Car12;
