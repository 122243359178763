import React from "react";

const Test = () => {
    const jsontest = [
        {
            ABC: "Plan",
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: "Fiscal Year Ends December 31",
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: "(All values in \u00a3000s)",
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Revenue Growth",
            "Unnamed: 2": null,
            "Unnamed: 3": 0.0028566026,
            "Unnamed: 4": 0.1064994623,
            "Unnamed: 5": -0.0478794372,
            "Unnamed: 6": -0.0014781974,
            "Unnamed: 7": 0.1046505668,
            "Unnamed: 8": -0.0882880756,
            "Unnamed: 9": 0.0293789419,
            "Unnamed: 10": -0.0445934954,
            "Unnamed: 11": -0.0087885725,
            "Unnamed: 12": -0.0466481513,
            "Unnamed: 13": 0.0526562303,
            "Unnamed: 14": null,
            "Unnamed: 15": 5,
            "Unnamed: 16": 0.0198764671,
            "Unnamed: 17": 0.05,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Gross Profit Margin",
            "Unnamed: 2": null,
            "Unnamed: 3": 1,
            "Unnamed: 4": 1,
            "Unnamed: 5": 1,
            "Unnamed: 6": 1,
            "Unnamed: 7": 1,
            "Unnamed: 8": 1,
            "Unnamed: 9": 1,
            "Unnamed: 10": 1,
            "Unnamed: 11": 1,
            "Unnamed: 12": 1,
            "Unnamed: 13": 1,
            "Unnamed: 14": null,
            "Unnamed: 15": 1,
            "Unnamed: 16": 1,
            "Unnamed: 17": 0.9,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Payroll Growth",
            "Unnamed: 2": null,
            "Unnamed: 3": -0.2877432092,
            "Unnamed: 4": 0.3458357521,
            "Unnamed: 5": -0.1040713381,
            "Unnamed: 6": 0.1169610264,
            "Unnamed: 7": 0.1659497125,
            "Unnamed: 8": -0.1555601887,
            "Unnamed: 9": 0.0389468251,
            "Unnamed: 10": -0.0578725271,
            "Unnamed: 11": 0.0606749614,
            "Unnamed: 12": -0.0360418635,
            "Unnamed: 13": -0.6802785602,
            "Unnamed: 14": null,
            "Unnamed: 15": 3.4348823689,
            "Unnamed: 16": -0.2655777168,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Other Opex",
            "Unnamed: 2": null,
            "Unnamed: 3": 0.019488235,
            "Unnamed: 4": -0.0692840945,
            "Unnamed: 5": 0.0296814828,
            "Unnamed: 6": -0.0088139503,
            "Unnamed: 7": 0.6001044736,
            "Unnamed: 8": -0.3059556162,
            "Unnamed: 9": 0.0026782061,
            "Unnamed: 10": -0.0031588133,
            "Unnamed: 11": 0.0227901216,
            "Unnamed: 12": -0.0568895938,
            "Unnamed: 13": -0.073337981,
            "Unnamed: 14": null,
            "Unnamed: 15": 0.9144508563,
            "Unnamed: 16": -0.8484216349,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Operating Profit Margin",
            "Unnamed: 2": null,
            "Unnamed: 3": -0.6057136976,
            "Unnamed: 4": -0.5417537398,
            "Unnamed: 5": -0.5802985033,
            "Unnamed: 6": -0.6421346362,
            "Unnamed: 7": -1.1223100937,
            "Unnamed: 8": -0.7291819086,
            "Unnamed: 9": -0.7067956034,
            "Unnamed: 10": -0.7439613855,
            "Unnamed: 11": -0.8237775491,
            "Unnamed: 12": -0.8190359267,
            "Unnamed: 13": -0.2054011864,
            "Unnamed: 14": null,
            "Unnamed: 15": -1.9434493185,
            "Unnamed: 16": 0.0134097397,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Net Profit Margin",
            "Unnamed: 2": null,
            "Unnamed: 3": -0.8677305914,
            "Unnamed: 4": -0.8174925828,
            "Unnamed: 5": -0.9277377764,
            "Unnamed: 6": -0.9546638595,
            "Unnamed: 7": -1.3886450044,
            "Unnamed: 8": -1.0460575573,
            "Unnamed: 9": -1.0253703485,
            "Unnamed: 10": -1.0873226863,
            "Unnamed: 11": -1.1629529349,
            "Unnamed: 12": -1.161216394,
            "Unnamed: 13": -0.4682068216,
            "Unnamed: 14": null,
            "Unnamed: 15": -2.3174602977,
            "Unnamed: 16": -0.3326061893,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": "Actual",
            "Unnamed: 3": "Actual",
            "Unnamed: 4": "Actual",
            "Unnamed: 5": "Actual",
            "Unnamed: 6": "Actual",
            "Unnamed: 7": "Actual",
            "Unnamed: 8": "Actual",
            "Unnamed: 9": "Actual",
            "Unnamed: 10": "Actual",
            "Unnamed: 11": "Actual",
            "Unnamed: 12": "Actual",
            "Unnamed: 13": "Actual",
            "Unnamed: 14": "Actual",
            "Unnamed: 15": "Actual",
            "Unnamed: 16": "Actual",
            "Unnamed: 17": "Budget",
            "Unnamed: 18": "Budget",
            "Unnamed: 19": "Budget",
            "Unnamed: 20": "Budget",
            "Unnamed: 21": "Budget",
            "Unnamed: 22": "Budget",
            "Unnamed: 23": "Budget",
            "Unnamed: 24": "Budget",
            "Unnamed: 25": "Budget",
            "Unnamed: 26": "Budget",
            "Unnamed: 27": "Budget",
        },
        {
            ABC: null,
            "Unnamed: 1": "Income Statement",
            "Unnamed: 2": 1675123200000,
            "Unnamed: 3": 1677542400000,
            "Unnamed: 4": 1680220800000,
            "Unnamed: 5": 1682812800000,
            "Unnamed: 6": 1685491200000,
            "Unnamed: 7": 1688083200000,
            "Unnamed: 8": 1690761600000,
            "Unnamed: 9": 1693440000000,
            "Unnamed: 10": 1696032000000,
            "Unnamed: 11": 1698710400000,
            "Unnamed: 12": 1701302400000,
            "Unnamed: 13": 1703980800000,
            "Unnamed: 14": "FY ' 2023",
            "Unnamed: 15": 1706659200000,
            "Unnamed: 16": 1709164800000,
            "Unnamed: 17": 1711843200000,
            "Unnamed: 18": 1714435200000,
            "Unnamed: 19": 1717113600000,
            "Unnamed: 20": 1719705600000,
            "Unnamed: 21": 1722384000000,
            "Unnamed: 22": 1725062400000,
            "Unnamed: 23": 1727654400000,
            "Unnamed: 24": 1730332800000,
            "Unnamed: 25": 1732924800000,
            "Unnamed: 26": 1735603200000,
            "Unnamed: 27": "FY ' 2024",
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Revenue",
            "Unnamed: 2": 6239285.71,
            "Unnamed: 3": 6257108.8699999992,
            "Unnamed: 4": 6923487.5999999987,
            "Unnamed: 5": 6591994.9100000039,
            "Unnamed: 6": 6582250.6399999987,
            "Unnamed: 7": 7271086.9000000013,
            "Unnamed: 8": 6629136.6299999962,
            "Unnamed: 9": 6823893.6500000013,
            "Unnamed: 10": 6519592.379999999,
            "Unnamed: 11": 6462294.4699999951,
            "Unnamed: 12": 6160840.3800000027,
            "Unnamed: 13": 6485247.0099999895,
            "Unnamed: 14": 78946219.1499999762,
            "Unnamed: 15": 6242630.5100000007,
            "Unnamed: 16": 6366711.9500000002,
            "Unnamed: 17": 6685047.5475000003,
            "Unnamed: 18": 6745762.1928258007,
            "Unnamed: 19": 6997867.2906472962,
            "Unnamed: 20": 7032669.5141444057,
            "Unnamed: 21": 7199512.4471008219,
            "Unnamed: 22": 7371026.3773562638,
            "Unnamed: 23": 7391424.8610946294,
            "Unnamed: 24": 7586522.8910347223,
            "Unnamed: 25": 7514448.9070698982,
            "Unnamed: 26": 7763963.5778986197,
            "Unnamed: 27": 84897588.0666724443,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Cost of Goods Sold",
            "Unnamed: 2": 0,
            "Unnamed: 3": 0,
            "Unnamed: 4": 0,
            "Unnamed: 5": 0,
            "Unnamed: 6": 0,
            "Unnamed: 7": 0,
            "Unnamed: 8": 0,
            "Unnamed: 9": 0,
            "Unnamed: 10": 0,
            "Unnamed: 11": 0,
            "Unnamed: 12": 0,
            "Unnamed: 13": 0,
            "Unnamed: 14": 0,
            "Unnamed: 15": 0,
            "Unnamed: 16": 0,
            "Unnamed: 17": -668504.7547500003,
            "Unnamed: 18": -971197.4579597071,
            "Unnamed: 19": -985773.3944208389,
            "Unnamed: 20": -992010.1138460275,
            "Unnamed: 21": -1001985.990102501,
            "Unnamed: 22": -1009152.1981060078,
            "Unnamed: 23": -1028203.8083118582,
            "Unnamed: 24": -1054838.1978471912,
            "Unnamed: 25": -1074314.2357247924,
            "Unnamed: 26": -1065685.5302204764,
            "Unnamed: 27": -9851665.6812894009,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Gross Profit",
            "Unnamed: 2": 6239285.71,
            "Unnamed: 3": 6257108.8699999992,
            "Unnamed: 4": 6923487.5999999987,
            "Unnamed: 5": 6591994.9100000039,
            "Unnamed: 6": 6582250.6399999987,
            "Unnamed: 7": 7271086.9000000013,
            "Unnamed: 8": 6629136.6299999962,
            "Unnamed: 9": 6823893.6500000013,
            "Unnamed: 10": 6519592.379999999,
            "Unnamed: 11": 6462294.4699999951,
            "Unnamed: 12": 6160840.3800000027,
            "Unnamed: 13": 6485247.0099999895,
            "Unnamed: 14": 78946219.1499999762,
            "Unnamed: 15": 6242630.5100000007,
            "Unnamed: 16": 6366711.9500000002,
            "Unnamed: 17": 6016542.79275,
            "Unnamed: 18": 5774564.7348660929,
            "Unnamed: 19": 6012093.8962264573,
            "Unnamed: 20": 6040659.4002983784,
            "Unnamed: 21": 6197526.4569983203,
            "Unnamed: 22": 6361874.1792502552,
            "Unnamed: 23": 6363221.0527827721,
            "Unnamed: 24": 6531684.6931875311,
            "Unnamed: 25": 6440134.6713451054,
            "Unnamed: 26": 6698278.0476781428,
            "Unnamed: 27": 75045922.3853830546,
        },
        {
            ABC: null,
            "Unnamed: 1": "Gross Margin (%)",
            "Unnamed: 2": 1,
            "Unnamed: 3": 1,
            "Unnamed: 4": 1,
            "Unnamed: 5": 1,
            "Unnamed: 6": 1,
            "Unnamed: 7": 1,
            "Unnamed: 8": 1,
            "Unnamed: 9": 1,
            "Unnamed: 10": 1,
            "Unnamed: 11": 1,
            "Unnamed: 12": 1,
            "Unnamed: 13": 1,
            "Unnamed: 14": 1,
            "Unnamed: 15": 1,
            "Unnamed: 16": 1,
            "Unnamed: 17": 0.9,
            "Unnamed: 18": 0.8560285065,
            "Unnamed: 19": 0.8591323108,
            "Unnamed: 20": 0.8589425947,
            "Unnamed: 21": 0.8608258549,
            "Unnamed: 22": 0.8630920381,
            "Unnamed: 23": 0.8608923411,
            "Unnamed: 24": 0.8609589382,
            "Unnamed: 25": 0.8570335298,
            "Unnamed: 26": 0.862739499,
            "Unnamed: 27": 0.8839582383,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Payroll",
            "Unnamed: 2": -4475546.4899999993,
            "Unnamed: 3": -3187738.3800000004,
            "Unnamed: 4": -4290172.2800000003,
            "Unnamed: 5": -3843688.3099999996,
            "Unnamed: 6": -4293250.040000001,
            "Unnamed: 7": -5005713.6499999985,
            "Unnamed: 8": -4227023.8899999987,
            "Unnamed: 9": -4391653.0500000026,
            "Unnamed: 10": -4137496.9899999993,
            "Unnamed: 11": -4388539.4600000009,
            "Unnamed: 12": -4230368.3199999984,
            "Unnamed: 13": -1352539.4499999955,
            "Unnamed: 14": -47823730.3100000024,
            "Unnamed: 15": -5998353.3600000003,
            "Unnamed: 16": -4405324.3699999992,
            "Unnamed: 17": -5260744.1484502507,
            "Unnamed: 18": -3623706.9835583866,
            "Unnamed: 19": -3606790.2201956701,
            "Unnamed: 20": -3612848.5412256708,
            "Unnamed: 21": -3590813.1499768109,
            "Unnamed: 22": -4087610.4109768113,
            "Unnamed: 23": -3631354.5776434774,
            "Unnamed: 24": -3621359.5776434774,
            "Unnamed: 25": -3639663.7443101443,
            "Unnamed: 26": -3653073.7443101443,
            "Unnamed: 27": -48731642.828290835,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Other Opex",
            "Unnamed: 2": -6728265.0299999993,
            "Unnamed: 3": -6859387.0399999991,
            "Unnamed: 4": -6384140.620000001,
            "Unnamed: 5": -6573631.3799999962,
            "Unnamed: 6": -6515691.7200000025,
            "Unnamed: 7": -10425787.4699999988,
            "Unnamed: 8": -7235959.2399999993,
            "Unnamed: 9": -7255338.6299999999,
            "Unnamed: 10": -7232420.3700000048,
            "Unnamed: 11": -7397248.1099999957,
            "Unnamed: 12": -6976421.6700000037,
            "Unnamed: 13": -6464784.9899999946,
            "Unnamed: 14": -86049076.2699999958,
            "Unnamed: 15": -12376513.1599999983,
            "Unnamed: 16": -1876011.6300000013,
            "Unnamed: 17": -8105706.8772162097,
            "Unnamed: 18": -7997547.7355950559,
            "Unnamed: 19": -7707404.8977125986,
            "Unnamed: 20": -6963514.3206553496,
            "Unnamed: 21": -7001360.4129701648,
            "Unnamed: 22": -7005155.4736136347,
            "Unnamed: 23": -7059697.8153450545,
            "Unnamed: 24": -7058985.3872409035,
            "Unnamed: 25": -6902538.9004991585,
            "Unnamed: 26": -7171239.6351653682,
            "Unnamed: 27": -87225676.2460134923,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Operating Expenses",
            "Unnamed: 2": -11203811.5199999996,
            "Unnamed: 3": -10047125.4199999999,
            "Unnamed: 4": -10674312.9000000022,
            "Unnamed: 5": -10417319.6899999958,
            "Unnamed: 6": -10808941.7600000035,
            "Unnamed: 7": -15431501.1199999973,
            "Unnamed: 8": -11462983.129999999,
            "Unnamed: 9": -11646991.6800000034,
            "Unnamed: 10": -11369917.3600000031,
            "Unnamed: 11": -11785787.5699999966,
            "Unnamed: 12": -11206789.9900000021,
            "Unnamed: 13": -7817324.4399999902,
            "Unnamed: 14": -133872806.5799999833,
            "Unnamed: 15": -18374866.5199999996,
            "Unnamed: 16": -6281336,
            "Unnamed: 17": -13366451.0256664604,
            "Unnamed: 18": -11621254.7191534415,
            "Unnamed: 19": -11314195.1179082692,
            "Unnamed: 20": -10576362.8618810214,
            "Unnamed: 21": -10592173.5629469752,
            "Unnamed: 22": -11092765.8845904469,
            "Unnamed: 23": -10691052.3929885328,
            "Unnamed: 24": -10680344.9648843817,
            "Unnamed: 25": -10542202.6448093019,
            "Unnamed: 26": -10824313.3794755116,
            "Unnamed: 27": -135957319.0743043423,
        },
        {
            ABC: null,
            "Unnamed: 1": "Operating Profit",
            "Unnamed: 2": -4964525.8099999996,
            "Unnamed: 3": -3790016.5500000007,
            "Unnamed: 4": -3750825.3000000035,
            "Unnamed: 5": -3825324.7799999919,
            "Unnamed: 6": -4226691.1200000048,
            "Unnamed: 7": -8160414.219999996,
            "Unnamed: 8": -4833846.5000000028,
            "Unnamed: 9": -4823098.0300000021,
            "Unnamed: 10": -4850324.9800000042,
            "Unnamed: 11": -5323493.1000000015,
            "Unnamed: 12": -5045949.6099999994,
            "Unnamed: 13": -1332077.4300000006,
            "Unnamed: 14": -54926587.4300000072,
            "Unnamed: 15": -12132236.0099999979,
            "Unnamed: 16": 85375.9500000002,
            "Unnamed: 17": -7530120.9701877395,
            "Unnamed: 18": -5846689.9842873486,
            "Unnamed: 19": -5302101.2216818118,
            "Unnamed: 20": -4535703.461582643,
            "Unnamed: 21": -4394647.1059486549,
            "Unnamed: 22": -4730891.7053401917,
            "Unnamed: 23": -4327831.3402057607,
            "Unnamed: 24": -4148660.2716968507,
            "Unnamed: 25": -4102067.9734641965,
            "Unnamed: 26": -4126035.3317973688,
            "Unnamed: 27": -61091609.4261925668,
        },
        {
            ABC: null,
            "Unnamed: 1": "Operating Profit Margin (%)",
            "Unnamed: 2": -0.795688167,
            "Unnamed: 3": -0.6057136976,
            "Unnamed: 4": -0.5417537398,
            "Unnamed: 5": -0.5802985033,
            "Unnamed: 6": -0.6421346362,
            "Unnamed: 7": -1.1223100937,
            "Unnamed: 8": -0.7291819086,
            "Unnamed: 9": -0.7067956034,
            "Unnamed: 10": -0.7439613855,
            "Unnamed: 11": -0.8237775491,
            "Unnamed: 12": -0.8190359267,
            "Unnamed: 13": -0.2054011864,
            "Unnamed: 14": -0.6957469024,
            "Unnamed: 15": -1.9434493185,
            "Unnamed: 16": 0.0134097397,
            "Unnamed: 17": -1.107378824,
            "Unnamed: 18": -0.8667204412,
            "Unnamed: 19": -0.7576738743,
            "Unnamed: 20": -0.6449476195,
            "Unnamed: 21": -0.6104089879,
            "Unnamed: 22": -0.6418226531,
            "Unnamed: 23": -0.5855205757,
            "Unnamed: 24": -0.5468460758,
            "Unnamed: 25": -0.5458907265,
            "Unnamed: 26": -0.5314341432,
            "Unnamed: 27": -0.7195918143,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Non-Operating Income/ (Expense)",
            "Unnamed: 2": -1849107.02,
            "Unnamed: 3": -1627561.2000000002,
            "Unnamed: 4": -1897074.4600000002,
            "Unnamed: 5": -2265467.9200000009,
            "Unnamed: 6": -2044263.1799999992,
            "Unnamed: 7": -1923744.2800000003,
            "Unnamed: 8": -2087754.9699999979,
            "Unnamed: 9": -2161920.1800000016,
            "Unnamed: 10": -2226875.7200000007,
            "Unnamed: 11": -2179851.2200000007,
            "Unnamed: 12": -2096119.2400000002,
            "Unnamed: 13": -1795568.2899999991,
            "Unnamed: 14": -24155307.6800000072,
            "Unnamed: 15": -2327729.02,
            "Unnamed: 16": -2195900.4199999999,
            "Unnamed: 17": -2256266.95559743,
            "Unnamed: 18": -2265882.9379437501,
            "Unnamed: 19": -2332870.7025418747,
            "Unnamed: 20": -2261241.9749691663,
            "Unnamed: 21": -2286936.4369820273,
            "Unnamed: 22": -2286936.4369820273,
            "Unnamed: 23": -2216890.9026195835,
            "Unnamed: 24": -2241211.8196860692,
            "Unnamed: 25": -2177180.7932445831,
            "Unnamed: 26": -2236625.8062095554,
            "Unnamed: 27": -27085674.2067760713,
        },
        {
            ABC: null,
            "Unnamed: 1": "Profit before Taxes (PBT)",
            "Unnamed: 2": -6813632.8300000001,
            "Unnamed: 3": -5417577.7500000009,
            "Unnamed: 4": -5647899.7600000035,
            "Unnamed: 5": -6090792.6999999927,
            "Unnamed: 6": -6270954.3000000045,
            "Unnamed: 7": -10084158.4999999963,
            "Unnamed: 8": -6921601.4700000007,
            "Unnamed: 9": -6985018.2100000037,
            "Unnamed: 10": -7077200.7000000048,
            "Unnamed: 11": -7503344.3200000022,
            "Unnamed: 12": -7142068.8499999996,
            "Unnamed: 13": -3127645.7199999997,
            "Unnamed: 14": -79081895.1099999994,
            "Unnamed: 15": -14459965.0299999975,
            "Unnamed: 16": -2110524.4699999997,
            "Unnamed: 17": -9786387.925785169,
            "Unnamed: 18": -8112572.9222310986,
            "Unnamed: 19": -7634971.9242236866,
            "Unnamed: 20": -6796945.4365518093,
            "Unnamed: 21": -6681583.5429306822,
            "Unnamed: 22": -7017828.142322219,
            "Unnamed: 23": -6544722.2428253442,
            "Unnamed: 24": -6389872.0913829198,
            "Unnamed: 25": -6279248.7667087801,
            "Unnamed: 26": -6362661.1380069237,
            "Unnamed: 27": -88177283.6329686195,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Less: Tax Expense",
            "Unnamed: 2": -12000,
            "Unnamed: 3": -11907.03,
            "Unnamed: 4": -12000,
            "Unnamed: 5": -24850,
            "Unnamed: 6": -12882.5,
            "Unnamed: 7": -12800,
            "Unnamed: 8": -12857,
            "Unnamed: 9": -12000,
            "Unnamed: 10": -11700,
            "Unnamed: 11": -12000,
            "Unnamed: 12": -12000,
            "Unnamed: 13": 91208.83,
            "Unnamed: 14": -55787.7,
            "Unnamed: 15": -7083.33,
            "Unnamed: 16": -7083.33,
            "Unnamed: 17": -12000,
            "Unnamed: 18": -14600,
            "Unnamed: 19": -12000,
            "Unnamed: 20": -12000,
            "Unnamed: 21": -12000,
            "Unnamed: 22": -12000,
            "Unnamed: 23": -12000,
            "Unnamed: 24": -12000,
            "Unnamed: 25": -12000,
            "Unnamed: 26": -12000,
            "Unnamed: 27": -136766.66,
        },
        {
            ABC: null,
            "Unnamed: 1": "Net Income/ (Loss)",
            "Unnamed: 2": -6825632.8300000001,
            "Unnamed: 3": -5429484.7800000012,
            "Unnamed: 4": -5659899.7600000035,
            "Unnamed: 5": -6115642.6999999927,
            "Unnamed: 6": -6283836.8000000045,
            "Unnamed: 7": -10096958.4999999963,
            "Unnamed: 8": -6934458.4700000007,
            "Unnamed: 9": -6997018.2100000037,
            "Unnamed: 10": -7088900.7000000048,
            "Unnamed: 11": -7515344.3200000022,
            "Unnamed: 12": -7154068.8499999996,
            "Unnamed: 13": -3036436.8899999997,
            "Unnamed: 14": -79137682.8100000024,
            "Unnamed: 15": -14467048.3599999975,
            "Unnamed: 16": -2117607.7999999998,
            "Unnamed: 17": -9798387.925785169,
            "Unnamed: 18": -8127172.9222310986,
            "Unnamed: 19": -7646971.9242236866,
            "Unnamed: 20": -6808945.4365518093,
            "Unnamed: 21": -6693583.5429306822,
            "Unnamed: 22": -7029828.142322219,
            "Unnamed: 23": -6556722.2428253442,
            "Unnamed: 24": -6401872.0913829198,
            "Unnamed: 25": -6291248.7667087801,
            "Unnamed: 26": -6374661.1380069237,
            "Unnamed: 27": -88314050.2929686308,
        },
        {
            ABC: null,
            "Unnamed: 1": "Net Income/ (Loss) Margin (%)",
            "Unnamed: 2": -1.0939766421,
            "Unnamed: 3": -0.8677305914,
            "Unnamed: 4": -0.8174925828,
            "Unnamed: 5": -0.9277377764,
            "Unnamed: 6": -0.9546638595,
            "Unnamed: 7": -1.3886450044,
            "Unnamed: 8": -1.0460575573,
            "Unnamed: 9": -1.0253703485,
            "Unnamed: 10": -1.0873226863,
            "Unnamed: 11": -1.1629529349,
            "Unnamed: 12": -1.161216394,
            "Unnamed: 13": -0.4682068216,
            "Unnamed: 14": -1.0024252417,
            "Unnamed: 15": -2.3174602977,
            "Unnamed: 16": -0.3326061893,
            "Unnamed: 17": -1.4409499317,
            "Unnamed: 18": -1.2047820083,
            "Unnamed: 19": -1.0927574941,
            "Unnamed: 20": -0.968187887,
            "Unnamed: 21": -0.9297273381,
            "Unnamed: 22": -0.9537108921,
            "Unnamed: 23": -0.887071487,
            "Unnamed: 24": -0.8438479898,
            "Unnamed: 25": -0.8372202466,
            "Unnamed: 26": -0.8210575789,
            "Unnamed: 27": -1.0402421589,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Cash & Cash Equivalents",
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": 20000000,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Common Equity",
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": 156000000,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Long-Term Debt",
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": 156000000,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Property, Plant & Equipment",
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": 20000000,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Balance Sheet",
            "Unnamed: 2": 1675123200000,
            "Unnamed: 3": 1677542400000,
            "Unnamed: 4": 1680220800000,
            "Unnamed: 5": 1682812800000,
            "Unnamed: 6": 1685491200000,
            "Unnamed: 7": 1688083200000,
            "Unnamed: 8": 1690761600000,
            "Unnamed: 9": 1693440000000,
            "Unnamed: 10": 1696032000000,
            "Unnamed: 11": 1698710400000,
            "Unnamed: 12": 1701302400000,
            "Unnamed: 13": 1703980800000,
            "Unnamed: 14": "FY ' 2023",
            "Unnamed: 15": 1706659200000,
            "Unnamed: 16": 1709164800000,
            "Unnamed: 17": 1711843200000,
            "Unnamed: 18": 1714435200000,
            "Unnamed: 19": 1717113600000,
            "Unnamed: 20": 1719705600000,
            "Unnamed: 21": 1722384000000,
            "Unnamed: 22": 1725062400000,
            "Unnamed: 23": 1727654400000,
            "Unnamed: 24": 1730332800000,
            "Unnamed: 25": 1732924800000,
            "Unnamed: 26": 1735603200000,
            "Unnamed: 27": "FY ' 2024",
        },
        {
            ABC: null,
            "Unnamed: 1": "Cash & Cash Equivalents",
            "Unnamed: 2": 25765099.3699999936,
            "Unnamed: 3": 26016809.4499999955,
            "Unnamed: 4": 20363006.8900000006,
            "Unnamed: 5": 18093488.9800000004,
            "Unnamed: 6": 18146153.0900000036,
            "Unnamed: 7": 16762898.6899999976,
            "Unnamed: 8": 10918057.3500000034,
            "Unnamed: 9": 17706405.9800000004,
            "Unnamed: 10": 19151410.3599999994,
            "Unnamed: 11": 12783475.6699999999,
            "Unnamed: 12": 12874935.0399999935,
            "Unnamed: 13": 14861804.2099999972,
            "Unnamed: 14": 14861804.2099999972,
            "Unnamed: 15": 16991794.5,
            "Unnamed: 16": 18546309.1000000015,
            "Unnamed: 17": 20000000,
            "Unnamed: 18": 19290747.9052973017,
            "Unnamed: 19": 19500428.4444892295,
            "Unnamed: 20": 19584557.764628794,
            "Unnamed: 21": 14138674.0044394322,
            "Unnamed: 22": 13068100.6715597808,
            "Unnamed: 23": 13392269.04573429,
            "Unnamed: 24": 7829605.7683042018,
            "Unnamed: 25": 9149494.8053977191,
            "Unnamed: 26": 10275723.0464417301,
            "Unnamed: 27": 10275723.0464417301,
        },
        {
            ABC: null,
            "Unnamed: 1": "Accounts Receivables",
            "Unnamed: 2": 2236274.7200000002,
            "Unnamed: 3": 1999723.3400000001,
            "Unnamed: 4": 1994272.95,
            "Unnamed: 5": 1545497.1000000001,
            "Unnamed: 6": 2342172.2000000002,
            "Unnamed: 7": 4398281.0499999998,
            "Unnamed: 8": 4292154.8899999997,
            "Unnamed: 9": 4601923.6799999997,
            "Unnamed: 10": 3694542.1699999999,
            "Unnamed: 11": 3535527.9899999998,
            "Unnamed: 12": 4263498.3900000006,
            "Unnamed: 13": 3760470.2999999998,
            "Unnamed: 14": 3760470.2999999998,
            "Unnamed: 15": 3933724.1999999997,
            "Unnamed: 16": 4334876.5499999998,
            "Unnamed: 17": 3489392.2053320063,
            "Unnamed: 18": 3632612.9382907911,
            "Unnamed: 19": 3554531.8495343402,
            "Unnamed: 20": 3700936.788469214,
            "Unnamed: 21": 3595858.965106789,
            "Unnamed: 22": 3611526.425245882,
            "Unnamed: 23": 3757397.5824811147,
            "Unnamed: 24": 3683651.2332321811,
            "Unnamed: 25": 3855856.4971649335,
            "Unnamed: 26": 3778786.2805172284,
            "Unnamed: 27": 3778786.2805172284,
        },
        {
            ABC: null,
            "Unnamed: 1": "Less: Provision for Bad Debt",
            "Unnamed: 2": 0,
            "Unnamed: 3": 0,
            "Unnamed: 4": 0,
            "Unnamed: 5": 0,
            "Unnamed: 6": 0,
            "Unnamed: 7": 0,
            "Unnamed: 8": 0,
            "Unnamed: 9": 0,
            "Unnamed: 10": 0,
            "Unnamed: 11": 0,
            "Unnamed: 12": 0,
            "Unnamed: 13": 0,
            "Unnamed: 14": 0,
            "Unnamed: 15": 0,
            "Unnamed: 16": 0,
            "Unnamed: 17": -228164.86,
            "Unnamed: 18": -253164.86,
            "Unnamed: 19": -278164.86,
            "Unnamed: 20": -303164.86,
            "Unnamed: 21": -328164.86,
            "Unnamed: 22": -353164.86,
            "Unnamed: 23": -378164.86,
            "Unnamed: 24": -403164.86,
            "Unnamed: 25": -428164.86,
            "Unnamed: 26": -453164.86,
            "Unnamed: 27": -453164.86,
        },
        {
            ABC: null,
            "Unnamed: 1": "Accrued Income",
            "Unnamed: 2": 2316312.3599999999,
            "Unnamed: 3": 2414946.8599999999,
            "Unnamed: 4": 2739201.5299999998,
            "Unnamed: 5": 3145529.3300000001,
            "Unnamed: 6": 2856726.29,
            "Unnamed: 7": 2853707.8799999999,
            "Unnamed: 8": 2934153.3500000001,
            "Unnamed: 9": 2918161.6099999999,
            "Unnamed: 10": 2903396.8100000001,
            "Unnamed: 11": 3014025.8799999999,
            "Unnamed: 12": 2717923.2200000002,
            "Unnamed: 13": 2617478.52,
            "Unnamed: 14": 2617478.52,
            "Unnamed: 15": 2424664.9100000001,
            "Unnamed: 16": 2764068.71,
            "Unnamed: 17": 2177506.0128583708,
            "Unnamed: 18": 2028243.4067459148,
            "Unnamed: 19": 2074548.7341642794,
            "Unnamed: 20": 2093441.7588456087,
            "Unnamed: 21": 2057584.8029516716,
            "Unnamed: 22": 2112994.6284179734,
            "Unnamed: 23": 2075341.3130746696,
            "Unnamed: 24": 2080434.7304916233,
            "Unnamed: 25": 2035321.5664902469,
            "Unnamed: 26": 2106359.4175638361,
            "Unnamed: 27": 2106359.4175638361,
        },
        {
            ABC: null,
            "Unnamed: 1": "Inventory",
            "Unnamed: 2": 2207928.2399999998,
            "Unnamed: 3": 2145228.4000000004,
            "Unnamed: 4": 2160717.7199999997,
            "Unnamed: 5": 2051517.8699999999,
            "Unnamed: 6": 1366512.21,
            "Unnamed: 7": 1130772.7599999998,
            "Unnamed: 8": 936951.5399999999,
            "Unnamed: 9": 791054.1900000001,
            "Unnamed: 10": 744293.95,
            "Unnamed: 11": 713873.5199999999,
            "Unnamed: 12": 715556.0699999999,
            "Unnamed: 13": 752383.0600000001,
            "Unnamed: 14": 752383.0600000001,
            "Unnamed: 15": 978998.95,
            "Unnamed: 16": 960833.9199999999,
            "Unnamed: 17": 943439.9645246598,
            "Unnamed: 18": 953539.9911211911,
            "Unnamed: 19": 945561.5613248163,
            "Unnamed: 20": 971909.4355200662,
            "Unnamed: 21": 1008733.1624346188,
            "Unnamed: 22": 1111797.4745003248,
            "Unnamed: 23": 1193241.5814744653,
            "Unnamed: 24": 1261487.794748297,
            "Unnamed: 25": 1179105.6142022288,
            "Unnamed: 26": 1080121.1314262059,
            "Unnamed: 27": 1080121.1314262059,
        },
        {
            ABC: null,
            "Unnamed: 1": "Prepaid Expenses",
            "Unnamed: 2": 1332877.4199999999,
            "Unnamed: 3": 1082644.54,
            "Unnamed: 4": 1048487.6599999999,
            "Unnamed: 5": 1417340.9399999999,
            "Unnamed: 6": 1579737.6000000001,
            "Unnamed: 7": 1821420.8600000001,
            "Unnamed: 8": 1637121.6100000001,
            "Unnamed: 9": 1646776.6699999999,
            "Unnamed: 10": 1375858.3,
            "Unnamed: 11": 1852924.1299999999,
            "Unnamed: 12": 1636069.8299999998,
            "Unnamed: 13": 1693912.0700000003,
            "Unnamed: 14": 1693912.0700000003,
            "Unnamed: 15": 1628725.5899999999,
            "Unnamed: 16": 1372864.0600000001,
            "Unnamed: 17": 1275595.1245299145,
            "Unnamed: 18": 1758686.6083974359,
            "Unnamed: 19": 1836057.6122649573,
            "Unnamed: 20": 1564870.8961324785,
            "Unnamed: 21": 1796239.27,
            "Unnamed: 22": 1584630.9038675213,
            "Unnamed: 23": 1384231.9477350428,
            "Unnamed: 24": 1832053.0316025643,
            "Unnamed: 25": 1442871.2754700854,
            "Unnamed: 26": 1125703.4893376068,
            "Unnamed: 27": 1125703.4893376068,
        },
        {
            ABC: null,
            "Unnamed: 1": "Other Current Receivables",
            "Unnamed: 2": 237726.16,
            "Unnamed: 3": 186238.97,
            "Unnamed: 4": 173341.04,
            "Unnamed: 5": 183976.69,
            "Unnamed: 6": 157682.62,
            "Unnamed: 7": 139796.73,
            "Unnamed: 8": 148869.37,
            "Unnamed: 9": 137341.3,
            "Unnamed: 10": 80378.33,
            "Unnamed: 11": 437877.6,
            "Unnamed: 12": -26538.59,
            "Unnamed: 13": 4666789.0300000003,
            "Unnamed: 14": 4666789.0300000003,
            "Unnamed: 15": 5407.38,
            "Unnamed: 16": 238435.07,
            "Unnamed: 17": 4666789.0300000003,
            "Unnamed: 18": 4666789.0300000003,
            "Unnamed: 19": 4666789.0300000003,
            "Unnamed: 20": 4666789.0300000003,
            "Unnamed: 21": 4666789.0300000003,
            "Unnamed: 22": 4666789.0300000003,
            "Unnamed: 23": 4666789.0300000003,
            "Unnamed: 24": 4666789.0300000003,
            "Unnamed: 25": 4666789.0300000003,
            "Unnamed: 26": 4666789.0300000003,
            "Unnamed: 27": 4666789.0300000003,
        },
        {
            ABC: null,
            "Unnamed: 1": "Other Current Assets",
            "Unnamed: 2": 0,
            "Unnamed: 3": 3270,
            "Unnamed: 4": 5410.56,
            "Unnamed: 5": 211978.77,
            "Unnamed: 6": -42936.74,
            "Unnamed: 7": 28890.8,
            "Unnamed: 8": 27125,
            "Unnamed: 9": 79447.87,
            "Unnamed: 10": 113744.72,
            "Unnamed: 11": 591742.1499999999,
            "Unnamed: 12": 121311.98,
            "Unnamed: 13": 718349.13,
            "Unnamed: 14": 718349.13,
            "Unnamed: 15": 442252.66,
            "Unnamed: 16": 89933.44,
            "Unnamed: 17": 718349.13,
            "Unnamed: 18": 718349.13,
            "Unnamed: 19": 718349.13,
            "Unnamed: 20": 718349.13,
            "Unnamed: 21": 718349.13,
            "Unnamed: 22": 718349.13,
            "Unnamed: 23": 718349.13,
            "Unnamed: 24": 718349.13,
            "Unnamed: 25": 718349.13,
            "Unnamed: 26": 718349.13,
            "Unnamed: 27": 718349.13,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Current Assets",
            "Unnamed: 2": 34096218.2699999884,
            "Unnamed: 3": 33848861.5599999949,
            "Unnamed: 4": 28484438.3499999978,
            "Unnamed: 5": 26649329.6800000072,
            "Unnamed: 6": 26406047.270000007,
            "Unnamed: 7": 27135768.7699999958,
            "Unnamed: 8": 20894433.1100000031,
            "Unnamed: 9": 27881111.3000000045,
            "Unnamed: 10": 28063624.6399999969,
            "Unnamed: 11": 22929446.9399999976,
            "Unnamed: 12": 22302755.9399999902,
            "Unnamed: 13": 29071186.3199999966,
            "Unnamed: 14": 29071186.3199999966,
            "Unnamed: 15": 26405568.1899999976,
            "Unnamed: 16": 28307320.8500000015,
            "Unnamed: 17": 33042906.6072449535,
            "Unnamed: 18": 32795804.1498526335,
            "Unnamed: 19": 33018101.5017776228,
            "Unnamed: 20": 32997689.9435961656,
            "Unnamed: 21": 27654063.5049325116,
            "Unnamed: 22": 26521023.4035914801,
            "Unnamed: 23": 26809454.7704995833,
            "Unnamed: 24": 21669205.8583788686,
            "Unnamed: 25": 22619623.0587252118,
            "Unnamed: 26": 23298666.6652866118,
            "Unnamed: 27": 23298666.6652866118,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": 0,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Property, Plant & Equipment",
            "Unnamed: 2": 1826865.3799999999,
            "Unnamed: 3": 1825654.3899999999,
            "Unnamed: 4": 1804809.3199999998,
            "Unnamed: 5": 1804809.72,
            "Unnamed: 6": 1807070.8599999999,
            "Unnamed: 7": 1840045.02,
            "Unnamed: 8": 2085728.4399999999,
            "Unnamed: 9": 2047162.22,
            "Unnamed: 10": 2052921.3100000001,
            "Unnamed: 11": 2051347.2999999998,
            "Unnamed: 12": 2053986.1699999999,
            "Unnamed: 13": 2056364.04,
            "Unnamed: 14": 2056364.04,
            "Unnamed: 15": 2072669.8599999999,
            "Unnamed: 16": 2101788.8599999999,
            "Unnamed: 17": 20000000,
            "Unnamed: 18": 1314962.49,
            "Unnamed: 19": 1298565.8100000001,
            "Unnamed: 20": 1298565.8100000001,
            "Unnamed: 21": 1298565.8100000001,
            "Unnamed: 22": 1298565.8100000001,
            "Unnamed: 23": 1298565.8100000001,
            "Unnamed: 24": 1298565.8100000001,
            "Unnamed: 25": 1298565.8100000001,
            "Unnamed: 26": 1298565.8100000001,
            "Unnamed: 27": 1298565.8100000001,
        },
        {
            ABC: null,
            "Unnamed: 1": "Capitalised Labour",
            "Unnamed: 2": 0,
            "Unnamed: 3": 0,
            "Unnamed: 4": 0,
            "Unnamed: 5": 0,
            "Unnamed: 6": 0,
            "Unnamed: 7": 0,
            "Unnamed: 8": 0,
            "Unnamed: 9": 0,
            "Unnamed: 10": 0,
            "Unnamed: 11": 0,
            "Unnamed: 12": 0,
            "Unnamed: 13": 0,
            "Unnamed: 14": 0,
            "Unnamed: 15": 0,
            "Unnamed: 16": 0,
            "Unnamed: 17": 8377772.0812190492,
            "Unnamed: 18": 8911996.5895563737,
            "Unnamed: 19": 9456308.7415718585,
            "Unnamed: 20": 10007197.1055873446,
            "Unnamed: 21": 10559248.5756028295,
            "Unnamed: 22": 11114588.1516183149,
            "Unnamed: 23": 11669927.7276338004,
            "Unnamed: 24": 12225267.3036492858,
            "Unnamed: 25": 12780606.8796647713,
            "Unnamed: 26": 13335946.4556802567,
            "Unnamed: 27": 13335946.4556802567,
        },
        {
            ABC: null,
            "Unnamed: 1": "Capitalised Material",
            "Unnamed: 2": 0,
            "Unnamed: 3": 0,
            "Unnamed: 4": 0,
            "Unnamed: 5": 0,
            "Unnamed: 6": 0,
            "Unnamed: 7": 0,
            "Unnamed: 8": 0,
            "Unnamed: 9": 0,
            "Unnamed: 10": 0,
            "Unnamed: 11": 0,
            "Unnamed: 12": 0,
            "Unnamed: 13": 0,
            "Unnamed: 14": 0,
            "Unnamed: 15": 0,
            "Unnamed: 16": 0,
            "Unnamed: 17": 20595385.8642569631,
            "Unnamed: 18": 21010490.1517145187,
            "Unnamed: 19": 21431988.3640983999,
            "Unnamed: 20": 21890108.688960351,
            "Unnamed: 21": 22392962.5196791179,
            "Unnamed: 22": 23010921.8464241251,
            "Unnamed: 23": 23721416.9382555559,
            "Unnamed: 24": 24516077.8780752048,
            "Unnamed: 25": 25240010.1123302057,
            "Unnamed: 26": 25874335.5420919433,
            "Unnamed: 27": 25874335.5420919433,
        },
        {
            ABC: null,
            "Unnamed: 1": "Less: Accumulated Depreciation",
            "Unnamed: 2": -4118310.3799999999,
            "Unnamed: 3": -4686377.6600000001,
            "Unnamed: 4": -5273517.9799999995,
            "Unnamed: 5": -5871412.3599999994,
            "Unnamed: 6": -6467958.0900000008,
            "Unnamed: 7": -7081006.9900000002,
            "Unnamed: 8": -7893013.1499999994,
            "Unnamed: 9": -8589070.9399999995,
            "Unnamed: 10": -9305554.4100000001,
            "Unnamed: 11": -10038882.5600000024,
            "Unnamed: 12": -10768897.6200000029,
            "Unnamed: 13": -11497758.7200000007,
            "Unnamed: 14": -11497758.7200000007,
            "Unnamed: 15": -12272385.7799999993,
            "Unnamed: 16": -13039958.4000000022,
            "Unnamed: 17": -13851813.1965384297,
            "Unnamed: 18": -14642575.2322351038,
            "Unnamed: 19": -15426036.7907960843,
            "Unnamed: 20": -16223932.2689157594,
            "Unnamed: 21": -17032335.3120105676,
            "Unnamed: 22": -17852495.695091363,
            "Unnamed: 23": -18679654.2730808966,
            "Unnamed: 24": -19525910.8525557145,
            "Unnamed: 25": -20388969.3897061795,
            "Unnamed: 26": -21271503.7082983591,
            "Unnamed: 27": -21271503.7082983591,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Property, Plant & Equipment",
            "Unnamed: 2": -2291445,
            "Unnamed: 3": -2860723.2700000005,
            "Unnamed: 4": -3468708.6599999997,
            "Unnamed: 5": -4066602.6399999997,
            "Unnamed: 6": -4660887.2300000004,
            "Unnamed: 7": -5240961.9700000007,
            "Unnamed: 8": -5807284.709999999,
            "Unnamed: 9": -6541908.7199999997,
            "Unnamed: 10": -7252633.0999999996,
            "Unnamed: 11": -7987535.2600000026,
            "Unnamed: 12": -8714911.450000003,
            "Unnamed: 13": -9441394.6799999997,
            "Unnamed: 14": -9441394.6799999997,
            "Unnamed: 15": -10199715.9199999999,
            "Unnamed: 16": -10938169.5400000028,
            "Unnamed: 17": 35121344.748937577,
            "Unnamed: 18": 16594873.9990357906,
            "Unnamed: 19": 16760826.1248741765,
            "Unnamed: 20": 16971939.3356319368,
            "Unnamed: 21": 17218441.5932713784,
            "Unnamed: 22": 17571580.1129510738,
            "Unnamed: 23": 18010256.2028084584,
            "Unnamed: 24": 18514000.1391687766,
            "Unnamed: 25": 18930213.4122887999,
            "Unnamed: 26": 19237344.0994738452,
            "Unnamed: 27": 19237344.0994738452,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": 0,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Goodwill",
            "Unnamed: 2": 294963706,
            "Unnamed: 3": 292360730.2200000286,
            "Unnamed: 4": 289757754.4399999976,
            "Unnamed: 5": 287154778.6600000262,
            "Unnamed: 6": 284551802.8799999952,
            "Unnamed: 7": 281766680.9600000381,
            "Unnamed: 8": 279133347.4900000095,
            "Unnamed: 9": 276500014.0199999809,
            "Unnamed: 10": 273866680.5500000119,
            "Unnamed: 11": 271233347.0800000429,
            "Unnamed: 12": 268600013.6100000143,
            "Unnamed: 13": 265966680.1400000155,
            "Unnamed: 14": 265966680.1400000155,
            "Unnamed: 15": 263333346.6700000167,
            "Unnamed: 16": 260700013.2000000179,
            "Unnamed: 17": 258066679.7300000191,
            "Unnamed: 18": 255433346.2600000203,
            "Unnamed: 19": 252800012.7900000215,
            "Unnamed: 20": 250166679.3200000226,
            "Unnamed: 21": 247533345.8500000238,
            "Unnamed: 22": 244900012.380000025,
            "Unnamed: 23": 242266678.9100000262,
            "Unnamed: 24": 239633345.4400000274,
            "Unnamed: 25": 237000011.9700000286,
            "Unnamed: 26": 234366678.5000000298,
            "Unnamed: 27": 234366678.5000000298,
        },
        {
            ABC: null,
            "Unnamed: 1": "Intangible Assets",
            "Unnamed: 2": 202417782.380000025,
            "Unnamed: 3": 200117010.6200000048,
            "Unnamed: 4": 198450837.5600000024,
            "Unnamed: 5": 196532085.8700000048,
            "Unnamed: 6": 194747667.5700000226,
            "Unnamed: 7": 186837879.4199999869,
            "Unnamed: 8": 184055517.2599999607,
            "Unnamed: 9": 181412817.7699999809,
            "Unnamed: 10": 178723734.8199999928,
            "Unnamed: 11": 176067828.0699999928,
            "Unnamed: 12": 173394255.8799999952,
            "Unnamed: 13": 170728184.1799999774,
            "Unnamed: 14": 170728184.1799999774,
            "Unnamed: 15": 169555516.9999999702,
            "Unnamed: 16": 166685000.2300000191,
            "Unnamed: 17": 161810203.1468138397,
            "Unnamed: 18": 158991953.2386648953,
            "Unnamed: 19": 156214620.6956313848,
            "Unnamed: 20": 154002698.9998019338,
            "Unnamed: 21": 151772159.873473227,
            "Unnamed: 22": 149528002.8088921607,
            "Unnamed: 23": 147270227.2974026203,
            "Unnamed: 24": 144998832.849316448,
            "Unnamed: 25": 142713818.9539136291,
            "Unnamed: 26": 140415185.1095710993,
            "Unnamed: 27": 140415185.1095710993,
        },
        {
            ABC: null,
            "Unnamed: 1": "Capitalised Financing Costs",
            "Unnamed: 2": 0,
            "Unnamed: 3": 0,
            "Unnamed: 4": 0,
            "Unnamed: 5": 0,
            "Unnamed: 6": 0,
            "Unnamed: 7": 0,
            "Unnamed: 8": 0,
            "Unnamed: 9": 0,
            "Unnamed: 10": 0,
            "Unnamed: 11": 0,
            "Unnamed: 12": 0,
            "Unnamed: 13": 0,
            "Unnamed: 14": 0,
            "Unnamed: 15": 0,
            "Unnamed: 16": 0,
            "Unnamed: 17": 2799834.4200000009,
            "Unnamed: 18": 2727145.7800000012,
            "Unnamed: 19": 2654457.1400000011,
            "Unnamed: 20": 2581768.5000000009,
            "Unnamed: 21": 2509079.8600000013,
            "Unnamed: 22": 2436391.2200000016,
            "Unnamed: 23": 2363702.5800000015,
            "Unnamed: 24": 2291013.9400000013,
            "Unnamed: 25": 2218325.3000000012,
            "Unnamed: 26": 2145636.6600000011,
            "Unnamed: 27": 2145636.6600000011,
        },
        {
            ABC: null,
            "Unnamed: 1": "Other Long-Term Assets",
            "Unnamed: 2": 63837.18,
            "Unnamed: 3": 63469.34,
            "Unnamed: 4": 63947.05,
            "Unnamed: 5": 63947.05,
            "Unnamed: 6": 63469.34,
            "Unnamed: 7": 71472.05,
            "Unnamed: 8": 71472.05,
            "Unnamed: 9": 70994.34,
            "Unnamed: 10": 70516.63,
            "Unnamed: 11": 70038.92,
            "Unnamed: 12": 69561.21,
            "Unnamed: 13": 70612.17,
            "Unnamed: 14": 70612.17,
            "Unnamed: 15": 103505.65,
            "Unnamed: 16": 133384.43,
            "Unnamed: 17": 70612.17,
            "Unnamed: 18": 70612.17,
            "Unnamed: 19": 70612.17,
            "Unnamed: 20": 70612.17,
            "Unnamed: 21": 70612.17,
            "Unnamed: 22": 70612.17,
            "Unnamed: 23": 70612.17,
            "Unnamed: 24": 70612.17,
            "Unnamed: 25": 70612.17,
            "Unnamed: 26": 70612.17,
            "Unnamed: 27": 70612.17,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Non-Current Assets",
            "Unnamed: 2": 497445325.5600000024,
            "Unnamed: 3": 492541210.1800000072,
            "Unnamed: 4": 488272539.0500000119,
            "Unnamed: 5": 483750811.5800000429,
            "Unnamed: 6": 479362939.7900000215,
            "Unnamed: 7": 468676032.4300000072,
            "Unnamed: 8": 463260336.8000000119,
            "Unnamed: 9": 457983826.1299999356,
            "Unnamed: 10": 452660932,
            "Unnamed: 11": 447371214.0700000525,
            "Unnamed: 12": 442063830.6999999881,
            "Unnamed: 13": 436765476.4900000095,
            "Unnamed: 14": 436765476.4900000095,
            "Unnamed: 15": 432992369.3199999332,
            "Unnamed: 16": 427518397.8600000739,
            "Unnamed: 17": 422747329.4668139219,
            "Unnamed: 18": 417223057.4486649632,
            "Unnamed: 19": 411739702.7956314087,
            "Unnamed: 20": 406821758.9898019433,
            "Unnamed: 21": 401885197.7534732819,
            "Unnamed: 22": 396935018.578892231,
            "Unnamed: 23": 391971220.9574026465,
            "Unnamed: 24": 386993804.3993164897,
            "Unnamed: 25": 382002768.3939136863,
            "Unnamed: 26": 376998112.4395711422,
            "Unnamed: 27": 376998112.4395711422,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": 0,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Assets",
            "Unnamed: 2": 529250098.8299999833,
            "Unnamed: 3": 523529348.4700000286,
            "Unnamed: 4": 513288268.7400000095,
            "Unnamed: 5": 506333538.6200000644,
            "Unnamed: 6": 501108099.8300000429,
            "Unnamed: 7": 490570839.2300000191,
            "Unnamed: 8": 478347485.1999999881,
            "Unnamed: 9": 479323028.7099999189,
            "Unnamed: 10": 473471923.5400000215,
            "Unnamed: 11": 462313125.7500000596,
            "Unnamed: 12": 455651675.1899999976,
            "Unnamed: 13": 456395268.1299999952,
            "Unnamed: 14": 456395268.1299999952,
            "Unnamed: 15": 449198221.5899999142,
            "Unnamed: 16": 444887549.1700000763,
            "Unnamed: 17": 490911580.8229964375,
            "Unnamed: 18": 466613735.5975533724,
            "Unnamed: 19": 461518630.4222832322,
            "Unnamed: 20": 456791388.2690300345,
            "Unnamed: 21": 446757702.8516771793,
            "Unnamed: 22": 441027622.0954347849,
            "Unnamed: 23": 436790931.9307106733,
            "Unnamed: 24": 427177010.3968641162,
            "Unnamed: 25": 423552604.8649277091,
            "Unnamed: 26": 419534123.2043315768,
            "Unnamed: 27": 419534123.2043315768,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": 0,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Accounts Payables",
            "Unnamed: 2": 3700254.4699999997,
            "Unnamed: 3": 2290242.1400000001,
            "Unnamed: 4": 1675721.97,
            "Unnamed: 5": 1150556.8699999999,
            "Unnamed: 6": 1834151.4299999999,
            "Unnamed: 7": 1670061.25,
            "Unnamed: 8": 1345569.6199999999,
            "Unnamed: 9": 1287247.97,
            "Unnamed: 10": 2139684.54,
            "Unnamed: 11": 2141965.1999999997,
            "Unnamed: 12": 2032982.1899999999,
            "Unnamed: 13": 2537849.6700000004,
            "Unnamed: 14": 2537849.6700000004,
            "Unnamed: 15": 2287709.48,
            "Unnamed: 16": 2284048.5200000005,
            "Unnamed: 17": 2777169.3318321137,
            "Unnamed: 18": 2776128.3243276095,
            "Unnamed: 19": 2446290.6370050414,
            "Unnamed: 20": 2329582.8808133979,
            "Unnamed: 21": 2308522.1141385688,
            "Unnamed: 22": 2337497.1096236939,
            "Unnamed: 23": 2582027.6612950093,
            "Unnamed: 24": 2578228.7090580356,
            "Unnamed: 25": 2550969.1194602479,
            "Unnamed: 26": 2660773.986603511,
            "Unnamed: 27": 2660773.986603511,
        },
        {
            ABC: null,
            "Unnamed: 1": "Payroll-Related Liabilities",
            "Unnamed: 2": 2962116.1100000003,
            "Unnamed: 3": 2851576.6000000001,
            "Unnamed: 4": 1553989,
            "Unnamed: 5": 1351320.1500000001,
            "Unnamed: 6": 1557441.5600000001,
            "Unnamed: 7": 2010641.03,
            "Unnamed: 8": 1914811.6299999999,
            "Unnamed: 9": 954858.46,
            "Unnamed: 10": 1120813.1399999999,
            "Unnamed: 11": 1311368.54,
            "Unnamed: 12": 1560225.1899999999,
            "Unnamed: 13": 1811592.2800000003,
            "Unnamed: 14": 1811592.2800000003,
            "Unnamed: 15": 2156297.5699999998,
            "Unnamed: 16": 881070.5200000001,
            "Unnamed: 17": 1595235.5250964449,
            "Unnamed: 18": 1476396.3337299342,
            "Unnamed: 19": 1807391.0376086149,
            "Unnamed: 20": 2116738.911612452,
            "Unnamed: 21": 2410057.8319430789,
            "Unnamed: 22": 1174952.6324354506,
            "Unnamed: 23": 1355132.2729594796,
            "Unnamed: 24": 1682023.2745283609,
            "Unnamed: 25": 1992631.1031221536,
            "Unnamed: 26": 2311117.3037180458,
            "Unnamed: 27": 2311117.3037180458,
        },
        {
            ABC: null,
            "Unnamed: 1": "Annual Bonus Accrual",
            "Unnamed: 2": 931423.58,
            "Unnamed: 3": 598133.71,
            "Unnamed: 4": 498057.91,
            "Unnamed: 5": 819287.4,
            "Unnamed: 6": 1110319.98,
            "Unnamed: 7": 1824931.3500000001,
            "Unnamed: 8": 1601180.7,
            "Unnamed: 9": 1849911.8999999999,
            "Unnamed: 10": 2111248.9199999999,
            "Unnamed: 11": 2381119.8799999999,
            "Unnamed: 12": 2595010.6099999999,
            "Unnamed: 13": 67692.5,
            "Unnamed: 14": 67692.5,
            "Unnamed: 15": 1508237.78,
            "Unnamed: 16": 1752002.8799999999,
            "Unnamed: 17": 699482.0708254166,
            "Unnamed: 18": 964864.0706491666,
            "Unnamed: 19": 1230246.0704729166,
            "Unnamed: 20": 1498320.5702966666,
            "Unnamed: 21": 1766395.0701204166,
            "Unnamed: 22": 2038059.5699441666,
            "Unnamed: 23": 2309724.0697679166,
            "Unnamed: 24": 2581388.5695916666,
            "Unnamed: 25": 2853053.0694154166,
            "Unnamed: 26": 3124717.5692391666,
            "Unnamed: 27": 3124717.5692391666,
        },
        {
            ABC: null,
            "Unnamed: 1": "Accrued Interest",
            "Unnamed: 2": 3058183,
            "Unnamed: 3": 4422958.0499999998,
            "Unnamed: 4": 1557903.1899999999,
            "Unnamed: 5": 811356.91,
            "Unnamed: 6": 2577578.6099999999,
            "Unnamed: 7": 4130710.4500000002,
            "Unnamed: 8": 1803281.98,
            "Unnamed: 9": 3665519.1299999999,
            "Unnamed: 10": 5609137.79,
            "Unnamed: 11": 2229340.46,
            "Unnamed: 12": 3857417.98,
            "Unnamed: 13": 5653996.6399999997,
            "Unnamed: 14": 5653996.6399999997,
            "Unnamed: 15": 7573814.1500000004,
            "Unnamed: 16": 3906989.1299999999,
            "Unnamed: 17": 5828129.8259474998,
            "Unnamed: 18": 2009268.350554375,
            "Unnamed: 19": 4092139.0530962497,
            "Unnamed: 20": 6058142.9902181942,
            "Unnamed: 21": 2030321.8495926522,
            "Unnamed: 22": 4067258.2865746794,
            "Unnamed: 23": 5988730.7468331521,
            "Unnamed: 24": 1984597.2322966931,
            "Unnamed: 25": 3911778.0255412762,
            "Unnamed: 26": 5853097.66751472,
            "Unnamed: 27": 5853097.66751472,
        },
        {
            ABC: null,
            "Unnamed: 1": "Tax Payables",
            "Unnamed: 2": 0,
            "Unnamed: 3": 0,
            "Unnamed: 4": 0,
            "Unnamed: 5": 0,
            "Unnamed: 6": 0,
            "Unnamed: 7": 0,
            "Unnamed: 8": 0,
            "Unnamed: 9": 0,
            "Unnamed: 10": 0,
            "Unnamed: 11": 0,
            "Unnamed: 12": 0,
            "Unnamed: 13": 0,
            "Unnamed: 14": 0,
            "Unnamed: 15": 0,
            "Unnamed: 16": 0,
            "Unnamed: 17": 316527.863777331,
            "Unnamed: 18": 312147.272710097,
            "Unnamed: 19": 323576.4426542612,
            "Unnamed: 20": 325349.8623308986,
            "Unnamed: 21": 332942.6897816429,
            "Unnamed: 22": 340312.3615331515,
            "Unnamed: 23": 339737.3805797307,
            "Unnamed: 24": 347602.3235594611,
            "Unnamed: 25": 343012.0065316094,
            "Unnamed: 26": 355824.019136617,
            "Unnamed: 27": 355824.019136617,
        },
        {
            ABC: null,
            "Unnamed: 1": "Deferred Revenue",
            "Unnamed: 2": 859162.3,
            "Unnamed: 3": 937672.55,
            "Unnamed: 4": 921356.1599999999,
            "Unnamed: 5": 884173.86,
            "Unnamed: 6": 870684.05,
            "Unnamed: 7": 541278.78,
            "Unnamed: 8": 277796.37,
            "Unnamed: 9": 256136.83,
            "Unnamed: 10": 198254.79,
            "Unnamed: 11": 191505.15,
            "Unnamed: 12": 129670.21,
            "Unnamed: 13": 184560.82,
            "Unnamed: 14": 184560.82,
            "Unnamed: 15": 157853.08,
            "Unnamed: 16": 139927.1,
            "Unnamed: 17": 184560.82,
            "Unnamed: 18": 184560.82,
            "Unnamed: 19": 184560.82,
            "Unnamed: 20": 184560.82,
            "Unnamed: 21": 184560.82,
            "Unnamed: 22": 184560.82,
            "Unnamed: 23": 184560.82,
            "Unnamed: 24": 184560.82,
            "Unnamed: 25": 184560.82,
            "Unnamed: 26": 184560.82,
            "Unnamed: 27": 184560.82,
        },
        {
            ABC: null,
            "Unnamed: 1": "Credit Line",
            "Unnamed: 2": 0,
            "Unnamed: 3": 0,
            "Unnamed: 4": 0,
            "Unnamed: 5": 0,
            "Unnamed: 6": 0,
            "Unnamed: 7": 0,
            "Unnamed: 8": 3000000,
            "Unnamed: 9": 3000000,
            "Unnamed: 10": 3000000,
            "Unnamed: 11": 3000000,
            "Unnamed: 12": 3000000,
            "Unnamed: 13": 3000000,
            "Unnamed: 14": 3000000,
            "Unnamed: 15": 3000000,
            "Unnamed: 16": 11500000,
            "Unnamed: 17": 11500000,
            "Unnamed: 18": 20000000,
            "Unnamed: 19": 20000000,
            "Unnamed: 20": 20000000,
            "Unnamed: 21": 20000000,
            "Unnamed: 22": 20000000,
            "Unnamed: 23": 20000000,
            "Unnamed: 24": 20000000,
            "Unnamed: 25": 20000000,
            "Unnamed: 26": 20000000,
            "Unnamed: 27": 20000000,
        },
        {
            ABC: null,
            "Unnamed: 1": "Current Portion of Long Term Debt",
            "Unnamed: 2": 1867700,
            "Unnamed: 3": 1867700,
            "Unnamed: 4": 1867700,
            "Unnamed: 5": 1867700,
            "Unnamed: 6": 1867700,
            "Unnamed: 7": 1867700,
            "Unnamed: 8": 1867700,
            "Unnamed: 9": 1927700,
            "Unnamed: 10": 1947700,
            "Unnamed: 11": 1947700,
            "Unnamed: 12": 1947700,
            "Unnamed: 13": 1947700,
            "Unnamed: 14": 1947700,
            "Unnamed: 15": 1947700,
            "Unnamed: 16": 1947700,
            "Unnamed: 17": 1947700,
            "Unnamed: 18": 1947700,
            "Unnamed: 19": 1947700,
            "Unnamed: 20": 1947700,
            "Unnamed: 21": 1947700,
            "Unnamed: 22": 1947700,
            "Unnamed: 23": 1947700,
            "Unnamed: 24": 1947700,
            "Unnamed: 25": 1947700,
            "Unnamed: 26": 1947700,
            "Unnamed: 27": 1947700,
        },
        {
            ABC: null,
            "Unnamed: 1": "Other Current Liabilities",
            "Unnamed: 2": 10878828.0200000033,
            "Unnamed: 3": 11855527.620000001,
            "Unnamed: 4": 13329879.4200000018,
            "Unnamed: 5": 14613038.3000000007,
            "Unnamed: 6": 13964167.6100000013,
            "Unnamed: 7": 13286280.1600000001,
            "Unnamed: 8": 9323731.290000001,
            "Unnamed: 9": 9284899.3600000013,
            "Unnamed: 10": 9625689.2799999975,
            "Unnamed: 11": 9895076.7200000007,
            "Unnamed: 12": 9292871.8900000006,
            "Unnamed: 13": 14387437.7300000004,
            "Unnamed: 14": 14387437.7300000004,
            "Unnamed: 15": 9154199.25,
            "Unnamed: 16": 12506842.1700000037,
            "Unnamed: 17": 15488381.5700000003,
            "Unnamed: 18": 15738381.5700000003,
            "Unnamed: 19": 15988381.5700000003,
            "Unnamed: 20": 16238381.5700000003,
            "Unnamed: 21": 16488381.5700000003,
            "Unnamed: 22": 16738381.5700000003,
            "Unnamed: 23": 16988381.5700000003,
            "Unnamed: 24": 17238381.5700000003,
            "Unnamed: 25": 17488381.5700000003,
            "Unnamed: 26": 17738381.5700000003,
            "Unnamed: 27": 17738381.5700000003,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Current Liabilities",
            "Unnamed: 2": 24257667.4800000042,
            "Unnamed: 3": 24823810.6700000018,
            "Unnamed: 4": 21404607.6500000022,
            "Unnamed: 5": 21497433.4900000021,
            "Unnamed: 6": 23782043.2400000021,
            "Unnamed: 7": 25331603.0200000033,
            "Unnamed: 8": 21134071.5900000036,
            "Unnamed: 9": 22226273.6499999985,
            "Unnamed: 10": 25752528.4599999972,
            "Unnamed: 11": 23098075.950000003,
            "Unnamed: 12": 24415878.0700000003,
            "Unnamed: 13": 29590829.6400000006,
            "Unnamed: 14": 29590829.6400000006,
            "Unnamed: 15": 27785811.3100000024,
            "Unnamed: 16": 34918580.3200000003,
            "Unnamed: 17": 40337187.0074788034,
            "Unnamed: 18": 45409446.7419711798,
            "Unnamed: 19": 48020285.6308370829,
            "Unnamed: 20": 50698777.6052716151,
            "Unnamed: 21": 47468881.9455763623,
            "Unnamed: 22": 48828722.3501111418,
            "Unnamed: 23": 51695994.5214352906,
            "Unnamed: 24": 48544482.4990342185,
            "Unnamed: 25": 51272085.7140707001,
            "Unnamed: 26": 54176172.9362120628,
            "Unnamed: 27": 54176172.9362120628,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": 0,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Long-Term Debt",
            "Unnamed: 2": 157600000,
            "Unnamed: 3": 157600000,
            "Unnamed: 4": 157200000,
            "Unnamed: 5": 157200000,
            "Unnamed: 6": 157200000,
            "Unnamed: 7": 156800000,
            "Unnamed: 8": 156800000,
            "Unnamed: 9": 156800000,
            "Unnamed: 10": 156400000,
            "Unnamed: 11": 156400000,
            "Unnamed: 12": 156400000,
            "Unnamed: 13": 156000000,
            "Unnamed: 14": 156000000,
            "Unnamed: 15": 156000000,
            "Unnamed: 16": 156000000,
            "Unnamed: 17": 156000000,
            "Unnamed: 18": 155600000,
            "Unnamed: 19": 155600000,
            "Unnamed: 20": 155200000,
            "Unnamed: 21": 155200000,
            "Unnamed: 22": 155200000,
            "Unnamed: 23": 154800000,
            "Unnamed: 24": 154800000,
            "Unnamed: 25": 154800000,
            "Unnamed: 26": 154400000,
            "Unnamed: 27": 154400000,
        },
        {
            ABC: null,
            "Unnamed: 1": "MDTL",
            "Unnamed: 2": 26492300,
            "Unnamed: 3": 26492300,
            "Unnamed: 4": 26425375,
            "Unnamed: 5": 26425375,
            "Unnamed: 6": 26425375,
            "Unnamed: 7": 26358450,
            "Unnamed: 8": 26358450,
            "Unnamed: 9": 34298450,
            "Unnamed: 10": 34211525,
            "Unnamed: 11": 34211525,
            "Unnamed: 12": 34211525,
            "Unnamed: 13": 34124600,
            "Unnamed: 14": 34124600,
            "Unnamed: 15": 42624600,
            "Unnamed: 16": 34124600,
            "Unnamed: 17": 34034675,
            "Unnamed: 18": 34034675,
            "Unnamed: 19": 34034675,
            "Unnamed: 20": 33947750,
            "Unnamed: 21": 33947750,
            "Unnamed: 22": 33947750,
            "Unnamed: 23": 33860825,
            "Unnamed: 24": 33860825,
            "Unnamed: 25": 33860825,
            "Unnamed: 26": 33773900,
            "Unnamed: 27": 33773900,
        },
        {
            ABC: null,
            "Unnamed: 1": "Other Long Term Liabilities",
            "Unnamed: 2": 5069808.1500000004,
            "Unnamed: 3": 4955744.6299999999,
            "Unnamed: 4": 4856134.2300000004,
            "Unnamed: 5": 4740972.75,
            "Unnamed: 6": 4681327.8100000005,
            "Unnamed: 7": 1815058.4300000002,
            "Unnamed: 8": 1755084.97,
            "Unnamed: 9": 1694946.51,
            "Unnamed: 10": 1634642.53,
            "Unnamed: 11": 1574172.53,
            "Unnamed: 12": 1513536.02,
            "Unnamed: 13": 1452732.48,
            "Unnamed: 14": 1452732.48,
            "Unnamed: 15": 2613609.7400000002,
            "Unnamed: 16": 2525541.8899999997,
            "Unnamed: 17": 1480525.67206995,
            "Unnamed: 18": 1421726.1092229348,
            "Unnamed: 19": 1362753.9693105891,
            "Unnamed: 20": 1252890.278174724,
            "Unnamed: 21": 1142684.0634477546,
            "Unnamed: 22": 1082591.0449927864,
            "Unnamed: 23": 1022275.9517699415,
            "Unnamed: 24": 961738.5317073634,
            "Unnamed: 25": 900978.55144322,
            "Unnamed: 26": 839995.8067126969,
            "Unnamed: 27": 839995.8067126969,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Long-Term Liabilities",
            "Unnamed: 2": 189162108.150000006,
            "Unnamed: 3": 189048044.6299999952,
            "Unnamed: 4": 188481509.2299999893,
            "Unnamed: 5": 188366347.75,
            "Unnamed: 6": 188306702.8100000024,
            "Unnamed: 7": 184973508.4300000072,
            "Unnamed: 8": 184913534.9699999988,
            "Unnamed: 9": 192793396.5099999905,
            "Unnamed: 10": 192246167.5300000012,
            "Unnamed: 11": 192185697.5300000012,
            "Unnamed: 12": 192125061.0200000107,
            "Unnamed: 13": 191577332.4799999893,
            "Unnamed: 14": 191577332.4799999893,
            "Unnamed: 15": 201238209.7400000095,
            "Unnamed: 16": 192650141.8899999857,
            "Unnamed: 17": 191515200.672069937,
            "Unnamed: 18": 191056401.1092229486,
            "Unnamed: 19": 190997428.9693105817,
            "Unnamed: 20": 190400640.2781747282,
            "Unnamed: 21": 190290434.0634477437,
            "Unnamed: 22": 190230341.0449927747,
            "Unnamed: 23": 189683100.951769948,
            "Unnamed: 24": 189622563.5317073762,
            "Unnamed: 25": 189561803.5514432192,
            "Unnamed: 26": 189013895.806712687,
            "Unnamed: 27": 189013895.806712687,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": 0,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Common Equity",
            "Unnamed: 2": 543707202.1600000858,
            "Unnamed: 3": 543707202.1600000858,
            "Unnamed: 4": 543707202.1600000858,
            "Unnamed: 5": 543707202.1600000858,
            "Unnamed: 6": 543707202.1600000858,
            "Unnamed: 7": 543707202.1600000858,
            "Unnamed: 8": 543707202.1600000858,
            "Unnamed: 9": 543707202.1600000858,
            "Unnamed: 10": 543707202.1600000858,
            "Unnamed: 11": 543707202.1600000858,
            "Unnamed: 12": 543707202.1600000858,
            "Unnamed: 13": 543707202.1600000858,
            "Unnamed: 14": 543707202.1600000858,
            "Unnamed: 15": 543707202.1600000858,
            "Unnamed: 16": 543707202.1600000858,
            "Unnamed: 17": 156000000,
            "Unnamed: 18": 543707202.1600000858,
            "Unnamed: 19": 543707202.1600000858,
            "Unnamed: 20": 543707202.1600000858,
            "Unnamed: 21": 543707202.1600000858,
            "Unnamed: 22": 543707202.1600000858,
            "Unnamed: 23": 543707202.1600000858,
            "Unnamed: 24": 543707202.1600000858,
            "Unnamed: 25": 543707202.1600000858,
            "Unnamed: 26": 543707202.1600000858,
            "Unnamed: 27": 543707202.1600000858,
        },
        {
            ABC: null,
            "Unnamed: 1": "Retained Earnings",
            "Unnamed: 2": -219733156.900000006,
            "Unnamed: 3": -219721619.6299999952,
            "Unnamed: 4": -219477355.9699999988,
            "Unnamed: 5": -219477355.9699999988,
            "Unnamed: 6": -219477355.9699999988,
            "Unnamed: 7": -219389743.1899999976,
            "Unnamed: 8": -219389743.1899999976,
            "Unnamed: 9": -219389743.1899999976,
            "Unnamed: 10": -219389504.4099999964,
            "Unnamed: 11": -219389504.4099999964,
            "Unnamed: 12": -219390950.7700000107,
            "Unnamed: 13": -219667346.5600000024,
            "Unnamed: 14": -219667346.5600000024,
            "Unnamed: 15": -300043068.5400000215,
            "Unnamed: 16": -300042840.1499999762,
            "Unnamed: 17": 188823988.074857533,
            "Unnamed: 18": -219667346.5600000024,
            "Unnamed: 19": -219667346.5600000024,
            "Unnamed: 20": -219667346.5600000024,
            "Unnamed: 21": -219667346.5600000024,
            "Unnamed: 22": -219667346.5600000024,
            "Unnamed: 23": -219667346.5600000024,
            "Unnamed: 24": -219667346.5600000024,
            "Unnamed: 25": -219667346.5600000024,
            "Unnamed: 26": -219667346.5600000024,
            "Unnamed: 27": -219667346.5600000024,
        },
        {
            ABC: null,
            "Unnamed: 1": "Sellers Note",
            "Unnamed: 2": 20700000,
            "Unnamed: 3": 20700000,
            "Unnamed: 4": 20700000,
            "Unnamed: 5": 20700000,
            "Unnamed: 6": 20700000,
            "Unnamed: 7": 20700000,
            "Unnamed: 8": 20700000,
            "Unnamed: 9": 20700000,
            "Unnamed: 10": 20700000,
            "Unnamed: 11": 20700000,
            "Unnamed: 12": 20700000,
            "Unnamed: 13": 20700000,
            "Unnamed: 14": 20700000,
            "Unnamed: 15": 20700000,
            "Unnamed: 16": 20700000,
            "Unnamed: 17": 20700000,
            "Unnamed: 18": 20700000,
            "Unnamed: 19": 20700000,
            "Unnamed: 20": 20700000,
            "Unnamed: 21": 20700000,
            "Unnamed: 22": 20700000,
            "Unnamed: 23": 20700000,
            "Unnamed: 24": 20700000,
            "Unnamed: 25": 20700000,
            "Unnamed: 26": 20700000,
            "Unnamed: 27": 20700000,
        },
        {
            ABC: null,
            "Unnamed: 1": "Gain/Loss from Income Statement",
            "Unnamed: 2": -6825632.8300000001,
            "Unnamed: 3": -12255117.6100000013,
            "Unnamed: 4": -17915017.3700000048,
            "Unnamed: 5": -24030660.0699999966,
            "Unnamed: 6": -30314496.870000001,
            "Unnamed: 7": -40411455.3699999973,
            "Unnamed: 8": -47345913.8399999961,
            "Unnamed: 9": -54342932.049999997,
            "Unnamed: 10": -61431832.75,
            "Unnamed: 11": -68947177.0700000077,
            "Unnamed: 12": -76101245.9200000018,
            "Unnamed: 13": -79137682.8100000024,
            "Unnamed: 14": -79137682.8100000024,
            "Unnamed: 15": -14467048.3599999975,
            "Unnamed: 16": -16584656.1599999964,
            "Unnamed: 17": -106464794.9314098209,
            "Unnamed: 18": -114591967.853640914,
            "Unnamed: 19": -122238939.7778646052,
            "Unnamed: 20": -129047885.2144164145,
            "Unnamed: 21": -135741468.7573471069,
            "Unnamed: 22": -142771296.8996693194,
            "Unnamed: 23": -149328019.1424946785,
            "Unnamed: 24": -155729891.2338775992,
            "Unnamed: 25": -162021140.0005863905,
            "Unnamed: 26": -168395801.1385933161,
            "Unnamed: 27": -168395801.1385933161,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Members' Equity",
            "Unnamed: 2": 337848412.4300001264,
            "Unnamed: 3": 332430464.9200000763,
            "Unnamed: 4": 327014828.8200000525,
            "Unnamed: 5": 320899186.1200000644,
            "Unnamed: 6": 314615349.3200000525,
            "Unnamed: 7": 304606003.6000000834,
            "Unnamed: 8": 297671545.1300001144,
            "Unnamed: 9": 290674526.9200000763,
            "Unnamed: 10": 283585865.0000001192,
            "Unnamed: 11": 276070520.6800001264,
            "Unnamed: 12": 268915005.4700000882,
            "Unnamed: 13": 265602172.7900000811,
            "Unnamed: 14": 265602172.7900000811,
            "Unnamed: 15": 249897085.2600000799,
            "Unnamed: 16": 247779705.8500001132,
            "Unnamed: 17": 259059193.1434476972,
            "Unnamed: 18": 230147887.7463591695,
            "Unnamed: 19": 222500915.8221354783,
            "Unnamed: 20": 215691970.3855836689,
            "Unnamed: 21": 208998386.8426529765,
            "Unnamed: 22": 201968558.7003307641,
            "Unnamed: 23": 195411836.4575054049,
            "Unnamed: 24": 189009964.3661224842,
            "Unnamed: 25": 182718715.599413693,
            "Unnamed: 26": 176344054.4614067674,
            "Unnamed: 27": 176344054.4614067674,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": 0,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": "Total Liabilities & Members' Equity",
            "Unnamed: 2": 551268188.0600001812,
            "Unnamed: 3": 546302320.2200000286,
            "Unnamed: 4": 536900945.7000000477,
            "Unnamed: 5": 530762967.3600000739,
            "Unnamed: 6": 526704095.3700000644,
            "Unnamed: 7": 514911115.0500000715,
            "Unnamed: 8": 503719151.6900001168,
            "Unnamed: 9": 505694197.0800000429,
            "Unnamed: 10": 501584560.9900001287,
            "Unnamed: 11": 491354294.1600001454,
            "Unnamed: 12": 485455944.560000062,
            "Unnamed: 13": 486770334.9100000858,
            "Unnamed: 14": 486770334.9100000858,
            "Unnamed: 15": 478921106.310000062,
            "Unnamed: 16": 475348428.060000062,
            "Unnamed: 17": 490911580.8229964375,
            "Unnamed: 18": 466613735.5975533128,
            "Unnamed: 19": 461518630.4222831726,
            "Unnamed: 20": 456791388.2690299749,
            "Unnamed: 21": 446757702.8516770601,
            "Unnamed: 22": 441027622.0954346657,
            "Unnamed: 23": 436790931.9307106733,
            "Unnamed: 24": 427177010.3968640566,
            "Unnamed: 25": 423552604.8649276495,
            "Unnamed: 26": 419534123.2043315172,
            "Unnamed: 27": 419534123.2043315172,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": null,
            "Unnamed: 3": null,
            "Unnamed: 4": null,
            "Unnamed: 5": null,
            "Unnamed: 6": null,
            "Unnamed: 7": null,
            "Unnamed: 8": null,
            "Unnamed: 9": null,
            "Unnamed: 10": null,
            "Unnamed: 11": null,
            "Unnamed: 12": null,
            "Unnamed: 13": null,
            "Unnamed: 14": null,
            "Unnamed: 15": null,
            "Unnamed: 16": null,
            "Unnamed: 17": null,
            "Unnamed: 18": null,
            "Unnamed: 19": null,
            "Unnamed: 20": null,
            "Unnamed: 21": null,
            "Unnamed: 22": null,
            "Unnamed: 23": null,
            "Unnamed: 24": null,
            "Unnamed: 25": null,
            "Unnamed: 26": null,
            "Unnamed: 27": null,
        },
        {
            ABC: null,
            "Unnamed: 1": null,
            "Unnamed: 2": -22018089.2300001979,
            "Unnamed: 3": -22772971.75,
            "Unnamed: 4": -23612676.9600000381,
            "Unnamed: 5": -24429428.7400000095,
            "Unnamed: 6": -25595995.5400000215,
            "Unnamed: 7": -24340275.8200000525,
            "Unnamed: 8": -25371666.4900001287,
            "Unnamed: 9": -26371168.370000124,
            "Unnamed: 10": -28112637.4500001073,
            "Unnamed: 11": -29041168.4100000858,
            "Unnamed: 12": -29804269.3700000644,
            "Unnamed: 13": -30375066.7800000906,
            "Unnamed: 14": -30375066.7800000906,
            "Unnamed: 15": -29722884.7200001478,
            "Unnamed: 16": -30460878.8899999857,
            "Unnamed: 17": 0,
            "Unnamed: 18": 0,
            "Unnamed: 19": 0,
            "Unnamed: 20": 0,
            "Unnamed: 21": 0,
            "Unnamed: 22": 0,
            "Unnamed: 23": 0,
            "Unnamed: 24": 0,
            "Unnamed: 25": 0,
            "Unnamed: 26": 0,
            "Unnamed: 27": 0,
        },
    ];

    return (
        <div>
            <h2>JSON Data as Table</h2>
            <table>
                <thead>
                    <tr>
                        {Object.keys(jsontest[0]).map((key, index) => (
                            <th key={index}>{key}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {jsontest.map((row: any, index: any) => (
                        <tr key={index}>
                            {Object.values(row).map((value: any, index: any) => (
                                <td key={index}>{value}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Test;
