import {
    Stack,
    Typography,
    Button,
    Modal,
    Checkbox,
    Box,
    Divider,
    IconButton,
    Input,
    MenuItem,
    Select,
    Tooltip,
    LinearProgress,
    Chip,
    OutlinedInput,
} from "@mui/material";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/setting";
import { makeStyles } from "@mui/styles";
import { Close, Add, PlayArrow, PauseCircle } from "@mui/icons-material";
import SearchMidiumIcon from "../../../components/Common/svgLogs/SearchMidiumIcon";
import { Api } from "../../../apis";
import ArraowUp from "../../../components/Common/svgLogs/ArraowUp";

const SelectionModal = () => {
    const classes = useStyles();
    const { contextStore, setContext } = useContext<any>(Context);
    const [open, setOpen] = useState<boolean>(false);
    const [list, setlist] = useState<any[]>([]);
    const [in_progress, setIn_progress] = useState<boolean>(false);
    const [prossess, setProssess] = useState<number>(1);
    const handleConfirm = () => {
        setOpen(false);
    };
    const handleUpload = (e: { target: { files: any } }) => {
        setIn_progress(true);
        setlist([{ file: Array?.from(e?.target?.files), load: true }, ...list]);
        const formData = new FormData();
        Array?.from(e?.target?.files)?.forEach((el: any) => {
            formData.append("files", el);
        });
        formData.append("user_id", contextStore?.profile?.userId);
        Api?.uploadBandhan(formData)
            ?.then((res: any) => {
                if (res?.status === 200) {
                    trackProgress();
                } else {
                    setlist((prev) => prev?.slice(1));
                    setIn_progress(false);
                    setProssess(0);
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Failed To Upload Documents, Please Try Again.",
                        },
                    });
                }
            })
            ?.catch((err) => {
                setIn_progress(false);
                setProssess(1);
                setlist((prev) => prev?.slice(1));
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Failed To Upload Documents, Please Try Again.",
                    },
                });
            });
    };

    let timeout: NodeJS.Timeout | undefined;
    const trackProgress = () => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = undefined;
        }
        Api?.uploadProgress(contextStore?.profile?.userId)
            ?.then((res: any) => {
                if (res?.data?.status === "processing" && res?.data?.percentage < 100) {
                    timeout = setTimeout(() => {
                        trackProgress();
                        setProssess(res?.data?.percentage);
                    }, 5000);
                } else {
                    Api?.getPdfs({
                        user_id: contextStore?.profile?.userId,
                    }).then((res: any) => {
                        if (res?.status === 200) {
                            setContext({ ...contextStore, pdfList: res?.data?.sort() });
                            setlist((prev) => prev?.slice(1));
                        }
                    });
                    setIn_progress(false);
                    setProssess(1);
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "success",
                            message: "Files Upload Success.",
                        },
                    });
                }
            })
            ?.catch((err) => {
                setIn_progress(false);
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Failed To Upload Documents, Please Try Again.",
                    },
                });
            });
    };

    const handleSearch = (e: { target: { value: string } }) => {
        if (e?.target?.value?.trim() === "") {
            setlist(contextStore?.pdfList?.filter((item: any) => item?.file_type === "bandhan") || []);
        } else {
            setlist(
                contextStore?.pdfList?.filter(
                    (file: any) =>
                        file?.file_name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase()) &&
                        file?.file_type === "bandhan"
                )
            );
        }
    };
    useEffect(() => {
        setlist(contextStore?.pdfList?.filter((item: any) => item?.file_type === "bandhan") || []);
    }, [contextStore?.pdfList]);

    return (
        <Stack sx={{ gap: 0.1, ml: 0.5 }} direction={"row"} alignItems={"center"}>
            <Typography
                sx={{
                    fontSize: "0.93rem",
                    lineHeight: "120%",
                    color: "#111",
                    fontWeight: 600,
                    letterSpacing: 1.05,
                }}
            >
                Using
            </Typography>
            <Button
                sx={{
                    backgroundColor: "#E3F4FF",
                    color: "#007BFF",
                    fontWeight: 600,
                    borderRadius: "19px",
                    py: 0.3,
                    ml: 1,
                    wordBreak: "keep-all",
                    gap: 1,
                }}
                onClick={() => setOpen(true)}
            >
                <Typography
                    noWrap
                    maxWidth={"10rem"}
                    sx={{ fontSize: "0.85rem", fontWeight: "bold", letterSpacing: 1.05 }}
                >
                    {contextStore?.pdfList?.find(
                        (item: any) => item?.file_id === contextStore?.aurigo?.selected[0]?.file_id
                    )?.alais ||
                        contextStore?.aurigo?.selected[0]?.file_name ||
                        "Select File"}{" "}
                    <span style={{ color: "#000", marginLeft: "6px" }}>
                        {contextStore?.aurigo?.selected?.length > 1
                            ? `+${contextStore?.aurigo?.selected?.length - 1} more`
                            : ""}
                    </span>
                </Typography>
                <p style={{ padding: "0px 4px" }}>
                    <ArraowUp />
                </p>
            </Button>
            <Modal open={open} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            background: "#fff",
                            borderRadius: "19px",
                            width: "65rem",
                            py: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            overflow: "hidden",
                        }}
                    >
                        <Box className={classes?.flexBetweenContainer} sx={{ px: 2 }}>
                            <Typography sx={{ size: "19px" }}>Data Sources</Typography>
                            <IconButton onClick={handleConfirm}>
                                <Close />
                            </IconButton>
                        </Box>
                        <Box sx={{ position: "relative", px: 2 }}>
                            <Box>
                                <Typography variant="caption" sx={{ color: "#6B6B6B" }}>
                                    Current Data Source in use
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex_start",
                                    alignItems: "center",
                                }}
                            >
                                <Select
                                    disabled
                                    value={"pdf"}
                                    size="small"
                                    sx={{
                                        width: "8rem",
                                        height: "2rem",
                                        borderRadius: "10px",
                                        borderColor: "#EBEBEB",
                                        fontSize: "0.87rem",
                                    }}
                                >
                                    {[{ title: "PDF", value: "pdf" }]?.map((type) => (
                                        <MenuItem value={type?.value}>{type?.title}</MenuItem>
                                    ))}
                                </Select>
                            </Box>

                            <Box
                                sx={{
                                    position: "absolute",
                                    right: "16px",
                                    bottom: "0px",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{ background: "#007BFF", color: "#fff" }}
                                    component="label"
                                    disabled={in_progress}
                                >
                                    <input hidden type="file" multiple accept=".pdf" onChange={handleUpload} />
                                    Upload File
                                </Button>
                            </Box>
                        </Box>

                        <Box>
                            <Divider sx={{ borderColor: "#e2e2e2" }} />
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Input
                                    startAdornment={<SearchMidiumIcon />}
                                    placeholder={`Search in PDF`}
                                    onChange={handleSearch}
                                    disableUnderline
                                    sx={{
                                        flex: 1,
                                        height: "2.5rem",
                                        "&:hover": {
                                            border: "none",
                                        },
                                        ".MuiInput-input::-webkit-input-placeholder": { fontStyle: "italic" },
                                        ".MuiInput-input": { pl: 1 },
                                        px: 2,
                                        ml: 2,
                                    }}
                                />
                            </Box>
                            <Divider sx={{ borderColor: "#e2e2e2" }} />
                        </Box>

                        <Box sx={{ px: 1.5 }}>
                            <Box sx={{ height: "34vh", overflowY: "auto" }}>
                                {list?.map((pdf: any) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            py: 0.5,
                                            pl: 0,
                                            transition: "ease-in-out 400ms",
                                        }}
                                    >
                                        {pdf?.load ? (
                                            <Stack gap={1} width={"100%"}>
                                                <Stack direction={"row"} gap={1} flexWrap={"wrap"} px={1.5}>
                                                    {pdf?.file?.map((item: any, index: number) => (
                                                        <Chip
                                                            sx={{
                                                                "& .MuiChip-label": {
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: 1,
                                                                    "& > p": {
                                                                        transition: "ease-in-out 200ms",
                                                                    },
                                                                    "&:hover": {
                                                                        "& > p": {
                                                                            width: "10rem",
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            label={
                                                                <>
                                                                    <Typography noWrap width={"1rem"}>
                                                                        {item?.name}
                                                                    </Typography>
                                                                    <Stack
                                                                        sx={{
                                                                            width: "1.3rem",
                                                                            height: "1.3rem",
                                                                            borderRadius: "50%",
                                                                            boxShadow:
                                                                                "0px 0px 3px 1px rgba(0,0,0,0.1)",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        {index + 1}
                                                                    </Stack>
                                                                </>
                                                            }
                                                        ></Chip>
                                                    ))}
                                                </Stack>
                                                <Stack px={2}>
                                                    <LinearProgress variant="determinate" value={prossess} />
                                                </Stack>
                                            </Stack>
                                        ) : (
                                            <Stack
                                                alignItems={"center"}
                                                justifyContent={"space-between"}
                                                direction={"row"}
                                                width={"100%"}
                                                pr={1}
                                            >
                                                <Stack
                                                    alignItems={"center"}
                                                    direction={"row"}
                                                    flex={1}
                                                    sx={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        let doc_list = contextStore?.aurigo?.selected || [];
                                                        const exist = doc_list?.find(
                                                            (item: any) => item?.file_id === pdf?.file_id
                                                        );
                                                        if (exist) {
                                                            doc_list = doc_list?.filter(
                                                                (item: any) => item?.file_id !== pdf?.file_id
                                                            );
                                                        } else {
                                                            doc_list = [...doc_list, pdf];
                                                        }
                                                        setContext({
                                                            ...contextStore,
                                                            aurigo: { ...contextStore?.aurigo, selected: doc_list },
                                                        });
                                                    }}
                                                >
                                                    <Checkbox
                                                        size="small"
                                                        checked={
                                                            contextStore?.aurigo?.selected?.find(
                                                                (item: any) => item?.file_id === pdf?.file_id
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <Tooltip title={pdf?.file_name}>
                                                        <Typography
                                                            sx={{ cursor: "pointer" }}
                                                            variant="subtitle2"
                                                            noWrap
                                                        >
                                                            {pdf?.file_name}
                                                        </Typography>
                                                    </Tooltip>
                                                </Stack>
                                                <Stack width={"10rem"}>
                                                    <OutlinedInput
                                                        value={pdf?.alais || ""}
                                                        size="small"
                                                        placeholder="Alias Name"
                                                        onChange={(e: { target: { value: string } }) => {
                                                            const docList = contextStore?.pdfList?.map((item: any) =>
                                                                item?.file_id === pdf?.file_id
                                                                    ? { ...item, alais: e?.target?.value }
                                                                    : item
                                                            );
                                                            setContext({ ...contextStore, pdfList: docList });
                                                        }}
                                                    />
                                                </Stack>
                                            </Stack>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        </Box>

                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <Button
                                variant="contained"
                                sx={{
                                    background: "#007BFF",
                                    width: "8rem",
                                    height: "2.3rem",
                                    borderRadius: "10px",
                                    color: "#fff",
                                }}
                                onClick={() => handleConfirm()}
                            >
                                Confirm
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Stack>
    );
};

const useStyles = makeStyles({
    flexBetweenContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
});

export default SelectionModal;
