import React from "react";

const AddIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="22" height="22" rx="3" fill="white" />
            <path
                d="M17 12H12V17C12 17.55 11.55 18 11 18C10.45 18 10 17.55 10 17V12H5C4.45 12 4 11.55 4 11C4 10.45 4.45 10 5 10H10V5C10 4.45 10.45 4 11 4C11.55 4 12 4.45 12 5V10H17C17.55 10 18 10.45 18 11C18 11.55 17.55 12 17 12Z"
                fill="#007BFF"
            />
        </svg>
    );
};

export default AddIcon;
