import React from "react";

const Upcoming2Icon = () => {
    return (
        <svg width="39" height="37" viewBox="0 0 39 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.3649 28.2518C0.967507 28.8991 0.51743 29.6578 0.162185 30.2584C0.0839109 30.3923 0.0342368 30.5414 0.0116577 30.6949C-0.0097755 30.8491 -0.00160126 31.0059 0.0357421 31.157C0.0718687 31.3091 0.138101 31.4506 0.228417 31.577C0.318734 31.7034 0.433135 31.8118 0.564093 31.8961L8.54657 36.8169C8.68355 36.9027 8.83859 36.9599 8.99965 36.9854C9.15921 37.011 9.32479 37.005 9.48285 36.9659C9.6409 36.9267 9.78992 36.8575 9.92088 36.7612C10.0518 36.6648 10.1617 36.5429 10.2445 36.4029C10.5666 35.867 10.9746 35.1731 11.4126 34.4325C14.5767 29.2438 17.7829 29.879 23.5105 32.6021L31.4282 36.3562C31.5765 36.4273 31.7375 36.4679 31.9017 36.4754C32.066 36.4829 32.23 36.4572 32.3841 36.3999C32.5376 36.3427 32.6791 36.2554 32.798 36.1425C32.9185 36.0296 33.0133 35.8941 33.0795 35.7436L36.8803 27.1741C36.9451 27.0296 36.9812 26.8745 36.9857 26.7165C36.9917 26.5599 36.9661 26.4019 36.912 26.2543C36.8578 26.1068 36.7765 25.9698 36.6711 25.8524C36.5657 25.735 36.4393 25.6402 36.2963 25.5694C34.627 24.7837 31.3048 23.2378 28.3274 21.7807C17.4969 16.5559 8.35841 16.9006 1.3649 28.2518Z"
                fill="url(#paint0_linear_320_3517)"
            />
            <path
                d="M37.1559 8.77119C37.5578 8.12392 38.0078 7.36677 38.3586 6.76617C38.4384 6.62919 38.491 6.47715 38.5136 6.3191C38.5362 6.16255 38.5272 6.00149 38.488 5.84795C38.448 5.69403 38.3785 5.54935 38.2833 5.42196C38.1883 5.29417 38.0691 5.18626 37.9326 5.10434L29.9622 0.1836C29.8237 0.0977989 29.6701 0.0405984 29.5091 0.0150088C29.348 -0.0105809 29.1839 -0.00305454 29.0259 0.0345773C28.8678 0.0737145 28.7188 0.142957 28.5878 0.239295C28.4569 0.335632 28.347 0.457559 28.2627 0.59755C27.9481 1.13343 27.5326 1.82736 27.0946 2.56795C23.9185 7.78072 20.7423 7.14549 15.0088 4.42245L7.09854 0.666793C6.95102 0.596045 6.79146 0.553897 6.62889 0.544865C6.46632 0.534329 6.30225 0.558413 6.14871 0.612603C5.99517 0.666793 5.85368 0.751088 5.73325 0.860973C5.61133 0.970858 5.51499 1.10332 5.44575 1.25084L1.64493 9.84445C1.5143 10.1359 1.50189 10.4667 1.61031 10.7672C1.6645 10.9162 1.74729 11.0532 1.85266 11.1706C1.95803 11.288 2.08597 11.3843 2.22898 11.4536C3.90435 12.2408 7.22649 13.7853 10.1979 15.2439C20.9998 20.4672 30.1669 20.124 37.1559 8.77119Z"
                fill="url(#paint1_linear_320_3517)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_320_3517"
                    x1="36.6245"
                    y1="39.365"
                    x2="12.5098"
                    y2="25.5061"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0052CC" />
                    <stop offset="0.92" stopColor="#2380FB" />
                    <stop offset="1" stopColor="#2684FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_320_3517"
                    x1="1.89526"
                    y1="-2.34677"
                    x2="26.0158"
                    y2="11.5121"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0052CC" />
                    <stop offset="0.92" stopColor="#2380FB" />
                    <stop offset="1" stopColor="#2684FF" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default Upcoming2Icon;
