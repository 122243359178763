import React from "react";

const UploadBoxIconSmall = () => {
    return (
        <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.6938 5.1622L15.0859 0.45901C14.7979 0.165106 14.4073 0 14 0C13.5927 0 13.2021 0.165106 12.9141 0.45901L8.30616 5.1622C8.02638 5.45788 7.87156 5.85389 7.87506 6.26494C7.87856 6.67599 8.04009 7.0692 8.32487 7.35987C8.60966 7.65054 8.9949 7.81542 9.39762 7.81899C9.80035 7.82256 10.1883 7.66454 10.478 7.37897L12.464 5.35189V23.5156C12.464 23.9314 12.6259 24.3302 12.9139 24.6242C13.202 24.9182 13.5926 25.0833 14 25.0833C14.4074 25.0833 14.798 24.9182 15.0861 24.6242C15.3741 24.3302 15.536 23.9314 15.536 23.5156V5.35189L17.522 7.37897C17.8117 7.66454 18.1997 7.82256 18.6024 7.81899C19.0051 7.81542 19.3903 7.65054 19.6751 7.35987C19.9599 7.0692 20.1214 6.67599 20.1249 6.26494C20.1284 5.85389 19.9736 5.45788 19.6938 5.1622Z"
                fill="#007BFF"
            />
            <path
                d="M23.3333 10.7914H15.5556V23.3555C15.5556 23.772 15.3917 24.1715 15.0999 24.466C14.8082 24.7605 14.4126 24.926 14 24.926C13.5874 24.926 13.1918 24.7605 12.9001 24.466C12.6083 24.1715 12.4444 23.772 12.4444 23.3555V10.7914H4.66667C3.42937 10.7926 2.2431 11.2894 1.3682 12.1727C0.493298 13.0561 0.00123545 14.2537 0 15.5029V26.4965C0.00123545 27.7457 0.493298 28.9434 1.3682 29.8267C2.2431 30.71 3.42937 31.2068 4.66667 31.208H23.3333C24.5706 31.2068 25.7569 30.71 26.6318 29.8267C27.5067 28.9434 27.9988 27.7457 28 26.4965V15.5029C27.9988 14.2537 27.5067 13.0561 26.6318 12.1727C25.7569 11.2894 24.5706 10.7926 23.3333 10.7914Z"
                fill="#E3F4FF"
            />
        </svg>
    );
};

export default UploadBoxIconSmall;
