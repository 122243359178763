import { AxiosResponse } from "axios";
import { defaultCatch } from "./";

function apiPromise(request: () => Promise<AxiosResponse<any, any>>) {
  return new Promise((resolve) => {
    request()
      .then((response) => resolve(response))
      .catch((error) => defaultCatch(error, resolve));
  });
}

export default apiPromise;
