import React from "react";

const YoutubeIcon = () => {
    return (
        <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M27.3888 3.07388C27.2277 2.48001 26.9141 1.93863 26.479 1.50354C26.0439 1.06844 25.5025 0.754765 24.9086 0.593688C22.7342 1.56462e-07 13.9825 0 13.9825 0C13.9825 0 5.23031 0.0175001 3.0555 0.611188C2.46164 0.772265 1.92026 1.08594 1.48516 1.52104C1.05006 1.95613 0.73639 2.49751 0.575312 3.09137C0 5.26619 0 9.81312 0 9.81312C0 9.81312 0 14.3601 0.59325 16.5524C0.754328 17.1462 1.068 17.6876 1.5031 18.1227C1.9382 18.5578 2.47957 18.8715 3.07344 19.0326C5.24781 19.6262 14 19.6262 14 19.6262C14 19.6262 22.7522 19.6262 24.927 19.033C25.5209 18.8719 26.0622 18.5582 26.4973 18.1231C26.9324 17.6881 27.2461 17.1467 27.4072 16.5528C28 14.378 28 9.81312 28 9.81312C28 9.81312 27.9825 5.26619 27.3888 3.07388Z"
                fill="#FF0000"
            />
            <path d="M11.1977 14.0179L18.458 9.81267L11.1973 5.60742L11.1977 14.0179Z" fill="white" />
        </svg>
    );
};

export default YoutubeIcon;
