import React, { useRef, useState } from "react";
import useWebSocket from "react-use-websocket";
import { Api } from "../../../apis";
import { Some } from "./Some";

export const ReportSocket: any = () => {
    const [msg, setMsg] = useState<string>("");
    const msgRef: any = useRef();

    const { getWebSocket, lastJsonMessage, lastMessage, readyState, sendJsonMessage, sendMessage } = useWebSocket(
        Api?.reportSocket,
        {
            onOpen: () => console.log("WebSocket connection established."),
            onMessage: (resp: any) => {
                const dataString = Some.String(resp?.data);
                msgRef.dataString = dataString; // Add dataString to msgRef
                if (
                    dataString.includes("Connection established") ||
                    dataString.includes("Provide pdf_name first") ||
                    dataString.includes("is_function_calling_model")
                ) {
                    return;
                }

                if (dataString.includes("index loaded for pdf")) {
                    return setTimeout(() => {
                        console.log("loaded successfully");
                    }, 800);
                }

                if (dataString?.startsWith("Error occurred")) {
                    setMsg((prev) => prev + "<EOD>");
                    msgRef.load = false;
                    return;
                }

                msgRef.load = true;
                setMsg((prev: string) => {
                    try {
                        const json = JSON.parse(dataString);
                        if (json) {
                            msgRef.graphData = json;
                        } else {
                            msgRef.msg = msgRef?.msg?.length > 0 ? msgRef?.msg + dataString : dataString;
                        }
                    } catch (error) {
                        if (dataString === "<EOD>") {
                            msgRef.load = false;
                        } else {
                            msgRef.msg = msgRef?.msg?.length > 0 ? msgRef?.msg + dataString : dataString;
                        }
                    }
                    return prev + dataString;
                });
            },
            onError: (event: any) => {
                console.log(event);
            },
        }
    );
    return [msg, { getWebSocket, lastJsonMessage, lastMessage, readyState, sendJsonMessage, sendMessage }, msgRef];
};

export default ReportSocket;
