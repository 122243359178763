import React from "react";

const SortIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.76774 3.93558C5.93964 3.76373 6.17275 3.66719 6.41582 3.66719C6.65889 3.66719 6.892 3.76373 7.0639 3.93558L10.7306 7.60224C10.8975 7.77513 10.9899 8.00668 10.9879 8.24703C10.9858 8.48737 10.8894 8.71729 10.7194 8.88724C10.5494 9.0572 10.3195 9.1536 10.0792 9.15569C9.83884 9.15778 9.60729 9.06539 9.4344 8.89841L7.33249 6.79649V17.417C7.33249 17.6601 7.23591 17.8933 7.064 18.0652C6.89209 18.2371 6.65893 18.3337 6.41582 18.3337C6.1727 18.3337 5.93955 18.2371 5.76764 18.0652C5.59573 17.8933 5.49915 17.6601 5.49915 17.417V6.79649L3.39724 8.89841C3.22435 9.06539 2.9928 9.15778 2.75245 9.15569C2.5121 9.1536 2.28219 9.0572 2.11224 8.88724C1.94228 8.71729 1.84587 8.48737 1.84378 8.24703C1.8417 8.00668 1.93409 7.77513 2.10107 7.60224L5.76774 3.93558ZM14.6658 15.2042V4.58366C14.6658 4.34054 14.7624 4.10739 14.9343 3.93548C15.1062 3.76357 15.3394 3.66699 15.5825 3.66699C15.8256 3.66699 16.0588 3.76357 16.2307 3.93548C16.4026 4.10739 16.4992 4.34054 16.4992 4.58366V15.2042L18.6011 13.1022C18.774 12.9353 19.0055 12.8429 19.2459 12.845C19.4862 12.847 19.7161 12.9435 19.8861 13.1134C20.056 13.2834 20.1524 13.5133 20.1545 13.7536C20.1566 13.994 20.0642 14.2255 19.8972 14.3984L16.2306 18.0651C16.0587 18.2369 15.8256 18.3335 15.5825 18.3335C15.3394 18.3335 15.1063 18.2369 14.9344 18.0651L11.2677 14.3984C11.1008 14.2255 11.0084 13.994 11.0105 13.7536C11.0125 13.5133 11.1089 13.2834 11.2789 13.1134C11.4489 12.9435 11.6788 12.847 11.9191 12.845C12.1595 12.8429 12.391 12.9353 12.5639 13.1022L14.6658 15.2042Z"
                fill="#007BFF"
            />
        </svg>
    );
};

export default SortIcon;
