import { ArrowBackIos, Subtitles } from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Api } from "../../../apis";
import VarianceSocket from "../utils/VarianceSocket";
import InputField from "./InputField";
import MessageBox from "./MessageBox";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import DocumentLoadingModal from "../../Chat/DocumentLoading/DocumentLoadingModal";
import TimeFrameIcon from "../../../components/Common/svgLogs/Finance_CFO/TimeFrameIcon";

const ChatBox = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [currentMonth, setCurrentMonth] = useState<string>(state?.currentMonth);
    const [previousMonth, setPreviousMonth] = useState<string>(state?.previousMonth);
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [tempMonths, setTempMonths] = useState<any>({
        currentMonth: state?.currentMonth,
        previousMonth: state?.previousMonth,
    });
    const [showPopover, setShowPopOver] = useState<boolean>(false);
    const [varianceMsg, varianceSocketHooks, varianceMsgRef] = VarianceSocket();
    const [status, setStatus] = useState<string>("Generating Report");
    const [visibleChat, setVisibleChat] = useState<boolean>(false);

    const generateReport = () => {
        setLoading(true);
        Api?.getVariaceReport()?.then((res: any) => {
            setStatus("Report Fetched");
            if (res?.status === 200) {
                setVisibleChat(true);
                setData(JSON?.parse(res?.data));
            } else {
                setVisibleChat(false);
                setData([]);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (varianceMsgRef?.load === false) {
            setStatus("Fetching Report");
            generateReport();
        }
    }, [varianceMsgRef?.load]);

    useEffect(() => {
        setLoading(true);
        varianceSocketHooks?.sendJsonMessage({
            variance_report: {
                current_month: currentMonth,
                previous_month: previousMonth,
            },
        });
    }, [previousMonth, currentMonth]);

    const tableHeads = [
        "Revenue",
        "Cost of Treatment",
        "Gross Profit",
        "Operating Expenses",
        "Operating Profit",
        "Non-Operating Incomes/ (Expenses)",
        "Net Profit",
    ];

    console.log(tempMonths);

    return (
        <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ flex: 1, display: "flex", width: "100%" }}>
                {/* {!visibleChat && (
                    <>
                        <Box sx={{ p: 4, display: "flex", flexDirection: "column", gap: 3 }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.3 }}>
                                <Typography variant="caption">Current Month</Typography>
                                <Select
                                    sx={{ height: "2.2rem", width: "18rem" }}
                                    value={currentMonth}
                                    onChange={(e) => setCurrentMonth(e?.target?.value)}
                                >
                                    {[
                                        "Jan_23",
                                        "Feb_23",
                                        "Mar_23",
                                        "Apr_23",
                                        "May_23",
                                        "Jun_23",
                                        "Jul_23",
                                        "Aug_23",
                                        "Sep_23",
                                        "Oct_23",
                                        "Nov_23",
                                        "Dec_23",
                                    ]?.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
                                </Select>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.3 }}>
                                <Typography variant="caption">Previous Month</Typography>
                                <Select
                                    sx={{ height: "2.2rem", width: "18rem" }}
                                    value={previousMonth}
                                    onChange={(e) => setPreviousMonth(e?.target?.value)}
                                >
                                    {[
                                        "Jan_23",
                                        "Feb_23",
                                        "Mar_23",
                                        "Apr_23",
                                        "May_23",
                                        "Jun_23",
                                        "Jul_23",
                                        "Aug_23",
                                        "Sep_23",
                                        "Oct_23",
                                        "Nov_23",
                                        "Dec_23",
                                    ]?.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
                                </Select>
                            </Box>
                            <Button
                                sx={{
                                    textTransform: "capitalize",
                                    height: "2.2rem",
                                    color: "#007BFF",
                                    width: "18rem",
                                    mt: 1,
                                }}
                                variant="outlined"
                                onClick={() => {
                                    setStatus("Generating Report");
                                    varianceSocketHooks?.sendJsonMessage({
                                        variance_report: {
                                            current_month: currentMonth,
                                            previous_month: previousMonth,
                                        },
                                    });
                                }}
                            >
                                {loading ? (
                                    <CircularProgress style={{ width: "25px", height: "25px" }} />
                                ) : (
                                    "Generate Report"
                                )}
                            </Button>
                        </Box>
                        <Divider orientation="vertical" />
                    </>
                )} */}
                <ReflexContainer orientation="vertical">
                    {visibleChat && (
                        <ReflexElement flex={1}>
                            <MessageBox
                                varianceSocketHooks={varianceSocketHooks}
                                varianceMsgRef={varianceMsgRef}
                                fileName={state?.fileName}
                            />
                        </ReflexElement>
                    )}
                    {visibleChat && (
                        <ReflexSplitter>
                            <Box
                                sx={{
                                    width: "16px",
                                    height: "100%",
                                    background: "#E3F4FF",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-evenly",
                                    borderWidth: "0px 2px 0px 2px",
                                    borderStyle: "solid",
                                    borderColor: "#E3F4FF",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "3px",
                                        height: "15px",
                                        background: "#0000001C",
                                        borderRadius: "10px",
                                    }}
                                ></Box>
                                <Box
                                    sx={{
                                        width: "3px",
                                        height: "15px",
                                        background: "#0000001C",
                                        borderRadius: "10px",
                                    }}
                                ></Box>
                            </Box>
                        </ReflexSplitter>
                    )}
                    <ReflexElement flex={1.5} minSize={600}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                p: 3,
                                pr: 1.5,
                                flexDirection: "column",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    justifyContent: "space-between",
                                    display: "flex",
                                    alignItems: "center",
                                    mb: 2,
                                }}
                            >
                                <Typography variant="h6">Generated Report</Typography>
                                <Box>
                                    <Button
                                        sx={{ display: "flex", alignItems: "center", position: "relative" }}
                                        onClick={() => setShowPopOver(!showPopover)}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 0.5,
                                            }}
                                        >
                                            <TimeFrameIcon />
                                            <Typography sx={{ mt: 0.1 }}>Time Frame</Typography>
                                        </Box>
                                    </Button>
                                    {showPopover ? (
                                        <Box
                                            sx={{
                                                width: "300px",
                                                height: "250px",
                                                position: "absolute",
                                                right: "10px",
                                                top: "65px",
                                                border: "1px solid #DFDFDF",
                                                borderRadius: "13px",
                                                zIndex: 10,
                                                p: 2,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-evenly",
                                                background: "#fff",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-start",
                                                    width: "100%",
                                                }}
                                            >
                                                <Typography sx={{ mb: 0.5 }}>Current Month</Typography>
                                                <Select
                                                    sx={{ height: "2.2rem", width: "100%" }}
                                                    value={tempMonths?.currentMonth}
                                                    onChange={(e) =>
                                                        setTempMonths({
                                                            currentMonth: e?.target?.value,
                                                            previousMonth: tempMonths?.previousMonth,
                                                        })
                                                    }
                                                >
                                                    {[
                                                        { id: "Jan_23", name: "January 2023" },
                                                        { id: "Feb_23", name: "February 2023" },
                                                        { id: "Mar_23", name: "March 2023" },
                                                        { id: "Apr_23", name: "April 2023" },
                                                        { id: "May_23", name: "May 2023" },
                                                        { id: "Jun_23", name: "June 2023" },
                                                        { id: "Jul_23", name: "July 2023" },
                                                        { id: "Aug_23", name: "August 2023" },
                                                        { id: "Sep_23", name: "September 2023" },
                                                        { id: "Oct_23", name: "October 2023" },
                                                        { id: "Nov_23", name: "November 2023" },
                                                        { id: "Dec_23", name: "December 2023" },
                                                    ]?.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
                                                </Select>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-start",
                                                    width: "100%",
                                                }}
                                            >
                                                <Typography sx={{ mb: 0.5 }}>Previous Month</Typography>
                                                <Select
                                                    sx={{ height: "2.2rem", width: "100%" }}
                                                    value={tempMonths?.previousMonth}
                                                    onChange={(e) =>
                                                        setTempMonths({
                                                            currentMonth: tempMonths?.currentMonth,
                                                            previousMonth: e?.target?.value,
                                                        })
                                                    }
                                                >
                                                    {[
                                                        { id: "Jan_23", name: "January 2023" },
                                                        { id: "Feb_23", name: "February 2023" },
                                                        { id: "Mar_23", name: "March 2023" },
                                                        { id: "Apr_23", name: "April 2023" },
                                                        { id: "May_23", name: "May 2023" },
                                                        { id: "Jun_23", name: "June 2023" },
                                                        { id: "Jul_23", name: "July 2023" },
                                                        { id: "Aug_23", name: "August 2023" },
                                                        { id: "Sep_23", name: "September 2023" },
                                                        { id: "Oct_23", name: "October 2023" },
                                                        { id: "Nov_23", name: "November 2023" },
                                                        { id: "Dec_23", name: "December 2023" },
                                                    ]?.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
                                                </Select>
                                            </Box>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    background: "#007BFF",
                                                    width: "100%",
                                                    height: "2.1rem",
                                                    borderRadius: "5px",
                                                    "&:hover": {
                                                        boxShadow: "0px -1px 6px 4px #d4d4d4",
                                                    },
                                                    mt: 3.1,
                                                }}
                                                onClick={() => {
                                                    setCurrentMonth(tempMonths?.currentMonth);
                                                    setPreviousMonth(tempMonths?.previousMonth);
                                                }}
                                            >
                                                Re-Generate
                                            </Button>
                                        </Box>
                                    ) : null}
                                </Box>
                            </Box>
                            {data?.length > 0 ? (
                                <Box
                                    sx={{
                                        pb: 1,
                                        width: "100%",
                                        overflow: "auto",
                                        height: "80vh",
                                        border: "1px solid #f2f2f2",
                                        borderRadius: "8px",
                                        boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.1)",
                                    }}
                                >
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {Object?.keys(data[0])?.map((item: string, headIndex: number) =>
                                                    item === "Comments" ? (
                                                        <TableCell
                                                            sx={{
                                                                background: "#e3f4ff",
                                                                minWidth: "25rem",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            {item}
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell
                                                            sx={{
                                                                background: "#e3f4ff",
                                                                minWidth: item === "Variance %" ? "8rem" : "auto",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            {[1, 2]?.includes(headIndex)
                                                                ? item?.replaceAll("_", "'")
                                                                : item}
                                                        </TableCell>
                                                    )
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data?.map((item) => {
                                                return (
                                                    <TableRow>
                                                        {Object?.keys(item)?.map((subItem) => (
                                                            <TableCell
                                                                sx={{
                                                                    border: "1px solid #f2f2f2",
                                                                    background:
                                                                        subItem === "Variance %"
                                                                            ? item["Impact"]?.includes("Unfavourable")
                                                                                ? "#ff9e9e"
                                                                                : item["Impact"]?.includes("Favourable")
                                                                                  ? "#89d089"
                                                                                  : "inherit"
                                                                            : "inherit",
                                                                }}
                                                            >
                                                                {isNaN(parseInt(item[subItem])) ? (
                                                                    tableHeads?.includes(item[subItem]) &&
                                                                    !item["Variance"] &&
                                                                    !item["Variance %"] &&
                                                                    !item["Comments"] ? (
                                                                        <b>{item[subItem] + " (in US$) "}</b>
                                                                    ) : (
                                                                        item[subItem]
                                                                    )
                                                                ) : item[subItem]?.toString()?.includes(".") ? (
                                                                    item[subItem]?.toString()?.includes("%") ? (
                                                                        Number(
                                                                            item[subItem]
                                                                                ?.toString()
                                                                                ?.replaceAll("%", "010101")
                                                                        )
                                                                            ?.toFixed(1)
                                                                            ?.replaceAll("010101", "%") + " %"
                                                                    ) : Math?.floor(Number(item[subItem])) < 0 ? (
                                                                        `-${Math?.floor(Number(item[subItem]))
                                                                            ?.toString()
                                                                            ?.slice(1)
                                                                            ?.split("")
                                                                            ?.reverse()
                                                                            ?.map((digit: string, index: number) =>
                                                                                index > 0 && index % 3 === 0
                                                                                    ? `${digit},`
                                                                                    : digit
                                                                            )
                                                                            ?.reverse()
                                                                            ?.join("")}`
                                                                    ) : (
                                                                        Math?.floor(Number(item[subItem]))
                                                                            ?.toString()
                                                                            ?.split("")
                                                                            ?.reverse()
                                                                            ?.map((digit: string, index: number) =>
                                                                                index > 0 && index % 3 === 0
                                                                                    ? `${digit},`
                                                                                    : digit
                                                                            )
                                                                            ?.reverse()
                                                                            ?.join("")
                                                                    )
                                                                ) : (
                                                                    item[subItem]
                                                                )}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography sx={{ fontSize: "1.3rem", fontWeight: 600 }}>{status}</Typography>
                                </Box>
                            )}
                        </Box>
                    </ReflexElement>
                </ReflexContainer>
            </Box>
            <DocumentLoadingModal loading={loading} />
        </Box>
    );
};

export default ChatBox;
