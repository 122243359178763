import React from "react";

const UploadIcon = ({ width, height }: { width?: number; height?: number }) => {
    return (
        <svg
            width={width || "65"}
            height={height || "72"}
            viewBox="0 0 65 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.4349 11.9096L34.8041 1.05897C34.1396 0.380912 33.2384 0 32.2988 0C31.3592 0 30.458 0.380912 29.7935 1.05897L19.1627 11.9096C18.5172 12.5917 18.16 13.5054 18.1681 14.4537C18.1762 15.402 18.5489 16.3092 19.2059 16.9798C19.8629 17.6504 20.7517 18.0308 21.6808 18.039C22.6099 18.0472 23.505 17.6827 24.1733 17.0238L28.7552 12.3472V54.2523C28.7552 55.2115 29.1286 56.1315 29.7931 56.8098C30.4577 57.4881 31.359 57.8692 32.2988 57.8692C33.2386 57.8692 34.14 57.4881 34.8045 56.8098C35.4691 56.1315 35.8424 55.2115 35.8424 54.2523V12.3472L40.4243 17.0238C41.0926 17.6827 41.9877 18.0472 42.9168 18.039C43.846 18.0308 44.7347 17.6504 45.3918 16.9798C46.0488 16.3092 46.4214 15.402 46.4295 14.4537C46.4376 13.5054 46.0804 12.5917 45.4349 11.9096Z"
                fill="#007BFF"
            />
            <path
                d="M53.8318 24.8965H35.8878V53.8828C35.8878 54.8438 35.5097 55.7654 34.8367 56.4449C34.1637 57.1244 33.2509 57.5061 32.2991 57.5061C31.3473 57.5061 30.4344 57.1244 29.7614 56.4449C29.0884 55.7654 28.7103 54.8438 28.7103 53.8828V24.8965H10.7664C7.91181 24.8994 5.17501 26.0455 3.15654 28.0834C1.13807 30.1212 0.00285027 32.8844 0 35.7664V61.1294C0.00285027 64.0114 1.13807 66.7745 3.15654 68.8124C5.17501 70.8503 7.91181 71.9964 10.7664 71.9993H53.8318C56.6863 71.9964 59.4231 70.8503 61.4416 68.8124C63.4601 66.7745 64.5953 64.0114 64.5981 61.1294V35.7664C64.5953 32.8844 63.4601 30.1212 61.4416 28.0834C59.4231 26.0455 56.6863 24.8994 53.8318 24.8965Z"
                fill="#E3F4FF"
            />
        </svg>
    );
};

export default UploadIcon;
