import { Box, Button, Chip, MenuItem, Modal, Select, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/setting";
import { Api } from "../../apis";
import UploadModal from "./UploadModal";
import DocumentAnalysis from "./DocumentAnalysis";
import UploadProgress from "./UploadProgress";
import GRIParametersModal from "./GRIParametersModal";
import { Done } from "@mui/icons-material";
import ImageClassification from "./ImageClassification";
import Loading from "../Auth/Loading";

const AlertGeneration = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const theme = useTheme();
    const md = useMediaQuery(theme?.breakpoints?.down("xl"));
    const [selectedFiles, setSelectedFiles] = useState<any>(null);
    const [GRIParameters, setGRIParameters] = useState<any>(null);
    const [proceed, setProceed] = useState<boolean>(false);
    const [processID, setProcessID] = useState<string>("");
    const [isUploadStarted, setIsUploadStarted] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [fileList, setFileList] = useState<any>(null);
    const [viewGRIParameters, setViewGRIParameters] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<string>("pdf");
    const [image, setImage] = useState<any>(null);
    const [closeEvent, setCloseEvent] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [chipState, setChipState] = useState<any>({
        reverseCanDetection: false,
        anomaly: false,
        dentClassification: false,
    });

    const getGRIParameters = () => {
        Api?.getGRIParameters()?.then((res: any) => {
            if (res?.data) {
                setGRIParameters(res?.data);
            } else
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Server issue! Please try after some time",
                    },
                });
        });
    };

    const uploadFiles = () => {
        if (selectedFiles !== null && selectedType === "pdf") {
            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                formData?.append("files", selectedFiles[i]);
            }
            setIsUploadStarted(true);

            Api?.uploadHenkelFiles(formData)?.then((res: any) => {
                if (res?.status === 200) {
                    setProcessID(res?.data?.process_uuid);
                    setFileList(res?.data?.file_names);
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Failed To Upload files. Please Retry after some time.",
                        },
                    });
                }
            });
            setSelectedFiles(null);
        }
    };

    const fetchImage = async () => {
        if (selectedFiles !== null && selectedType === "image") {
            if (
                chipState.anomaly === false &&
                chipState.dentClassification === false &&
                chipState.reverseCanDetection === false
            ) {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Select an option for image classification before proceeding forward.",
                    },
                });
            } else if (chipState.anomaly) {
                setLoading(true);
                try {
                    const formdata = new FormData();
                    formdata.append("file", selectedFiles[0]);
                    formdata.append("mode", "Anomaly");
                    const requestOptions: any = {
                        method: "POST",
                        body: formdata,
                        redirect: "follow",
                    };
                    const res = await fetch(
                        "https://softx-demo.softsensor.ai/bond_llm/detect_can_quality",
                        requestOptions
                    );
                    const imageBlob = await res.blob();
                    const imageObjectURL = URL.createObjectURL(imageBlob);
                    setImage(imageObjectURL);
                    setLoading(false);
                } catch (e) {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Some error occurred. Please Retry after some time.",
                        },
                    });
                }
            } else if (chipState.reverseCanDetection) {
                setLoading(true);
                try {
                    const formdata = new FormData();
                    formdata.append("file", selectedFiles[0]);
                    formdata.append("mode", "Reversed");
                    const requestOptions: any = {
                        method: "POST",
                        body: formdata,
                        redirect: "follow",
                    };
                    const res = await fetch(
                        "https://softx-demo.softsensor.ai/bond_llm/detect_can_quality",
                        requestOptions
                    );
                    const imageBlob = await res.blob();
                    const imageObjectURL = URL.createObjectURL(imageBlob);
                    setImage(imageObjectURL);
                    setLoading(false);
                } catch (e) {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Some error occurred. Please Retry after some time.",
                        },
                    });
                }
            } else if (chipState.dentClassification) {
                setLoading(true);
                try {
                    const formdata = new FormData();
                    formdata.append("file", selectedFiles[0]);
                    formdata.append("mode", "Deformation");
                    const requestOptions: any = {
                        method: "POST",
                        body: formdata,
                        redirect: "follow",
                    };
                    const res = await fetch(
                        "https://softx-demo.softsensor.ai/bond_llm/detect_can_quality",
                        requestOptions
                    );
                    const imageBlob = await res.blob();
                    const imageObjectURL = URL.createObjectURL(imageBlob);
                    setImage(imageObjectURL);
                    setLoading(false);
                } catch (e) {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Some error occurred. Please Retry after some time.",
                        },
                    });
                }
            }
        }
    };

    useEffect(() => {
        if (processID) {
            const ev = new EventSource(`${Api.henkelFileUploadProgressEvent}${processID}`);

            ev.addEventListener("message", (sse: any) => {
                setData(sse?.data);
                if (JSON.parse(sse?.data).progress === 100) {
                    ev.close();
                }
            });
        }
    }, [processID]);

    useEffect(() => {
        getGRIParameters();
    }, []);

    useEffect(() => {
        uploadFiles();
        fetchImage();
    }, [selectedFiles]);

    return proceed ? (
        <DocumentAnalysis
            setProceed={setProceed}
            setIsUploadStarted={setIsUploadStarted}
            data={data}
            fileList={fileList}
            setData={setData}
            setProcessID={setProcessID}
        />
    ) : // {image ? <Box></Box> : }
    image ? (
        <ImageClassification image={image} setImage={setImage} selectedFiles={selectedFiles} />
    ) : (
        <Box sx={{ width: "100%", height: "100%", p: "2vh 10vw" }}>
            {loading ? <Loading /> : null}
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    border: "1px solid #E0E0E0",
                    borderRadius: "10px",
                    p: "5% 3%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        <Typography variant={md ? "h6" : "h4"}>Alert Generation</Typography>
                        <Typography sx={{ color: "#424F65" }}>Start by uploading PDFs to create directory</Typography>
                    </Box>
                    {selectedType !== "image" && (
                        <Button
                            sx={{
                                border: "1px solid #007BFF",
                                borderRadius: "7px",
                                color: "#007BFF",
                                fontWeight: "600",
                                height: "20%",
                            }}
                            onClick={() => setViewGRIParameters(true)}
                        >
                            GRI Parameters
                        </Button>
                    )}
                </Box>
                <Box sx={{ width: "100%", height: "50px", mt: "2%", display: "flex", gap: 5 }}>
                    <Select
                        value={selectedType}
                        onChange={(e: { target: { value: string } }) => setSelectedType(() => e?.target?.value)}
                        sx={{
                            height: "3rem",
                            borderRadius: "10px",
                            borderColor: "#EBEBEB",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#7d7d7d",
                            },
                            fontSize: "0.87rem",
                        }}
                    >
                        {[
                            { title: "Sustainability report", value: "pdf" },
                            { title: "Tin Can Quality Detection", value: "image" },
                        ]?.map((type) => <MenuItem value={type?.value}>{type?.title}</MenuItem>)}
                    </Select>
                    {selectedType === "image" && (
                        <Box sx={{ width: "60%", display: "flex", justifyContent: "space-between" }}>
                            <Box
                                sx={{
                                    width: "30%",
                                    height: "3rem",
                                    background: chipState.anomaly ? "#C5E1FF" : "none",
                                    border: "1px solid #E4E4E4",
                                    root: {
                                        "&&:hover": {
                                            backgroundColor: "none",
                                        },
                                        "&&:focus": {
                                            backgroundColor: "none",
                                        },
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    setChipState({
                                        ...chipState,
                                        anomaly: !chipState.anomaly,
                                        reverseCanDetection: false,
                                        dentClassification: false,
                                    })
                                }
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: 1,
                                        color: "#007BFF",
                                    }}
                                >
                                    {chipState.anomaly ? <Done /> : null}
                                    <Typography>Anomaly</Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: "30%",
                                    height: "3rem",
                                    background: chipState.reverseCanDetection ? "#C5E1FF" : "none",
                                    border: "1px solid #E4E4E4",
                                    root: {
                                        "&&:hover": {
                                            backgroundColor: "none",
                                        },
                                        "&&:focus": {
                                            backgroundColor: "none",
                                        },
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    setChipState({
                                        ...chipState,
                                        reverseCanDetection: !chipState.reverseCanDetection,
                                        anomaly: false,
                                        dentClassification: false,
                                    })
                                }
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: 1,
                                        color: "#007BFF",
                                    }}
                                >
                                    {chipState.reverseCanDetection ? <Done /> : null}
                                    <Typography>Reverse Can Detection</Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: "30%",
                                    height: "3rem",
                                    background: chipState.dentClassification ? "#C5E1FF" : "none",
                                    border: "1px solid #E4E4E4",
                                    root: {
                                        "&&:hover": {
                                            backgroundColor: "none",
                                        },
                                        "&&:focus": {
                                            backgroundColor: "none",
                                        },
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    setChipState({
                                        ...chipState,
                                        dentClassification: !chipState.dentClassification,
                                        anomaly: false,
                                        reverseCanDetection: false,
                                    })
                                }
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: 1,
                                        color: "#007BFF",
                                    }}
                                >
                                    {chipState.dentClassification ? <Done /> : null}
                                    <Typography>Dent Classification</Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
                {isUploadStarted ? (
                    <UploadProgress data={data} selectedFiles={fileList} />
                ) : (
                    <Box
                        sx={{
                            width: "100%",
                            height: "50%",
                            border: "1px solid #E0E0E0",
                            mt: "3%",
                            borderRadius: "10px",
                        }}
                    >
                        <UploadModal setSelectedFiles={setSelectedFiles} selectedType={selectedType} />
                    </Box>
                )}
                {isUploadStarted && (
                    <Box sx={{ width: "100%", mt: "3%", display: "flex", justifyContent: "center" }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <Box
                                sx={{
                                    width: "15%",
                                    minWidth: "200px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button
                                    sx={{ color: "red" }}
                                    onClick={() => {
                                        setIsUploadStarted(false);
                                        setData(null);
                                        setProcessID("");
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ background: "#007BFF", borderRadius: "7px", color: "#fff", width: "100px" }}
                                    onClick={() => setProceed(true)}
                                    disabled={JSON.parse(data)?.progress !== 100}
                                >
                                    Proceed
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
            <Modal open={viewGRIParameters} onClose={() => setViewGRIParameters(false)}>
                <GRIParametersModal parameters={GRIParameters} onClose={setViewGRIParameters} />
            </Modal>
        </Box>
    );
};

export default AlertGeneration;
