import { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/setting";
import RotatePhoneImage from "../../components/Common/svgLogs/DamageDetectionTest/RotatePhoneImage";

const LandingPage = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    localStorage.setItem("startTime", `${new Date()}`);

    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            {/* top most header box containing back button and heading */}
            <Box
                sx={{
                    width: "100%",
                    background: "#fff",
                    height: "6%",
                    maxHeight: "45px",
                    display: "flex",
                    minHeight: "35px",
                }}
            >
                <Button sx={{ zIndex: "10", position: "absolute" }}>
                    <ArrowBack sx={{ color: "#007BFF" }} />
                </Button>
                <Typography
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#007BFF",
                    }}
                >
                    AI-Inspection
                </Typography>
            </Box>

            {/* Main content box which will cover rest of the space */}
            <Box sx={{ width: "100%", height: "100%" }}>
                {/* van info and number of images uploaded */}
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        background: "#f2f9ff",
                        p: 1,
                        boxSizing: "border-box",
                        alignItems: "center",
                    }}
                >
                    <Typography>Van 28</Typography>
                    <Typography sx={{ color: "#3E3E3E", fontSize: "12px" }}>Completed 0/10</Typography>
                </Box>
                {/* User id */}
                <Typography
                    sx={{
                        width: "100%",
                        background: "#f2f9ff",
                        p: 1,
                        boxSizing: "border-box",
                    }}
                >
                    {contextStore?.profile?.userId}
                </Typography>
                {/* main */}
                <Box
                    sx={{
                        width: "100%",
                        background: "#f2f9ff",
                        boxSizing: "border-box",
                        display: "flex",
                        p: 1,
                        height: "95%",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        // gap: "50%",
                        textAlign: "center",
                    }}
                >
                    <Typography sx={{ color: "#3E3E3E", fontSize: "12px" }}>No Image Uploaded!</Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            alignItems: "center",
                            gap: 2,
                        }}
                    >
                        <RotatePhoneImage />
                        <Typography sx={{ color: "#3E3E3E", fontSize: "12px" }}>
                            To start capturing 360 imaged of the Vehicle rotate your mobile.
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* Bottom most sticky button */}
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: "5vh",
                    boxSizing: "border-box",
                }}
            >
                <Button
                    sx={{
                        width: "80%",
                        height: "50px",
                        borderRadius: "30px",
                        background: "#007BFF",
                        color: "#fff",
                        "&:hover": {
                            boxShadow: "0px -1px 6px 4px #d4d4d4",
                            background: "#007BFF",
                        },
                    }}
                    onClick={() => navigate("/capture")}
                >
                    Start
                </Button>
            </Box>
        </Box>
    );
};

export default LandingPage;

//
