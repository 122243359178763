import { Box, Button, CircularProgress, Link, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Api } from "../../apis";
import { Context } from "../../context/setting";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Close } from "@mui/icons-material";
import DownloadIcon from "../../components/Common/svgLogs/Finance_CFO/DownloadIcon";

const ShowPPT = ({ onClose, report }: any) => {
    const [ppt, setPPT] = useState<any>(null);
    const { contextStore, setContext } = useContext<any>(Context);

    useEffect(() => {
        Api?.getPPT({
            report: report,
        })?.then((res: any) => {
            if (res?.data) {
                setPPT(res?.data);
            } else {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Server issue! Please try after some time",
                    },
                });
            }
        });
    }, []);

    return (
        <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px",
                width: "80%",
                height: "90%",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                {report === "scenario" ? (
                    <Typography variant="h6">Scenario Analysis Presentation</Typography>
                ) : (
                    <Typography variant="h6">Variance Analysis Presentation</Typography>
                )}

                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Button
                        sx={{ background: "#E3F4FF", borderRadius: "10px", height: "40px" }}
                        onClick={() => window.open(ppt?.sas_url, "_blank")}
                        disabled={ppt === null}
                    >
                        <DownloadIcon />
                        <Link href={ppt?.sas_url} />
                    </Button>
                    <Button
                        onClick={() => onClose(false)}
                        sx={{ borderRadius: "10px", height: "50px", color: "#9F9B9B" }}
                    >
                        <Close />
                    </Button>
                </Box>
            </Box>
            <Box sx={{ width: "100%", height: "90%" }}>
                {ppt === null ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <DocViewer
                        style={{ width: "100%", height: "100%" }}
                        documents={[
                            {
                                uri: ppt?.sas_url,
                                fileType: "ppt",
                            },
                        ]}
                        pluginRenderers={DocViewerRenderers}
                    />
                )}
            </Box>
        </Box>
    );
};

export default ShowPPT;
