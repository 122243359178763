import React from "react";

const EllipseIcon = () => {
    return (
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_320_3513"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="4"
                y="4"
                width="34"
                height="34"
            >
                <path
                    d="M21.0002 37.8012C30.2786 37.8012 37.8002 30.2796 37.8002 21.0012C37.8002 11.7228 30.2786 4.20117 21.0002 4.20117C11.7218 4.20117 4.2002 11.7228 4.2002 21.0012C4.2002 30.2796 11.7218 37.8012 21.0002 37.8012Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_320_3513)">
                <path
                    d="M28.1927 13.6512H5.2502C4.6727 13.6512 4.2002 13.1787 4.2002 12.6012V5.25117C4.2002 4.67367 4.6727 4.20117 5.2502 4.20117H35.1752C35.7527 4.20117 36.2252 4.67367 36.2252 5.25117V5.61867C36.1727 10.0287 32.6027 13.6512 28.1927 13.6512Z"
                    fill="#F0BF1A"
                />
            </g>
            <mask
                id="mask1_320_3513"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="4"
                y="4"
                width="34"
                height="34"
            >
                <path
                    d="M21.0002 37.8012C30.2786 37.8012 37.8002 30.2796 37.8002 21.0012C37.8002 11.7228 30.2786 4.20117 21.0002 4.20117C11.7218 4.20117 4.2002 11.7228 4.2002 21.0012C4.2002 30.2796 11.7218 37.8012 21.0002 37.8012Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask1_320_3513)">
                <path
                    d="M36.2772 37.7996H4.30469V28.3496H28.2972C32.7072 28.3496 36.2772 31.9196 36.2772 36.3296V37.7996Z"
                    fill="#3EBEB0"
                />
            </g>
            <mask
                id="mask2_320_3513"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="4"
                y="4"
                width="34"
                height="34"
            >
                <path
                    d="M21.0002 37.8012C30.2786 37.8012 37.8002 30.2796 37.8002 21.0012C37.8002 11.7228 30.2786 4.20117 21.0002 4.20117C11.7218 4.20117 4.2002 11.7228 4.2002 21.0012C4.2002 30.2796 11.7218 37.8012 21.0002 37.8012Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask2_320_3513)">
                <path
                    d="M26.303 25.7254H2.52051V16.2754H26.303C28.928 16.2754 31.028 18.3754 31.028 21.0004C31.028 23.6254 28.8755 25.7254 26.303 25.7254Z"
                    fill="#07A5DE"
                />
            </g>
            <mask
                id="mask3_320_3513"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="4"
                y="4"
                width="34"
                height="34"
            >
                <path
                    d="M21.0002 37.8012C30.2786 37.8012 37.8002 30.2796 37.8002 21.0012C37.8002 11.7228 30.2786 4.20117 21.0002 4.20117C11.7218 4.20117 4.2002 11.7228 4.2002 21.0012C4.2002 30.2796 11.7218 37.8012 21.0002 37.8012Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask3_320_3513)">
                <path
                    d="M18.9004 16.2754H3.36035V25.7254H18.9004C19.2679 24.3079 19.4779 22.7329 19.4779 21.0004C19.4779 19.2679 19.2679 17.6929 18.9004 16.2754Z"
                    fill="#231F20"
                />
            </g>
            <path
                d="M12.5474 6.45703C9.71238 8.13703 7.34988 10.657 5.87988 13.6495H18.2699C17.0099 10.762 15.0149 8.34703 12.5474 6.45703Z"
                fill="#D7A229"
            />
            <path
                d="M13.0724 35.8066C15.4874 33.8641 17.4299 31.2916 18.6374 28.3516H5.87988C7.45488 31.5016 9.97488 34.1266 13.0724 35.8066Z"
                fill="#019B8F"
            />
        </svg>
    );
};

export default EllipseIcon;
