import React from "react";

const DeleteIcon = ({ color }: { color?: string }) => {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            style={{ margin: "0px 6px" }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_320_3309"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="17"
                height="17"
            >
                <rect width="17" height="17" fill={"#D9D9D9"} />
            </mask>
            <g mask="url(#mask0_320_3309)">
                <path
                    d="M4.95801 14.875C4.56842 14.875 4.23492 14.7363 3.95749 14.4589C3.68006 14.1814 3.54134 13.8479 3.54134 13.4583V4.25H2.83301V2.83333H6.37467V2.125H10.6247V2.83333H14.1663V4.25H13.458V13.4583C13.458 13.8479 13.3193 14.1814 13.0419 14.4589C12.7644 14.7363 12.4309 14.875 12.0413 14.875H4.95801ZM12.0413 4.25H4.95801V13.4583H12.0413V4.25ZM6.37467 12.0417H7.79134V5.66667H6.37467V12.0417ZM9.20801 12.0417H10.6247V5.66667H9.20801V12.0417Z"
                    fill={color || "#007BFF"}
                />
            </g>
        </svg>
    );
};

export default DeleteIcon;
