import { Box, IconButton, TextField } from "@mui/material";
import SofieSendIcon from "../../components/Common/svgLogs/SofieSendIcon";
import { useContext } from "react";
import { Context } from "../../context/setting";

const InputHandler = ({
    textFieldValue,
    setTextFieldValue,
    handleSendClick,
}: {
    setTextFieldValue: any;
    textFieldValue: any;
    handleSendClick: any;
}) => {
    const { contextStore, setContext } = useContext<any>(Context);
    return (
        <Box
            sx={{
                py: "5px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                height: "100%",
                width: "100%",
                // border: "1px solid red",
                px: "15px",
                gap: "3px",
            }}
        >
            <Box sx={{ border: "0.5px solid #e3e3e3", width: "100%" }}></Box>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "end",
                    // border: "1px solid red",
                }}
            >
                <TextField
                    label="Ask Sofie"
                    variant="standard"
                    sx={{ width: "85%" }}
                    value={textFieldValue}
                    onChange={(e) => setTextFieldValue(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault(); // Prevent default form submission behavior
                            if (textFieldValue.trim() === "") {
                                setContext({
                                    ...contextStore,
                                    snackState: {
                                        open: true,
                                        errorType: "warning",
                                        message: "Please Ask A Question",
                                    },
                                });
                            } else {
                                handleSendClick();
                            }
                        }
                    }}
                />
                <IconButton
                    onClick={() => {
                        if (textFieldValue.trim() === "") {
                            setContext({
                                ...contextStore,
                                snackState: {
                                    open: true,
                                    errorType: "warning",
                                    message: "Please Ask A Question",
                                },
                            });
                        } else {
                            handleSendClick();
                        }
                    }}
                    sx={{ background: "#007BFF", "&:hover": { background: "#007BFF" } }}
                >
                    <SofieSendIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default InputHandler;
