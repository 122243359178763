import React, { useContext, useEffect, useState } from "react";
import { Add, Close, KeyboardArrowDown, Reply, Translate } from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    IconButton,
    MenuItem,
    OutlinedInput,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import PdfIcon from "../../components/Common/svgLogs/PdfIcon";
import WebsiteIcon from "../../components/Common/svgLogs/WebsiteIcon";
import DatabaseTypeIcon from "../../components/Common/svgLogs/DatabaseTypeIcon";
import DeleteIcon from "../../components/Common/svgLogs/DeleteIcon";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/setting";
import ExelIcon from "../../components/Common/svgLogs/ExelIcon";
import DocumentLoadingModal from "../Chat/DocumentLoading/DocumentLoadingModal";
import { Api } from "../../apis";
import TableContentModal from "./models/TableContentModal";
import DocInfoIcon from "../../components/Common/svgLogs/DocInfoIcon";
import UploadSocket from "./utils/UploadSocket";
import UploadProgress from "./UploadProgress";
import UploadCloud from "../../components/Common/svgLogs/UploadCloud";
import ArraowDownIcon from "../../components/Common/svgLogs/ArraowDownIcon";
import StickyProgress from "./utils/StickyProgress";

const DataSource = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    const theme = useTheme();
    const [tabSelect, setTabSelect] = useState<string>(contextStore?.activeSource || "");
    const [activeScreen, setActiveScreen] = useState<string>("uploaded");
    const [pdfListUploaded, setPdfList] = useState<any>([]);
    const [pdfListUploading, setPdfListUploading] = useState<any[]>([]);
    const [Selected, setSelected] = useState(contextStore?.selectedPdf || pdfListUploaded?.[0] || {});
    const [selectedExcel, setSelectedExcel] = useState<any>(contextStore?.selectedExcel || {});
    const [msg, socketHooks, msgRef] = UploadSocket();
    const [spread, setSpread] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);
    const [tableModal, setTableModal] = useState<{ open: boolean; title: string; id: string }>({
        open: false,
        title: "",
        id: "",
    });
    const smd = useMediaQuery(theme?.breakpoints?.down("sm"));

    useEffect(() => {
        setLoading(() => contextStore?.loading);
    }, [contextStore?.loading]);

    const icons: any = {
        PDF: <PdfIcon />,
        EXCEL: <ExelIcon />,
        Website: <WebsiteIcon />,
        MongoDB: <DatabaseTypeIcon />,
    };

    useEffect(() => {
        if (tabSelect === "pdf") {
            setPdfList(
                contextStore?.pdfList?.filter(
                    (pdf: any) => pdf?.is_processed && !pdf?.is_deleted && pdf?.file_type === "pdf"
                )
            );
            setPdfListUploading(
                contextStore?.pdfList?.filter(
                    (pdf: any) => !pdf?.is_processed && !pdf?.is_deleted && pdf?.file_type === "pdf"
                )
            );
            setContext({
                ...contextStore,
                activeSource: "pdf",
                data_source: true,
            });
        }
        if (tabSelect === "excel") {
            setPdfList(contextStore?.excelList);
            if (Object?.keys(contextStore?.excelList)?.length > 0) {
                setSelectedExcel(contextStore?.selectedExcel || {});
                setContext({
                    ...contextStore,
                    activeSource: "excel",
                    data_source: true,
                });
            }
        }
        if (tabSelect === "youtube") {
            setPdfList(
                contextStore?.pdfList?.filter(
                    (elem: any) => elem?.is_processed && !elem?.is_deleted && elem?.file_type === "video"
                )
            );
            setPdfListUploading(
                contextStore?.pdfList?.filter(
                    (elem: any) => !elem?.is_processed && !elem?.is_deleted && elem?.file_type === "video"
                )
            );
            setContext({
                ...contextStore,
                activeSource: "youtube",
                data_source: true,
            });
        }
    }, [tabSelect, contextStore.pdfList, contextStore.excelList]);

    const handleEmptyString = () => {
        if (tabSelect === "") {
            return "Please Select Source Type";
        }
        if (pdfListUploaded?.length === 0) {
            return "Data sources are not uploaded yet, Please add new one.";
        }
    };

    // console.log(contextStore?.pdfList, pdfListUploaded);

    useEffect(() => {
        setTabSelect(() => contextStore?.activeSource);
    }, [contextStore?.activeSource]);

    const handleFetchPdf = () => {
        Api?.getPdfs({ user_id: contextStore?.profile?.userId })?.then((res: any) => {
            if (res?.status === 200) {
                setContext({ ...contextStore, pdfList: res?.data?.sort() });
            }
        });
    };

    const handleDeleteFile = (file_id: string) => {
        Api?.deleteSourceESG({
            file_id,
            deleted_type: "soft",
            user_id: contextStore?.profile?.userId,
        })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "success",
                            message: "File Deleted Successfully",
                        },
                    });
                    // handleFetchExcel();
                    handleFetchPdf();
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "something went wrong",
                        },
                    });
                }
            })
            .catch((err) =>
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "something went wrong",
                    },
                })
            );
    };

    const styles = () => {
        if (smd) {
            return {
                head: {
                    flexDirection: "column",
                    gap: 1,
                },
                title: {
                    pl: 5,
                },
                tableTitle: {
                    minWidth: "9rem",
                },
                tableBox: {
                    height: "60vh",
                },
            };
        }
        return {};
    };

    const handleFileSelect = (item: any) => {
        if (Selected?.file_id === item?.file_name) {
            setSelected({});
            setContext({ ...contextStore, selectedPdf: {} });
        } else {
            setSelected(item);
            setContext({
                ...contextStore,
                selectedPdf: item,
                bucketName: item?.file_id,
            });
        }
    };

    // console.log({ contextStore });

    return (
        <Box sx={{ padding: "3vh 2vw", width: "100%", display: "flex", flexDirection: "column", gap: 3 }}>
            {/* <StickyProgress /> */}
            <Box sx={{ display: "flex", justifyContent: "space-between", ...styles()?.head }}>
                <Box sx={{ ...styles()?.title }}>
                    <Typography variant="h4" fontWeight={500}>
                        Data Sources
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box
                    sx={{
                        display: "flex",
                        width: "700px",
                        gap: 1,
                        height: "60px",
                        alignItems: "center",
                    }}
                >
                    <FormControl sx={{ minWidth: 110 }}>
                        <Select
                            value={tabSelect}
                            onChange={(e: any) => {
                                setTabSelect(e?.target?.value);
                            }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#ECECEC",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#ECECEC",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#ECECEC",
                                },
                            }}
                        >
                            <MenuItem value={undefined}>Select source</MenuItem>
                            <MenuItem value="pdf">PDF</MenuItem>
                            <MenuItem value="excel">EXCEL</MenuItem>
                            <MenuItem value="youtube">YOUTUBE</MenuItem>
                        </Select>
                    </FormControl>
                    <Box
                        sx={{
                            width: "350px",
                            minWidth: "220px",
                            height: "40px",
                            display: "flex",
                            border: "1px solid #ECECEC",
                            borderRadius: "8px",
                            p: "3px",
                        }}
                    >
                        <Button
                            sx={{
                                width: "50%",
                                background: activeScreen === "uploaded" ? "#E3F4FF" : "#fff",
                                color: activeScreen === "uploaded" ? "#007BFF" : "#000",
                                borderRadius: "8px",
                            }}
                            onClick={() => setActiveScreen("uploaded")}
                        >
                            Uploaded documents
                        </Button>
                        <Button
                            sx={{
                                width: "50%",
                                background: activeScreen === "uploading" ? "#E3F4FF" : "#fff",
                                color: activeScreen === "uploading" ? "#007BFF" : "#000",
                                borderRadius: "8px",
                            }}
                            onClick={() => setActiveScreen("uploading")}
                        >
                            Uploading (in progress)
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", height: "60px", alignItems: "center", gap: 2 }}>
                    <OutlinedInput
                        sx={{
                            pr: "1.8px",
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "7.6px",
                                borderColor: "#CFCFCF",
                                overflow: "hidden",
                            },
                            "& .MuiOutlinedInput-input": {
                                height: "0.5rem",
                                padding: "14px 12px",
                            },
                        }}
                        onChange={(e: { target: { value: string } }) =>
                            setPdfList(
                                contextStore?.pdfList?.filter(
                                    (item: any) =>
                                        item?.file_name
                                            ?.toLowerCase()
                                            ?.includes(e?.target?.value?.trim()?.toLowerCase())
                                )
                            )
                        }
                        placeholder="Search"
                        endAdornment={
                            <IconButton sx={{ borderRadius: "4px" }}>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.91732 15.4245C10.3225 15.4242 11.6872 14.9538 12.7941 14.0881L16.2743 17.5683L17.3937 16.4489L13.9135 12.9687C14.7796 11.8617 15.2503 10.4967 15.2507 9.09115C15.2507 5.5991 12.4094 2.75781 8.91732 2.75781C5.42528 2.75781 2.58398 5.5991 2.58398 9.09115C2.58398 12.5832 5.42528 15.4245 8.91732 15.4245ZM8.91732 4.34115C11.5369 4.34115 13.6673 6.47152 13.6673 9.09115C13.6673 11.7108 11.5369 13.8411 8.91732 13.8411C6.29769 13.8411 4.16732 11.7108 4.16732 9.09115C4.16732 6.47152 6.29769 4.34115 8.91732 4.34115Z"
                                        fill="#484848"
                                    />
                                </svg>
                            </IconButton>
                        }
                    />
                    <Button
                        startIcon={<Add />}
                        sx={{
                            textTransform: "none",
                            background: "#fff",
                            color: "#007BFF",
                            px: 2,
                            borderRadius: "8px",
                            border: "1px solid #007BFF",
                            "&:hover": { background: "#fffc3" },
                        }}
                        onClick={() => navigate("/data_source/add_source")}
                    >
                        New
                    </Button>
                    <Button
                        sx={{
                            textTransform: "none",
                            background: "#007BFF",
                            color: "#fff",
                            px: 2,
                            borderRadius: "7.31px",
                            "&:hover": { background: "#007BFFc3" },
                        }}
                        startIcon={<Reply sx={{ transform: "rotateY(180deg)" }} />}
                        onClick={() => {
                            if (tabSelect === "pdf" && Object?.keys(Selected)?.length > 0) {
                                navigate("/chat");
                            } else if (tabSelect === "excel") {
                                navigate("/chat");
                            } else if (tabSelect === "youtube" && Object?.keys(Selected)?.length > 0) {
                                navigate("/chat");
                                setContext({
                                    ...contextStore,
                                    bucketName: Selected?.file_id,
                                });
                            } else {
                                setContext({
                                    ...contextStore,
                                    snackState: {
                                        open: true,
                                        errorType: "warning",
                                        message: "Please Select a Document first",
                                    },
                                });
                            }
                        }}
                    >
                        Start Chat
                    </Button>
                </Box>
            </Box>
            <Box sx={{ height: "65.8vh", overflowY: "auto", ...styles()?.tableBox }}>
                {activeScreen === "uploaded" ? (
                    <Table stickyHeader>
                        <TableBody>
                            {tabSelect === "pdf" &&
                                Array?.isArray(pdfListUploaded) &&
                                pdfListUploaded?.map((item: any) => (
                                    <TableRow key={item?.file_id}>
                                        <TableCell sx={{ cursor: "pointer" }} width="5%">
                                            <Checkbox
                                                checked={Selected?.file_id === item?.file_id}
                                                onChange={() => handleFileSelect(item)}
                                            />
                                        </TableCell>

                                        <TableCell
                                            width="90%"
                                            sx={{
                                                pl: 1,
                                                minWidth: "24rem",
                                                cursor: "pointer",
                                                ...styles()?.tableTitle,
                                            }}
                                            onClick={() => handleFileSelect(item)}
                                        >
                                            {item?.file_name}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                sx={{
                                                    width: "fit-content",
                                                    border: "1px solid #747474",
                                                    borderRadius: "15px",
                                                    paddingX: "10px",
                                                    color: "#747474",
                                                    fontSize: "16px",
                                                    fontFamily: "Outfit",
                                                }}
                                            >
                                                <Translate sx={{ fontSize: "16px", marginRight: "0.55rem" }} />
                                                {item?.file_settings?.lang || "English"}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={"Document Content"} placement="top">
                                                <span>
                                                    <IconButton
                                                        onClick={(e: any) =>
                                                            setTableModal({
                                                                open: true,
                                                                title: item?.file_name,
                                                                id: item?.file_id,
                                                            })
                                                        }
                                                    >
                                                        <DocInfoIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={"Delete"} placement="top">
                                                <span>
                                                    <IconButton onClick={(e: any) => handleDeleteFile(item?.file_id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            {tabSelect === "excel" &&
                                Object?.keys(pdfListUploaded)?.map((item: string) => (
                                    <>
                                        <TableRow key={item}>
                                            <TableCell width="5%">
                                                <Checkbox
                                                    checked={selectedExcel?.hasOwnProperty(item)}
                                                    onChange={() => {
                                                        if (selectedExcel?.hasOwnProperty(item)) {
                                                            setSelectedExcel({});
                                                            setContext({ ...contextStore, selectedExcel: {} });
                                                        } else {
                                                            setSelectedExcel({ [item]: pdfListUploaded[item] });
                                                            setContext({
                                                                ...contextStore,
                                                                selectedExcel: { [item]: pdfListUploaded[item] },
                                                            });
                                                        }
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell
                                                sx={{ pl: 3, minWidth: "24rem", ...styles()?.tableTitle }}
                                                width="90%"
                                            >
                                                <Box sx={{ display: "flex", flexDirection: "column", gap: 0.8 }}>
                                                    {pdfListUploaded[item]["tables"][0]["file_name"] || ""}
                                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                        {spread === item &&
                                                            pdfListUploaded[item]["tables"]?.map((table: any) => (
                                                                <Chip
                                                                    sx={{
                                                                        background: "#fff",
                                                                        border: "1px solid #F3F3F4",
                                                                    }}
                                                                    label={
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                gap: 0.5,
                                                                            }}
                                                                        >
                                                                            <Checkbox size="small" checked />{" "}
                                                                            {table["name"] || ""}
                                                                            <Close fontSize="small" />
                                                                        </Box>
                                                                    }
                                                                />
                                                            ))}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Button
                                                        endIcon={
                                                            <KeyboardArrowDown
                                                                sx={{
                                                                    transform:
                                                                        spread === item
                                                                            ? "rotate(180deg)"
                                                                            : "rotate(0deg)",
                                                                }}
                                                            />
                                                        }
                                                        onClick={() =>
                                                            setSpread((prev: string) => (prev === item ? "" : item))
                                                        }
                                                    >
                                                        Sheets
                                                    </Button>
                                                    <IconButton
                                                        // disabled
                                                        onClick={(e: any) => console.log({ item })}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            {tabSelect === "youtube" &&
                                Array?.isArray(pdfListUploaded) &&
                                pdfListUploaded?.map((item: any) => (
                                    <TableRow key={item?.file_id}>
                                        <TableCell sx={{ cursor: "pointer" }} width="5%">
                                            <Checkbox
                                                checked={Selected?.file_id === item?.file_id}
                                                onChange={() => handleFileSelect(item)}
                                            />
                                        </TableCell>

                                        <TableCell
                                            width="90%"
                                            sx={{
                                                pl: 1,
                                                minWidth: "24rem",
                                                cursor: "pointer",
                                                ...styles()?.tableTitle,
                                            }}
                                            onClick={() => handleFileSelect(item)}
                                        >
                                            {item?.file_name}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                sx={{
                                                    width: "fit-content",
                                                    border: "1px solid #747474",
                                                    borderRadius: "15px",
                                                    paddingX: "10px",
                                                    color: "#747474",
                                                    fontSize: "16px",
                                                    fontFamily: "Outfit",
                                                }}
                                            >
                                                <Translate sx={{ fontSize: "16px", marginRight: "0.55rem" }} />
                                                {item?.file_settings?.lang || "English"}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={"Document Content"} placement="top">
                                                <span>
                                                    <IconButton
                                                        onClick={(e: any) =>
                                                            setTableModal({
                                                                open: true,
                                                                title: item?.file_name,
                                                                id: item?.file_id,
                                                            })
                                                        }
                                                    >
                                                        <DocInfoIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={"Delete"} placement="top">
                                                <span>
                                                    <IconButton onClick={(e: any) => handleDeleteFile(item?.file_id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                ) : pdfListUploading?.length > 0 ? (
                    pdfListUploading?.map((file: any) => {
                        return (
                            <Box
                                sx={{
                                    height: "70px",
                                    borderTop: "1px solid #F3F3F4",
                                    p: "15px 0px",
                                }}
                            >
                                <UploadProgress
                                    key={file?.file_id}
                                    file={file}
                                    socketHooks={socketHooks}
                                    msgRef={msgRef}
                                    setPdfList={setPdfList}
                                />
                            </Box>
                        );
                    })
                ) : (
                    <Box sx={{ display: "grid", placeItems: "center", height: "85%" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
                            <UploadCloud />
                            <Typography sx={{ fontStyle: "italic", color: "#424F65" }}>
                                No Uploads in Progress
                            </Typography>
                        </Box>
                    </Box>
                )}

                {pdfListUploaded?.length === 0 && (
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", p: 8 }}>
                        <Typography variant="caption" sx={{ fontSize: "1rem" }}>
                            {handleEmptyString()}
                        </Typography>
                    </Box>
                )}
            </Box>
            <DocumentLoadingModal loading={loading} />
            <TableContentModal
                handleClose={() => setTableModal({ open: false, title: "", id: "" })}
                tableModal={tableModal}
            />
        </Box>
    );
};

export default DataSource;
