import { Box, Button, Typography } from "@mui/material";
import VarianceAnalysis from "../../components/Common/svgLogs/Finance_CFO/VarianceAnalysis";
import ScenarioAnalysis from "../../components/Common/svgLogs/Finance_CFO/ScenarioAnalysis";
import AnomalyDetection from "../../components/Common/svgLogs/Finance_CFO/AnomalyDetectionIcon";
import InvoiceReconciliation from "../../components/Common/svgLogs/Finance_CFO/InvoiceReconciliation";
import { useNavigate } from "react-router-dom";
import CFO from "../../components/Common/svgLogs/CFO";
import XLogo from "../../components/Common/svgLogs/HR_Analysis/XLogo";
import { makeStyles } from "@mui/styles";

const InfoBox = ({
    children,
    heading,
    text,
    onClickRoute,
}: {
    children: any;
    heading: String;
    text: String;
    onClickRoute: any;
}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    return (
        <Box
            className={classes?.infoBox}
            sx={{
                flex: 1,
                px: 1,
                py: 3,
                background: "#F4F4F4",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                cursor: "pointer",
            }}
            onClick={() => {
                navigate(onClickRoute);
            }}
        >
            <Box
                className={classes?.infoLogo}
                sx={{
                    height: "5rem",
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                    "& > svg": {
                        mixBlendMode: "darken",
                    },
                }}
            >
                {children}
            </Box>
            <Typography
                sx={{
                    color: "#000",
                    textAlign: "center",
                    fontSize: "1.05rem",
                    height: "3.5rem",
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                {heading}
            </Typography>
            <Typography
                sx={{
                    textAlign: "center",
                    fontSize: "0.9rem",
                    height: "5rem",
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                {text}
            </Typography>
        </Box>
    );
};

const useStyles = makeStyles({
    infoBox: {
        "&:hover": {
            background: "#E3F4FF",
            "& $infoLogo > svg": {
                mixBlendMode: "color-burn",
            },
        },
    },
    infoLogo: {},
});

const Index = () => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    width: "76%",
                    height: "90%",
                    borderRadius: "10px",
                    border: "2px solid #EEEEEE",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 3,
                    px: 2,
                    pt: 3.5,
                }}
            >
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "1.1rem" }}>
                        Welcome to{" "}
                        <span style={{ color: "#007BFF" }}>
                            Softsensor
                            <XLogo />
                        </span>
                    </Typography>
                    <Typography sx={{ fontSize: "1.1rem" }}>Your go-to for seamless assitance in finance</Typography>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        color: "#6B7582",
                        "& > *": { fontSize: "0.9rem" },
                    }}
                >
                    <Typography>
                        Engage with cutting-edge technology as we explore, learn, and innovate together.
                    </Typography>
                    <Typography>Softsensor X is designed to provide you with quick and helpful responses.</Typography>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        color: "#6B7582",
                        "& > *": { fontSize: "0.9rem" },
                    }}
                >
                    <Typography>Explore what we can do for you.</Typography>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        color: "#6B7582",
                        gap: 2,
                    }}
                >
                    <InfoBox
                        children={<ScenarioAnalysis />}
                        heading="Scenario Analysis"
                        text="Anticipate future outcomes with different assumptions"
                        onClickRoute="/finance/trial_balance"
                    />
                    <InfoBox
                        children={<VarianceAnalysis />}
                        heading="Variance Analysis"
                        text="compare PDFs, highlighting discrepancies"
                        onClickRoute="/variance_analysis"
                    />
                    <InfoBox
                        children={<AnomalyDetection />}
                        heading="Anomaly Detection"
                        text="identify abnormal patterns or outliers in data"
                        onClickRoute="/anamoly_detection"
                    />
                    <InfoBox
                        children={<InvoiceReconciliation />}
                        heading="Invoice Reconciliation"
                        text="verify invoice accuracy against transactions for financial integrity"
                        onClickRoute="/invoice_reconciliation"
                    />
                    <InfoBox
                        children={<CFO />}
                        heading="CFO Doc Intelligence"
                        text="compare and reconcile single or multiple Documents"
                        onClickRoute="/cfo_doc_intel"
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Index;
