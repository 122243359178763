import React from "react";

const foodIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: "1.063rem", height: "1.125rem" }}
            viewBox="0 0 24 24"
            fill="none"
        >
            <mask
                id="mask0_4323_475"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4323_475)">
                <path
                    d="M4.37461 21L2.97461 19.6L13.2246 9.35C12.9246 8.65 12.8829 7.85833 13.0996 6.975C13.3163 6.09167 13.7913 5.3 14.5246 4.6C15.4079 3.71667 16.3913 3.2 17.4746 3.05C18.5579 2.9 19.4413 3.16667 20.1246 3.85C20.8079 4.53333 21.0746 5.41667 20.9246 6.5C20.7746 7.58333 20.2579 8.56667 19.3746 9.45C18.6746 10.1833 17.8829 10.6583 16.9996 10.875C16.1163 11.0917 15.3246 11.05 14.6246 10.75L13.3746 12L20.9746 19.6L19.5746 21L11.9746 13.45L4.37461 21ZM7.32461 12.45L4.32461 9.45C3.42461 8.55 2.97461 7.475 2.97461 6.225C2.97461 4.975 3.42461 3.9 4.32461 3L10.5246 9.25L7.32461 12.45Z"
                    fill="#007BFF"
                />
            </g>
        </svg>
    );
};

export default foodIcon;
