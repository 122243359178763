const Stencil7 = ({ stroke }: any) => {
    return (
        <svg width="70vw" height="80vh" viewBox="0 0 261 302" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M130.422 1.04771H219.498C230.676 0.38004 253.556 6.4558 255.652 36.1002C257.748 65.7446 259.32 195.338 259.844 256.429C261.066 271.452 255.652 301.397 224.213 300.996C192.775 300.595 148.586 300.829 130.422 300.996M130.578 1.04771H41.5023C30.3242 0.38004 7.4439 6.4558 5.34799 36.1002C3.25209 65.7446 1.68017 195.338 1.15619 256.429C-0.0664152 271.452 5.34799 301.397 36.7865 300.996C68.225 300.595 112.414 300.829 130.578 300.996"
                stroke={stroke}
                stroke-width="2"
            />
        </svg>
    );
};

export default Stencil7;
