import React from "react";

const SearchIcon = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.9438 12.0563L10.8188 9.9375C11.5044 9.06404 11.8764 7.98541 11.875 6.875C11.875 5.8861 11.5818 4.9194 11.0324 4.09715C10.4829 3.27491 9.70205 2.63404 8.78842 2.2556C7.87479 1.87717 6.86946 1.77815 5.89955 1.97108C4.92965 2.164 4.03873 2.64021 3.33947 3.33947C2.64021 4.03873 2.164 4.92965 1.97108 5.89955C1.77815 6.86946 1.87717 7.87479 2.2556 8.78842C2.63404 9.70205 3.27491 10.4829 4.09715 11.0324C4.9194 11.5818 5.8861 11.875 6.875 11.875C7.98541 11.8764 9.06404 11.5044 9.9375 10.8188L12.0563 12.9438C12.1144 13.0023 12.1835 13.0488 12.2596 13.0806C12.3358 13.1123 12.4175 13.1286 12.5 13.1286C12.5825 13.1286 12.6642 13.1123 12.7404 13.0806C12.8165 13.0488 12.8856 13.0023 12.9438 12.9438C13.0023 12.8856 13.0488 12.8165 13.0806 12.7404C13.1123 12.6642 13.1286 12.5825 13.1286 12.5C13.1286 12.4175 13.1123 12.3358 13.0806 12.2596C13.0488 12.1835 13.0023 12.1144 12.9438 12.0563ZM3.125 6.875C3.125 6.13332 3.34494 5.4083 3.75699 4.79161C4.16905 4.17493 4.75472 3.69428 5.43994 3.41045C6.12516 3.12663 6.87916 3.05236 7.60659 3.19706C8.33402 3.34175 9.00221 3.6989 9.52665 4.22335C10.0511 4.7478 10.4083 5.41599 10.5529 6.14341C10.6976 6.87084 10.6234 7.62484 10.3396 8.31007C10.0557 8.99529 9.57508 9.58096 8.95839 9.99301C8.34171 10.4051 7.61668 10.625 6.875 10.625C5.88044 10.625 4.92661 10.2299 4.22335 9.52665C3.52009 8.82339 3.125 7.86956 3.125 6.875Z"
                fill="#484848"
            />
        </svg>
    );
};

export default SearchIcon;
