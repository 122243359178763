import { Box, IconButton, Typography, TextField, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import LessThanIcon from "../../components/Common/svgLogs/LessThanIcon";
import UploadBoxIcon from "../../components/Common/svgLogs/UploadBoxIcon";
import AttachmentIcon from "../../components/Common/svgLogs/AttachmentIcon";
import MongoDBColorIcon from "../../components/Common/svgLogs/MongoDBColorIcon";
import SqlIcon from "../../components/Common/svgLogs/SqlIcon";
import PostgresIcon from "../../components/Common/svgLogs/PostgresIcon";
import YoutubeIcon from "../../components/Common/svgLogs/YoutubeIcon";
import GoogleDocsIcon from "../../components/Common/svgLogs/GoogleDocsIcon";
import GoogleDriveIcon from "../../components/Common/svgLogs/GoogleDriveIcon";
import GoogleMailIcon from "../../components/Common/svgLogs/GoogleMailIcon";
import Upcoming1Icon from "../../components/Common/svgLogs/Upcoming1Icon";
import EllipseIcon from "../../components/Common/svgLogs/EllipseIcon";
import Upcoming2Icon from "../../components/Common/svgLogs/Upcoming2Icon";
import { Context } from "../../context/setting";
import { useNavigate } from "react-router-dom";
import GoogleSheetsIcon from "../../components/Common/svgLogs/GoogleSheetsIcon";
import { select } from "redux-saga/effects";
import { Api } from "../../apis";

const AddGoogleDocsSource = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    // const [selectedFileCredentials, setSelectedFileCredentials] = useState<any>(null);
    const [selectedFileToken, setSelectedFileToken] = useState<any>(null);
    const [gsheetsData, setGSheetsData] = useState<any>({ id: "", name: "" });


    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setGSheetsData((prev: any) => ({ ...prev, [name]: value }));
    };

    const handleUpload = () => {
        const formData = new FormData();
        formData.append("token", selectedFileToken);
        formData.append("document_id", gsheetsData.id);
        Api?.uploadGoogleDocsData(formData)?.then((res: any) => {
            if (res?.status === 200) {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message: "Google docs Uploaded Successful",
                    },
                });
            } else {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Something Went Wrong",
                    },
                });
            }
        });
    };

    return (
        <Box sx={{ p: 2.3, width: "100%", display: "flex", flexDirection: "column", gap: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <IconButton onClick={() => navigate(contextStore?.previousRoute || "/data_source/add_source")}>
                    <LessThanIcon />
                </IconButton>
                <Typography variant="h6" fontWeight={600}>
                    Add Data Source
                </Typography>
            </Box>
            <Box sx={{ display: "flex", px: 1.5, gap: 8 }}>
                <Box sx={{ width: "58%" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        <GoogleDocsIcon />
                        <Typography variant="h5">Google Docs</Typography>
                    </Box>
                    {/* <Box sx={{ mt: "5vh" }}>
                        <Typography variant="h5">Upload Credentials.json</Typography>
                        <Box
                            sx={{
                                height: "5rem",
                                border: "1px dashed #007BFF",
                                borderRadius: "14px",
                                mt: 3,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                position: "relative",
                                width: "40vw",
                            }}
                            component={"label"}
                            onDrop={(e: any) => {
                                e.preventDefault();
                            }}
                            onDragOver={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            <input
                                hidden
                                type="file"
                                accept=".pdf, .xls, .xlsx, .csv"
                                onChange={(e: { target: { files: any; value: any } }) => {
                                    setSelectedFileCredentials(e?.target?.files[0]);
                                    e.target.value = "";
                                }}
                            />
                            <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                                <UploadBoxIcon />
                                {selectedFileCredentials ? (
                                    <Typography>{selectedFileCredentials?.name}</Typography>
                                ) : (
                                    <Typography variant="h6">
                                        Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box> */}
                    <Box sx={{ mt: "5vh" }}>
                        <Typography variant="h5">Upload Token.json</Typography>
                        <Box
                            sx={{
                                height: "5rem",
                                border: "1px dashed #007BFF",
                                borderRadius: "14px",
                                mt: 3,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                position: "relative",
                                width: "40vw",
                            }}
                            component={"label"}
                            onDrop={(e: any) => {
                                e.preventDefault();
                            }}
                            onDragOver={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            <input
                                hidden
                                type="file"
                                accept=".json"
                                onChange={(e: { target: { files: any; value: any } }) => {
                                    setSelectedFileToken(e?.target?.files[0]);
                                    e.target.value = "";
                                }}
                            />
                            <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                                <UploadBoxIcon />
                                {selectedFileToken ? (
                                    <Typography>{selectedFileToken?.name}</Typography>
                                ) : (
                                    <Typography variant="h6">
                                        Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ mt: "5vh" }}>
                        <Typography>Document ID</Typography>
                        <TextField
                            id="doc_id"
                            label="Enter Document ID"
                            variant="outlined"
                            sx={{ width: "40vw", mt: "15px" }}
                            name="id"
                            onChange={handleChange}
                        />
                    </Box>

                    <Box
                        sx={{
                            mt: "5vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "80%",
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{ background: "#007BFF", width: "300px", height: "50px", borderRadius: "10px" }}
                            onClick={handleUpload}
                        >
                            Add Data Source
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ width: "40%" }}>
                    <Typography variant="subtitle2">Other Source Types</Typography>
                    <Box sx={{ mt: 3, display: "flex", gap: 5, flexWrap: "wrap" }}>
                        {[
                            { title: "Website", icon: AttachmentIcon, key: "website" },
                            { title: "MongoDB", icon: MongoDBColorIcon, key: "mongoDB" },
                            { title: "SQL", icon: SqlIcon, key: "sql" },
                            { title: "PostgresSQL", icon: PostgresIcon, key: "postgresql" },
                            { title: "Google Docs", icon: GoogleDocsIcon, key: "googleDocs" },
                            { title: "Youtube", icon: YoutubeIcon, key: "youtube" },
                            { title: "Google Sheets", icon: GoogleSheetsIcon, key: "googleSheets" },
                        ]?.map((elem) => (
                            <Box
                                onClick={(e) => {
                                    navigate(`/data_source/add_source/${elem?.key}`);
                                    setContext({ ...contextStore, active_data_source: elem?.key });
                                }}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    boxShadow:
                                        contextStore.active_data_source === elem.key
                                            ? "3px 3px 3px 3px #E3F4FF"
                                            : "0px 4px 8px 0px #AAAAAA1A",
                                    width: "13rem",
                                    height: "4.5rem",
                                    borderRadius: "14px",
                                    gap: 3,
                                    pl: 4,
                                    cursor: "pointer",
                                }}
                            >
                                <elem.icon />
                                <Typography variant="subtitle2">{elem?.title}</Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{ mt: 5 }}>
                        <Typography variant="subtitle2">Upcoming</Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 4, mt: 2 }}>
                            <GoogleDriveIcon />
                            <GoogleMailIcon />
                            <Upcoming1Icon />
                            <EllipseIcon />
                            <Upcoming2Icon />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AddGoogleDocsSource;
