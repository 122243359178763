import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useContext } from "react";
import SophieIcon from "../../components/Common/svgLogs/SophieIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../context/setting";

const TextBox = ({ text }: any) => {
    const isSmallThan1560 = useMediaQuery("(max-width:1560px)");
    return (
        <Box
            sx={{
                width: "100%",
                minHeight: "50px",
                display: "flex",
                alignItems: "center",
                background: "rgba(217, 217, 217, 0.3)",
                borderRadius: "6px",
                mb: "2vh",
                fontSize: isSmallThan1560 ? "12px" : "14px",
                p: 1,
            }}
        >
            {text}
        </Box>
    );
};

const Bot = () => {
    const { contextStore, setContext } = useContext<any>(Context);

    const navigate = useNavigate();

    const data = {
        BFSI: {
            try: [
                "Invoices and billing documents",
                "Customs clearance forms",
                "Perishable goods shipment records",
                "Pharmaceutical product tracking",
            ],
            pro: [
                "Efficiently process and analyse multiple logistics documents simultaneously.",
                "Automatically detect and alert on the expiry date of perishable goods.",
                "Side-by-side comparison of different logistics documents for easy identification of variations.",
            ],
            limitation: [
                "Performance may be influenced by the diversity of document formats.",
                "Accuracy in expiry date detection relies on document clarity and consistency.",
                "Optimal results with standardized documents; struggles with diverse formats.",
            ],
        },
        IT: {
            try: [
                "Adapting the API to preferred IT models",
                "Analysis of large datasets",
                "Analyzing server performance graphs",
            ],
            pro: [
                "Flexibility to adapt to specific requirements, improving task outcomes.",
                "Provide model options based on cost and accuracy demands.",
                "Empowers IT professionals to interpret graphs for data-driven decision-making.",
            ],
            limitation: [
                "May not guarantee improved results; requires user familiarity with IT tasks.",
                "Accuracy relies on data quality and model training.",
                "May not handle highly complex or specialized IT graphs effectively.",
            ],
        },
        Logistics: {
            try: ["Invoices and billing documents", "Customs clearance forms", "Perishable goods shipment records"],
            pro: [
                "Efficiently process and analyze multiple logistics documents simultaneously.",
                "Automatically detect and alert on the expiry date of perishable goods.",
                "Facilitate side-by-side comparison of logistics documents to identify variations.",
            ],
            limitation: [
                "Performance may be influenced by the diversity of document formats.",
                "Accuracy in expiry date detection relies on document clarity and consistency.",
                "Optimal results with standardized documents; struggles with diverse formats.",
            ],
        },
        FMCG: {
            try: [
                "Sales performance graph interpretation	",
                "Market share trend analysis",
                "Expiry date detection for perishable goods	",
            ],
            pro: [
                "Fine-tuned model for accurate analysis of market share trends.",
                "Swift identification of damaged products in videos.",
                "Timely awareness of product and material expiry.",
            ],
            limitation: [
                "Accuracy may vary with diverse data formats.",
                "May struggle with dynamic product placement in videos.",
                "May not suit complex expiration scenarios with multiple factors.",
            ],
        },
        ESG: {
            try: [
                "Data transformation from Excel",
                "Environmental impact data categorization",
                "Inquiries about ESG initiatives",
            ],
            pro: [
                "Converts raw ESG data into actionable reports.",
                "Provides a structured overview of environmental and social metrics.",
                "Allows for multi-language accessibility of ESG information.",
            ],
            limitation: [
                "Accuracy may vary with diverse ESG data formats.",
                "Challenges with non-standardized ESG data.",
                "Effectiveness depends on data quality and clarity.",
            ],
        },
        "Health & Insurance": {
            try: [
                "Medical report analysis",
                "Comparison of medical reports",
                "Signature verification on health insurance contracts",
            ],
            pro: [
                "Simplifies comprehension by presenting medical data in a dropdown menu",
                "Facilitates detailed health progress analysis through side-by-side comparison.",
                "Adds security to health insurance contracts.",
            ],
            limitation: [
                "Interpretation accuracy may vary with complex medical data.",
                "Effectiveness depends on report data clarity and consistency.",
                "Relies on consistent signature identification for accuracy.",
            ],
        },
        "Supply chain": {
            try: [
                "Supply chain graph interpretation",
                "Inventory turnover graph analysis",
                "Monitoring vehicle conditions",
            ],
            pro: [
                "Interprets production and distribution graphs for supply chain insights.",
                "Analyzes trends in inventory turnover for supply chain management.",
                "Identifies damaged products or packaging issues in supply chain videos.",
            ],
            limitation: [
                "Accuracy relies on data quality and model training.",
                "Effectiveness depends on the clarity and quality of data.",
                "Accuracy of detection relies on information clarity and consistency.",
            ],
        },
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box sx={{ width: "70%", height: "84%", border: "1px solid #EEEEEE", borderRadius: "10px", p: 2 }}>
                <Box
                    sx={{
                        width: "100%",
                        height: "10%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ width: "5%", minWidth: "50px" }}>
                        <SophieIcon />
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", width: "95%" }}>
                        <Typography>Hi I’m Sophie !</Typography>
                        <Typography>Welcome to SoftsensorX! Your go-to for seamless assistance</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: "87%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        mt: "2%",
                        ml: "max(5%, 50px)",
                        height: "70%",
                    }}
                >
                    <Box sx={{ width: "27%" }}>
                        <Typography variant="body2" sx={{ color: "#6B7582", mb: "2vh" }}>
                            Try Asking
                        </Typography>
                        {data[contextStore?.sector as keyof typeof data]?.try?.map((text: any) => {
                            return <TextBox text={text} />;
                        })}
                    </Box>
                    <Box sx={{ width: "27%" }}>
                        <Typography variant="body2" sx={{ color: "#6B7582", mb: "2vh" }}>
                            Proficiency{" "}
                        </Typography>
                        {data[contextStore?.sector as keyof typeof data]?.pro?.map((text: any) => {
                            return <TextBox text={text} />;
                        })}
                    </Box>
                    <Box sx={{ width: "27%" }}>
                        <Typography variant="body2" sx={{ color: "#6B7582", mb: "2vh" }}>
                            Limitations
                        </Typography>
                        {data[contextStore?.sector as keyof typeof data]?.limitation?.map((text: any) => {
                            return <TextBox text={text} />;
                        })}
                    </Box>
                </Box>

                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", height: "20%" }}>
                    <Button
                        variant="contained"
                        sx={{ background: "#007BFF", width: "160px", height: "50px", borderRadius: "10px" }}
                        onClick={() => navigate("/landing")}
                    >
                        Get Started !
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Bot;
