const Stencil5 = ({ stroke }: any) => {
    return (
        <svg width="70vw" height="80vh" viewBox="0 0 672 284" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M635 244.5C635 265.763 617.763 283 596.5 283C575.237 283 558 265.763 558 244.5C558 223.237 575.237 206 596.5 206C617.763 206 635 223.237 635 244.5Z"
                stroke={stroke}
                stroke-width="2"
            />
            <path
                d="M163 244.5C163 265.763 145.763 283 124.5 283C103.237 283 85.9999 265.763 85.9999 244.5C85.9999 240.637 86.6018 237.898 87.7143 236C95.4999 236 108.1 236 125.5 236C142.9 236 155.5 236 161.459 236C162.464 237.898 163 240.637 163 244.5Z"
                stroke={stroke}
                stroke-width="2"
            />
            <path
                d="M669.5 230C667.099 238.8 651.166 243.667 643.5 245C643.5 223 629.001 197.3 597.001 196.5C557.001 195.5 549.5 232 548.5 242.5C547.7 250.9 542.833 252.333 540.5 252C418 252 175.701 254.5 172.501 254.5C168.501 254.5 166.501 251 166.501 247C166.501 243.8 168.001 232.5 161.501 232.5H85.5007C80.5007 232.5 81.5007 236.5 82.5007 246C83.3007 253.6 77.5155 253.323 74.5007 253C60.5 251.5 31 248.5 17.4996 246C2.94704 243.305 0.6663 226.667 1.49963 217V17C1.49963 4.6 16.4996 1.16667 23.9996 1H479.5C519.5 4.6 546.166 27.1667 554.5 38L630.5 147.5C655.3 156.7 665.5 170.667 667.5 176.5C669.166 190.667 671.9 221.2 669.5 230Z"
                stroke={stroke}
                stroke-width="2"
            />
        </svg>
    );
};

export default Stencil5;
