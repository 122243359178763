import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import SoftSensorXLogo from "../../components/Common/svgLogs/SoftSensorXLogo";
import { useNavigate } from "react-router-dom";

const OverViewPage = () => {
    const smd = useMediaQuery("(max-width:1500px)");

    const navigate = useNavigate();
    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box
                sx={{
                    width: "90%",
                    height: "90%",

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <Box
                        sx={{
                            transform: smd ? "scale(1.2)" : "scale(2)",
                            mr: smd ? "15px" : "50px",
                            mt: smd ? "7px" : "15px",
                        }}
                    >
                        <SoftSensorXLogo />
                    </Box>
                    <Typography variant={smd ? "h6" : "h4"} sx={{ color: "#007BFF" }}>
                        Your Data Revolution With Transformative AI Frameworks
                    </Typography>
                </Box>
                {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "center", mt: smd ? "10px" : "25px" }}>
                    <Typography variant={smd ? "body1" : "h6"}>
                        {" "}
                        Embrace The Next-Gen In Data Intelligence With SoftsensorX.
                    </Typography>
                </Box> */}
                <img
                    src="https://d3fvaqnlz9wyiv.cloudfront.net/softsensorX/web-banner-2.gif"
                    style={{ width: "60%", marginTop: "2%", height: "70%" }}
                />
                <Button
                    variant="contained"
                    sx={{
                        background: "#007BFF",
                        width: smd ? "85px" : "160px",
                        height: smd ? "35px" : "50px",
                        borderRadius: "10px",
                        mt: smd ? "10px" : "25px",
                        fontSize: smd ? "12px" : "14px",
                    }}
                    onClick={() => navigate("/introduction")}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
};

export default OverViewPage;
