import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const Banner = ({ setBanner, data }: any) => {
    const [step, setStep] = useState<number>(0);
    return (
        <Box
            sx={{
                zIndex: "10",
                width: "100%",
                position: "absolute",
                height: "100%",
                display: "flex",
                left: 0,
                top: 0,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    height: "85%",
                    width: "80%",
                    background: "linear-gradient(to bottom right, rgb(239,247,254), rgb(212,224,233))",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        minWidth: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "4%",
                        flexDirection: "row",
                        display: "flex",
                        mt: -1,
                    }}
                >
                    <Box></Box>
                    <Button
                        sx={{ width: "40px", mr: -2 }}
                        onClick={() => {
                            setBanner(false);
                            if (window.location.href.includes("compare")) {
                                sessionStorage.setItem("compare", "true");
                            } else if (window.location.href.includes("chat")) {
                                sessionStorage.setItem("chat", "true");
                            } else if (window.location.href.includes("summarize")) {
                                sessionStorage.setItem("summarize", "true");
                            } else if (window.location.href.includes("pathology")) {
                                sessionStorage.setItem("pathology", "true");
                            } else if (window.location.href.includes("report_generation")) {
                                sessionStorage.setItem("report_generation", "true");
                            }
                        }}
                    >
                        <Close sx={{ color: "#fff" }} />
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography sx={{ color: "#007BFF", fontSize: "max(1.5vw, 16px)" }}>{data[step]?.text}</Typography>
                    <Typography sx={{ mt: "", fontSize: "max(1vw, 12px)", textAlign: "center" }}>
                        {data[step]?.altText}
                    </Typography>
                    <img src={data[step]?.url} style={{ width: "60%", marginTop: "2%" }} />
                </Box>
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", mt: "2%", justifyContent: "center" }}>
                    {data?.map((value: any, index: number) => {
                        return (
                            <Button
                                onClick={() => setStep(index)}
                                sx={{ background: step === index ? "#3386D3" : "#F3F3F3", mr: "1%", maxWidth: "30px" }}
                            ></Button>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default Banner;
