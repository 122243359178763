import React from "react";

const ErrorState = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 16.75C12.7975 16.75 13.047 16.6492 13.2486 16.4476C13.4502 16.246 13.5507 15.9968 13.55 15.7C13.5493 15.4032 13.4485 15.154 13.2476 14.9524C13.0467 14.7508 12.7975 14.65 12.5 14.65C12.2025 14.65 11.9533 14.7508 11.7524 14.9524C11.5515 15.154 11.4507 15.4032 11.45 15.7C11.4493 15.9968 11.5501 16.2463 11.7524 16.4486C11.9547 16.6509 12.2039 16.7514 12.5 16.75ZM11.45 12.55H13.55V6.25H11.45V12.55ZM12.5 22C11.0475 22 9.6825 21.7242 8.405 21.1726C7.1275 20.621 6.01625 19.873 5.07125 18.9287C4.12625 17.9844 3.3783 16.8732 2.8274 15.595C2.2765 14.3168 2.0007 12.9518 2 11.5C1.9993 10.0482 2.2751 8.6832 2.8274 7.405C3.3797 6.1268 4.12765 5.01555 5.07125 4.07125C6.01485 3.12695 7.1261 2.379 8.405 1.8274C9.6839 1.2758 11.0489 1 12.5 1C13.9511 1 15.3161 1.2758 16.595 1.8274C17.8739 2.379 18.9851 3.12695 19.9287 4.07125C20.8723 5.01555 21.6206 6.1268 22.1736 7.405C22.7266 8.6832 23.0021 10.0482 23 11.5C22.9979 12.9518 22.7221 14.3168 22.1726 15.595C21.6231 16.8732 20.8751 17.9844 19.9287 18.9287C18.9823 19.873 17.8711 20.6213 16.595 21.1736C15.3189 21.7259 13.9539 22.0014 12.5 22Z"
                fill="#CC5555"
            />
        </svg>
    );
};

export default ErrorState;
