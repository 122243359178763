import React from "react";

const CompareIcon = ({ fill }: { fill?: string }) => {
    return (
        <svg
            style={{ width: "1.063rem", height: "1.125rem" }}
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_339_299"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                style={{ width: "1.375rem", height: "1.375rem", maskType: "alpha" }}
            >
                <rect x="0.0625" y="0.0625" width="14.875" height="14.875" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_339_299)">
                <path
                    d="M6.26042 14.3171V13.0775H3.16146C2.82057 13.0775 2.52875 12.9561 2.286 12.7133C2.04325 12.4706 1.92188 12.1788 1.92188 11.8379V3.16081C1.92188 2.81992 2.04325 2.5281 2.286 2.28535C2.52875 2.0426 2.82057 1.92122 3.16146 1.92122H6.26042V0.681641H7.5V14.3171H6.26042ZM3.16146 11.2181H6.26042V7.49935L3.16146 11.2181ZM8.73958 13.0775V7.49935L11.8385 11.2181V3.16081H8.73958V1.92122H11.8385C12.1794 1.92122 12.4712 2.0426 12.714 2.28535C12.9567 2.5281 13.0781 2.81992 13.0781 3.16081V11.8379C13.0781 12.1788 12.9567 12.4706 12.714 12.7133C12.4712 12.9561 12.1794 13.0775 11.8385 13.0775H8.73958Z"
                    fill={fill || "#484848"}
                />
            </g>
        </svg>
    );
};

export default CompareIcon;
