import { TableHead, TableRow, TableCell, TableBody, Table, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { json } from "react-router-dom";

const Relevencies = ({ value }: { value: any }) => {
    const [relevencie, setRelevencie] = useState<any>("");
    useEffect(() => {
        try {
            const json = JSON?.parse(
                '[{"C4-Integrated Document(For Internal Use Only)": {"C4-Integrated Document(For Internal Use Only)": "Sub-Clause 4.3 of the General Conditions of Contract Agreement:(Clause/Section/Rule) Not Relevant"}}]'
            );
            setRelevencie(json);
        } catch (error) {
            setRelevencie(value);
        }
    }, [value]);

    if (Array?.isArray(relevencie)) {
        return (
            <Box sx={{ m: 1 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {["Document", "Clause Statement", "Relvancy"]?.map((head) => (
                                <TableCell sx={{ background: "#c9d9ff", border: "1px solid #D9D9D9" }}>
                                    {head}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {relevencie?.map((row: any) => (
                            <TableRow>
                                {Object?.keys(row)?.map((rowHead) => (
                                    <>
                                        <TableCell size="small" sx={{ border: "1px solid #D9D9D9", minWidth: "10rem" }}>
                                            {rowHead}
                                        </TableCell>
                                        <TableCell size="small" sx={{ border: "1px solid #D9D9D9", minWidth: "10rem" }}>
                                            {row[rowHead][rowHead]?.split(":")[0]}
                                        </TableCell>
                                        <TableCell size="small" sx={{ border: "1px solid #D9D9D9", minWidth: "10rem" }}>
                                            {row[rowHead][rowHead]?.split(":")[1]}
                                        </TableCell>
                                    </>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    }
    return <div>{relevencie}</div>;
};

export default Relevencies;
