// import Libraries

import { instance, defaultCatch, logger, log_prefix, messages, VERBOSE } from "../lib/axios";
import { ResponseGenerator } from "../store/sagas/auth";


export default class Auth {
    static login(values: any): Promise<ResponseGenerator> {
        const payload = values;
        logger(`${log_prefix} ${messages.start} login`, VERBOSE, "info");
        return new Promise((resolve) => {
            instance
                .post("url_login", payload.data)
                .then(function (response: any) {
                    resolve(response);
                })
                .catch(function (error: any) {
                    defaultCatch(error, resolve);
                });
        });
    }
    static logOut(values: any): Promise<ResponseGenerator> {
        const payload = values;
        instance.defaults.headers.common["x-access-token"] = payload.sessionToken;
        instance.defaults.headers.common["x-access-user"] = payload.user;
        logger(`${log_prefix} ${messages.start} logout`, VERBOSE, "logout");
        return new Promise((resolve) => {
            instance
                .post("url_logout")
                .then(function (response: any) {
                    resolve(response);
                })
                .catch(function (error: any) {
                    defaultCatch(error, resolve);
                });
        });
    }

}

