import { Check, Close, Pending } from "@mui/icons-material";
import { Box, Tooltip, Typography, LinearProgress, IconButton } from "@mui/material";
import { IconExclamationCircle, IconExclamationMark } from "@tabler/icons-react";
import moment from "moment";
import React, { useEffect, useState } from "react";

const UploadProgess = ({
    item,
    loading,
    response,
    socketHooks,
    msgRef,
    setUploadFiles,
}: {
    item: any;
    loading: string;
    response: any;
    socketHooks: any;
    msgRef: any;
    setUploadFiles: any;
}) => {
    const [value, setValue] = useState<number>(-1);
    const [status, setStatus] = useState<string>(loading);

    useEffect(() => {
        const name = item?.name?.replace(".pdf", "");
        if (response[name]) {
            if (response[name] === "Pdf already exists, added to project!") {
                setValue(100);
            } else {
                socketHooks?.sendJsonMessage({
                    upload_status: { file_id: response[name] },
                });
            }
        }
    }, [response]);

    useEffect(() => {
        if (msgRef?.json?.file_id === response[item?.name?.replace(".pdf", "")]) {
            if (typeof msgRef?.json?.progress === "number") {
                if (msgRef?.json?.progress < 100) {
                    setTimeout(() => {
                        socketHooks?.sendJsonMessage({
                            upload_status: { file_id: msgRef?.json?.file_id },
                        });
                    }, 3000);
                }
                setValue(msgRef?.json?.progress);
            } else {
                setStatus("failed");
            }
        }
    }, [msgRef?.json]);

    useEffect(() => {
        setStatus(() => loading);
    }, [loading]);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 1,
                borderRadius: "4px",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Tooltip title={item?.name}>
                    <Typography noWrap sx={{ width: "11rem" }}>
                        {item?.name}
                    </Typography>
                </Tooltip>
            </Box>
            {["uploaded", "upload", "failed"]?.includes(status) && (
                <Box sx={{ flex: 1, px: 3, display: "flex", alignItems: "center", gap: 1 }}>
                    <LinearProgress
                        variant="determinate"
                        value={value}
                        sx={{ borderRadius: "19px", flex: 1 }}
                        className={value === 100 ? "" : "travelFlash"}
                    />
                    <Box
                        sx={{
                            fontSize: "0.87rem",
                            border: "1px solid",
                            borderColor: status === "failed" ? "red" : value === 100 ? "#17a817" : "#fff",
                            borderRadius: "50%",
                            width: "18px",
                            height: "18px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {status === "failed" ? (
                            <IconExclamationMark style={{ fontSize: "0.85rem", color: "red" }} />
                        ) : value === 100 ? (
                            <Check sx={{ fontSize: "0.85rem", color: "#17a817" }} />
                        ) : (
                            `${value < 0 ? "0" : value}%`
                        )}
                    </Box>
                </Box>
            )}
            <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, width: "10rem", justifyContent: "space-between" }}
            >
                <Typography sx={{ fontSize: "0.8rem", color: "#9D9B9B" }}>
                    Date {moment(item?.lastModifiedDate)?.format("DD/MM/YYYY")}
                </Typography>
                <IconButton
                    onClick={() =>
                        setUploadFiles((prev: any) => prev?.filter((file: any) => file?.name !== item?.name))
                    }
                    disabled={(value > 0 && value < 100) || ["uploaded", "upload", "failed"]?.includes(status)}
                >
                    <Close fontSize="small" />
                </IconButton>
            </Box>
        </Box>
    );
};

export default UploadProgess;
