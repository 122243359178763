import React from "react";

const LogisticsIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M43.9012 18.75H35.625V22.5H42.3488L52.5 32.6512V46.0988L51.7238 46.875H50.625V46.0988L45.7762 41.25H40.4738L35.625 46.0988V46.875H24.375V46.0988L19.5262 41.25H14.2238L9.375 46.0988V46.875H8.27625L7.5 46.0988V35.625H3.75V47.6512L6.72375 50.625H9.375V51.4012L14.2238 56.25H19.5262L24.375 51.4012V50.625H35.625V51.4012L40.4738 56.25H45.7762L50.625 51.4012V50.625H53.2762L56.25 47.6512V31.0988L43.9012 18.75ZM20.625 49.8488L17.9738 52.5H15.7762L13.125 49.8488V47.6512L15.7762 45H17.9738L20.625 47.6512V49.8488ZM46.875 49.8488L44.2238 52.5H42.0262L39.375 49.8488V47.6512L42.0262 45H44.2238L46.875 47.6512V49.8488Z"
                fill="black"
            />
            <path
                d="M30.7762 33.75L33.75 30.7762V6.72375L30.7762 3.75H6.72375L3.75 6.72375V30.7762L6.72375 33.75H30.7762ZM16.875 7.5H20.625V13.125H16.875V7.5ZM7.5 8.27625L8.27625 7.5H13.125V16.875H24.375V7.5H29.2238L30 8.27625V29.2238L29.2238 30H8.27625L7.5 29.2238V8.27625Z"
                fill="black"
            />
        </svg>
    );
};

export default LogisticsIcon;
