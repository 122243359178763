const VerifiedIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.25 3.471L9.00338 1.5L15.75 3.471V7.51275C15.75 9.58414 15.0981 11.603 13.8869 13.2833C12.6756 14.9636 10.9663 16.2202 9.00113 16.875C7.03536 16.2203 5.32548 14.9635 4.11379 13.2829C2.90209 11.6022 2.25003 9.5828 2.25 7.51087V3.471Z"
                stroke="#12924F"
                stroke-width="2"
                stroke-linejoin="round"
            />
            <path
                d="M5.625 8.625L8.25 11.25L12.75 6.75"
                stroke="#12924F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default VerifiedIcon;
