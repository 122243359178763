import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/setting";
import { OutlinedInput, Box, IconButton } from "@mui/material";
import SendWhiteIcon from "../../../components/Common/svgLogs/SendWhiteIcon";
import { Mic } from "@mui/icons-material";

const InputField = ({ handleSend }: { handleSend: (value: string) => void }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [query, setQuery] = useState<string>("");
    const [activeMic, setActiveMic] = useState<boolean>(false);

    const wind: any = window;
    const speechRecognization = wind?.SpeechRecognition || wind?.webkitSpeechRecognition;
    const rec = new speechRecognization();

    const handleMicorphone = () => {
        setActiveMic(true);
        rec.lang = "en-US";
        rec.continuous = true;
        rec.interimResults = true;
        rec?.start();
        rec.onresult = (res: any) => {
            let text: string = Array.from(res.results)
                .map((r: any) => r[0])
                .map((txt: any) => txt.transcript)
                .join("");
            setQuery(text);
        };
        setTimeout(() => {
            setActiveMic(false);
            rec?.stop();
        }, 7000);
    };

    return (
        <OutlinedInput
            value={query}
            onChange={(e: { target: { value: string }; preventDefault: any }) => {
                setQuery(e.target.value);
            }}
            onKeyDown={(e: any) => {
                if (e?.key === "Enter") {
                    handleSend(e?.target?.value);
                    setQuery("");
                    rec?.stop();
                }
            }}
            fullWidth
            endAdornment={
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {/* {contextStore?.activeSource === "excel" && (
                        <IconButton onClick={() => handleSend(query, "sql")}>
                            <ESGSearchIcon />
                        </IconButton>
                    )} */}
                    <IconButton
                        sx={{ background: "#007BFF", "&:hover": { background: "#007BFFaa" } }}
                        onClick={() => {
                            handleSend(query);
                            setQuery("");
                            rec?.stop();
                        }}
                    >
                        <SendWhiteIcon />
                    </IconButton>
                </Box>
            }
            startAdornment={
                <IconButton size="small" onClick={handleMicorphone}>
                    <Mic sx={{ color: activeMic ? "#007BFF" : "inherit" }} />
                </IconButton>
            }
            sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "80px",
                },
                "& .MuiOutlinedInput-input": {
                    padding: "10px 20px",
                },
                width: "100%",
            }}
            placeholder="Ask Me Anything"
        />
    );
};

export default InputField;
