import { Box, Button, Checkbox, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/setting";
import { useNavigate } from "react-router-dom";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";
import { Api } from "../../apis";
import DocumentLoadingModal from "../Chat/DocumentLoading/DocumentLoadingModal";
import VerifiedIcon from "../../components/Common/svgLogs/CyberSecurity/VerifiedIcon";
import IsScannedIcon from "../../components/Common/svgLogs/CyberSecurity/IsScannedIcon";

const CyberSecurity = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    let [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [page, setPage] = useState<any>(1);
    const [logs, setLogs] = useState<any>(null);
    const [loader, setLoader] = useState<boolean>(false);
    const [totalPage, setTotalPage] = useState<number>(0);
    const [insights, setInsights] = useState<any>(null);

    const getLogs = () => {
        Api?.getLogsInsight()?.then((res: any) => {
            if (res?.status === 200) {
                setInsights(res?.data);
            } else {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: res?.message || "Something Went Wrong",
                    },
                });
            }
        });
    };

    useEffect(() => {
        getLogs();
    }, []);

    const handleLogs = async (page: any) => {
        setLoader(true);
        const result = await response_generator(Api?.getCybersecurityLogs(page));
        if (result) {
            setLogs(result);
            setTotalPage(result?.total_pages);
        }
        setLoader(false);
    };

    const response_generator = async (promise: any) => {
        const result = await promise;
        if (result?.status === 200) {
            return result?.data;
        } else {
            if (result?.status === 500) {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: result?.message || "Something Went Wrong",
                    },
                });
            }
            return undefined;
        }
    };

    const getAnalysis = () => {
        if (selectedFiles.length === 0)
            setContext({
                ...contextStore,
                snackState: {
                    open: true,
                    errorType: "error",
                    message: "Please select atleast one log, then try again",
                },
            });
        else {
            setLoader(true);
            Api?.getLogAnalysis({ ids: selectedFiles })?.then((res: any) => {
                if (res?.status === 200) {
                    navigate("/cybersecurity/analysis", { state: res?.data });
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Server issue! Please try after some time",
                        },
                    });
                }
                setLoader(false);
            });
        }
    };

    useEffect(() => {
        handleLogs(page);
    }, [page]);

    const updateState = (fileName: any) => {
        const index = selectedFiles.indexOf(fileName);
        if (index > -1) {
            selectedFiles = selectedFiles.filter((e: any) => e !== fileName);
            setSelectedFiles(selectedFiles);
        } else {
            setSelectedFiles([...selectedFiles, fileName]);
        }
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: 2,
            }}
        >
            <Box
                sx={{
                    width: "60%",
                    height: "15%",
                    display: "flex",
                    border: "1px solid #ECECEC",
                    borderRadius: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: "20%",
                        borderRight: "1px solid #ECECEC",
                        height: "70%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="h4" sx={{ color: "#007BFF" }}>
                        {insights?.["Total Logs"]}
                    </Typography>
                    <Typography>Total logs</Typography>
                </Box>
                <Box
                    sx={{
                        width: "20%",
                        borderRight: "1px solid #ECECEC",
                        height: "70%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="h4" sx={{ color: "#007BFF" }}>
                        {insights?.["Scanned Logs"]}
                    </Typography>
                    <Typography>Scanned logs</Typography>
                </Box>
                <Box
                    sx={{
                        width: "20%",
                        borderRight: "1px solid #ECECEC",
                        height: "70%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="h4" sx={{ color: "#007BFF" }}>
                        {insights?.["Pending Logs"]}
                    </Typography>
                    <Typography>Pending logs</Typography>
                </Box>
                <Box
                    sx={{
                        width: "20%",
                        height: "70%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="h4" sx={{ color: "#007BFF" }}>
                        {insights?.["Attacks Detected"]}
                    </Typography>
                    <Typography>Attacks Detected</Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    width: "60%",
                    height: "70%",
                    display: "flex",
                    border: "1px solid #ECECEC",
                    borderRadius: "10px",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        width: "90%",
                        height: "10%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        mt: "2%",
                    }}
                >
                    <Typography variant="h4" sx={{ color: "#007BFF" }}>
                        Your Logs
                    </Typography>
                    <Typography sx={{ color: "#9D9B9B" }}>Select the desired logs to analyse</Typography>
                </Box>
                <Box
                    sx={{
                        width: "90%",
                        height: "45vh",
                        display: "flex",
                        flexDirection: "column",
                        overflowY: "scroll",
                        mt: "2%",
                    }}
                >
                    {logs?.documents?.map((id: any) => {
                        return (
                            <Box
                                sx={{
                                    width: "100%",
                                    minHeight: "80px",
                                    borderBottom: "1px solid #ECECEC",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "0 10px",
                                }}
                            >
                                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                                    <Checkbox
                                        sx={{
                                            color: "#007BFF",
                                            "&.Mui-checked": {
                                                color: "#007BFF",
                                            },
                                        }}
                                        onChange={(e: any) => updateState(id?.id)}
                                        checked={selectedFiles.includes(id?.id)}
                                    />
                                    <Typography>{id?.id}</Typography>
                                </Box>

                                <Box sx={{ display: "flex", gap: 3, alignItems: "center", mr: "2%" }}>
                                    <Typography>{id?.timestamp}</Typography>
                                    {id?.is_verified && <VerifiedIcon />}
                                    {id?.is_scanned && <IsScannedIcon />}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
                <Box sx={{ display: "flex", width: "93%", justifyContent: "center", alignItems: "center", mt: "1%" }}>
                    <Button
                        onClick={() => {
                            if (page >= 1) {
                                setPage(page - 1);
                            }
                        }}
                        disabled={page === 1}
                    >
                        <ChevronLeftRounded />
                    </Button>
                    <Typography>{`Page: ${page}`}</Typography>
                    <Button
                        onClick={() => {
                            if (page < totalPage) {
                                setPage(page + 1);
                            }
                        }}
                        disabled={page === totalPage}
                    >
                        <ChevronRightRounded />
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: "90%",
                        height: "15%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography sx={{ color: "#9D9B9B" }}>{`${selectedFiles.length} files selected`}</Typography>
                    <Button
                        variant="contained"
                        sx={{
                            background: "#007BFF",
                            width: "12rem",
                            height: "2.3rem",
                            borderRadius: "10px",
                            "&:hover": {
                                boxShadow: "0px -1px 6px 4px #d4d4d4",
                            },
                        }}
                        onClick={() => getAnalysis()}
                    >
                        Continue
                    </Button>
                </Box>
            </Box>
            <DocumentLoadingModal loading={loader} />
        </Box>
    );
};

export default CyberSecurity;
