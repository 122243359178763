import React from "react";

const SummaryIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 7.5H18V9.16667H2V7.5ZM2 10.8333H14.4444V12.5H2V10.8333Z" fill="#747474" />
        </svg>
    );
};

export default SummaryIcon;
