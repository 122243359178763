import React from 'react'
import HHighlightSearch from './HighlightSearch/HHighlightSearch'
import App2 from './PDFDisplay/App2'

const SearchHighlighter = () => {
  return (
    <HHighlightSearch/>
  )
}

export default SearchHighlighter