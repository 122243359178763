import React from "react";

const AttachmentIcon = () => {
    return (
        <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3032 6.94527L17.2166 3.95111C17.8177 3.33276 18.5314 2.84218 19.317 2.5074C20.1027 2.17262 20.9448 2.0002 21.7952 2C22.6457 1.9998 23.4879 2.17181 24.2737 2.50621C25.0595 2.84061 25.7734 3.33085 26.3748 3.94892C26.9762 4.56699 27.4532 5.30077 27.7786 6.10836C28.1039 6.91594 28.2713 7.7815 28.2711 8.65558C28.2709 9.52966 28.1031 10.3951 27.7774 11.2026C27.4517 12.01 26.9743 12.7435 26.3727 13.3613L22.2108 17.6387C21.6096 18.2566 20.8959 18.7467 20.1104 19.0811C19.3249 19.4155 18.483 19.5876 17.6327 19.5876C16.7825 19.5876 15.9406 19.4155 15.1551 19.0811C14.3696 18.7467 13.6559 18.2566 13.0547 17.6387"
                stroke="#007BFF"
                strokeWidth="3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M15.9678 24.0545L13.0545 27.0486C12.4534 27.667 11.7397 28.1576 10.9541 28.4923C10.1684 28.8271 9.32633 28.9995 8.47586 28.9997C7.62538 28.9999 6.7832 28.8279 5.99742 28.4935C5.21164 28.1591 4.49767 27.6689 3.89629 27.0508C3.29491 26.4327 2.81791 25.699 2.49254 24.8914C2.16717 24.0838 1.9998 23.2182 2 22.3442C2.0002 21.4701 2.16796 20.6046 2.4937 19.7972C2.81943 18.9897 3.29677 18.2562 3.89842 17.6384L8.06029 13.361C8.66149 12.7431 9.37522 12.253 10.1607 11.9186C10.9462 11.5842 11.7881 11.4121 12.6383 11.4121C13.4886 11.4121 14.3305 11.5842 15.116 11.9186C15.9015 12.253 16.6152 12.7431 17.2164 13.361"
                stroke="#007BFF"
                strokeWidth="3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default AttachmentIcon;
