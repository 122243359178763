import { Box, Button, Typography } from "@mui/material";
import React from "react";

interface InitialChatProps {
    setSelectedTab: (tab: string) => void;
}

const InitialChat: React.FC<InitialChatProps> = ({ setSelectedTab }) => {
    return (
        <Box
            sx={{
                width: "80vw",
                height: "40vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    width: "50vw",
                    // height: "20vh",
                    // border: "1px solid red",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "20px",
                    boxShadow: "0px 15px 15px rgba(0, 0, 0, 0.1)", // Add box shadow
                    p: 1,
                }}
            >
                <Typography sx={{ fontSize: "2rem" }}> Hey There !</Typography>
                <Typography sx={{ fontSize: "1rem", color: "#424F65" }}>
                    {" "}
                    Please Select a file to start interacting
                </Typography>
                <Button
                    sx={{
                        background: "#007BFF",
                        color: "white",
                        paddingX: "22px",
                        "&:hover": {
                            background: "#007BFF", // Set the hover background color to be the same as the normal background color
                        },
                    }}
                    onClick={() => setSelectedTab("my_files")}
                >
                    Select a File
                </Button>
            </Box>
        </Box>
    );
};

export default InitialChat;
