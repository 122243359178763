import { Box, Typography, IconButton } from "@mui/material";
import Fade from "@mui/material/Fade";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useRef, useState } from "react";
import InputField from "./InputField";
import axios from "axios";
import Loader from "./Loader";
import ProfileAvatar from "../../../components/Common/svgLogs/ProfileAvatar";
import SSXLogo from "../../../components/Common/svgLogs/Search_Highlighter/SSXLogo";
import moment from "moment";
import MessageBox from "./MessageBox";
import App2 from "../PDFDisplay/App2";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import PdfViewer from "../PDFDisplay/PdfViewer";

const sampleQueryData = {
    tryAsking: [
        "What are the main topics and key points covered in the document discussing financial analysis concepts?",
        "Can you outline the document's content, emphasizing the primary topics and key aspects of financial analysis",
        "Which financial analysis concepts does document cover, what are the key insights from these discussions?",
    ],
    limitations: [
        "Optimal results with standardized documents; struggles with diverse formats.",
        "May not handle highly complex or specialized IT graphs effectively.",
        "May not guarantee improved results; requires user familiarity with IT tasks.",
    ],
};

type HideFunction = () => void;
const HHighlightSearch: React.FC = () => {
    const containerRef: any = useRef<any>();
    const [msgList, setMsgList] = useState<any[]>([
        {
            in: true,
            init: true,
            init_msg: (
                <Box
                    sx={{
                        justifyContent: "space-between",
                        borderRadius: "10px",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            height: "10%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ width: "5%", ml: 2, minWidth: "50px" }}>
                            <SSXLogo />
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", width: "95%" }}>
                            <Typography>Let's get started!</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: "87%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mt: "2%",
                            ml: "max(5%, 50px)",
                            height: "50%",
                            gap: "1rem",
                        }}
                    >
                        <Box
                            sx={{
                                width: "50%",
                                ml: 2,
                            }}
                        >
                            <Typography variant="body2" sx={{ color: "#6B7582", mb: "2vh" }}>
                                Try Asking
                            </Typography>
                            {sampleQueryData?.tryAsking?.map((text: any) => (
                                <Box
                                    sx={{
                                        width: "100%",
                                        minHeight: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        background: "rgba(217, 217, 217, 0.3)",
                                        borderRadius: "6px",
                                        mb: "2vh",
                                        fontSize: "14px",
                                        p: 1,
                                    }}
                                >
                                    {text}
                                </Box>
                            ))}
                        </Box>
                        <Box
                            sx={{
                                width: "50%",
                                ml: 2,
                            }}
                        >
                            <Typography variant="body2" sx={{ color: "#6B7582", mb: "2vh" }}>
                                Limitations
                            </Typography>
                            {sampleQueryData?.limitations?.map((text: any) => (
                                <Box
                                    sx={{
                                        width: "100%",
                                        minHeight: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        background: "rgba(217, 217, 217, 0.3)",
                                        borderRadius: "6px",
                                        mb: "2vh",
                                        fontSize: "14px",
                                        p: 1,
                                    }}
                                >
                                    {text}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            ),
        },
    ]);
    const [pdfName, setPdfName] = useState<string>("");
    const [visiblePdf, setVisiblePdf] = useState<boolean>(false);

    const handleSend = (value: string) => {
        // setVisiblePdf(false)
        setMsgList((prev: any[]) => [
            ...prev?.filter((item) => !item?.init),
            { out: true, message: value },
            { in: true, msg: "loading", load: true },
        ]);
        setTimeout(() => {
            containerRef?.current?.lastElementChild?.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "end",
            });
        }, 100);
        axios
            .post("https://softxapi-demo.softsensor.ai/bond_llm/aurigo_info_retriever", {
                query: value,
            })
            ?.then((res) => {
                if (res?.status === 200) {
                    setVisiblePdf(true);
                    setMsgList((prev: any[]) => {
                        return prev?.map((items) => {
                            if (items?.load) {
                                items.load = false;
                                if (typeof res?.data === "string") {
                                    items.msg = res?.data;
                                    items.err = true;
                                } else {
                                    items.msg = res?.data?.answer;
                                    items.metadata = res?.data?.metadata_dict[0]?.Matches[0];
                                    items.pdfName = res?.data?.best_match_pdf_name;
                                    setPdfName(res?.data?.best_match_pdf_name);
                                }
                            }
                            return items;
                        });
                    });

                    setTimeout(() => {
                        containerRef?.current?.lastElementChild?.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                            inline: "end",
                        });
                    }, 100);
                }
            })
            ?.catch((err) => {
                setVisiblePdf(false);
                setMsgList((prev: any[]) => [
                    ...prev,
                    { in: true, msg: err?.response?.data?.detail || err?.message, err: true },
                ]);
                setTimeout(() => {
                    containerRef?.current?.lastElementChild?.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "end",
                    });
                }, 100);
            });
    };
    return (
        <ReflexContainer orientation="vertical">
            <ReflexElement flex={1} minSize={500}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flex: 1,
                        p: 1.5,
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            width: "60rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                        }}
                    >
                        <Box
                            sx={{
                                flex: "1 0 10vh",
                                overflow: "auto",
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                                fontSize: "0.9rem",
                                fontFamily: "Gilroy-SemiBold",
                                mt: 0.5,
                                pb: 1,
                                px: 0.5,
                            }}
                            ref={containerRef}
                        >
                            {msgList?.map((item) => <MessageBox item={item} />)}
                        </Box>
                        <Box>
                            <InputField handleSend={handleSend} />
                        </Box>
                    </Box>
                </Box>
            </ReflexElement>
            {visiblePdf && (
                <ReflexSplitter>
                    <Box
                        sx={{
                            width: "16px",
                            height: "100%",
                            background: "#E3F4FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                        }}
                    >
                        <Box
                            sx={{
                                width: "3px",
                                height: "15px",
                                background: "#0000001C",
                                borderRadius: "10px",
                            }}
                        ></Box>
                        <Box
                            sx={{
                                width: "3px",
                                height: "15px",
                                background: "#0000001C",
                                borderRadius: "10px",
                            }}
                        ></Box>
                    </Box>
                </ReflexSplitter>
            )}
            {visiblePdf && (
                <ReflexElement minSize={430} size={400}>
                    <Box sx={{ ml: 1, display: "flex", flexDirection: "column", height: "100%" }}>
                        <PdfViewer pdfName={pdfName} />
                    </Box>
                </ReflexElement>
            )}
        </ReflexContainer>
    );
};

export default HHighlightSearch;
