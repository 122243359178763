import { Typography } from "@mui/material";
import React from "react";

const Bounce = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px" }}>
            <div
                style={{
                    display: "flex",
                    background: "#fff",
                    gap: "10px",
                }}
            >
                <div className="bounce1"></div>
                <div className="bounce1"></div>
                <div className="bounce1"></div>
            </div>
            <Typography>Loading ...</Typography>
        </div>
    );
};

export default Bounce;
