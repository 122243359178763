import { Menu, MenuItem, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { Context } from "../../../../context/setting";

const FileMenu = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Typography
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    ml: 1.5,
                    cursor: "pointer",
                    width: "fit-content",
                }}
                onClick={handleClick}
            >
                Using
                <Typography
                    sx={{
                        background: "#E3F4FF",
                        padding: "4px 12px",
                        borderRadius: "19px",
                        fontSize: "0.9rem",
                        fontFamily: "Gilroy-SemiBold",
                        maxWidth: "12rem",
                    }}
                    noWrap
                >
                    {contextStore?.finance?.cfo?.files?.length > 1
                        ? `${contextStore?.finance?.cfo?.files[0]} + ${contextStore?.finance?.cfo?.files?.length}`
                        : contextStore?.finance?.cfo?.files[0]}
                </Typography>
            </Typography>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                {contextStore?.finance?.cfo?.files?.map((item: string) => (
                    <MenuItem onClick={handleClose}>
                        <Typography
                            sx={{ fontSize: "0.9rem", fontFamily: "Gilroy-SemiBold", maxWidth: "12rem" }}
                            noWrap
                        >
                            {item}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default FileMenu;
