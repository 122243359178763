import {
    Avatar,
    Box,
    Button,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SoftSensorXLogo from "../../components/Common/svgLogs/SoftSensorXLogo";
import { makeStyles } from "@mui/styles";
import SettingIcon from "../../components/Common/svgLogs/SettingIcon";
import BellIcon from "../../components/Common/svgLogs/BellIcon";
import ProfileAvatar from "../../components/Common/svgLogs/ProfileAvatar";
import { Context } from "../../context/setting";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import TranslateIcon from "@mui/icons-material/Translate";
import Search from "../Search/Search";
import SearchIcon from "../../components/Common/svgLogs/SearchIcon";

const AppBar = () => {
    const { logout, user } = useAuth0();
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [translateAnchorEl, setTranslateAnchorEl] = React.useState<null | HTMLElement>(null);
    const translateOpen = Boolean(translateAnchorEl);
    const [selectedLanguage, setSelectedLanguage] = useState<string>("English");
    const [dataSource, setDataSource] = useState<string>(contextStore?.activeSource || "");
    const [visibleSwitch, setVisibleSwitch] = useState<boolean>(false);
    const smd = useMediaQuery(theme?.breakpoints?.down("sm"));

    const handleTranslateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setTranslateAnchorEl(event.currentTarget);
    };

    const handleTranslateClose = () => {
        setTranslateAnchorEl(null);
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setSelectedLanguage(contextStore?.Language);
    }, [contextStore?.Language]);

    useEffect(() => {
        setVisibleSwitch(() => true);
    }, [contextStore?.data_source]);

    useEffect(() => {
        setDataSource(() => contextStore?.activeSource);
    }, [contextStore?.activeSource]);

    const styles = () => {
        if (smd) {
            return {
                body2: {
                    gap: 1,
                },
            };
        }
        return {};
    };

    return (
        <Box className={classes.container}>
            <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                <SoftSensorXLogo />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 3, ...styles()?.body2 }}>
                <Box sx={{ display: "flex" }}>
                    {/* {window?.location?.pathname?.includes("chat") && (
                        <IconButton
                            size="small"
                            sx={{
                                width: "fit-content",
                                border: "1px solid #747474",
                                borderRadius: "15px",
                                paddingX: "10px",
                                color: "#747474",
                                fontSize: "1rem",
                                fontFamily: "Outfit",
                                marginRight: "10px",
                            }}
                            aria-label="Translate"
                            onClick={handleTranslateClick}
                        >
                            <TranslateIcon sx={{ fontSize: "1rem", marginRight: "0.55rem" }} />
                            {selectedLanguage}
                        </IconButton>
                    )} */}
                    <Menu
                        id="translate-menu"
                        anchorEl={translateAnchorEl}
                        open={translateOpen}
                        onClose={handleTranslateClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        MenuListProps={{
                            "aria-labelledby": "translate-button",
                            sx: {
                                display: "flex",
                                flexDirection: "column",
                                gap: 1.4,
                                py: 1,
                                px: 2,
                            },
                        }}
                    >
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography sx={{ fontSize: "1.05rem" }}>Languages</Typography>
                            <Typography sx={{ display: "flex", alignItems: "center", gap: 0.5, fontSize: "0.75rem" }}>
                                <SearchIcon /> Search
                            </Typography>
                        </Box>
                        <Divider sx={{ borderColor: "#eee" }} />
                        <Typography fontSize={"0.8rem"} color={"#6B6B6B"}>
                            Choose the language for response
                        </Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, width: "14rem" }}>
                            {[
                                "English",
                                "Spanish",
                                "German",
                                "हिंदी",
                                "French",
                                "Swedish",
                                "Arabic",
                                "Russian",
                                "Telugu",
                                "Tamil",
                                "Malayalam",
                                "Kannada",
                                "Gujarati",
                                "Marathi",
                                "Bengali",
                            ]?.map((item: string) => (
                                <MenuItem
                                    onClick={() => {
                                        setContext({ ...contextStore, Language: item });
                                        setSelectedLanguage(item);
                                    }}
                                    sx={{
                                        border: "1px solid #EEE",
                                        borderRadius: "5px",
                                        paddingX: "10px",
                                        cursor: "pointer",
                                        background: selectedLanguage === item ? "#e3f4ff" : "#fff",
                                    }}
                                >
                                    {item}
                                </MenuItem>
                            ))}
                        </Box>
                    </Menu>
                    <IconButton
                        size="small"
                        sx={{
                            fontSize: "1rem",
                            // border: "1px solid #e3f4ff",
                            fontWeight: "400",
                            borderRadius: "15px",
                            color: "#007BFF",
                            backgroundColor: "#e3f4ff",
                            display: "flex",
                            alignItems: "center",
                            paddingX: "15px",
                        }}
                        onClick={() => navigate("/setting")}
                    >
                        <Box sx={{ marginRight: "5px", marginTop: "2px" }}>
                            <SettingIcon />
                        </Box>
                        Settings
                    </IconButton>
                    {/* <IconButton size="small">
                        <BellIcon />
                    </IconButton> */}
                    <IconButton size="small">
                        <ProfileAvatar />
                    </IconButton>
                    <Box sx={{ display: "grid", placeItems: "center" }}>
                        <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            sx={{ color: "#000" }}
                        >
                            {user?.given_name}
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <MenuItem
                                onClick={() =>
                                    logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })
                                }
                            >
                                Log out
                            </MenuItem>
                        </Menu>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.3rem 1rem",
        background: "#fff",
        boxShadow: "1px 0px 21px 0px rgba(83, 124, 204, 0.08)",
        position: "relative",
        zIndex: 2,
        paddingLeft: "1.5rem",
    },
});

export default AppBar;
