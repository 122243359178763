import {
    Box,
    FormControlLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Slider,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Context } from "../../context/setting";

const EnterSettings = ({ userSettings }: { userSettings: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const theme = useTheme();
    const [parsona, setParsona] = useState<string>(contextStore.temparary?.parsona || "");
    const [model, setModel] = useState<string>(contextStore.temparary?.model || "gpt-3.5-turbo");
    const [tempreture, setTempreture] = useState<number>(contextStore.temparary?.tempreture || 0);
    const [prompts, setPrompts] = useState<string[]>(contextStore.temparary?.prompts || [""]);
    const smd = useMediaQuery(theme?.breakpoints?.down("sm"));

    const styles = () => {
        if (smd) {
            return {
                box: {
                    width: "100%",
                },
                main: {
                    flexDirection: "column",
                },
            };
        }
    };

    return (
        <Box sx={{ display: "flex", gap: 4, ...styles()?.main }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3.5, width: "30rem", ...styles()?.box }}>
                <Box>
                    <Box>
                        <Typography variant="caption">Choose Persona</Typography>
                    </Box>
                    <Select
                        displayEmpty
                        fullWidth
                        size="small"
                        value={parsona}
                        sx={{
                            borderColor: "#CFCFCF",
                            mt: 1,
                            color: parsona === "" ? "#6B6B6B" : "inherit",
                        }}
                        onChange={(e: { target: { value: string } }) => {
                            setParsona(e?.target?.value);
                            userSettings.parsona = e?.target?.value;
                        }}
                    >
                        <MenuItem disabled value="">
                            Default Persona
                        </MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                    </Select>
                </Box>
                <Box>
                    <Typography variant="caption">LLM Model</Typography>
                    <RadioGroup
                        value={model}
                        row
                        sx={{ gap: 1 }}
                        onChange={(e: any) => {
                            setModel(e?.target?.value);
                            userSettings.model = e?.target?.value;
                        }}
                    >
                        <FormControlLabel
                            value={"gpt-3.5-turbo"}
                            control={<Radio size="small" />}
                            label={"gpt-3.5-turbo"}
                            sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.8rem" } }}
                        />
                        <FormControlLabel
                            value={"gpt-3.5-finetuned"}
                            control={<Radio size="small" />}
                            label={"gpt-3.5-finetuned"}
                            sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.8rem" } }}
                        />
                        <FormControlLabel
                            value={"bedrock"}
                            control={<Radio size="small" />}
                            label={"bedrock"}
                            sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.8rem" } }}
                        />
                    </RadioGroup>
                </Box>
                <Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="caption">Tempreture</Typography>
                        <Box
                            sx={{
                                border: "1px solid #CFCFCF",
                                px: 1,
                                py: 0.5,
                                color: "#007BFF",
                                borderRadius: "4px",
                            }}
                        >
                            {tempreture / 100}
                        </Box>
                    </Box>
                    <Box sx={{ px: 1 }}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", pt: 1.5 }}>
                            <Typography variant="caption" color={"#007BFF"}>
                                0.00
                            </Typography>
                            <Typography variant="caption" color={"#007BFF"}>
                                1.00
                            </Typography>
                        </Box>
                        <Slider
                            value={tempreture}
                            onChange={(e: any, value: number | number[]) => {
                                setTempreture(value as number);
                                userSettings.tempreture = e?.target?.value;
                            }}
                        ></Slider>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ width: "30rem" }}>
                <Box>
                    <Typography variant="caption">Custom Prompts</Typography>
                </Box>
                <OutlinedInput
                    multiline
                    value={prompts[0]}
                    rows={9}
                    sx={{
                        my: 1,
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "7.64px",
                            borderColor: "#CFCFCF",
                            overflow: "hidden",
                        },
                        "& .MuiOutlinedInput-input": {
                            height: "0.4rem",
                            // padding: "14px 12px",
                            fontSize: "0.9rem",
                        },
                    }}
                    onChange={(e: { target: { value: string } }) => {
                        setPrompts([e?.target?.value]);
                        userSettings.prompts = [e?.target?.value];
                    }}
                />
            </Box>
        </Box>
    );
};

export default EnterSettings;
