import { Box, IconButton, Typography, TextField, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import LessThanIcon from "../../components/Common/svgLogs/LessThanIcon";
import AttachmentIcon from "../../components/Common/svgLogs/AttachmentIcon";
import MongoDBColorIcon from "../../components/Common/svgLogs/MongoDBColorIcon";
import SqlIcon from "../../components/Common/svgLogs/SqlIcon";
import PostgresIcon from "../../components/Common/svgLogs/PostgresIcon";
import YoutubeIcon from "../../components/Common/svgLogs/YoutubeIcon";
import GoogleDocsIcon from "../../components/Common/svgLogs/GoogleDocsIcon";
import GoogleDriveIcon from "../../components/Common/svgLogs/GoogleDriveIcon";
import GoogleMailIcon from "../../components/Common/svgLogs/GoogleMailIcon";
import Upcoming1Icon from "../../components/Common/svgLogs/Upcoming1Icon";
import EllipseIcon from "../../components/Common/svgLogs/EllipseIcon";
import Upcoming2Icon from "../../components/Common/svgLogs/Upcoming2Icon";
import { Context } from "../../context/setting";
import { useNavigate } from "react-router-dom";
import GoogleSheetsIcon from "../../components/Common/svgLogs/GoogleSheetsIcon";
import { Preview } from "@mui/icons-material";
import { Api } from "../../apis";

const AddMongoSource = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    const [mongoData, setMongoData] = useState<any>({ dbname: "", uri: "" });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setMongoData((prev: any) => ({ ...prev, [name]: value }));
    };

    const handleUpload = () => {
        Api?.uploadMongoData({ uri: mongoData.uri, db_name: mongoData.dbname })?.then((res: any) => {
            if (res?.status === 200) {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message: "Mongo Data Uploaded Successful",
                    },
                });
            } else {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Something Went Wrong",
                    },
                });
            }
        });
    };

    return (
        <Box sx={{ p: 2.3, width: "100%", display: "flex", flexDirection: "column", gap: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <IconButton onClick={() => navigate(contextStore?.previousRoute || "/data_source/add_source")}>
                    <LessThanIcon />
                </IconButton>
                <Typography variant="h6" fontWeight={600}>
                    Add Data Source
                </Typography>
            </Box>
            <Box sx={{ display: "flex", px: 1.5, gap: 8 }}>
                <Box sx={{ width: "58%" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        <MongoDBColorIcon />
                        <Typography sx={{ fontSize: "1.2rem" }}>Mongo DB</Typography>
                    </Box>
                    <Box>
                        <Box sx={{ mt: "5vh" }}>
                            <Typography>Database Name</Typography>
                            <TextField
                                size="small"
                                id="db_name"
                                label="Enter Database Name"
                                variant="outlined"
                                sx={{ width: "40vw", mt: "15px" }}
                                name="dbname"
                                onChange={handleChange}
                            />
                        </Box>
                        <Box sx={{ mt: "5vh" }}>
                            <Typography>URI</Typography>
                            <TextField
                                size="small"
                                id="uri"
                                label="Enter URI"
                                variant="outlined"
                                sx={{ width: "40vw", mt: "15px" }}
                                name="uri"
                                onChange={handleChange}
                            />
                        </Box>
                        <Box
                            sx={{
                                mt: "5vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "80%",
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleUpload}
                                sx={{ background: "#007BFF", width: "15rem", height: "2.8rem", borderRadius: "10px" }}
                            >
                                Add Data Source
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: "40%" }}>
                    <Typography variant="subtitle2">Other Source Types</Typography>
                    <Box sx={{ height: "45vh", overflowY: "auto" }}>
                        <Box sx={{ mt: 3, display: "flex", gap: 5, flexWrap: "wrap" }}>
                            {[
                                // { title: "Website", icon: AttachmentIcon, key: "website" },
                                { title: "MongoDB", icon: MongoDBColorIcon, key: "mongoDB" },
                                // { title: "SQL", icon: SqlIcon, key: "sql" },
                                // { title: "PostgresSQL", icon: PostgresIcon, key: "postgresql" },
                                // { title: "Google Docs", icon: GoogleDocsIcon, key: "googleDocs" },
                                { title: "Youtube", icon: YoutubeIcon, key: "youtube" },
                                { title: "Google Sheets", icon: GoogleSheetsIcon, key: "googleSheets" },
                            ]?.map((elem) => (
                                <Box
                                    onClick={(e) => {
                                        navigate(`/data_source/add_source/${elem?.key}`);
                                        setContext({ ...contextStore, active_data_source: elem?.key });
                                    }}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        boxShadow:
                                            contextStore.active_data_source === elem.key
                                                ? "3px 3px 3px 3px #E3F4FF"
                                                : "0px 4px 8px 0px #AAAAAA1A",
                                        width: "13rem",
                                        height: "4.5rem",
                                        borderRadius: "14px",
                                        gap: 3,
                                        pl: 4,
                                        cursor: "pointer",
                                    }}
                                >
                                    <elem.icon />
                                    <Typography variant="subtitle2">{elem?.title}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ mt: 5 }}>
                        <Typography variant="subtitle2">Upcoming</Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 4, mt: 2 }}>
                            <GoogleDriveIcon />
                            <GoogleMailIcon />
                            <Upcoming1Icon />
                            <EllipseIcon />
                            <Upcoming2Icon />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AddMongoSource;
