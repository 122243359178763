import { Box, Button, Typography } from "@mui/material";
import React from "react";

const PageNotFound = () => {
    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h3">The page you are looking for does not exist</Typography>
        </Box>
    );
};

export default PageNotFound;
