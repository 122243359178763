import React from "react";

const MedicalSummarizer = () => {
    return (
        <svg
            style={{ width: "1.063rem", height: "1.125rem" }}
            fill="none"
            viewBox="0 0 18 22"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.9288 2.35718H15.2859C15.7027 2.35718 16.1024 2.52274 16.3971 2.81744C16.6918 3.11214 16.8574 3.51184 16.8574 3.92861V19.6429C16.8574 20.0597 16.6918 20.4594 16.3971 20.7541C16.1024 21.0488 15.7027 21.2143 15.2859 21.2143H2.71449C2.29773 21.2143 1.89803 21.0488 1.60333 20.7541C1.30863 20.4594 1.14307 20.0597 1.14307 19.6429V3.92861C1.14307 3.51184 1.30863 3.11214 1.60333 2.81744C1.89803 2.52274 2.29773 2.35718 2.71449 2.35718H5.07164"
                stroke="#484848"
                stroke-width="1.57143"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11.3573 0.785645H6.64302C6.22626 0.785645 5.82656 0.951205 5.53186 1.24591C5.23716 1.54061 5.0716 1.9403 5.0716 2.35707V3.14279C5.0716 3.55956 5.23716 3.95926 5.53186 4.25396C5.82656 4.54866 6.22626 4.71422 6.64302 4.71422H11.3573C11.7741 4.71422 12.1738 4.54866 12.4685 4.25396C12.7632 3.95926 12.9287 3.55956 12.9287 3.14279V2.35707C12.9287 1.9403 12.7632 1.54061 12.4685 1.24591C12.1738 0.951205 11.7741 0.785645 11.3573 0.785645ZM7.90488 8.18707C7.72567 8.18707 7.5538 8.25826 7.42708 8.38499C7.30036 8.51171 7.22917 8.68358 7.22917 8.86279V10.7595H5.33245C5.15324 10.7595 4.98137 10.8307 4.85465 10.9574C4.72793 11.0841 4.65674 11.256 4.65674 11.4352V13.6274C4.65674 13.9998 4.96002 14.3031 5.33245 14.3031H7.22917V16.1998C7.22917 16.5722 7.53245 16.8755 7.90488 16.8755H10.097C10.2762 16.8755 10.4481 16.8043 10.5748 16.6776C10.7015 16.5509 10.7727 16.379 10.7727 16.1998V14.2999H12.6695C12.8487 14.2999 13.0205 14.2287 13.1473 14.102C13.274 13.9753 13.3452 13.8034 13.3452 13.6242V11.4352C13.3448 11.2563 13.2734 11.0848 13.1467 10.9584C13.02 10.832 12.8484 10.7611 12.6695 10.7611H10.7727V8.86122C10.7723 8.68228 10.7009 8.51081 10.5743 8.38443C10.4476 8.25805 10.276 8.18707 10.097 8.18707H7.90488Z"
                stroke="#484848"
                stroke-width="1.57143"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default MedicalSummarizer;
