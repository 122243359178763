import { Box } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import RestaurantBanner from "../../assets/RestaurantBanner.png";
import Chats from "./Chats";
import InputHandler from "./InputHandler";
import SofieSocket from "../Chat/utils/SofieSocket";
import { useState } from "react";
import { Context } from "../../context/setting";
import { useAuth0 } from "@auth0/auth0-react";

const RestaurantBot = () => {
    const { user } = useAuth0();
    const [msg, socketHooks, msgRef] = SofieSocket();
    const [textFieldValue, setTextFieldValue] = useState("");
    const [messages, setMessages] = useState([
        {
            text: "Hi, I'm Sofie! Just tell me what you're craving, and I'll handle the rest. Easy food ordering at your fingertips!",
            isReceived: true,
            withAvatar: true,
        },
    ]);

    const handleSendClick = () => {
        const outMessage = {
            text: textFieldValue,
            isReceived: false,
            withAvatar: false,
        };
        setMessages((prevMessages) => [...prevMessages, outMessage]);
        socketHooks?.sendJsonMessage({
            user_name: user?.given_name,
            query: textFieldValue.trim().toLowerCase(),
        });
        setTextFieldValue("");
    };

    useEffect(() => {
        if (!msgRef.load) {
            try {
                if (msgRef?.json?.response) {
                    setMessages((prevMessages) => {
                        const text = msgRef?.json?.response;
                        msgRef.json = {};
                        return [...prevMessages, { text, isReceived: true, withAvatar: true }];
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [msgRef.load]);

    return (
        <Box
            sx={{
                // border: "3px solid red",
                width: "100%",
                height: "100%",
                backgroundImage: `url(${RestaurantBanner})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    width: "28%",
                    height: "90%",
                    background: "white",
                    borderRadius: "30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingX: "0.5rem",
                    paddingY: "0.5rem",
                }}
            >
                <Box sx={{ height: "90%", width: "100%" }}>
                    <Chats messages={messages} />
                </Box>
                <Box sx={{ height: "10%", width: "100%", mt: "10px" }}>
                    <InputHandler
                        handleSendClick={handleSendClick}
                        setTextFieldValue={setTextFieldValue}
                        textFieldValue={textFieldValue}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default RestaurantBot;
