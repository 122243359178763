import React, { useState } from 'react';
import { Box, Typography, Button, Stepper, Step, StepLabel, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HomeStepOneIcon from '../../components/Common/svgLogs/ChatX/HomeStepOneIcon';
import HomeStepTwoIcon from '../../components/Common/svgLogs/ChatX/HomeStepTwoIcon';
import HomeStepThreeIcon from '../../components/Common/svgLogs/ChatX/HomeStepThreeIcon';

interface StepProps {
  onSkip: () => void;
}

const StepContent: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 4,
    }}>
      {children}
    </Box>
);

const StepText: React.FC<{ title: string; description: string; onSkip?: () => void }> = ({ title, description, onSkip }) => (
  <Box sx={{ flex: 1, maxWidth: '400px' }}>
    <Typography variant="h5" gutterBottom>{title}</Typography>
    <Typography variant="body1" paragraph>{description}</Typography>
    {onSkip && <Button variant="text" color="primary" onClick={onSkip}>Skip & Start Chat →</Button>}
  </Box>
);

const StepIcon: React.FC<{ Icon: React.ComponentType }> = ({ Icon }) => {
  const theme = useTheme();
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        width: '100%',
        height: 'auto',
        maxWidth: '250px',  // Increased max width
        maxHeight: '250px', // Increased max height
        [theme.breakpoints.up('md')]: {
          maxWidth: '300px',  // Further increase for medium and larger screens
          maxHeight: '300px',
        },
        [theme.breakpoints.up('lg')]: {
          maxWidth: '350px',  // Further increase for large screens
          maxHeight: '350px',
        },
      },
    }}>
      <Icon />
    </Box>
  );
};

const Step1: React.FC<StepProps> = ({ onSkip }) => (
  <StepContent>
    <StepText 
      title="Upload Your Data" 
      description="To begin, upload your data from various formats like PDFs, Excel, Google Sheets, Google Docs, MongoDB, YouTube links, and more. Just click 'Upload' and choose your data source."
      onSkip={onSkip}
    />
    <StepIcon Icon={HomeStepOneIcon} />
  </StepContent>
);

const Step2: React.FC<StepProps> = ({ onSkip }) => (
  <StepContent>
    <StepText 
      title="Select Your Data Source" 
      description="Once you've uploaded your data, you can select the specific data source you'd like to use for your chat. This allows our chatbot to understand the format and content of your data."
      onSkip={onSkip}
    />
    <StepIcon Icon={HomeStepTwoIcon} />
  </StepContent>
);

const Step3: React.FC = () => (
  <StepContent>
    <StepText 
      title="Start Chatting" 
      description="Now that your data source is selected, you're ready to start chatting with our chatbot. Your conversations will be informed by the data you've provided, making it a powerful tool for accessing and analyzing your information."
    />
    <StepIcon Icon={HomeStepThreeIcon } />
  </StepContent>
);

const ChatHome: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Upload Your Data', 'Select Your Data Source', 'Start Chatting'];
  const navigate = useNavigate();
  const theme = useTheme();

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      navigate('/chat');
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleSkip = () => {
    navigate('/chat');
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <Step1 onSkip={handleSkip} />;
      case 1:
        return <Step2 onSkip={handleSkip} />;
      case 2:
        return <Step3 />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: theme.spacing(3),
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
    }}>
      <Stepper 
        activeStep={activeStep} 
        orientation="vertical"
        sx={{ 
          marginRight: theme.spacing(4),
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ flex: 1 }}>
        {getStepContent(activeStep)}
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          sx={{ marginTop: theme.spacing(4) }}
        >
          {activeStep === steps.length - 1 ? 'Start Chat' : 'Next'}
        </Button>
      </Box>
    </Box>
  );
};

export default ChatHome;
