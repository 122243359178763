import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

const GRIParametersModal = ({ parameters, onClose }: any) => {
    return (
        <Box
            sx={{
                width: "75%",
                height: "80%",
                p: "2% 3%",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "#fff",
                borderRadius: "18px",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                    display: "none",
                },
                "&-ms-overflow-style:": {
                    display: "none",
                },
            }}
        >
            <Box sx={{ width: "100%", height: "fit-content", mb: 2, display: "flex", justifyContent: "space-between" }}>
                <Box>
                    <Typography variant="h4" sx={{ color: "#007BFF" }}>
                        GRI Parameters
                    </Typography>
                    <Typography sx={{ color: "#424F65", mt: 1 }} variant="body1">
                        List of all GRI Parameters SoftsensorX examines
                    </Typography>
                </Box>
                <Button sx={{ height: "50px" }} onClick={() => onClose(false)}>
                    <Close />
                </Button>
            </Box>
            <Box>
                {Object.entries(parameters?.GRI_Parameters)?.map((parameter: any) => {
                    return (
                        <Box sx={{ mt: 1.4 }}>
                            <Typography variant="h6">{parameter?.[0]}</Typography>
                            <Box sx={{ ml: 2 }}>
                                {Object.entries(parameter?.[1])?.map((subParameters: any) => {
                                    return (
                                        <Box sx={{ mt: 0.8 }}>
                                            <Typography>{subParameters?.[0]}</Typography>
                                            {subParameters?.[1]?.map((value: any, index: number) => {
                                                return (
                                                    <Typography sx={{ fontSize: "13px" }}>{`${
                                                        index + 1
                                                    }. ${value}`}</Typography>
                                                );
                                            })}
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default GRIParametersModal;
