const DownloadIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.9974 11.6666L3.41406 7.08329L4.6974 5.75413L7.08073 8.13746V0.666626H8.91406V8.13746L11.2974 5.75413L12.5807 7.08329L7.9974 11.6666ZM2.4974 15.3333C1.99323 15.3333 1.56163 15.1538 1.2026 14.7948C0.843576 14.4357 0.664062 14.0041 0.664062 13.5V10.75H2.4974V13.5H13.4974V10.75H15.3307V13.5C15.3307 14.0041 15.1512 14.4357 14.7922 14.7948C14.4332 15.1538 14.0016 15.3333 13.4974 15.3333H2.4974Z"
                fill="#007BFF"
            />
        </svg>
    );
};

export default DownloadIcon;
