import React from "react";

const CopyIcon = () => {
    return (
        <svg
            style={{ width: "1.5rem", height: "1.5rem" }}
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.5" y="0.5" width="35" height="35" rx="10.587" fill="white" />
            <rect x="0.5" y="0.5" width="35" height="35" rx="10.587" stroke="#F5F5F5" />
            <path
                d="M21.667 9.75H10.667V21.6667"
                stroke="#007BFF"
                stroke-width="1.83333"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14.334 13.4167H25.334V24.4167C25.334 24.903 25.1408 25.3693 24.797 25.7131C24.4532 26.0569 23.9869 26.2501 23.5007 26.2501H16.1673C15.6811 26.2501 15.2148 26.0569 14.871 25.7131C14.5271 25.3693 14.334 24.903 14.334 24.4167V13.4167Z"
                stroke="#007BFF"
                stroke-width="1.83333"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default CopyIcon;
