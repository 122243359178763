import { Box } from "@mui/material";
import React, { useRef, useState } from "react";
import InputField from "./InputField";
import MessageBox from "./MessageBox";
import { Api } from "../../../apis";

const ChatBox = () => {
    const scrollRef = useRef<any>();
    const [msglist, setMsglist] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const handleSend = (value: string) => {
        setLoading(true);
        setMsglist((prev: any[]) => [...prev, { out: true, msg: value }, { in: true, msg: "", latest: true }]);
        setTimeout(() => {
            scrollRef?.current?.lastElementChild?.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
            });
        }, 100);
        Api?.anamolyChatResponse({ user_query: value, model: "gpt-3.5-turbo" })?.then((res: any) => {
            if (res?.status === 200) {
                setMsglist(
                    (prev) =>
                        prev?.map((msg) => {
                            if (msg?.latest) {
                                msg.msg = res?.data;
                                msg.latest = false;
                            }
                            return msg;
                        })
                );
                setTimeout(() => {
                    scrollRef?.current?.lastElementChild?.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "nearest",
                    });
                }, 100);
            } else {
                setMsglist(
                    (prev) =>
                        prev?.map((msg) => {
                            if (msg?.latest) {
                                msg.msg = "Failed To Generate Response";
                                msg.error = true;
                                msg.latest = false;
                            }
                            return msg;
                        })
                );
            }
            setLoading(false);
        });
    };
    return (
        <Box sx={{ px: 2, display: "flex", flexDirection: "column", flex: 1 }}>
            <Box ref={scrollRef} sx={{ flex: "1 0 0px", overflowY: "auto", py: 1 }}>
                {msglist?.map((msg) => <MessageBox msg={msg} loading={loading} />)}
            </Box>
            <InputField handleSend={handleSend} />
        </Box>
    );
};

export default ChatBox;
