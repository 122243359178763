import { Close } from "@mui/icons-material";
import { Box, Checkbox, Typography, IconButton, Divider, Stack, Switch, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SummaryIcon from "../../../components/Common/svgLogs/SummaryIcon";
import { setContext } from "redux-saga/effects";
import { Context } from "../../../context/setting";
import { useNavigate } from "react-router-dom";
import { Api } from "../../../apis";
import Bounce from "../../../components/Loaders/Bounce";

const LibraryProject = ({
    setListType,
    setFilteredFiles,
    handleClose,
    filteredFiles,
}: {
    setListType: any;
    setFilteredFiles: any;
    handleClose: () => void;
    filteredFiles: any[];
}) => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([...filteredFiles]);
    const [pdfList, setPdfList] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        setLoader(true);
        Api?.getClaimsExistingPdfLibrary({ user_id: contextStore?.profile?.userId })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setPdfList(res?.data);
                }
                setLoader(false);
            })
            ?.catch((err) => setLoader(false));
    }, []);

    return (
        <Box sx={{ display: "flex", justifyContent: "center", p: 1, width: "100%", background: "#FEFDFD" }}>
            <Box
                sx={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "0.7rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: "50rem",
                    height: "fit-content",
                    transition: "300ms ease-out",
                    fontFamily: "Gilroy-SemiBold !important",
                    background: "#fff",
                }}
            >
                <Box sx={{ px: 5, pt: 3 }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography
                            sx={{
                                color: "#007BFF",
                                fontSize: "1.07rem",
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                fontFamily: "Gilroy-SemiBold !important",
                            }}
                        >
                            Document Library
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, height: "2rem" }}>
                            <Typography sx={{ color: "#007BFF" }}>
                                {selectedFiles?.length > 0 && selectedFiles?.length} Select
                            </Typography>
                            {selectedFiles?.length > 0 && (
                                <IconButton onClick={() => setSelectedFiles([])}>
                                    <Close fontSize="small" sx={{ color: "#007BFF" }} />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                    <Typography sx={{ color: "#9D9B9B", fontSize: "0.9rem" }}>
                        Select the documents with which you wish to add in your project
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", height: "71vh" }}>
                    {loader ? (
                        <Box sx={{ flex: 1 }}>
                            <Box
                                sx={{
                                    height: "20rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Bounce />
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                borderRadius: "7px",
                                display: "flex",
                                flexDirection: "column",
                                gap: 0.5,
                                px: 3,
                                pt: 1,
                                mt: 1,
                                transition: "300ms ease-out",
                                flex: 1,
                                overflowY: "scroll",
                            }}
                        >
                            {pdfList?.length > 0 ? (
                                pdfList?.map((item: any) => (
                                    <>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                background: selectedFiles?.find(
                                                    (pdf) => pdf?.file_name === item?.file_name
                                                )
                                                    ? "#E3F4FF"
                                                    : "inherit",
                                                p: 0.7,
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                <Checkbox
                                                    checked={
                                                        selectedFiles?.findIndex(
                                                            (pdf) => pdf?.file_name === item?.file_name
                                                        ) >= 0
                                                            ? true
                                                            : false
                                                    }
                                                    size="small"
                                                    onClick={() => {
                                                        if (
                                                            selectedFiles?.find(
                                                                (pdf) => pdf?.file_name === item?.file_name
                                                            )
                                                        ) {
                                                            setSelectedFiles(
                                                                (prev: any[]) =>
                                                                    prev?.filter(
                                                                        (pdf) => pdf?.file_name !== item?.file_name
                                                                    )
                                                            );
                                                        } else {
                                                            setSelectedFiles((prev) => [...prev, item]);
                                                        }
                                                    }}
                                                />

                                                <Typography>{item?.file_name}</Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                {selectedFiles?.find((pdf) => pdf?.file_name === item?.file_name) && (
                                                    <IconButton
                                                        onClick={() =>
                                                            setSelectedFiles(
                                                                (prev: any[]) =>
                                                                    prev?.filter(
                                                                        (pdf) => pdf?.file_name !== item?.file_name
                                                                    )
                                                            )
                                                        }
                                                    >
                                                        <Close fontSize="small" />
                                                    </IconButton>
                                                )}
                                                <IconButton
                                                    onClick={() => {
                                                        try {
                                                            setContext({
                                                                ...contextStore,
                                                                claims: {
                                                                    ...contextStore?.claims,
                                                                    summary: {
                                                                        ...item,
                                                                    },
                                                                    state: {
                                                                        prevRoute: "/claims/new_project",
                                                                        listType: "Library",
                                                                    },
                                                                },
                                                            });
                                                            navigate("/claims/summary");
                                                        } catch (error) {
                                                            console.log(error);
                                                        }
                                                    }}
                                                >
                                                    <SummaryIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <Divider sx={{ widt: "100%" }} />
                                    </>
                                ))
                            ) : (
                                <Box
                                    sx={{
                                        display: "felx",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "30%",
                                    }}
                                >
                                    <Typography>Please Upload Documents</Typography>
                                </Box>
                            )}
                        </Box>
                    )}
                    <Divider />
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            px: 3,
                            py: 2,
                        }}
                    >
                        <Button onClick={() => setSelectedFiles(pdfList)}>Select All</Button>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Stack direction={"row"}>
                                <Button
                                    sx={{ fontFamily: "Gilroy-SemiBold", fontSize: "0.85rem", color: "#3E3E3E" }}
                                    onClick={() =>
                                        setListType((prev: string) => (prev === "Library" ? "Upload" : "Library"))
                                    }
                                >
                                    Go back
                                </Button>
                            </Stack>
                            <Stack>
                                <Button
                                    disabled={selectedFiles?.length === 0}
                                    sx={{
                                        color: "#fff",
                                        background: "#007BFF",
                                        "&:hover": {
                                            color: "#fff",
                                            background: "#007BFF",
                                        },
                                        "&:disabled": {
                                            color: "#c2c2c2",
                                            background: "#efefef",
                                        },
                                        px: "2.5rem",
                                    }}
                                    onClick={() => {
                                        if (selectedFiles?.length > 0) {
                                            setFilteredFiles((prev: any) => {
                                                const temp = new Set([...prev, ...selectedFiles]);
                                                return Array?.from(temp);
                                            });
                                            handleClose();
                                        }
                                    }}
                                >
                                    Add
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default LibraryProject;
