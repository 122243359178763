import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

const UploadProgress = ({ file, socketHooks, msgRef, setPdfList }: any) => {
    const [value, setValue] = useState<number>(0);
    const [color, setColor] = useState("");
    const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 4,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        },
        "& .MuiLinearProgress-bar": {
            transition: "ease-in 1s",
            borderRadius: 5,
            backgroundColor: color === "red" ? "red !important" : "#3193f4 !important",
        },
    }));

    useEffect(() => {
        socketHooks?.sendJsonMessage({ upload_status: { file_id: file?.file_id } });
    }, []);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (msgRef?.msg?.file_id && msgRef?.msg?.file_id === file?.file_id) {
            if (msgRef?.msg?.progress < 100) {
                setValue(msgRef?.msg?.progress);
                timeout = setTimeout(() => {
                    socketHooks?.sendJsonMessage({ upload_status: { file_id: file?.file_id } });
                    clearTimeout(timeout);
                }, 5000);
            } else if (msgRef?.msg?.status === "failed") {
                setValue(100);
                setColor("red");
            } else if (msgRef?.msg?.progress === 100) {
                setPdfList((prevState: any) => {
                    return prevState?.filter((pdf: any) => pdf?.file_id !== msgRef?.msg?.file_id);
                });
                setValue(msgRef?.msg?.progress);
            }
        }
    }, [msgRef?.msg]);

    return (
        <Box key={file?.file_id} sx={{ px: 1, py: 0.5, height: "3rem" }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ display: "flex", height: "100%", alignItems: "center" }}>
                    <CircularProgress
                        variant="determinate"
                        value={value}
                        size={20}
                        thickness={6}
                        sx={{ color: color === "red" ? "red" : "#007BFF" }}
                    />
                    <Box sx={{ ml: 2 }}>
                        <Typography>{file?.file_name}</Typography>
                        {value > 0 && value < 100 ? (
                            <Typography sx={{ fontSize: "12px", color: "#007BFF" }}>{`Uploading: ${value}`}</Typography>
                        ) : null}
                    </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography variant="caption" color={color}>
                        {color === "red" ? "Failed" : null}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default UploadProgress;
