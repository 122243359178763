import { Stack, Typography, Button, OutlinedInput } from "@mui/material";
import React, { useContext, useState } from "react";
import { Context } from "../../../context/setting";

const QuestionForm = ({ setStep }: { setStep: any }) => {
    const { contextStore, setContext } = useContext(Context);
    const [details, setDetails] = useState<any>({
        LIGHTS: {},
        ELECTRICAL: {},
        "DRIVER COCKPIT": {},
        "PASSENGER AREA": {},
        "ENGINE MECHANICAL": {},
    });

    console.log(details);
    return (
        <>
            <Stack sx={{ background: "#F3FAFF", px: 1 }} flex={1}>
                <Stack
                    direction={"row"}
                    px={0.5}
                    py={1}
                    sx={{ alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}
                >
                    <Typography>Van 28</Typography>
                    <Typography>Completed {contextStore?.damage_detection?.verify?.questions}/15</Typography>
                </Stack>
                <Typography>{contextStore?.profile?.userId}</Typography>
                <Stack sx={{ flex: "1 0 10vh", overflowY: "auto", gap: 1, pr: 1 }}>
                    {[
                        {
                            title: "LIGHTS",
                            data: [
                                { que: "Do both headlights illuminate?", option: ["Yes", "No"] },
                                { que: "Do all marker lights illuminate?", option: ["Yes", "No"] },
                                { que: "Do both brake lights illuminate?", option: ["Yes", "No"] },
                                { que: "Is the driver’s side mirror marker lamp operational?", option: ["Yes", "No"] },
                                {
                                    que: "Is the passenger’s side mirror marker lamp operational?",
                                    option: ["Yes", "No"],
                                },
                            ],
                        },
                        {
                            title: "ELECTRICAL",
                            data: [{ que: "Are there any electrical issue to disclose?", option: ["No"] }],
                        },
                        {
                            title: "DRIVER COCKPIT",
                            data: [
                                { que: "Does the vehicle start?", option: ["Yes", "No"] },
                                { que: "Is seatbelt free of damage?", option: ["Yes", "No"] },
                                { que: "Is parking break operational?", option: ["Yes", "No"] },
                                { que: "Is rear view camera operational?", option: ["Yes", "No"] },
                                { que: "Is the netradyne cameratelematics device secure?", option: ["Yes", "No"] },
                            ],
                        },
                        {
                            title: "PASSENGER AREA",
                            data: [
                                { que: "Is the seatbelt free of damage?", option: ["Yes", "No"] },
                                { que: "Does the seatbelt properly buckle? ", option: ["Yes", "No"] },
                            ],
                        },
                        {
                            title: "ENGINE MECHANICAL",
                            data: [
                                { que: "Does the vehicle drive?", option: ["Yes", "No"] },
                                {
                                    que: "Are there any known mechanical damage to the vehicle? ",
                                    option: ["Yes", "No"],
                                },
                                {
                                    que: "Provide details:",
                                    option: [],
                                    type: "input",
                                    placeholder: "Write your response here",
                                },
                            ],
                        },
                    ]?.map((item) => (
                        <Stack key={item?.title} sx={{ borderRadius: "6px", border: "1px solid #BEDFF1" }}>
                            <Typography
                                sx={{
                                    py: 0.7,
                                    px: 1,
                                    background:
                                        "linear-gradient(90deg,rgba(190, 223, 241, 1) 0%,rgba(190, 222, 241, 0) 100%)",
                                }}
                            >
                                {item?.title}
                            </Typography>
                            {item?.data?.map((subItem) => (
                                <Stack key={subItem?.que} sx={{ background: "#fff", p: 1, gap: 1 }}>
                                    <Typography sx={{ fontSize: "0.93rem", px: 1 }}>{subItem?.que}</Typography>
                                    <Stack direction={"row"} justifyContent={"space-around"}>
                                        {subItem?.option?.map((subItem1, ind: number) => (
                                            <Button
                                                key={subItem1 + ind}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    background:
                                                        subItem1 === details[item?.title][subItem?.que]
                                                            ? "#E3F4FF"
                                                            : "inherit",
                                                    border:
                                                        subItem1 === details[item?.title][subItem?.que]
                                                            ? "1px solid #007BFF"
                                                            : "1px solid #E3F4FF",
                                                }}
                                                onClick={() =>
                                                    setDetails((prev: any) => {
                                                        let result = { ...prev };
                                                        result[item?.title][subItem?.que] = subItem1;
                                                        return result;
                                                    })
                                                }
                                            >
                                                {subItem1}
                                            </Button>
                                        ))}
                                    </Stack>
                                    <Stack px={1}>{subItem?.type === "input" && <OutlinedInput multiline />}</Stack>
                                </Stack>
                            ))}
                        </Stack>
                    ))}
                </Stack>
            </Stack>
            <Stack px={0.5} py={1} sx={{ background: "#F3FAFF" }}>
                <Button
                    sx={{ borderRadius: "19px", fontSize: "1.1rem" }}
                    variant="contained"
                    onClick={() => {
                        setStep("2");
                        setContext({
                            ...contextStore,
                            damage_detection: { ...contextStore?.damage_detection, question: details },
                        });
                    }}
                >
                    Submit
                </Button>
            </Stack>
        </>
    );
};

export default QuestionForm;
