import React from "react";

const LoadingDots = () => {
    return (
        <svg height="20" width="30" style={{ paddingLeft: "5px" }}>
            <circle className="dot" cx="5" cy="16" r="2" fill={"grey"} />
            <circle className="dot" cx="13" cy="16" r="2" fill={"grey"} />
            <circle className="dot" cx="21" cy="16" r="2" fill={"grey"} />
        </svg>
    );
};

export default LoadingDots;
