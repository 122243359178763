import { Box, Button, IconButton, OutlinedInput, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import LessThanIcon from "../../components/Common/svgLogs/LessThanIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { Add, ArrowForward } from "@mui/icons-material";
import EnterUserName from "./EnterUserName";
import EnterSource from "./EnterSource";
import EnterSettings from "./EnterSettings";
import { Context } from "../../context/setting";

const NewChat = ({ backRoute }: { backRoute?: boolean }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    const theme = useTheme();
    const [formField, setFormField] = useState("name");
    const userDetails: any = useRef<any>();
    const userSources: any = useRef<any>();
    const userSettings: any = useRef<any>();
    const smd = useMediaQuery(theme?.breakpoints?.down("sm"));
    const location = useLocation();
    const FormControll = () => {
        if (formField === "name") {
            return <EnterUserName userDetails={userDetails} />;
        } else if (formField === "source") {
            return <EnterSource userSources={userSources} />;
        } else if (formField === "setting") {
            return <EnterSettings userSettings={userSettings} />;
        } else {
            return <></>;
        }
    };

    const handleNext = () => {
        if (formField === "name") {
            setContext({
                ...contextStore,
                temparary: {
                    ...contextStore?.temparary,
                    ...userDetails,
                },
            });
            setFormField("source");
        }
        if (formField === "source") {
            setContext({
                ...contextStore,
                temparary: {
                    ...contextStore?.temparary,
                    ...userSources,
                },
            });
            setFormField("setting");
        }
        if (formField === "setting") {
            const name = contextStore?.temparary?.userName;
            const source = contextStore?.temparary?.source;
            if (name && source) {
                setContext({
                    ...contextStore,
                    chats: {
                        ...contextStore?.chats,
                        [name]: {
                            ...contextStore?.temparary,
                            ...userSettings,
                            created: new Date(),
                            lase_used: new Date(),
                            status: "active",
                        },
                    },
                    temparary: {},
                    selected: name,
                });
                navigate("/chat");
            } else {
                setContext({
                    ...contextStore,
                    temparary: { ...contextStore.temparary, ...userSettings },
                    snackState: {
                        open: true,
                        errorType: "warning",
                        message: "Please fill the input fields or select pdf atleast",
                    },
                });
            }
        }
    };

    useEffect(() => {
        setContext({
            ...contextStore,
            temparary: { ...contextStore.temparary, ...userDetails, ...userSources, ...userSettings },
        });
    }, [formField]);

    const styles = () => {
        if (smd) {
            return {
                head: {
                    flexDirection: "column",
                    gap: 1.5,
                    justifyContent: "flex-start",
                },
                head1: {
                    pl: 5,
                    alignItems: "center",
                },
                head12: {
                    gap: 1,
                },
                footerButton: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    pt: 1.7,
                },
                addSource: {
                    right: "10px",
                    bottom: "-2.5rem",
                },
                settingTab: {
                    width: formField === "setting" ? "100%" : "auto",
                },
            };
        }
        return {};
    };

    return (
        <Box
            sx={{
                p: 2.3,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: 3,
                ...styles()?.head,
            }}
        >
            <Box sx={{ display: "flex", gap: 2, ...styles()?.head1 }}>
                {backRoute && (
                    <IconButton onClick={() => navigate(location.search?.replaceAll("?", "") || "/chat_history/main")}>
                        <LessThanIcon />
                    </IconButton>
                )}
                <Typography variant="h6" fontWeight={600}>
                    New Chat
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "26rem",
                    justifyContent: "space-between",
                    width: formField === "setting" ? "60%" : "auto",
                    ...styles()?.settingTab,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        position: "relative",
                        justifyContent: formField === "setting" ? "center" : "inherit",
                        ...styles()?.head12,
                    }}
                >
                    {formField === "source" && (
                        <Button
                            sx={{
                                color: "#007BFF",
                                position: "absolute",
                                right: "-10.5rem",
                                textTransform: "none",
                                ...styles()?.addSource,
                            }}
                            startIcon={<Add />}
                            onClick={() => {
                                setContext({ ...contextStore, previousRoute: "/new_chat" });
                                navigate("/data_source/add_source");
                            }}
                        >
                            Add New Source
                        </Button>
                    )}
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <Button
                            sx={{
                                textTransform: "none",
                                borderRadius: "7px",
                                background: formField === "name" ? "#E3F4FF" : "inherit",
                                color: "#6B6B6B",
                                px: 4,
                                py: 0.8,
                            }}
                            onClick={() => setFormField("name")}
                        >
                            1. Chat Name
                        </Button>
                        <Button
                            sx={{
                                textTransform: "none",
                                borderRadius: "7px",
                                background: formField === "source" ? "#E3F4FF" : "inherit",
                                color: "#6B6B6B",
                                px: 4,
                                py: 0.8,
                            }}
                            onClick={() => setFormField("source")}
                        >
                            2. Data Source
                        </Button>
                        <Button
                            sx={{
                                textTransform: "none",
                                borderRadius: "7px",
                                background: formField === "setting" ? "#E3F4FF" : "inherit",
                                color: "#6B6B6B",
                                px: 4,
                                py: 0.8,
                            }}
                            onClick={() => setFormField("setting")}
                        >
                            3. Settings
                        </Button>
                    </Box>
                </Box>
                <FormControll />
            </Box>
            <Box sx={{ ...styles()?.footerButton }}>
                <Button
                    endIcon={formField !== "setting" && <ArrowForward />}
                    sx={{
                        textTransform: "none",
                        borderRadius: "7px",
                        background: "#007BFF",
                        color: "#fff",
                        px: 4,
                        py: 0.8,
                        minWidth: "9rem",
                        "&:hover": { background: "#007BFFaf" },
                    }}
                    onClick={handleNext}
                >
                    {formField === "setting" ? "Create Chat" : "Next"}
                </Button>
            </Box>
        </Box>
    );
};

export default NewChat;
