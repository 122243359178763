import React from "react";

const LessThanIcon = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 13.5L3.5 7.5L10 1.5"
                stroke="#25282B"
                strokeWidth="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default LessThanIcon;
