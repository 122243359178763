import {
    Box,
    Typography,
    FormControl,
    Select,
    MenuItem,
    IconButton,
    Button,
    Chip,
    CircularProgress,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import ReportSocket from "../../Chat/utils/ReportGenerationSocket";
import { Context } from "../../../context/setting";
import { Api } from "../../../apis";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import Badge from "./Badge";

const SeeReport = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [selectedId, setSelectedId] = useState<any>(62);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(null);
    const [ids, setids] = useState<any[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const [msg, socketHooks, msgRef] = ReportSocket();
    console.log({ contextStore });

    useEffect(() => {
        Api?.getExcelReportsIDs()?.then((res: any) => {
            if (res?.status === 200) {
                setids(res?.data);
            }
        });
    }, []);

    useEffect(() => {
        setFiles(contextStore?.lmdFiles);
    }, [contextStore?.lmdFiles]);

    const generateReport = () => {
        if (selectedId) {
            setLoading(true);
            setContext({
                ...contextStore,
                snackState: {
                    open: true,
                    errorType: "success",
                    message: "Report received, generating report in no time",
                },
            });
            // socketHooks?.sendJsonMessage({ id: `${selectedId}` });
            socketHooks?.sendJsonMessage({ report: `${selectedId}` });
            const id = setInterval(() => {
                // socketHooks?.sendJsonMessage("pong");
                if (msgRef?.graphData) {
                    setData(msgRef?.graphData);
                    setLoading(false);
                }
            }, 7000);
            if (msgRef?.graphData) clearInterval(id);
        } else {
            setContext({
                ...contextStore,
                snackState: {
                    open: true,
                    errorType: "warning",
                    message: "Please select an ID",
                },
            });
        }
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                p: "3vh 2vw 0px 2vw",
                zIndex: "1",
                display: "flex",
                flexDirection: "column",
            }}
        >
            {loading ? (
                <Box sx={{ position: "absolute", top: "35%", left: "35%" }}>
                    <Loading />
                </Box>
            ) : null}
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", mb: "4vh" }}>
                <Box sx={{ display: "flex" }}>
                    <IconButton onClick={() => navigate("/report_generation")}>
                        <ArrowBackIos />
                    </IconButton>
                    <Typography variant="h4" fontWeight={500}>
                        {files?.[0] || ""}
                    </Typography>
                </Box>
                <Box sx={{ gap: 3, display: "flex", alignItems: "center" }}>
                    <Typography sx={{ color: "#424F65" }}>Select Company ID</Typography>
                    <FormControl sx={{ minWidth: 110 }}>
                        <Select
                            value={selectedId}
                            onChange={(e: any) => {
                                setSelectedId(e?.target?.value);
                            }}
                            MenuProps={{
                                style: {
                                    maxHeight: 400,
                                },
                            }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#ECECEC",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#ECECEC",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#ECECEC",
                                },
                            }}
                        >
                            <MenuItem value={undefined}>Select id</MenuItem>
                            {ids?.map((id: any) => {
                                return (
                                    <MenuItem value={id} key={id}>
                                        {id}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Button
                        sx={{
                            textTransform: "none",
                            background: "#007BFF",
                            color: "#fff",
                            px: 2,
                            borderRadius: "8px",
                            "&:hover": { background: "#007BFF" },
                        }}
                        onClick={generateReport}
                        // disabled={selectedId === undefined}
                    >
                        Generate Report
                    </Button>
                </Box>
            </Box>
            {data ? (
                <Box>
                    <Box sx={{ mb: "20px" }}>
                        <Typography sx={{ color: "#424F65", mb: "10px" }}>
                            <em>Fico_score</em>
                        </Typography>
                        <Typography sx={{ mb: "10px" }}>{data?.fico_score?.response}</Typography>
                        <Box sx={{ display: "flex", gap: "15px" }}>
                            <Badge score={data?.fico_score?.result[0]?.fantastic} index={0} text="Fantastic" />
                            <Badge score={data?.fico_score?.result[0]?.fair} index={1} text="Fair" />
                            <Badge score={data?.fico_score?.result[0]?.good} index={2} text="Good" />
                            <Badge score={data?.fico_score?.result[0]?.poor} index={3} text="Poor" />
                        </Box>
                    </Box>
                    <Box sx={{ mb: "20px" }}>
                        <Typography sx={{ color: "#424F65", mb: "10px" }}>
                            <em>Seatbelt off rate</em>
                        </Typography>
                        <Typography sx={{ mb: "10px" }}>{data?.seatbelt_off_rate?.response}</Typography>
                        <Box sx={{ display: "flex", gap: "15px" }}>
                            <Badge score={data?.seatbelt_off_rate?.result[0]?.fantastic} index={0} text="Fantastic" />
                            <Badge score={data?.seatbelt_off_rate?.result[0]?.fair} index={1} text="Fair" />
                            <Badge score={data?.seatbelt_off_rate?.result[0]?.good} index={2} text="Good" />
                            <Badge score={data?.seatbelt_off_rate?.result[0]?.poor} index={3} text="Poor" />
                        </Box>
                    </Box>
                    <Box sx={{ mb: "20px" }}>
                        <Typography sx={{ color: "#424F65", mb: "10px" }}>
                            <em>Speeding event rate</em>
                        </Typography>
                        <Typography sx={{ mb: "10px" }}>{data?.speeding_event_rate?.response}</Typography>
                        <Box sx={{ display: "flex", gap: "15px" }}>
                            <Badge score={data?.speeding_event_rate?.result[0]?.fantastic} index={0} text="Fantastic" />
                            <Badge score={data?.speeding_event_rate?.result[0]?.fair} index={1} text="Fair" />
                            <Badge score={data?.speeding_event_rate?.result[0]?.good} index={2} text="Good" />
                            <Badge score={data?.speeding_event_rate?.result[0]?.poor} index={3} text="Poor" />
                        </Box>
                    </Box>
                    <Box sx={{ mb: "20px" }}>
                        <Typography sx={{ color: "#424F65", mb: "10px" }}>
                            <em>Distractions rate</em>
                        </Typography>
                        <Typography sx={{ mb: "10px" }}>{data?.distractions_rate?.response}</Typography>
                        {/* <Box sx={{ display: "flex", gap: "15px", width: "100%" }}>
                         <Typography>Distraction rate:</Typography>
                         {Object.values(
                             Object.keys(data?.distractions_rate?.result).map((key) => [
                                 key,
                                 (data?.distractions_rate?.result)[key],
                             ])[0][1]
                         ).map((value: any, index: number) => {
                             return <Badge score={value} index={0} text={index} />;
                         })}
                     </Box> */}
                        <Box sx={{ display: "flex", gap: "15px" }}>
                            <Badge
                                score={data?.sign_signal_violations_rate?.result[0]?.fantastic}
                                index={0}
                                text="Fantastic"
                            />
                            <Badge score={data?.distractions_rate?.result[0]?.fair} index={1} text="Fair" />
                            <Badge score={data?.distractions_rate?.result[0]?.good} index={2} text="Good" />
                            <Badge score={data?.distractions_rate?.result[0]?.poor} index={3} text="Poor" />
                        </Box>
                        {/* <Box sx={{ display: "flex", gap: "15px", width: "100%" }}>
                         <Typography>Distraction count:</Typography>
                         {Object.values(
                             Object.keys(data?.distractions_rate?.result).map((key) => [
                                 key,
                                 (data?.distractions_rate?.result)[key],
                             ])[1][1]
                         ).map((value: any, index: number) => {
                             return <Badge score={value} index={0} text={index} />;
                         })}
                     </Box> */}
                    </Box>
                    <Box sx={{ mb: "20px" }}>
                        <Typography sx={{ color: "#424F65", mb: "10px" }}>
                            <em>Sign signal Violations</em>
                        </Typography>
                        <Typography sx={{ mb: "10px" }}>{data?.sign_signal_violations_rate?.response}</Typography>
                        <Box sx={{ display: "flex", gap: "15px" }}>
                            <Badge
                                score={data?.sign_signal_violations_rate?.result[0]?.fantastic}
                                index={0}
                                text="Fantastic"
                            />
                            <Badge score={data?.sign_signal_violations_rate?.result[0]?.fair} index={1} text="Fair" />
                            <Badge score={data?.sign_signal_violations_rate?.result[0]?.good} index={2} text="Good" />
                            <Badge score={data?.sign_signal_violations_rate?.result[0]?.poor} index={3} text="Poor" />
                        </Box>
                    </Box>
                    <Box sx={{ mb: "20px" }}>
                        <Typography sx={{ color: "#424F65", mb: "10px" }}>
                            <em>CDF</em>
                        </Typography>
                        <Typography sx={{ mb: "10px" }}>{data?.cdf?.response}</Typography>
                        <Box sx={{ display: "flex", gap: "15px" }}>
                            <Badge score={data?.cdf?.result[0]?.fantastic} index={0} text="Fantastic" />
                            <Badge score={data?.cdf?.result[0]?.fair} index={1} text="Fair" />
                            <Badge score={data?.cdf?.result[0]?.good} index={2} text="Good" />
                            <Badge score={data?.cdf?.result[0]?.poor} index={3} text="Poor" />
                        </Box>
                    </Box>
                    <Box sx={{ mb: "20px" }}>
                        <Typography sx={{ color: "#424F65", mb: "10px" }}>
                            <em>CED</em>
                        </Typography>
                        <Typography sx={{ mb: "10px" }}>{data?.ced?.response}</Typography>
                        <Box sx={{ display: "flex", gap: "15px" }}>
                            <Badge score={data?.ced?.result[0]?.fantastic} index={0} text="Fantastic" />
                            <Badge score={data?.ced?.result[0]?.fair} index={1} text="Fair" />
                            <Badge score={data?.ced?.result[0]?.good} index={2} text="Good" />
                            <Badge score={data?.ced?.result[0]?.poor} index={3} text="Poor" />
                        </Box>
                    </Box>
                    <Box sx={{ mb: "20px" }}>
                        <Typography sx={{ color: "#424F65", mb: "10px" }}>
                            <em>DAR</em>
                        </Typography>
                        <Typography sx={{ mb: "10px" }}>{data?.dar?.response}</Typography>
                        <Box sx={{ display: "flex", gap: "15px" }}>
                            <Badge score={data?.dar?.result[0]?.fantastic} index={0} text="Fantastic" />
                            <Badge score={data?.dar?.result[0]?.fair} index={1} text="Fair" />
                            <Badge score={data?.dar?.result[0]?.good} index={2} text="Good" />
                            <Badge score={data?.dar?.result[0]?.poor} index={3} text="Poor" />
                        </Box>
                    </Box>
                    <Box sx={{ mb: "20px" }}>
                        <Typography sx={{ color: "#424F65", mb: "10px" }}>
                            <em>SWC_AD</em>
                        </Typography>
                        <Typography sx={{ mb: "10px" }}>{data?.swc_ad?.response}</Typography>
                        <Box sx={{ display: "flex", gap: "15px" }}>
                            <Badge score={data?.swc_ad?.result[0]?.fantastic} index={0} text="Fantastic" />
                            <Badge score={data?.swc_ad?.result[0]?.fair} index={1} text="Fair" />
                            <Badge score={data?.swc_ad?.result[0]?.good} index={2} text="Good" />
                            <Badge score={data?.swc_ad?.result[0]?.poor} index={3} text="Poor" />
                        </Box>
                    </Box>
                    <Box sx={{ mb: "20px" }}>
                        <Typography sx={{ color: "#424F65", mb: "10px" }}>
                            <em>DCR</em>
                        </Typography>
                        <Typography sx={{ mb: "10px" }}>{data?.dcr?.response}</Typography>
                        <Box sx={{ display: "flex", gap: "15px" }}>
                            <Badge score={data?.dcr?.result[0]?.fantastic} index={0} text="Fantastic" />
                            <Badge score={data?.dcr?.result[0]?.fair} index={1} text="Fair" />
                            <Badge score={data?.dcr?.result[0]?.good} index={2} text="Good" />
                            <Badge score={data?.dcr?.result[0]?.poor} index={3} text="Poor" />
                        </Box>
                    </Box>
                    <Box sx={{ mb: "20px" }}>
                        <Typography sx={{ color: "#424F65", mb: "10px" }}>
                            <em>SWC_POD</em>
                        </Typography>
                        <Typography sx={{ mb: "10px" }}>{data?.swc_pod?.response}</Typography>
                        <Box sx={{ display: "flex", gap: "15px" }}>
                            <Badge score={data?.swc_pod?.result[0]?.fantastic} index={0} text="Fantastic" />
                            <Badge score={data?.swc_pod?.result[0]?.fair} index={1} text="Fair" />
                            <Badge score={data?.swc_pod?.result[0]?.good} index={2} text="Good" />
                            <Badge score={data?.swc_pod?.result[0]?.poor} index={3} text="Poor" />
                        </Box>
                    </Box>
                    <Box sx={{ mb: "20px" }}>
                        <Typography sx={{ color: "#424F65", mb: "10px" }}>
                            <em>SWC_CC</em>
                        </Typography>
                        <Typography sx={{ mb: "10px" }}>{data?.swc_cc?.response}</Typography>
                        <Box sx={{ display: "flex", gap: "15px" }}>
                            <Badge score={data?.swc_cc?.result[0]?.fantastic} index={0} text="Fantastic" />
                            <Badge score={data?.swc_cc?.result[0]?.fair} index={1} text="Fair" />
                            <Badge score={data?.swc_cc?.result[0]?.good} index={2} text="Good" />
                            <Badge score={data?.swc_cc?.result[0]?.poor} index={3} text="Poor" />
                        </Box>
                    </Box>
                </Box>
            ) : null}
        </Box>
    );
};

export default SeeReport;
