const ScenarioAnalysisShortIcon = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <rect width="25" height="24" fill="url(#pattern0_9205_930)" />
            <defs>
                <pattern id="pattern0_9205_930" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0_9205_930" transform="matrix(0.005 0 0 0.00520833 0 -0.0208333)" />
                </pattern>
                <image
                    id="image0_9205_930"
                    width="200"
                    height="200"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAeFBMVEX///8AAAAXFxeTk5Pn5+cvLy/Pz89ra2v7+/sEBATu7u5FRUUKCgoUFBT29vaioqIbGxvS0tKpqalkZGRYWFi/v79zc3OCgoI9PT2wsLAsLCwkJCTb29s0NDRnZ2dKSkp7e3u8vLzGxsZUVFSamppJSUmOjo5ycnKXPR4gAAAExUlEQVR4nO2ca3fiIBCGTYyau+ZiY7w1Xmr//z/cNkNsEti1QTbIOe/zbdlTmBGYgZkhkwkAAAAAAAAAAAAAAAAAAAAAAAAA/g9+WWR5vpieXd2SPEUZWA3RYq9bGmnWW6uNl/m6JZLCfbP62GvdQkkQv3N6fE3KUrdYg3FFenyR6hZsKPd1ZWfTdFkEHvtnmOiWbBhrJvdqycyus2AtM7PsMLNXQctOnSJqm+qTajgl06Pz689DmiSTpoT84KrnN5akXqlHJhl8kpiztZu6Odchkhy0smxuDaU0Uc4IxEoUKWqBM649bozwCHyoOA9ldVcC87R9MLpK3hUoktc9CZx48GBwpShYXaSI4Fy1GVEPT4GZJy9e8P+xGlGRxfN6TKZ1TwHXvqch7BHYVSr87pnm1um3kzWbKRhhJNxIOLk+NVcaJJKFHXVP3VayAZZJB/k9eb5o3m6srL9snVeGXKIV/phgn82HNf/Hn70efmNoN2ntl/ZFpNAqjsn6fqzytsHmx38c1JzmRmQp9FI7ziS/PmnI63EwUI/JJOHOup/GrSvCnXbOVoFZ9qqDW+Y27fhZZZIfFOKcT+vE0DUFAAAAgOeZTz+z/FiUZmazG/af9v1K9m5QKqWHf+wG7A9n3RLJceZjqkoihmOTivInF/NOzqU4D3TRLddQkvslP6iWy1veBJCsSrdkw3BnTO6MRSjca2O/zNrxlIuwwlbA2GcZroM+qYbjksEKO7++yzQxyZ+wYolevs6n1aUisTkWuXgRXcnHazut+POh0MriMsEsZ3Qd2p2iAso3ycoAj3d+mVxP1kGFKuIA9i/Y8n3dZPvaKFAkfzzMrwdPZftSUTCweDzKrxWRnt1QwWkzEWQUfsWK76uQVaR6Xo+vO95lNhQand+hrIZtaHcbbRWHpAlX9uGQ/TMoI0HJ66jv+Wi72VpEkiOhtdWrEzxR66cemeQIBHt0Tn7dM+qhw5lZm/xndS2Z9TtqFEuCxvvYRX2zisumTK1fdvvqxM0V0bJ2wcfs7os84x5rODuhZzPvqcbEmfFqhMY91PgmPvb12BrkCjucO4Wo9s3EOCNj/sZKP6JLarAaNfH8dDobWX8DAAAA6MRPF5vdanfIrka70SRvhZqD0+M/eE38/on5w6gb/p2Ev42FJiW4Gs7C6OxVt1iD+VuU2bRrZdysqzBLE2df3gtyTHv/30SQjk3AKL4xA2ZURrt5AeW1F9LcNnBxsfxiN17E3v8LsngvS0wi919psxIKg+o+qMYg5E4lFLyoVAzhltMRoE+v8B8soESwiqxuPOYraT6iSqmWSIEi0rlxGfi94JIxUxAUk86zyyA4IlJ6SEEyohpTEUFwmGZEQcGAL84P/B94z0fP5sPn9fjS5LYYgUMtMP+xDyqNMuiQQmUqIbcXSME3HSLJ4XtCiVkZjkkJu0stsde9pTtks3aaZJKCZbTD9o+/Z4bGqK9fNQ7LK+7OL2Vlz1uzckROU629KubxxN1Pm2yqZ9DZt2bdimi1r+833YINRvjAwap0iyXBKeLU8Mza6A37/ieitqbtjztl+/qzu5plr7okxcd3JXe0qYydjRbmPWECAAAAAAAAAAAAAAAAAAAAAAAAAAAAgH/yB/SaOlVjuNStAAAAAElFTkSuQmCC"
                />
            </defs>
        </svg>
    );
};

export default ScenarioAnalysisShortIcon;
