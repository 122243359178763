import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../../context/setting";
import { Box, Button, Divider, IconButton, Input, Stack, Switch, Tooltip, Typography } from "@mui/material";
import TitleEditIcon from "../../../components/Common/svgLogs/TitleEditIcon";
import SortSection from "./SortSection";
import moment from "moment";
import SummaryIcon from "../../../components/Common/svgLogs/SummaryIcon";
import { useNavigate } from "react-router-dom";
import ClaimReportIcon from "../../../components/Common/svgLogs/ClaimReportIcon";
import { Api } from "../../../apis";
import Bounce from "../../../components/Loaders/Bounce";
import ArraowDownIcon from "../../../components/Common/svgLogs/ArraowDownIcon";
import { ArrowBackIos } from "@mui/icons-material";
import ClaimsTableIcon from "../../../components/Common/svgLogs/ClaimsTableIcon";

const ViewProject = () => {
    const navigate = useNavigate();
    const inputRef = useRef<any>();
    const { contextStore, setContext } = useContext<any>(Context);
    const [activeProject, setActiveProject] = useState<any>({});
    const [claimCheck, setClaimCheck] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [filter, setFilter] = useState<boolean>(false);
    const [filterDocs, setFilterDocs] = useState<any[]>([]);

    useEffect(() => {
        if (Object?.keys(contextStore?.claims?.activeProject)?.length > 0) {
            setLoader(true);
            Api?.getClaimsExistingProjectsPdf({ project_id: contextStore?.claims?.activeProject?.project_id })
                ?.then((res: any) => {
                    if (res?.status === 200) {
                        setActiveProject({ ...contextStore?.claims?.activeProject, pdfs: res?.data?.pdfs });
                        setContext({
                            ...contextStore,
                            claims: {
                                ...contextStore?.claims,
                                activeProject: { ...contextStore?.claims?.activeProject, pdfs: res?.data?.pdfs },
                            },
                        });
                    }
                    setLoader(false);
                })
                .catch((err) => setLoader(false));
        } else {
            navigate("/claims");
        }
    }, []);

    const handleFilter = () => {
        if (activeProject?.pdfs?.length > 0) {
            setLoader(true);
            const filterpdfs = activeProject?.pdfs?.reduce(
                (filter: any, item: any) => {
                    if (item?.claim_status === "Claim") {
                        filter?.claim_pdfs?.push({
                            pdf_id: item?.pdf_id,
                            Date: item?.publish_date,
                            pdf_name: item?.pdf_name,
                        });
                    } else {
                        filter?.check_pdfs?.push({
                            pdf_id: item?.pdf_id,
                            Date: item?.publish_date,
                            pdf_name: item?.pdf_name,
                        });
                    }
                    return filter;
                },
                { claim_pdfs: [], check_pdfs: [] }
            );
            Api?.getClaimsExistingProjectsFilter(filterpdfs)
                ?.then((res: any) => {
                    if (res?.status === 200) {
                        setFilterDocs(res?.data);
                    }
                    setLoader(false);
                })
                ?.catch((err) => {
                    setFilter(false);
                    setFilterDocs([]);
                    setLoader(false);
                });
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
                width: "100%",
                background: "#FEFDFD",
            }}
        >
            <Box
                sx={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "0.7rem",
                    display: "flex",
                    flexDirection: "column",
                    width: "50rem",
                    height: "fit-content",
                    transition: "300ms ease-out",
                    fontFamily: "Gilroy-SemiBold !important",
                    background: "#fff",
                }}
            >
                <Box sx={{ px: 3, py: 1.5 }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography
                            sx={{
                                color: "#007BFF",
                                fontSize: "1.07rem",
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                fontFamily: "Gilroy-SemiBold !important",
                            }}
                        >
                            {activeProject?.name}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <IconButton
                                onClick={() => {
                                    setContext({
                                        ...contextStore,
                                        claims: {
                                            ...contextStore?.claims,
                                            table: {},
                                            state: {
                                                prevRoute: "/claims/reports",
                                            },
                                        },
                                    });
                                    navigate("/claims/tables");
                                }}
                            >
                                <ClaimsTableIcon />
                            </IconButton>
                            {activeProject?.pdfs_count > 0 && (
                                <SortSection setPdfList={filter ? setFilterDocs : setActiveProject} />
                            )}
                        </Box>
                    </Box>
                    <Typography sx={{ color: "#9D9B9B", fontSize: "0.9rem" }}>
                        {activeProject?.pdfs_count} Files
                    </Typography>
                </Box>
                {filter ? (
                    <Box sx={{ display: "flex", flexDirection: "column", height: "74vh" }}>
                        {loader ? (
                            <Box
                                sx={{
                                    height: "20rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Bounce />
                            </Box>
                        ) : (
                            <>
                                <Box sx={{ px: 3, pb: 1, flex: 1, overflowY: "scroll" }}>
                                    {filterDocs?.map((doc) => (
                                        <Box
                                            key={doc?.pdf?.pdf_name}
                                            sx={{
                                                p: 1,
                                                pt: 0,
                                                border: "1px solid #F3F3F4",
                                                borderRadius: "13px",
                                                mt: 1,
                                            }}
                                        >
                                            <Box sx={{ p: 1 }}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: 2,
                                                            px: 2,
                                                            py: 1,
                                                        }}
                                                    >
                                                        <Box>
                                                            <Tooltip title={doc?.pdf?.pdf_name}>
                                                                <Typography
                                                                    noWrap
                                                                    sx={{
                                                                        width: "11rem",
                                                                        fontSize: "0.9rem",
                                                                        color: "#007BFF",
                                                                    }}
                                                                >
                                                                    {doc?.pdf?.pdf_name}
                                                                </Typography>
                                                            </Tooltip>
                                                            <Typography sx={{ fontSize: "0.75rem", color: "#9D9B9B" }}>
                                                                Matching Documents
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                textAlign: "center",
                                                                background: "rgba(135, 206, 172, 0.32)",
                                                                border: "1px solid #12924F",
                                                                borderRadius: "6px",
                                                                px: 0.7,
                                                            }}
                                                        >
                                                            <Typography sx={{ fontSize: "0.86rem" }}>
                                                                Claim Document
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                                        <Button
                                                            variant="outlined"
                                                            sx={{ color: "#007BFF", borderColor: "#007BFF" }}
                                                            size="small"
                                                            onClick={() => {
                                                                try {
                                                                    setContext({
                                                                        ...contextStore,
                                                                        claims: {
                                                                            ...contextStore?.claims,
                                                                            comparison: {
                                                                                ...doc,
                                                                            },
                                                                            state: {
                                                                                prevRoute: "/claims/reports",
                                                                            },
                                                                        },
                                                                    });
                                                                    navigate("/claims/comparison");
                                                                } catch (error) {
                                                                    console.log(error);
                                                                }
                                                            }}
                                                        >
                                                            Compare
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            sx={{ color: "#007BFF", borderColor: "#007BFF" }}
                                                            size="small"
                                                            onClick={() => {
                                                                try {
                                                                    setContext({
                                                                        ...contextStore,
                                                                        claims: {
                                                                            ...contextStore?.claims,
                                                                            chat: {
                                                                                ...doc,
                                                                            },
                                                                            state: {
                                                                                prevRoute: "/claims/reports",
                                                                            },
                                                                        },
                                                                    });
                                                                    navigate("/claims/chat");
                                                                } catch (error) {
                                                                    console.log(error);
                                                                }
                                                            }}
                                                        >
                                                            Chat
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                <Divider sx={{ borderColor: "#F3F3F4" }} />
                                            </Box>
                                            {doc?.top_match_docs?.map((checkDoc: any) => (
                                                <>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                gap: 2,
                                                                px: 2,
                                                                py: 1,
                                                            }}
                                                        >
                                                            <Tooltip title={checkDoc?.pdf_name}>
                                                                <Typography
                                                                    noWrap
                                                                    sx={{ width: "11rem", fontSize: "0.9rem" }}
                                                                >
                                                                    {checkDoc?.pdf_name}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Box>
                                                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                                            <Typography sx={{ fontSize: "0.75rem", color: "#9D9B9B" }}>
                                                                {checkDoc?.Date}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Divider sx={{ borderColor: "#F3F3F4" }} />
                                                </>
                                            ))}
                                        </Box>
                                    ))}
                                </Box>
                                <Divider />
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        p: 1.5,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 2,
                                            p: 0.7,
                                            transition: "200ms ease-in",
                                        }}
                                    >
                                        <Stack direction={"row"}>
                                            <Button
                                                sx={{
                                                    fontFamily: "Gilroy-SemiBold",
                                                    fontSize: "0.85rem",
                                                    color: "#3E3E3E",
                                                }}
                                                onClick={() => {
                                                    setFilter(false);
                                                    setFilterDocs([]);
                                                }}
                                            >
                                                Go back
                                            </Button>
                                        </Stack>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                ) : (
                    <Box sx={{ display: "flex", flexDirection: "column", height: "74vh" }}>
                        {loader ? (
                            <Box sx={{ flex: 1 }}>
                                <Box
                                    sx={{
                                        height: "20rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Bounce />
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{ flex: 1, overflowY: "scroll", px: 3 }}>
                                {activeProject?.pdfs?.map((item: any) => (
                                    <>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                p: 1.5,
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 7 }}>
                                                <Tooltip title={item?.pdf_name}>
                                                    <Typography noWrap sx={{ width: "11rem" }}>
                                                        {item?.pdf_name}
                                                    </Typography>
                                                </Tooltip>
                                                {claimCheck && item?.claim_status === "Claim" && (
                                                    <Box
                                                        sx={{
                                                            textAlign: "center",
                                                            background: "rgba(135, 206, 172, 0.32)",
                                                            border: "1px solid #12924F",
                                                            borderRadius: "6px",
                                                            px: 0.7,
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: "0.86rem" }}>
                                                            Claim Document
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                <Typography sx={{ fontSize: "0.8rem", color: "#9D9B9B" }}>
                                                    Date {item?.publish_date}
                                                </Typography>
                                                <IconButton
                                                    onClick={() => {
                                                        try {
                                                            setContext({
                                                                ...contextStore,
                                                                claims: {
                                                                    ...contextStore?.claims,
                                                                    reference: {
                                                                        ...item,
                                                                    },
                                                                    state: {
                                                                        prevRoute: "/claims/reports",
                                                                        filteredFiles: activeProject?.pdfs,
                                                                    },
                                                                },
                                                            });
                                                            navigate("/claims/reference");
                                                        } catch (error) {
                                                            console.log(error);
                                                        }
                                                    }}
                                                >
                                                    <ClaimReportIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        try {
                                                            setContext({
                                                                ...contextStore,
                                                                claims: {
                                                                    ...contextStore?.claims,
                                                                    summary: {
                                                                        ...item,
                                                                    },
                                                                    state: {
                                                                        prevRoute: "/claims/reports",
                                                                        filteredFiles: activeProject?.pdfs,
                                                                    },
                                                                },
                                                            });
                                                            navigate("/claims/summary");
                                                        } catch (error) {
                                                            console.log(error);
                                                        }
                                                    }}
                                                >
                                                    <SummaryIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <Divider sx={{ widt: "100%" }} />
                                    </>
                                ))}
                            </Box>
                        )}
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                p: 1.5,
                            }}
                        >
                            <Stack direction="row" sx={{ alignItems: "center", gap: 1 }}>
                                <Switch
                                    size="small"
                                    checked={claimCheck}
                                    onChange={(e: any, checked: boolean) => setClaimCheck(checked)}
                                />
                                <Box>
                                    <Typography
                                        sx={{ color: "#007BFF", fontFamily: "Gilroy-SemiBold", fontSize: "0.85rem" }}
                                    >
                                        Highlight Claim Documents
                                    </Typography>
                                    <Typography sx={{ fontSize: "0.7rem" }}>Auto- selected</Typography>
                                </Box>
                            </Stack>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2,
                                    p: 0.7,
                                    transition: "200ms ease-in",
                                }}
                            >
                                <Stack direction={"row"}>
                                    <Button
                                        sx={{ fontFamily: "Gilroy-SemiBold", fontSize: "0.85rem", color: "#3E3E3E" }}
                                        onClick={() => {
                                            navigate("/claims");
                                        }}
                                    >
                                        Go back
                                    </Button>
                                </Stack>
                                {claimCheck && (
                                    <Stack direction={"row"}>
                                        <Button
                                            sx={{
                                                fontFamily: "Gilroy-SemiBold",
                                                fontSize: "0.85rem",
                                                color: "#fff",
                                                background: "#007BFF",
                                                "&:hover": {
                                                    color: "#fff",
                                                    background: "#007BFF",
                                                },
                                                px: 4,
                                            }}
                                            onClick={() => {
                                                setFilter(true);
                                                handleFilter();
                                            }}
                                        >
                                            Auto filter
                                        </Button>
                                    </Stack>
                                )}
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default ViewProject;
