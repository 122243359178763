import { Box, Divider, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Context } from "../../context/setting";
import ComparePdfModal from "../Chat/ChatBox/PDFSection/ComparePDFModal";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";

const ViewMultiplePDF = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    return (
        <Box sx={{ width: "100%", display: "flex" }}>
            <Typography
                sx={{
                    display: "flex",
                    height: "5%",
                    marginLeft: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 0.5,
                    fontSize: "0.9rem",
                    WebkitTextStrokeWidth: 0.2,
                }}
            >
                <Typography
                    sx={{
                        display: "flex",
                        gap: 0.5,
                        mr: 1,
                        color: "#007BFF",
                        cursor: "pointer",
                        alignItems: "center",
                    }}
                    onClick={() => navigate("/chat")}
                >
                    <ArrowBackIos sx={{ fontSize: "0.86rem" }} /> Back
                </Typography>
            </Typography>
            {contextStore?.temparary?.compare?.map((pdfs: any) => (
                <>
                    <Box
                        sx={{
                            height: "100%",
                            width: `${100 / contextStore?.temparary?.compare?.length}%`,
                        }}
                    >
                        <ComparePdfModal
                            file_id={contextStore?.pdfList?.find((id: any) => id?.file_name === pdfs)?.file_id}
                        />
                    </Box>
                    <Divider orientation="vertical" sx={{ height: "100%" }} />
                </>
            ))}
        </Box>
    );
};

export default ViewMultiplePDF;
