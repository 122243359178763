import { Box, CircularProgress } from "@mui/material";
import React from "react";

const Loading = () => {
    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.8,
                background: "#e2e2e2",
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 10,
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default Loading;
