import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import GraphAnalysis from "./GraphAnalysis";

interface Props {
    setProceed: React.Dispatch<React.SetStateAction<boolean>>;
    setIsUploadStarted: React.Dispatch<React.SetStateAction<boolean>>;
    data: any;
    fileList: any;
    setData: React.Dispatch<React.SetStateAction<any>>;
    setProcessID: React.Dispatch<React.SetStateAction<string>>;
}

const DocumentAnalysis = ({ setProceed, setIsUploadStarted, data, fileList, setData, setProcessID }: Props) => {
    const [file, setFile] = useState<any>("");
    const [currentData, setCurrentData] = useState<any>(null);
    const [isAlertGenerationActive, setIsAlertGenerationActive] = useState<boolean>(false);
    const parseData = JSON.parse(data);
    const processedData = Object.entries(parseData)?.filter((item: any) => item[0] !== "progress");
    const options = Object.entries(parseData)
        ?.filter((item: any) => item[0] && item[0] !== "progress")
        ?.map((value: any) => value[0]);

    useEffect(() => {
        setCurrentData(processedData.find((element) => element[0] === file));
    }, [file]);

    return (
        <Box sx={{ width: "100%", height: "100%", maxHeight: "95vh", display: "flex", p: 2, alignItems: "flex-start" }}>
            <Box
                sx={{
                    width: "8%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                }}
            >
                <Button
                    onClick={() => {
                        if (isAlertGenerationActive) {
                            setIsAlertGenerationActive(false);
                        } else {
                            setProceed(false);
                            setIsUploadStarted(false);
                            setData(null);
                            setProcessID("");
                        }
                    }}
                >
                    Back
                </Button>
            </Box>

            <Box
                sx={{
                    width: "90%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                {isAlertGenerationActive ? (
                    <GraphAnalysis processedData={processedData} fileList={fileList} />
                ) : (
                    <Box
                        sx={{
                            width: "90%",
                            height: "100%",
                            border: "1px solid #D9D9D9",
                            borderRadius: "10px",
                            p: "3vh 2vw",
                            overflowY: "scroll",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                mb: "2vh",
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            }}
                        >
                            <Typography variant="h5">{file ? file : "Choose the file"}</Typography>
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <select
                                    style={{
                                        border: "1px solid #EDEDED",
                                        width: "fit-content",
                                        borderRadius: "10px",
                                        padding: "4px",
                                    }}
                                    onChange={(e) => setFile(e.target.value)}
                                >
                                    <option value="" hidden>
                                        Choose the file
                                    </option>
                                    {options?.map((option: any) => {
                                        return <option value={option}>{option}</option>;
                                    })}
                                </select>
                                <Box sx={{ height: "100%", display: "flex", alignItems: "center", gap: 1 }}>
                                    <Box
                                        sx={{ background: "rgba(204, 85, 85, 0.33)", height: "40px", width: "40px" }}
                                    />
                                    <Typography>Unavailable data</Typography>
                                    <Button
                                        sx={{
                                            border: "1px solid #007BFF",
                                            borderRadius: "7px",
                                            color: "#007BFF",
                                            fontWeight: "600",
                                        }}
                                        onClick={() => setIsAlertGenerationActive(true)}
                                    >
                                        Generate Alert
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ width: "100%", maxHeight: "100%" }}>
                            {currentData &&
                                Object.entries(currentData?.[1])?.map((data: any) => {
                                    return (
                                        <Box sx={{ width: "100%", mb: "2%" }}>
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    background:
                                                        "linear-gradient(to right, rgba(227, 244, 255, 1), #fff)",
                                                    height: "35px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    p: "0 1vw",
                                                }}
                                            >
                                                <Typography sx={{ color: "#007BFF" }}>
                                                    {`${data?.[0]?.[0]}${data?.[0]
                                                        ?.replaceAll("_", " ")
                                                        .toLowerCase()
                                                        .slice(1)}`}
                                                </Typography>
                                            </Box>
                                            {Object.entries(data?.[1])?.map((entries: any) => {
                                                return (
                                                    <Box>
                                                        <Box
                                                            sx={{
                                                                width: "100%",
                                                                height: "35px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                border: "1px solid #D9D9D9",
                                                            }}
                                                        >
                                                            <Typography>
                                                                {`${entries?.[0]?.[0].toUpperCase()}${entries?.[0]
                                                                    ?.replaceAll("_", " ")
                                                                    .toLowerCase()
                                                                    .slice(1)}`}
                                                            </Typography>
                                                        </Box>

                                                        {entries?.[1]?.map((entry: any) => {
                                                            return (
                                                                <Box
                                                                    sx={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        // minHeight: "35",
                                                                        // height: "5vh",
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            width: "60%",
                                                                            border: "1px solid #D9D9D9",
                                                                            p: 2,
                                                                        }}
                                                                    >
                                                                        {entry?.type}
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            width: "40%",
                                                                            border: "1px solid #D9D9D9",
                                                                            p: 2,
                                                                            background:
                                                                                entry?.value?.length > 0
                                                                                    ? null
                                                                                    : "rgba(204, 85, 85, 0.33)",
                                                                        }}
                                                                    >
                                                                        {entry?.value}
                                                                    </Box>
                                                                </Box>
                                                            );
                                                        })}
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    );
                                })}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default DocumentAnalysis;
