import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import ESGSearchIcon from "../../../components/Common/svgLogs/ESGSearchIcon";
import SendWhiteIcon from "../../../components/Common/svgLogs/SendWhiteIcon";
import { Context } from "../../../context/setting";
import { KeyboardVoice, Mic, PauseCircle, PlayArrow } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

var a: any;

const InputField = ({ handleSend, inputRef }: any) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [query, setQuery] = useState<string>("");

    const wind: any = window;
    const speechRecognization = wind?.SpeechRecognition || wind?.webkitSpeechRecognition;
    const rec = new speechRecognization();

    return (
        <OutlinedInput
            ref={inputRef}
            value={query}
            onChange={(e: { target: { value: string }; preventDefault: any }) => {
                setQuery(e.target.value);
            }}
            onKeyDown={(e: any) => {
                if (e?.key === "Enter") {
                    if (e?.target?.value?.length === 0) {
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "warning",
                                message: "Please Ask A Question",
                            },
                        });
                    } else {
                        setQuery(() => "");

                        if (query?.length === 0) {
                            setContext({
                                ...contextStore,
                                snackState: {
                                    open: true,
                                    errorType: "warning",
                                    message: "Please Ask A Question",
                                },
                            });
                        } else {
                            setQuery(() => "");
                            handleSend(e?.target?.value, "general");
                            rec?.stop();
                        }
                    }
                }
                return;
            }}
            fullWidth
            endAdornment={
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <IconButton
                        sx={{ background: "#007BFF", "&:hover": { background: "#007BFFaa" } }}
                        onClick={() => {
                            if (query?.length === 0) {
                                setContext({
                                    ...contextStore,
                                    snackState: {
                                        open: true,
                                        errorType: "warning",
                                        message: "Please Ask A Question",
                                    },
                                });
                            } else {
                                if (
                                    ![
                                        "pdf",
                                        "excel",
                                        "pathalogy",
                                        "image",
                                        "agent-pipeline",
                                        "video",
                                        "audio",
                                    ]?.includes(contextStore?.activeSource)
                                ) {
                                    setContext({
                                        ...contextStore,
                                        snackState: {
                                            open: true,
                                            errorType: "warning",
                                            message: "Please Select Data Source First",
                                        },
                                    });
                                } else if (query?.length === 0) {
                                    setContext({
                                        ...contextStore,
                                        snackState: {
                                            open: true,
                                            errorType: "warning",
                                            message: "Please Ask A Question",
                                        },
                                    });
                                } else {
                                    setQuery(() => "");
                                    handleSend(query, "general");
                                    rec?.stop();
                                }
                            }
                        }}
                    >
                        <SendWhiteIcon />
                    </IconButton>
                </Box>
            }
            sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "80px",
                    border: "1px solid #E0E0E0",
                },
                "& .MuiOutlinedInput-input": {
                    padding: "10px 20px",
                },
                width: "100%",
            }}
            placeholder="Ask Me Anything"
        />
    );
};

export default InputField;
