import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import UploadBoxIcon from "../../components/Common/svgLogs/UploadBoxIcon";
import { Close } from "@mui/icons-material";
import { Api } from "../../apis";
import { Context } from "../../context/setting";

const FileUpload = ({ selectedFile, setSelectedFile, containsFiles, setContainsFile }: any) => {
    const { contextStore, setContext } = useContext<any>(Context);

    const handleUploadPathology = () => {
        const formData = new FormData();
        const userId: string = contextStore?.profile?.userId;
        const azure_provider: any = contextStore?.isAzure ? 1 : 0;
        formData.append("azure_provider", azure_provider);
        formData?.append("pdf_file", selectedFile);
        formData.append("user_id", userId);

        Api?.uploadPathology(formData)?.then((res: any) => {
            if (res?.status === 202) {
                setSelectedFile(null);
                setContainsFile(true);
                setContext({
                    ...contextStore,
                    source_file: undefined,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message: `Upload Process Started For ${selectedFile?.name}`,
                    },
                });
            }
        });
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    width: "50%",
                    height: "80%",
                    border: "1px solid #F5F5F5",
                    borderRadius: "11px",
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4">Medical reports</Typography>
                <Typography variant="body1" sx={{ mt: "3vh", color: "#007BFF" }}>
                    <em>No reports uploaded</em>
                </Typography>
                <Typography sx={{ color: "#424F65", mt: "1vh" }}>
                    Analyse, Compare and get Insights about your medical reports
                </Typography>
                <Box
                    sx={{
                        width: "100%",
                        height: "20%",
                        mt: "4vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px dashed #007BFF",
                        borderRadius: "8px",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            p: 1,
                            pl: 4,
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyItems: "center",
                            alignItems: "center",
                        }}
                        component={"label"}
                        onDrop={(e: any) => {
                            e.preventDefault();
                            setSelectedFile(e?.dataTransfer?.files[0]);
                        }}
                        onDragOver={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                    >
                        <Box sx={{ transform: "scale(0.8)", ml: "3%", mr: "5%" }}>
                            <UploadBoxIcon />
                        </Box>
                        <Box
                            sx={{
                                height: "100%",
                                width: "100%",
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    justifyContent: "center",
                                    display: "flex",
                                    ml: 2,
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Typography variant="h5">
                                    {" "}
                                    Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                                </Typography>
                                <Typography variant="subtitle2" sx={{ color: "#424F65" }}>
                                    <em>Supported .pdf</em>
                                </Typography>
                            </Box>

                            <input
                                hidden
                                type="file"
                                accept=".pdf"
                                onChange={(e: { target: { files: any; value: any } }) => {
                                    setSelectedFile(e?.target?.files[0]);
                                    e.target.value = "";
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: "100%", mt: "4vh" }}>
                    {selectedFile ? (
                        <Box>
                            <Typography sx={{ color: "#424F65" }}>
                                <em>Report ready to upload</em>
                            </Typography>
                            <Box
                                sx={{
                                    width: "100%",

                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    height: "50px",
                                }}
                            >
                                <Typography variant="subtitle1">{selectedFile?.name}</Typography>
                                <Button onClick={() => setSelectedFile(null)}>
                                    <Close sx={{ color: "#000" }} />
                                </Button>
                            </Box>
                        </Box>
                    ) : null}
                </Box>
                {selectedFile && (
                    <Button
                        variant="contained"
                        sx={{
                            background: "#007BFF",
                            width: "15rem",
                            height: "2.8rem",
                            borderRadius: "10px",
                            mt: "20%",
                        }}
                        onClick={handleUploadPathology}
                    >
                        Upload
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default FileUpload;
