import { Box, Typography } from "@mui/material";
import BulbIcon from "../Common/svgLogs/BulbIcon";

export default function FallbackLoadingModal() {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                background: "#e2e2e2",
            }}
        >
            <Box
                sx={{
                    background: "#fff",
                    borderRadius: "18px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    boxShadow: "0px 4px 34.4px 0px rgba(0, 0, 0, 0.09)",
                    height: "10.5rem",
                    width: "21rem",
                    py: 2,
                    px: 3,
                }}
            >
                <Box sx={{ textAlign: "center" }}>
                    <div className="dot-spin"></div>
                    <Typography>Just a moment</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "center" }}>
                    <BulbIcon fill="#007BFF" />
                    <Typography sx={{ fontStyle: "italic", color: "#717171", fontSize: "0.8rem" }}>
                        Facts about A.I. or Softsensor X
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
