import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { pdfjs, Page, Document } from "react-pdf";
import { Context } from "../../../context/setting";
import axios from "axios";
import Environment from "../../../enviroment";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const PdfSection = () => {
    const navigate = useNavigate();
    const containerRef = useRef<any>();
    const { contextStore, setContext } = useContext<any>(Context);
    const [file, setFile] = useState<any>(null);
    const [numPages, setNumPages] = useState<number>(0);
    const [loading, setloading] = useState<boolean>(false);
    const [sizes, setSizes] = useState({ width: 100, height: 100 });
    const base = Environment.USER_URL;

    pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

    useEffect(() => {
        if (contextStore?.care?.selectedFile) {
            handlePdf(contextStore?.care?.selectedFile?.file_id);
        }
    }, [contextStore?.care?.selectedFile]);

    const handlePdf = async (file_id: string) => {
        const response = await axios?.post(
            `${base}/get_pdf`,
            {
                get_file: file_id,
                user_id: contextStore?.profile?.userId,
            },
            { responseType: "arraybuffer" }
        );
        const blob = new Blob([response?.data], { type: "application/pdf" });
        const pdfUrl = `${URL.createObjectURL(blob)}#view=fitH&page=${0}`;
        setFile(pdfUrl);
    };

    return file ? (
        <Box
            sx={{
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                py: 1,
                px: 2,
                height: "100%",
            }}
            onMouseEnter={(e) =>
                sizes?.width !== containerRef?.current?.offsetWidth &&
                setSizes(() => ({
                    width: containerRef?.current?.offsetWidth - 10,
                    height: containerRef?.current?.offsetHeight,
                }))
            }
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <IconButton onClick={() => navigate("/care")}>
                    <ArrowBackIos sx={{ fontSize: "0.8rem" }} />
                </IconButton>
                <Typography>{contextStore?.care?.selectedFile?.file_name}</Typography>
            </Box>
            <Box ref={containerRef} sx={{ flex: "1 0 10vh", overflow: "scroll" }}>
                <Document file={file} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, py: 1 }}>
                        {numPages &&
                            Array(numPages)
                                ?.fill(0)
                                ?.map((e, index: number) => (
                                    <Box textAlign={"center"}>
                                        <Box
                                            sx={{
                                                boxShadow: "0px 4px 6.3px 0px rgba(0, 0, 0, 0.32)",
                                                p: 1,
                                                transition: "200ms ease-in",
                                                transitionDelay: "50ms",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Page
                                                pageNumber={index + 1}
                                                scale={1}
                                                width={sizes?.width} // Set the width as needed
                                                height={sizes?.height} // Set the height as needed
                                            ></Page>
                                        </Box>
                                        <Box sx={{ py: 1 }}>
                                            <Typography>{index + 1}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                    </Box>
                </Document>
            </Box>
        </Box>
    ) : (
        <Box sx={{ width: "100%", height: "67vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress style={{ width: "1.8rem", height: "1.8rem", animationDuration: "800ms" }} />
        </Box>
    );
};

export default PdfSection;
