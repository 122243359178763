import React, { useState } from "react";
import Chat from "./ChatBox/Chat";
import PdfViewer from "./PdfViewer";
import { Box, Stack } from "@mui/material";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";

const Aurigo = () => {
    const [visiblePdf, setVisiblePdf] = useState<boolean>(false);

    return (
        <Stack direction={"row"} flex={1}>
            <ReflexContainer orientation="vertical">
                <ReflexElement flex={1}>
                    <Chat visiblePdf={visiblePdf} setVisiblePdf={setVisiblePdf} />
                </ReflexElement>
                {/* {visiblePdf && (
                    <ReflexSplitter>
                        {" "}
                        <Box
                            sx={{
                                width: "16px",
                                height: "100%",
                                background: "#E3F4FF",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                borderWidth: "0px 2px 0px 2px",
                                borderStyle: "solid",
                                borderColor: "#E3F4FF",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "3px",
                                    height: "15px",
                                    background: "#0000001C",
                                    borderRadius: "10px",
                                }}
                            ></Box>
                            <Box
                                sx={{
                                    width: "3px",
                                    height: "15px",
                                    background: "#0000001C",
                                    borderRadius: "10px",
                                }}
                            ></Box>
                        </Box>
                    </ReflexSplitter>
                )}
                {visiblePdf && (
                    <ReflexElement minSize={400}>
                        <PdfViewer setVisiblePdf={setVisiblePdf} />
                    </ReflexElement>
                )} */}
            </ReflexContainer>
        </Stack>
    );
};

export default Aurigo;
