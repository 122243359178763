import React from "react";

const PostgresIcon = () => {
    return (
        <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.078 22.1971C22.2651 20.6396 22.2089 20.4124 23.3681 20.6637L23.663 20.6901C24.5548 20.7303 25.7209 20.5467 26.4176 20.2288C27.8924 19.5447 28.767 18.4016 27.3128 18.7023C23.9959 19.3863 23.7549 18.2627 23.7549 18.2627C27.2589 13.0634 28.7246 6.46396 27.4586 4.8491C24.0108 0.44179 18.0414 2.52608 17.9416 2.58117L17.9094 2.58691C17.2552 2.44918 16.5322 2.36884 15.6943 2.35736C14.1931 2.33441 13.0545 2.75103 12.1891 3.40639C12.1891 3.40639 1.54157 -0.980255 2.03625 8.92356C2.14184 11.0308 5.0548 24.8771 8.53243 20.6879C9.80527 19.1568 11.0334 17.8633 11.0334 17.8633C11.6417 18.2684 12.3728 18.475 13.1383 18.4004L13.198 18.3499C13.1803 18.5483 13.1884 18.7481 13.2221 18.9444C12.3268 19.9453 12.5908 20.1209 10.8004 20.4893C8.98923 20.8623 10.0543 21.5268 10.7476 21.7013C11.59 21.9125 13.5389 22.2109 14.8565 20.3676L14.8037 20.5777C15.1549 20.8589 15.1308 22.5977 15.1824 23.8395C15.2341 25.0814 15.3156 26.2417 15.5692 26.9247C15.8229 27.6076 16.1201 29.3693 18.473 28.8758C20.439 28.4546 21.9426 27.8474 22.0803 22.2075"
                fill="black"
            />
            <path
                d="M22.078 22.1971C22.2651 20.6396 22.2089 20.4124 23.3681 20.6637L23.663 20.6901C24.5548 20.7303 25.7209 20.5467 26.4176 20.2288C27.8924 19.5447 28.767 18.4016 27.3128 18.7023C23.9959 19.3863 23.7549 18.2627 23.7549 18.2627C27.2589 13.0634 28.7246 6.46396 27.4586 4.8491C24.0108 0.44179 18.0414 2.52608 17.9416 2.58117L17.9094 2.58691C17.2552 2.44918 16.5322 2.36884 15.6943 2.35736C14.1931 2.33441 13.0545 2.75103 12.1891 3.40639C12.1891 3.40639 1.54157 -0.980255 2.03625 8.92356C2.14184 11.0308 5.0548 24.8771 8.53243 20.6879C9.80527 19.1568 11.0334 17.8633 11.0334 17.8633C11.6417 18.2684 12.3728 18.475 13.1383 18.4004L13.198 18.3499C13.1803 18.5483 13.1884 18.7481 13.2221 18.9444C12.3268 19.9453 12.5908 20.1209 10.8004 20.4893C8.98923 20.8623 10.0543 21.5268 10.7476 21.7013C11.59 21.9125 13.5389 22.2109 14.8565 20.3676L14.8037 20.5777C15.1549 20.8589 15.1308 22.5977 15.1824 23.8395C15.2341 25.0814 15.3156 26.2417 15.5692 26.9246C15.8229 27.6076 16.1201 29.3693 18.473 28.8758C20.439 28.4546 21.9426 27.8474 22.0803 22.2075"
                stroke="black"
                strokeWidth="2.46648"
            />
            <path
                d="M27.3028 18.7116C23.9858 19.3956 23.7448 18.272 23.7448 18.272C27.2489 13.0728 28.7145 6.47329 27.4497 4.85842C24.0019 0.451116 18.0325 2.53655 17.9327 2.5905L17.9006 2.59623C17.1714 2.44994 16.4302 2.3727 15.6866 2.36554C14.1853 2.34258 13.0468 2.75921 12.1814 3.41457C12.1814 3.41457 1.53269 -0.972076 2.02851 8.93174C2.1341 11.039 5.04706 24.8853 8.5247 20.696C9.80557 19.1558 11.0336 17.8623 11.0336 17.8623C11.6419 18.2674 12.3731 18.474 13.1386 18.3994L13.1983 18.3489C13.1807 18.5473 13.1888 18.7471 13.2224 18.9434C12.3271 19.9443 12.5911 20.1199 10.8007 20.4883C8.98953 20.8613 10.0546 21.5258 10.7479 21.7003C11.5903 21.9115 13.5392 22.2099 14.8568 20.3666L14.804 20.5767C15.1552 20.8579 15.4008 22.405 15.3595 23.8075C15.3181 25.2101 15.2906 26.1719 15.5661 26.9248C15.8415 27.6777 16.117 29.3695 18.4698 28.8759C20.4359 28.4547 21.4539 27.3609 21.5974 25.5406C21.6984 24.246 21.9257 24.4365 21.9417 23.2796L22.1254 22.731C22.3354 20.9749 22.1598 20.4091 23.3707 20.6731L23.6656 20.6995C24.5574 20.7396 25.7247 20.556 26.4087 20.2381C27.8836 19.554 28.757 18.4109 27.3028 18.7116Z"
                fill="#336791"
            />
            <path
                d="M14.9977 19.7067C14.9059 22.9732 15.0206 26.2626 15.3397 27.0522C15.6588 27.8419 16.344 29.4051 18.698 28.9024C20.664 28.4811 21.3802 27.6651 21.6901 25.8643L22.4166 20.1061M12.1765 3.33196C12.1765 3.33196 1.52096 -1.02485 2.01678 8.87897C2.12237 10.9862 5.03533 24.8325 8.51297 20.6433C9.78236 19.1133 10.9301 17.9162 10.9301 17.9162M17.9313 2.53658C17.564 2.65135 23.8582 0.235367 27.4368 4.8068C28.6993 6.42166 27.236 13.0211 23.7319 18.2204"
                stroke="white"
                strokeWidth="0.821779"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M23.7328 18.2178C23.7328 18.2178 23.9623 19.3426 27.2908 18.6563C28.7449 18.3556 27.8692 19.4987 26.3955 20.1828C25.1858 20.7452 22.4726 20.8886 22.4278 20.1139C22.313 18.1111 23.8556 18.7194 23.7443 18.2178C23.6433 17.7656 22.9523 17.3226 22.4978 16.2162C22.0995 15.2521 17.0346 7.8492 23.9026 8.94758C24.1551 8.89593 22.1122 2.40549 15.6849 2.31137C9.25753 2.21726 9.46068 10.2135 9.46068 10.2135"
                stroke="white"
                strokeWidth="0.821779"
                stroke-linecap="round"
                stroke-linejoin="bevel"
            />
            <path
                d="M13.1992 18.9018C12.3039 19.9026 12.5679 20.0782 10.7774 20.4466C8.96631 20.8196 10.0314 21.4842 10.7246 21.6586C11.5671 21.8698 13.5159 22.1682 14.8335 20.3238C15.2352 19.7614 14.8312 18.8662 14.2803 18.6389C14.0141 18.5288 13.6583 18.391 13.2015 18.9029L13.1992 18.9018Z"
                stroke="white"
                strokeWidth="0.821779"
                stroke-linejoin="round"
            />
            <path
                d="M13.1408 18.8855C13.049 18.2967 13.3337 17.5978 13.6378 16.7783C14.0946 15.5502 15.1482 14.3221 14.3058 10.4233C13.678 7.51948 9.46235 9.8184 9.46235 10.2121C9.46235 10.6057 9.65288 12.2091 9.39349 14.0742C9.05261 16.5097 10.9429 18.5687 13.119 18.3587"
                stroke="white"
                strokeWidth="0.821779"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.1377 10.1556C12.1182 10.2899 12.3845 10.6491 12.73 10.6973C13.0754 10.7455 13.3704 10.4655 13.3899 10.33C13.4094 10.1946 13.1431 10.0477 12.7977 9.99949C12.4522 9.95128 12.1549 10.0224 12.1377 10.1556Z"
                fill="white"
                stroke="white"
                strokeWidth="0.274309"
            />
            <path
                d="M22.6553 9.88214C22.6737 10.0164 22.4086 10.3757 22.0631 10.4239C21.7176 10.4721 21.4204 10.192 21.4031 10.0566C21.3859 9.92116 21.6499 9.77425 21.9954 9.72605C22.3408 9.67784 22.6381 9.749 22.6553 9.88214Z"
                fill="white"
                stroke="white"
                strokeWidth="0.136581"
            />
            <path
                d="M23.8903 8.95117C23.9477 10.0071 23.6631 10.7244 23.6264 11.8481C23.5736 13.4801 24.4045 15.3487 23.1523 17.2195"
                stroke="white"
                strokeWidth="0.821779"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default PostgresIcon;
