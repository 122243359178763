import { Box, Button, CircularProgress, IconButton, Input, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../../../context/setting";
import axios from "axios";
import * as pdfJsDist from "pdfjs-dist";
import { PDFDocument, rgb } from "pdf-lib";
import { Api } from "../../../../apis";
import Environment from "../../../../enviroment";
import { Document, Page, pdfjs } from "react-pdf";
import { Add, ArrowForward, Remove, SkipNext, SkipPrevious, ZoomIn, ZoomOut } from "@mui/icons-material";

const ComparePdfModal = ({ file_id }: { file_id: string }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const containerRef = useRef<any>();
    const [pdfLoad, setPdfLoad] = useState(false);
    const [file, setFile] = useState<any>();
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [scale, setScale] = useState(1.0);
    const [sizes, setSizes] = useState({ width: 100, height: 100 });

    useEffect(() => {
        setPdfLoad(true);
        const texts = contextStore?.compare_source_texts?.filter((item: any) => item[file_id]);
        handlePDF(texts?.length > 0 ? texts[0][file_id]?.source_texts : []);
    }, [contextStore?.compare_source_texts]);

    const handlePDF = async (sourceTexts: any[]) => {
        const base = Environment.USER_URL;
        const bucket = file_id;
        let response = await axios?.post(
            `${base}/get_pdf`,
            {
                get_file: bucket,
                user_id: contextStore?.profile?.userId, // for now hardocde value
            },
            { responseType: "arraybuffer" }
        );
        pdfJsDist.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfJsDist.version}/build/pdf.worker.min.js`;
        pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
        const readPDFDoc: any = await pdfJsDist?.getDocument(response?.data)?.promise?.then((res) => res);
        let pageTexts: any = { ...sourceTexts };
        if (Object?.keys(pageTexts)?.length > 0) {
            const drawPDFDoc = await PDFDocument.load(response.data, { ignoreEncryption: true });

            if (Object?.keys(pageTexts)?.length > 0) {
                Object?.keys(pageTexts)
                    ?.sort((a: string, b: string) => Number(a) - Number(b))
                    ?.forEach((number: string, pageTexts_index: number) => {
                        let joinedText = pageTexts[number]?.join("");
                        readPDFDoc
                            ?.getPage(Number(number))
                            ?.then((page: any) => {
                                return page.getTextContent()?.then((textContent: any) => {
                                    textContent?.items?.forEach((item: any, textContent_index: number) => {
                                        if (
                                            item?.str?.trim() !== "" &&
                                            joinedText?.includes(item?.str) &&
                                            item?.str?.trim()?.length > 4
                                        ) {
                                            drawPDFDoc.getPage(Number(number) - 1)?.drawRectangle({
                                                x: item?.transform[4],
                                                y: item?.transform[5] - 1,
                                                color: rgb(1, 1, 0),
                                                opacity: 0.5,
                                                width: item?.width,
                                                height: item?.height,
                                            });
                                        }
                                    });
                                });
                            })
                            ?.then((pdf: any) => {
                                drawPDFDoc.save()?.then((modifiedPdfBytes: any) => {
                                    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
                                    const pdfUrl = `${URL.createObjectURL(blob)}#view=fitH&page=${pageNumber}`;
                                    setFile(pdfUrl);
                                    setPdfLoad(false);
                                });
                            });
                    });
                setPageNumber(() => Math?.min(...Object?.keys(pageTexts)?.map((item) => Number(item))));
            } else {
                const modifiedPdfBytes = await drawPDFDoc.save();
                const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
                const pdfUrl = `${URL.createObjectURL(blob)}#view=fitH&page=${pageNumber}`;
                setFile(pdfUrl);
                setPdfLoad(false);
            }
        } else {
            const blob = new Blob([response?.data], { type: "application/pdf" });
            const pdfUrl = `${URL.createObjectURL(blob)}#view=fitH&page=${pageNumber}`;
            setFile(pdfUrl);
            setPdfLoad(false);
        }
    };

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    };

    return (
        <Box
            ref={containerRef}
            sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: "14px",
                height: "93.4vh",
                flexDirection: "column",
            }}
            onMouseEnter={(e) =>
                sizes?.width !== containerRef?.current?.offsetWidth &&
                setSizes(() => ({
                    width: (containerRef?.current?.offsetWidth / 100) * 71,
                    height: containerRef?.current?.offsetHeight,
                }))
            }
        >
            <Box
                sx={{
                    height: "5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                }}
            >
                <Typography
                    noWrap
                    variant="subtitle1"
                    sx={{ WebkitTextStrokeWidth: 0.1, marginLeft: "10%", width: "22rem" }}
                >
                    {contextStore?.pdfList?.find((item: any) => item?.file_id === file_id)?.file_name}
                </Typography>
            </Box>
            {pdfLoad ? (
                <Box
                    sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 1.5,
                    }}
                >
                    <CircularProgress sx={{ animationDuration: "600ms", width: "28px", height: "28px" }} />
                    <Box>
                        <Typography variant="body1">Loading PDF Please Wait ...</Typography>
                    </Box>
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            boxShadow: "0px 0px 4px 2px #f2f2f2",
                            p: 0.5,
                            px: 5,
                            height: "83%",
                            overflowY: "auto",
                            borderRadius: "4px",
                            width: "94%",
                        }}
                    >
                        {file && (
                            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                <Page
                                    key={`page_${pageNumber}`}
                                    pageNumber={pageNumber}
                                    scale={scale}
                                    width={sizes?.width} // Set the width as needed
                                    height={sizes?.height} // Set the height as needed
                                />
                            </Document>
                        )}
                    </Box>
                    <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: 1 }}>
                        <IconButton onClick={() => setScale((prev) => (prev > 1 ? prev - 0.2 : prev))}>
                            <ZoomOut />
                        </IconButton>
                        <IconButton onClick={() => setPageNumber((prev) => (prev > 1 ? prev - 1 : prev))}>
                            <SkipPrevious />
                        </IconButton>
                        <Input
                            value={pageNumber}
                            onChange={(e: { target: { value: string } }) =>
                                setPageNumber(() => Number(e?.target?.value))
                            }
                            sx={{
                                ".MuiInput-input::-webkit-input-placeholder": { fontStyle: "italic" },
                                ".MuiInput-input": { pl: 1 },
                                background: "#fff",
                                width: "3.5rem",
                                borderRadius: "10px",
                                border: "1px solid #f2f2f2",
                                boxShadow: "inset 0px 0px 4px -1px #ccc",
                            }}
                            disableUnderline
                        />
                        <IconButton onClick={() => setPageNumber((prev) => (prev < numPages ? prev + 1 : prev))}>
                            <SkipNext />
                        </IconButton>
                        <IconButton onClick={() => setScale((prev) => (prev < 2 ? prev + 0.2 : prev))}>
                            <ZoomIn />
                        </IconButton>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ComparePdfModal;
