import { Box, CircularProgress, Typography } from "@mui/material";
import anamolyBanner from "../../assets/anamolyBanner.png";
import UploadBoxIcon from "../../components/Common/svgLogs/UploadBoxIcon";
import { useContext, useState } from "react";
import { Api } from "../../apis";
import { Context } from "../../context/setting";

const AnamolyDetectionUpload = ({ setSelectedFile }: { setSelectedFile: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [loader, setLoader] = useState(false);

    const handleUpload = (file: File) => {
        setLoader(true);
        const formData = new FormData();
        formData?.append("file", file);
        formData?.append("user_id", "ec0732c9-9e59-460d-b496-9830fd7d272b");
        setSelectedFile(file);
        Api?.uploadFinaceExcel(formData)?.then((res: any) => {
            if (res?.status === 200) {
                setSelectedFile(file);
            } else {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Failed To Upload File Please Retry",
                    },
                });
            }
            setLoader(false);
        });
    };

    return (
        <Box>
            <Box
                sx={{
                    width: "100vw",
                    height: "30vh",
                    background: `url(${anamolyBanner}) center/cover`,
                }}
            ></Box>

            {/* Another Box positioned relative to the center */}
            <Box
                sx={{
                    position: "relative",
                    top: "-90px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "680px",
                    height: "300px",
                    backgroundColor: "white", // You can adjust the background color
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    flexDirection: "column",
                    boxShadow: "0px 4px 9.8px 0px rgba(0, 0, 0, 0.06)",
                    borderRadius: "0.68rem",
                }}
            >
                <Typography sx={{ fontWeight: "500", fontSize: "2rem" }}>Anomaly Detection</Typography>
                <Typography sx={{ fontWeight: "500", fontSize: "1rem" }}>
                    Upload your financial documents, efficiently detect unusual combinations
                </Typography>
                <Box sx={{ border: "1px solid #f2f2f2", borderRadius: "10px" }}>
                    {loader ? (
                        <Box
                            sx={{
                                width: "22rem",
                                height: "10vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress style={{ width: "25px", height: "25px" }} />
                        </Box>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "10vh",
                                    p: 1,
                                    pl: 4,
                                    minHeight: "50px",
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                                component={"label"}
                                onDrop={(e: any) => {
                                    e.preventDefault();
                                    // setSelectedFile(e?.dataTransfer?.files[0]);
                                    handleUpload(e?.dataTransfer?.files[0]);
                                }}
                                onDragOver={(e: any) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            >
                                <Box sx={{ transform: "scale(0.6)" }}>
                                    <UploadBoxIcon />
                                </Box>
                                <Box
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: "300px",
                                            height: "100%",
                                            justifyContent: "center",
                                            display: "flex",
                                            ml: 2,
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                        }}
                                    >
                                        <Typography variant="h5">
                                            Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                                        </Typography>
                                    </Box>

                                    <input
                                        hidden
                                        type="file"
                                        accept=".pdf, .xls, .xlsx, .csv"
                                        onChange={(e: { target: { files: any; value: any } }) => {
                                            // setSelectedFile(e?.target?.files[0]);
                                            handleUpload(e?.target?.files[0]);
                                            // setOpen(true);
                                            e.target.value = "";
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    maxHeight: "42vh",
                                    overflow: "auto",
                                    display: "flex",
                                    flexDirection: "column",
                                    p: 1,
                                    gap: 0.8,
                                }}
                            ></Box>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default AnamolyDetectionUpload;
