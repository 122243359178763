import React, { useContext, useState } from "react";
import FileUpload from "./FileUpload";
import FileView from "./FileView";
import { Api } from "../../apis";
import { Context } from "../../context/setting";
import Banner from "../Landing_page/Banner";
import { Box } from "@mui/material";

const Index = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [selectedFile, setSelectedFile] = useState<any>();
    const [containsFiles, setContainsFile] = useState<any>(true);
    const [pdfList, setPdfList] = useState<any>([]);
    const [banner, setBanner] = useState<boolean>(true);

    const fetchPathologyList = () => {
        Api?.getPathoNamespaces({ user_id: contextStore?.profile?.userId })?.then((res: any) => {
            if (res?.status === 200) {
                setContext({ ...contextStore, pathalogyList: res?.data?.sort() });
                if (res?.data?.length > 0) {
                    setContainsFile(true);
                } else {
                    setContainsFile(false);
                }
            }
        });
    };

    const data = [
        {
            text: "Form key-value pairs and Table extraction",
            altText: "Automate data processing from your business documents with AI",
            url: "https://d3fvaqnlz9wyiv.cloudfront.net/softsensorX/lab-report.gif",
        },
    ];

    return containsFiles ? (
        <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
            {banner && !sessionStorage.getItem("pathology") ? <Banner setBanner={setBanner} data={data} /> : null}
            <FileView
                containsFiles={containsFiles}
                setContainsFile={setContainsFile}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                pdfList={pdfList}
                setPdfList={setPdfList}
                fetchPathologyList={fetchPathologyList}
            />
        </Box>
    ) : (
        <Box>
            {banner ? <Banner setBanner={setBanner} data={data} /> : null}{" "}
            <FileUpload
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                containsFiles={containsFiles}
                setContainsFile={setContainsFile}
            />
        </Box>
    );
};

export default Index;
