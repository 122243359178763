import axios from "axios";
import Environment from "../../enviroment";


const log_prefix = "[FLEET SCHEDULING-APP]";

const VERBOSE = Environment.LOGS;

const messages = {
    start: "Starting request.",
    end: "End request.",
    non200x: "The request was made and the server responded with a status code that falls out of the range of 2xx.",
    noResp: "The request was made but no response was received.",
    badConfig: "Something happened in setting up the request that triggered an Error.",
};

const genericError = {
    message: "Something Went Wrong",
    status: 500,
};

function logger(message: any, verbose: any, type: any) {
    if (verbose) {
        if (type === "error") {
            message = `[ERROR] ${message}`;
            type = "info";
        }
        if (process.env.NODE_ENV !== "production") {
            // console[type](message);
        }
    }
}



function defaultCatch(error: any, resolve: any) {
    if (error.response) {
        logger(`${log_prefix} ${messages.non200x}`, VERBOSE, "error");
        logger(`${log_prefix} evaluating(error.response) ${error.response}`, VERBOSE, "error");

        resolve(error.response);
    } else if (error.request) {
        logger(`${log_prefix} ${messages.noResp}`, VERBOSE, "error");
        logger(`${log_prefix} evaluating(http.ClientRequest) ${error.request}`, VERBOSE, "error");

        resolve(genericError);
    } else {
        logger(`${log_prefix} ${messages.badConfig}`, VERBOSE, "error");
        logger(`${log_prefix} evaluating(config) ${error.config}`, VERBOSE, "error");
        logger(`${log_prefix} evaluating(axios.instance) ${instance}`, VERBOSE, "error");

        resolve(genericError);
    }
}



const instance: any = axios.create({
    baseURL: Environment.USER_URL,
    headers: { "Content-Type": "application/json", "app-type": "app_type" },
});

export { instance, logger, defaultCatch,log_prefix,VERBOSE,messages }