import React from "react";

const BulbIcon = ({ fill }: { fill?: string }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_1051_307"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
            >
                <rect width="20" height="20" fill={fill || "#D9D9D9"} />
            </mask>
            <g mask="url(#mask0_1051_307)">
                <path
                    d="M10.0007 18.3327C9.63954 18.3327 9.31315 18.2459 9.02148 18.0723C8.72982 17.8987 8.50065 17.666 8.33398 17.3743C7.87565 17.3743 7.48329 17.2112 7.1569 16.8848C6.83051 16.5584 6.66732 16.166 6.66732 15.7077V12.7493C5.84787 12.2077 5.19162 11.4924 4.69857 10.6035C4.20551 9.71463 3.95898 8.74935 3.95898 7.70768C3.95898 6.02713 4.54579 4.60004 5.7194 3.42643C6.89301 2.25282 8.3201 1.66602 10.0007 1.66602C11.6812 1.66602 13.1083 2.25282 14.2819 3.42643C15.4555 4.60004 16.0423 6.02713 16.0423 7.70768C16.0423 8.77713 15.7958 9.74935 15.3027 10.6243C14.8097 11.4993 14.1534 12.2077 13.334 12.7493V15.7077C13.334 16.166 13.1708 16.5584 12.8444 16.8848C12.518 17.2112 12.1257 17.3743 11.6673 17.3743C11.5007 17.666 11.2715 17.8987 10.9798 18.0723C10.6882 18.2459 10.3618 18.3327 10.0007 18.3327ZM8.33398 15.7077H11.6673V14.9577H8.33398V15.7077ZM8.33398 14.1243H11.6673V13.3327H8.33398V14.1243ZM8.16732 11.666H9.37565V9.41602L7.54232 7.58268L8.41732 6.70768L10.0007 8.29102L11.584 6.70768L12.459 7.58268L10.6257 9.41602V11.666H11.834C12.584 11.3049 13.1951 10.7737 13.6673 10.0723C14.1395 9.37088 14.3757 8.58268 14.3757 7.70768C14.3757 6.48546 13.952 5.45074 13.1048 4.60352C12.2576 3.75629 11.2229 3.33268 10.0007 3.33268C8.77843 3.33268 7.74371 3.75629 6.89648 4.60352C6.04926 5.45074 5.62565 6.48546 5.62565 7.70768C5.62565 8.58268 5.86176 9.37088 6.33398 10.0723C6.80621 10.7737 7.41732 11.3049 8.16732 11.666Z"
                    fill={fill || "#757575"}
                />
            </g>
        </svg>
    );
};

export default BulbIcon;
