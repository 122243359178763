import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AttackDetectedBox from "./AttackDetectedBox";
import NoAttackDetectedBox from "./NoAttackDetectedBox";
import { ChevronLeftSharp } from "@mui/icons-material";

const LogsAnalysis = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const logs = Object.entries(state?.response);
    const [selectedLog, setSelectedLog] = useState<any>(logs[0]);
    // console.log(Object.entries(selectedLog?.[1])?.[0]?.[1]?.hasOwnProperty("Attack"));
    // console.log(Object.entries(selectedLog?.[1])?.[0]?.[1]);

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Button
                sx={{
                    position: "absolute",
                    left: "65px",
                    top: "55px",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                }}
                onClick={() => navigate("/cybersecurity")}
            >
                <ChevronLeftSharp />
            </Button>
            <Box
                sx={{
                    width: "60%",
                    height: "90%",
                    display: "flex",
                    border: "1px solid #ECECEC",
                    borderRadius: "10px",
                    p: 2,
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" sx={{ color: "#007BFF", width: "93%" }}>
                    Analysis
                </Typography>
                <Box sx={{ width: "93%", mt: "1%" }}>
                    <Typography sx={{ color: "#9D9B9B" }}>Selected logs:</Typography>
                    <Box sx={{ mt: "0.2%" }}>
                        {logs?.map((log: any) => {
                            return (
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "6%",
                                        minHeight: "60px",
                                        background: selectedLog?.[0] === log?.[0] ? "#f0f9ff" : null,
                                        display: "flex",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setSelectedLog(log)}
                                >
                                    <Typography sx={{ ml: "2%" }}>{log?.[0]}</Typography>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
                <Box sx={{ width: "93%", height: "60vh", overflowY: "scroll", mt: "2%" }}>
                    {Object.entries(selectedLog?.[1])?.[0]?.[1]?.hasOwnProperty("Attack") ? (
                        <AttackDetectedBox data={Object.entries(selectedLog?.[1])} />
                    ) : (
                        <NoAttackDetectedBox data={Object.entries(selectedLog?.[1])} />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default LogsAnalysis;
