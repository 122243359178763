import { Box, Typography, IconButton, Fade } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import ProfileAvatar from "../../../components/Common/svgLogs/ProfileAvatar";
import SSXLogo from "../../../components/Common/svgLogs/Search_Highlighter/SSXLogo";
import Loader from "./Loader";
import { MoreVert } from "@mui/icons-material";

const MessageBox = ({ item }: { item: any }) => {
    const [costBoxVisible, setCostBoxVisible] = useState<boolean>(false);
    return (
        <>
            {item?.in && (
                <Box
                    sx={{
                        p: 1,
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        // height: '38%',
                        border: "1px solid #E0E0E0",
                        // border: "2px solid green",
                        color: item?.err ? "#fe6767" : "inherit",
                        // boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.3)",
                    }}
                >
                    {item?.init ? (
                        item?.init_msg
                    ) : item?.load ? (
                        <Loader />
                    ) : (
                        <Box sx={{ pl: 0, mt: 1 }}>
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{ mr: 1, mt: "6px" }}>{item?.out ? <ProfileAvatar /> : <SSXLogo />}</Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: "1rem",
                                            color: item?.error ? item?.color : "#007BFF",
                                            fontWeight: 500,
                                        }}
                                    >
                                        {item?.pdfName}
                                    </Typography>
                                    {item?.msg?.split("\n")?.map((msg: string) => (
                                        <Box
                                            sx={{
                                                fontSize: "16px",
                                                fontFamily: "Gilroy-Medium",
                                                mt: 1,
                                            }}
                                        >
                                            {msg}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {!item?.init && !item?.load && !item?.err && (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    borderTop: costBoxVisible ? "1px solid #EEEEEE" : null,
                                    mt: 1,
                                    // border: "8px solid pink",
                                    pb: 5,
                                }}
                            >
                                {costBoxVisible && (
                                    <Fade
                                        in={costBoxVisible}
                                        style={{ transitionDelay: costBoxVisible ? "300ms" : "300ms" }}
                                    >
                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "80px",

                                                display: "flex",
                                                // border: "2px solid green",
                                                justifyContent: "flex-start",
                                                // alignItems: "center",
                                                flexDirection: "column",
                                                px: "15px",
                                                // gap: 3.3,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: "auto",
                                                    height: "80%",
                                                    // border: "1px solid red",
                                                    display: "flex",
                                                    // border: "2px solid blue",
                                                    justifyContent: "space-evenly",
                                                    alignItems: "flex-start",
                                                    // border: "3px dotted purple",
                                                    flexDirection: "column",
                                                    ml: 2,
                                                    paddingTop: "2px",
                                                }}
                                            >
                                                <Typography sx={{ fontSize: "16px", color: "#757575" }}>
                                                    Details
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    height: "80px",
                                                    // borderTop: "1px solid #EEEEEE",
                                                    display: "flex",
                                                    // border: "2px dotted yellow",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                    px: "15px",
                                                    // mb: 3,
                                                    gap: 3.3,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: "auto",
                                                        height: "80%",
                                                        // border: "1px solid red",
                                                        display: "flex",
                                                        justifyContent: "space-evenly",
                                                        alignItems: "flex-start",
                                                        // border: "2px solid black",
                                                        // border: "3px dotted black",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{ fontSize: "13px", mb: 1, fontFamily: "Gilroy-Medium" }}
                                                    >
                                                        Doc Name
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "#007BFF",
                                                            fontSize: "15px",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {/* $ {elem?.msg?.cost_analysis?.total_cost.toFixed(2) || " "} */}
                                                        {item?.metadata?.DocName}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: "auto",
                                                        height: "80%",
                                                        // border: "1px solid red",
                                                        display: "flex",
                                                        justifyContent: "space-evenly",
                                                        alignItems: "flex-start",
                                                        // border: "3px dotted purple",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Typography sx={{ fontSize: "13px", mb: 1 }}>
                                                        Author Name
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "#007BFF",
                                                            fontSize: "15px",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {/* {elem?.msg?.cost_analysis?.model || " "} */}
                                                        {item?.metadata?.AuthorName}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: "auto",
                                                        height: "80%",
                                                        // border: "1px solid red",
                                                        display: "flex",
                                                        justifyContent: "space-evenly",
                                                        alignItems: "flex-start",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Typography sx={{ fontSize: "13px", mb: 1 }}>
                                                        Creation Date
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "#007BFF",
                                                            fontSize: "15px",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {/* {parseFloat(elem?.msg?.cost_analysis?.time_taken).toFixed(1) || " "} S */}
                                                        {moment(item?.metadata?.CreationDate)?.format("HH MMM YYYY")}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: "auto",
                                                        height: "80%",
                                                        // border: "1px solid red",
                                                        display: "flex",
                                                        justifyContent: "space-evenly",
                                                        alignItems: "flex-start",
                                                        // border: "3px dotted purple",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Typography sx={{ fontSize: "13px", mb: 1 }}>
                                                        Updation Date
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "#007BFF",
                                                            fontSize: "15px",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {/* {elem?.msg?.cost_analysis?.model || " "} */}
                                                        {item?.metadata?.UpdationDate?.toString()}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Fade>
                                )}
                                <IconButton
                                    onClick={() => setCostBoxVisible(!costBoxVisible)}
                                    sx={{ width: "2.8rem", height: "2.8rem", color: "#007BFF" }}
                                >
                                    <MoreVert />
                                </IconButton>
                            </Box>
                        </>
                    )}
                </Box>
            )}
            {item?.out && (
                <Box
                    sx={{
                        p: 1,
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        // border: "2px dotted green",
                        width: "100%",
                        alignSelf: "flex-start",
                        color: item?.err ? "#fe6767" : "inherit",
                        // boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.3)",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            pt: 0.5,
                        }}
                    >
                        <Box sx={{ mr: 1 }}>{item?.out ? <ProfileAvatar /> : <SSXLogo />}</Box>
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                color: item?.error ? item?.color : "inherit",
                                flexGrow: 1,
                            }}
                        >
                            {item?.message}
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default MessageBox;
