import React from "react";

const LogoXIcon = () => {
    return (
        <svg width="25" height="16" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.613281 17.8621C3.7857 15.1034 10.6547 9.3931 11.096 9.17241L19.3719 2H28.1995L17.7167 9.58621L9.27908 16.8323C8.50535 17.4967 7.51921 17.8621 6.49932 17.8621H0.613281Z"
                fill="#BEDFF1"
            />
            <path
                d="M5.16504 2C8.8432 7.24138 16.2547 17.7793 16.4754 18H23.7857L12.1995 2H5.16504Z"
                fill="white"
                stroke="url(#paint0_linear_339_144)"
                strokeWidth="2.13333"
            />
            <path d="M28.2001 2H19.3725L10.5449 9.62791L16.43 18H23.7863L17.7173 9.62791L28.2001 2Z" fill="#007BFF" />
            <defs>
                <linearGradient
                    id="paint0_linear_339_144"
                    x1="11.5099"
                    y1="11.7931"
                    x2="19.3719"
                    y2="5.17241"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3386D3" stopOpacity="0.35" />
                    <stop offset="0.0001" stopColor="#3386D3" stopOpacity="0.35" />
                    <stop offset="1" stopColor="#0085FF" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default LogoXIcon;
