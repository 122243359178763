import { Box, Typography } from "@mui/material";

const AttackDetectedBox = ({ data }: any) => {
    // console.log(data);
    // const attack = { technique: "", attack: "", procedure_score: "", procedures: "", mitigation: "" };
    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", mb: "2%" }}>
            <Typography variant="h5" sx={{ color: "#CC5555", mb: "1%" }}>
                Attack Detected!
            </Typography>
            <Box sx={{ border: "1px solid #D9D9D9", borderRadius: "8px" }}>
                <Typography
                    sx={{ background: "#f0f9ff", p: 2, borderBottom: "1px solid #D9D9D9" }}
                >{`Technique: ${data?.[0]?.[0]}`}</Typography>
                <Box sx={{ width: "100%", display: "flex", borderBottom: "1px solid #D9D9D9" }}>
                    <Box
                        sx={{
                            borderRight: "1px solid #D9D9D9",
                            width: "30%",
                            p: 2,
                        }}
                    >
                        Attack
                    </Box>
                    <Box sx={{ p: 2, width: "70%" }}>
                        <Typography sx={{ textDecoration: "underline" }}>Reason:</Typography>
                        {data?.[0]?.[1]?.Attack?.reason?.map((attack: any, index: any) => {
                            return <Typography sx={{ ml: "10px" }}>{`${index + 1}. ${attack}`}</Typography>;
                        })}
                        <Typography sx={{ textDecoration: "underline", mt: "15px" }}>Recommendation:</Typography>
                        {data?.[0]?.[1]?.Attack?.recommendation?.map((attack: any, index: any) => {
                            return (
                                <Box
                                    sx={{
                                        mt: "5px",
                                        borderBottom:
                                            index !== data?.[0]?.[1]?.Attack?.recommendation?.length - 1
                                                ? "1px dotted #D9D9D9"
                                                : null,
                                    }}
                                >
                                    {attack?.map((rec: any, num: any) => {
                                        return (
                                            <Typography
                                                sx={{ ml: "10px", mb: num === attack?.length - 1 ? "5px" : null }}
                                            >{`${rec}`}</Typography>
                                        );
                                    })}
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", borderBottom: "1px solid #D9D9D9" }}>
                    <Box
                        sx={{
                            borderRight: "1px solid #D9D9D9",
                            width: "30%",
                            p: 2,
                        }}
                    >
                        Procedure Score
                    </Box>
                    <Box sx={{ p: 2, width: "70%" }}>
                        {data?.[0]?.[1]?.Procedure_score?.map((attack: any, index: any) => {
                            return <Typography>{`${index + 1}. ${attack}`}</Typography>;
                        })}
                    </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", borderBottom: "1px solid #D9D9D9" }}>
                    <Box
                        sx={{
                            borderRight: "1px solid #D9D9D9",
                            width: "30%",
                            p: 2,
                        }}
                    >
                        Procedures
                    </Box>
                    <Box sx={{ p: 2, width: "70%" }}>
                        {data?.[0]?.[1]?.Procedures?.map((attack: any, index: any) => {
                            return <Typography>{`${index + 1}. ${attack}`}</Typography>;
                        })}
                    </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", borderBottom: "1px solid #D9D9D9" }}>
                    <Box
                        sx={{
                            borderRight: "1px solid #D9D9D9",
                            width: "30%",
                            p: 2,
                        }}
                    >
                        Mitigation
                    </Box>
                    <Box sx={{ p: 2, width: "70%" }}>
                        {data?.[0]?.[1]?.Mitigations?.map((attack: any, index: any) => {
                            return <Typography>{`${index + 1}. ${attack}`}</Typography>;
                        })}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AttackDetectedBox;
