import React from "react";
import Button from "@mui/material/IconButton";

const IconButton = ({
    children,
    style,
    variant,
    onClick,
}: {
    children: any;
    style?: any;
    variant: "rounded" | "square";
    onClick?: () => void;
}) => {
    const radiusStyles = (key: string) => {
        switch (key) {
            case "rounded":
                return { borderRadius: "10px" };
            case "square":
                return { borderRadius: "50%" };

            default:
                return { borderRadius: "50%" };
        }
    };

    return (
        <Button sx={{ ...style, ...radiusStyles(variant) }} onClick={onClick}>
            {children}
        </Button>
    );
};

export default IconButton;
