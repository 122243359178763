import { Box, Button, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import Generation from "./Generation/Generation";
import { Api } from "../../apis";
import Variance from "./Variance/Variance";
import Banner from "../Landing_page/Banner";
import { useLocation, useNavigate } from "react-router-dom";

const Index = () => {
    const navigate = useNavigate();
    const [banner, setBanner] = useState<boolean>(true);
    const [excelIds, setExcelIds] = useState(null);
    const [lmdFiles, setLmdFiles] = useState<any>(null);
    const [tabSelected, setTabSelected] = useState("variance_analysis");

    useEffect(() => {
        Api?.getExcelReportsIDs()?.then((res: any) => {
            if (res?.status === 200) {
                setExcelIds(res?.data);
            }
        });

        Api.getAllLMDFiles()?.then((res: any) => {
            if (res?.status === 200) {
                setLmdFiles(res?.data);
            }
        });
    }, []);

    const data = [
        /*
        Report generation GIF removed for now.
        {
        //     text: "Report Generation",
        //     altText:
        //         "Automate your report generation with AI, creating compliant documents compared against guidelines for better analytics and strategic decisions.",
        //     url: "https://d3fvaqnlz9wyiv.cloudfront.net/softsensorX/REPORT-GENERATION.gif",
        }, 
         */
        {
            text: "Variance Analysis",
            altText:
                "Identify variances for each parameter by comparing data for different months with AI-driven variance analysis. Gain key insights for maintaining quality control and financial accuracy.",
            url: "https://d3fvaqnlz9wyiv.cloudfront.net/softsensorX/VARIANCE-ANALYSIS.gif",
        },
    ];

    return (
        // <Box
        //     sx={{
        //         display: "flex",
        //         justifyContent: "flex-start",
        //         width: "100%",
        //         height: "100%",
        //         flexDirection: "column",
        //         gap: "20px",
        //         alignItems: "center",
        //         position: "relative",
        //     }}
        // >
        //     <Button sx={{ position: "absolute", left: "20px", top: "20px" }} onClick={() => navigate("/finance")}>
        //         <ArrowBackIos sx={{ transform: "scale(0.6)" }} /> Back{" "}
        //     </Button>
        //     <Box
        //         sx={{
        //             display: "flex",
        //             justifyContent: "center",
        //             width: "100%",
        //             height: "10%",
        //             gap: "20px",
        //             alignItems: "center",
        //             // border: "1px solid red",
        //         }}
        //     >
        //         {/* <Button
        //             disableRipple // Disable ripple effect
        //             sx={{
        //                 px: "18px",
        //                 py: "8px",
        //                 borderRadius: "15px",
        //                 fontSize: "1.2rem",
        //                 color: "#007BFF",
        //                 backgroundColor: tabSelected === "report_generation" ? " #E3F4FF " : "transparent",
        //                 fontWeight: tabSelected === "report_generation" ? "bold" : "normal",
        //                 "&:hover": {
        //                     // Remove or adjust hover effect styles
        //                     backgroundColor: tabSelected === "report_generation" ? " #E3F4FF " : "transparent",
        //                 },
        //             }}
        //             onClick={() => setTabSelected("report_generation")}
        //         >
        //             Report Generation
        //         </Button> */}
        //         <Button
        //             disableRipple // Disable ripple effect
        //             sx={{
        //                 px: "18px",
        //                 py: "8px",
        //                 borderRadius: "15px",
        //                 fontSize: "1.2rem",
        //                 color: "#007BFF",
        //                 backgroundColor: tabSelected === "variance_analysis" ? " #E3F4FF " : "transparent",
        //                 fontWeight: tabSelected === "variance_analysis" ? "bold" : "normal",
        //                 "&:hover": {
        //                     // Remove or adjust hover effect styles
        //                     backgroundColor: tabSelected === "variance_analysis" ? " #E3F4FF " : "transparent",
        //                 },
        //             }}
        //             onClick={() => setTabSelected("variance_analysis")}
        //         >
        //             Variance Analysis
        //         </Button>
        //     </Box>
        //     {banner && !sessionStorage.getItem("report_generation") ? (
        //         <Banner setBanner={setBanner} data={data} />
        //     ) : null}
        //     {tabSelected === "report_generation" ? <Generation /> : <Variance />}
        //     {/* <Generation /> */}
        //     {/* <Divider sx={{ borderColo: "#eee" }} orientation="vertical" /> */}
        //     {/* <Variance /> */}
        // </Box>
        <>
            <Variance />
            {banner && !sessionStorage.getItem("report_generation") ? (
                <Banner setBanner={setBanner} data={data} />
            ) : null}
        </>
    );
};

export default Index;
