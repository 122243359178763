import React from "react";

const BfsiIcon = () => {
    return (
        <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M45.2392 19.0469C45.5519 19.047 45.8615 18.9854 46.1504 18.8658C46.4393 18.7462 46.7019 18.5708 46.923 18.3497C47.1441 18.1286 47.3195 17.8661 47.4391 17.5772C47.5587 17.2882 47.6202 16.9786 47.6202 16.6659V9.52286C47.6204 9.02327 47.4632 8.5363 47.1711 8.13103C46.8789 7.72577 46.4666 7.4228 45.9925 7.2651L24.5635 0.122073C24.0744 -0.0406909 23.5458 -0.0406909 23.0567 0.122073L1.62763 7.2651C1.15358 7.4228 0.741228 7.72577 0.449072 8.13103C0.156916 8.5363 -0.00020508 9.02327 2.00896e-07 9.52286V16.6659C-8.11332e-05 16.9786 0.0614502 17.2882 0.181078 17.5772C0.300706 17.8661 0.476087 18.1286 0.6972 18.3497C0.918313 18.5708 1.18083 18.7462 1.46974 18.8658C1.75865 18.9854 2.06831 19.047 2.38101 19.0469H4.76202V36.1527C3.37326 36.6419 2.16997 37.5489 1.31733 38.7493C0.4647 39.9497 0.00452981 41.3846 2.00896e-07 42.857V47.619C-8.11332e-05 47.9317 0.0614502 48.2413 0.181078 48.5303C0.300706 48.8192 0.476087 49.0817 0.6972 49.3028C0.918313 49.5239 1.18083 49.6993 1.46974 49.8189C1.75865 49.9385 2.06831 50.0001 2.38101 50H45.2392C45.5519 50.0001 45.8615 49.9385 46.1504 49.8189C46.4393 49.6993 46.7019 49.5239 46.923 49.3028C47.1441 49.0817 47.3195 48.8192 47.4391 48.5303C47.5587 48.2413 47.6202 47.9317 47.6202 47.619V42.857C47.6156 41.3846 47.1555 39.9497 46.3028 38.7493C45.4502 37.5489 44.2469 36.6419 42.8581 36.1527V19.0469H45.2392ZM42.8581 45.238H4.76202V42.857C4.76265 42.2257 5.0137 41.6204 5.46009 41.174C5.90648 40.7277 6.51174 40.4766 7.14302 40.476H40.4771C41.1084 40.4766 41.7137 40.7277 42.1601 41.174C42.6065 41.6204 42.8575 42.2257 42.8581 42.857V45.238ZM9.52403 35.714V19.0469H14.286V35.714H9.52403ZM19.0481 35.714V19.0469H28.5721V35.714H19.0481ZM33.3341 35.714V19.0469H38.0961V35.714H33.3341ZM4.76202 14.2849V11.2389L23.8101 4.88871L42.8581 11.2389V14.2849H4.76202Z"
                fill="black"
            />
        </svg>
    );
};

export default BfsiIcon;
