import React from "react";

const CFO = () => {
    return (
        <svg width="55" height="57" viewBox="0 0 55 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.9527 10.3359C13.6433 10.3378 9.33984 14.6424 9.33984 19.9522C9.33984 25.2631 13.6451 29.5684 18.9561 29.5684C24.267 29.5684 28.5723 25.2631 28.5723 19.9522C28.5723 14.6424 24.2689 10.3379 18.9596 10.3359H18.9561H18.9527ZM17.8876 12.5486C14.263 13.0671 11.4768 16.1842 11.4768 19.9522C11.4768 21.1902 11.7776 22.358 12.3101 23.3864L17.8876 19.4024V12.5486ZM20.0246 12.5486V19.9522C20.0246 20.2972 19.858 20.621 19.5772 20.8216L13.5534 25.1243C14.9149 26.5462 16.8321 27.4315 18.9561 27.4315C23.0868 27.4315 26.4354 24.0829 26.4354 19.9522C26.4354 16.1842 23.6491 13.0671 20.0246 12.5486Z"
                fill="black"
            />
            <path
                d="M7.19738 6.06055C6.60726 6.06055 6.12891 6.5389 6.12891 7.12902C6.12891 7.71914 6.60726 8.19749 7.19738 8.19749H22.156C22.7461 8.19749 23.2245 7.71914 23.2245 7.12902C23.2245 6.5389 22.7461 6.06055 22.156 6.06055H7.19738Z"
                fill="black"
            />
            <path
                d="M6.12891 40.254C6.12891 39.6639 6.60726 39.1855 7.19738 39.1855H22.156C22.7461 39.1855 23.2245 39.6639 23.2245 40.254C23.2245 40.8441 22.7461 41.3225 22.156 41.3225H7.19738C6.60726 41.3225 6.12891 40.8441 6.12891 40.254Z"
                fill="black"
            />
            <path
                d="M7.19738 34.9102C6.60726 34.9102 6.12891 35.3885 6.12891 35.9786C6.12891 36.5687 6.60726 37.0471 7.19738 37.0471H31.7722C32.3624 37.0471 32.8407 36.5687 32.8407 35.9786C32.8407 35.3885 32.3624 34.9102 31.7722 34.9102H7.19738Z"
                fill="black"
            />
            <path
                d="M6.12891 31.7052C6.12891 31.1151 6.60726 30.6367 7.19738 30.6367H31.7722C32.3624 30.6367 32.8407 31.1151 32.8407 31.7052C32.8407 32.2953 32.3624 32.7737 31.7722 32.7737H7.19738C6.60726 32.7737 6.12891 32.2953 6.12891 31.7052Z"
                fill="black"
            />
            <path
                d="M33.6015 45.9086C33.1843 45.4914 32.5077 45.4914 32.0905 45.9086L29.9536 48.0456C29.5363 48.4628 29.5363 49.1394 29.9536 49.5566C30.3708 49.9738 31.0473 49.9738 31.4646 49.5566L32.846 48.1752L34.2274 49.5566C34.6447 49.9738 35.3212 49.9738 35.7385 49.5566C36.1557 49.1394 36.1557 48.4628 35.7385 48.0456L33.6015 45.9086Z"
                fill="black"
            />
            <path
                d="M40.6335 45.9086C41.0507 45.4914 41.7273 45.4914 42.1445 45.9086L44.2814 48.0456C44.6987 48.4628 44.6987 49.1394 44.2814 49.5566C43.8642 49.9738 43.1876 49.9738 42.7704 49.5566L41.389 48.1752L40.0076 49.5566C39.5903 49.9738 38.9138 49.9738 38.4965 49.5566C38.0793 49.1394 38.0793 48.4628 38.4965 48.0456L40.6335 45.9086Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.0216 47.7325H6.13142C3.18092 47.7325 0.789062 45.3406 0.789062 42.3901V6.06209C0.789062 3.11159 3.18092 0.719727 6.13142 0.719727H27.8675C29.2844 0.719727 30.6432 1.28258 31.6451 2.28447L35.5523 6.19169C36.5542 7.1936 37.1171 8.55248 37.1171 9.96938V39.1847H44.5964C46.8017 39.1847 48.6951 40.5211 49.5106 42.4281C49.6552 42.4454 49.798 42.4685 49.9388 42.497V39.0023C48.6938 38.5623 47.8018 37.3749 47.8018 35.9793C47.8018 34.209 49.2369 32.7739 51.0072 32.7739C52.7776 32.7739 54.2127 34.209 54.2127 35.9793C54.2127 37.3749 53.3207 38.5623 52.0757 39.0023V43.4582C53.3733 44.4328 54.2127 45.9847 54.2127 47.7325C54.2127 50.4663 52.1593 52.7204 49.5106 53.0369C48.6951 54.9439 46.8017 56.2803 44.5964 56.2803H29.6378C27.4325 56.2803 25.5391 54.9439 24.7236 53.0369C22.0749 52.7204 20.0216 50.4663 20.0216 47.7325ZM2.92601 6.06209C2.92601 4.29179 4.36107 2.85667 6.13142 2.85667H27.8675C28.1055 2.85667 28.3408 2.88314 28.5693 2.93443V8.19903C28.5693 8.78915 29.0477 9.2675 29.6378 9.2675H34.9024C34.9537 9.49605 34.9802 9.73133 34.9802 9.96938V39.1847H29.6378C27.4324 39.1847 25.5391 40.5211 24.7236 42.4281C22.8111 42.6566 21.209 43.8954 20.466 45.5956H6.13142C4.36107 45.5956 2.92601 44.1605 2.92601 42.3901V6.06209ZM30.7063 7.13056V4.36771L33.4691 7.13056H30.7063ZM51.0072 34.9108C50.4171 34.9108 49.9388 35.3892 49.9388 35.9793C49.9388 36.5694 50.4171 37.0478 51.0072 37.0478C51.5974 37.0478 52.0757 36.5694 52.0757 35.9793C52.0757 35.3892 51.5974 34.9108 51.0072 34.9108ZM52.0757 47.7325C52.0757 49.1281 51.1838 50.3155 49.9388 50.7555V44.7095C51.1838 45.1495 52.0757 46.3369 52.0757 47.7325ZM44.5964 41.3217C46.3668 41.3217 47.8018 42.7567 47.8018 44.5271V50.9379C47.8018 52.7083 46.3668 54.1433 44.5964 54.1433H29.6378C27.8675 54.1433 26.4324 52.7083 26.4324 50.9379V44.5271C26.4324 42.7567 27.8675 41.3217 29.6378 41.3217H44.5964ZM24.2954 44.7095C23.0505 45.1495 22.1585 46.3369 22.1585 47.7325C22.1585 49.1281 23.0505 50.3155 24.2954 50.7555V44.7095Z"
                fill="black"
            />
        </svg>
    );
};

export default CFO;
