import React from "react";

const MongoDBColorIcon = () => {
    return (
        <svg width="14" height="32" viewBox="0 0 14 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.7506 12.4415C12.1074 5.17562 8.68266 3.24135 7.80902 1.87402C7.44877 1.27168 7.12964 0.645576 6.85381 0C6.80766 0.645474 6.72287 1.05212 6.17551 1.54161C5.07649 2.5238 0.408853 6.3364 0.0160376 14.592C-0.349946 22.2893 5.66142 27.0357 6.45563 27.5262C7.06632 27.8274 7.81009 27.5327 8.17286 27.2562C11.0707 25.2628 15.03 19.9484 13.7538 12.4415"
                fill="#10AA50"
            />
            <path
                d="M7.10294 23.4277C6.95161 25.333 6.84321 26.4399 6.45898 27.5286C6.45898 27.5286 6.7112 29.3424 6.88829 31.2638H7.51508C7.66459 29.9108 7.89287 28.5677 8.19875 27.2414C7.38736 26.8412 7.13407 25.0995 7.10294 23.4277Z"
                fill="#B8C4C2"
            />
            <path
                d="M8.19741 27.2428C7.37743 26.863 7.14024 25.0847 7.10375 23.428C7.30653 20.6951 7.36564 17.9535 7.28084 15.2144C7.23791 13.7771 7.30123 1.90254 6.92773 0.164062C7.18323 0.752866 7.4776 1.32394 7.80889 1.87349C8.68252 3.2419 12.1084 5.17616 13.7505 12.442C15.0298 19.936 11.092 25.2364 8.19741 27.2428Z"
                fill="#12924F"
            />
        </svg>
    );
};

export default MongoDBColorIcon;
