import React from "react";

const HomeIcon = ({ fill }: { fill?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <mask
                id="mask0_3375_819"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="22"
                height="22"
            >
                <rect width="22" height="22" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3375_819)">
                <path
                    d="M5.50033 17.4167H8.25033V11.9167H13.7503V17.4167H16.5003V9.16667L11.0003 5.04167L5.50033 9.16667V17.4167ZM3.66699 19.25V8.25L11.0003 2.75L18.3337 8.25V19.25H11.917V13.75H10.0837V19.25H3.66699Z"
                    fill="#007BFF"
                />
            </g>
        </svg>
    );
};

export default HomeIcon;
