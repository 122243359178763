import React from "react";

const Search_HighlighterLogo = () => {
    return (
        <svg width="20" height="20" style={{ fill: "#fff" }} viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.4141 4.16772L12.4141 9.66772C12.4141 10.6806 14.2611 11.5011 16.5391 11.5011C18.817 11.5011 20.6641 10.6806 20.6641 9.66772L20.6641 4.16772"
                stroke="#484848"
                stroke-width="1.83333"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.4141 6.91768C12.4141 7.9306 14.2611 8.75102 16.5391 8.75102C18.817 8.75102 20.6641 7.9306 20.6641 6.91768M7.83073 3.25102L5.08073 3.25102C4.5945 3.25102 4.12818 3.44417 3.78437 3.78799C3.44055 4.1318 3.24739 4.59812 3.24739 5.08435L3.24739 7.83435M15.1641 19.751L17.9141 19.751C18.4003 19.751 18.8666 19.5579 19.2104 19.214C19.5542 18.8702 19.7474 18.4039 19.7474 17.9177L19.7474 15.1677M12.4141 4.16768C12.4141 5.1806 14.2611 6.00102 16.5391 6.00102C18.817 6.00102 20.6641 5.1806 20.6641 4.16768C20.6641 3.15477 18.817 2.33435 16.5391 2.33435C14.2611 2.33435 12.4141 3.15477 12.4141 4.16768Z"
                stroke="#484848"
                stroke-width="1.83333"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.45833 16.0843C5.19268 16.0843 4.16667 15.0583 4.16667 13.7926C4.16667 12.527 5.19268 11.501 6.45833 11.501C7.72399 11.501 8.75 12.527 8.75 13.7926C8.75 15.0583 7.72399 16.0843 6.45833 16.0843Z"
                stroke="#484848"
                stroke-width="1.83333"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M2.32812 20.6677L10.5781 20.6677C10.5781 20.126 10.4714 19.5896 10.2641 19.0892C10.0568 18.5887 9.75298 18.134 9.36994 17.7509C8.9869 17.3679 8.53216 17.064 8.03169 16.8567C7.53123 16.6494 6.99483 16.5427 6.45312 16.5427C5.91142 16.5427 5.37502 16.6494 4.87455 16.8567C4.37409 17.064 3.91935 17.3679 3.53631 17.7509C3.15327 18.134 2.84942 18.5887 2.64212 19.0892C2.43482 19.5896 2.32812 20.126 2.32812 20.6677Z"
                stroke="#484848"
                stroke-width="1.83333"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default Search_HighlighterLogo;
