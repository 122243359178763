import { Close } from "@mui/icons-material";
import {
    Box,
    CircularProgress,
    Dialog,
    Divider,
    IconButton,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Api } from "../../../apis";
import { Context } from "../../../context/setting";

const TableContentModal = ({
    handleClose,
    tableModal,
}: {
    handleClose: () => void;
    tableModal: { open: boolean; title: string; id: string };
}) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [tableLoader, setTableLoader] = useState<boolean>(false);
    const [tableContent, setTableContent] = useState<any>({});

    useEffect(() => {
        if (tableModal?.id !== "") {
            fetchTableContent(tableModal?.id);
        }
    }, [tableModal?.title]);

    const fetchTableContent = (file_id: string) => {
        setTableLoader(true);
        Api?.getPdfTableContent({ get_file: file_id })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setTableContent(res?.data);
                } else {
                    setTableContent({});
                }
                setTableLoader(false);
            })
            ?.catch((err: any) => {
                setTableContent({});
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: err?.message || "something went wrong",
                    },
                });
            });
    };

    const dummy = JSON.parse(
        '[{"0":"CARDIO CRP IN mg\\/L ","1":"CARDIOVASCULAR RISK "},{"0":"<1 ","1":"Low "},{"0":"1-3 ","1":"Average "},{"0":"3-10 ","1":"High "},{"0":">10 ","1":"Persistent elevation may represent Non cardiovascular inflammation "}]'
    );

    return (
        <Modal open={tableModal?.open} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box
                sx={{
                    width: "55rem",
                    height: "33rem",
                    background: "#fff",
                    borderRadius: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        py: 1,
                        px: 1.5,
                    }}
                >
                    <Typography variant="subtitle1" textAlign={"center"} width={"100%"} fontWeight={600}>
                        {tableModal?.title}
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
                <Box sx={{ overflowY: "auto", height: "29rem" }}>
                    {tableLoader ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "95%",
                            }}
                        >
                            <CircularProgress sx={{ width: "25px !important", height: "25px !important" }} />
                        </Box>
                    ) : Object?.keys(tableContent)?.length > 0 ? (
                        Object?.keys(tableContent)?.map(
                            (main) =>
                                tableContent[main]?.length > 0 &&
                                tableContent[main]?.map((main1: any) => (
                                    <Box>
                                        <Table stickyHeader sx={{ py: 1, px: 1.5 }}>
                                            <TableHead>
                                                <TableRow>
                                                    {Object?.keys(JSON.parse(main1)[0])?.map((main2) => (
                                                        <TableCell
                                                            sx={{
                                                                background: "#007BFF",
                                                                color: "#fff",
                                                                textAlign: "center",
                                                                border: "1px solid #007BFF",
                                                                "&:first-child": {
                                                                    borderTopLeftRadius: "10px",
                                                                },
                                                                "&:last-child": {
                                                                    borderTopRightRadius: "10px",
                                                                },
                                                            }}
                                                            size="small"
                                                        >
                                                            {main2}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {JSON?.parse(main1)?.map((main2: any) => (
                                                    <TableRow>
                                                        {Object?.keys(main2)?.map((main3) => (
                                                            <TableCell
                                                                sx={{
                                                                    textAlign: "center",
                                                                    border: "1px solid rgb(224 224 224)",
                                                                }}
                                                                size="small"
                                                            >
                                                                {main2[main3]}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <Divider sx={{ my: 2, borderWidth: "2px" }} />
                                    </Box>
                                ))
                        )
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "95%",
                                py: 1,
                                px: 1.5,
                            }}
                        >
                            <Typography>No Tables Found</Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default TableContentModal;
