import { ArrowBackIos } from "@mui/icons-material";
import {
    Box,
    IconButton,
    Typography,
    Divider,
    Tooltip,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import RedirectIcon from "../../../components/Common/svgLogs/RedirectIcon";
import Bounce from "../../../components/Loaders/Bounce";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../context/setting";
import { Api } from "../../../apis";

const References = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [pdfName, setPdfName] = useState<string>("");
    const [filteredFiles, setFilteredFiles] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [referenceData, setReferenceData] = useState<any[]>([]);

    useEffect(() => {
        if (contextStore?.claims?.reference) {
            setPdfName(contextStore?.claims?.reference?.pdf_name);
        }
    }, [contextStore?.claims?.reference]);

    useEffect(() => {
        if (contextStore?.claims?.state?.filteredFiles) {
            setFilteredFiles(contextStore?.claims?.state?.filteredFiles);
            const filterDocs = contextStore?.claims?.state?.filteredFiles?.reduce(
                (filter: any, item: any) => {
                    filter?.pdf_ids?.push(item?.pdf_id);
                    return filter;
                },
                { pdf_ids: [], filter_pdf_id: contextStore?.claims?.reference?.pdf_id }
            );
            handleReferences(filterDocs);
        }
    }, [contextStore?.claims?.state?.filteredFiles]);

    const handleReferences = (data: any) => {
        setLoader(true);
        Api?.getClaimsExistingProjectsPdfReference(data)
            ?.then((res: any) => {
                if (res?.status == 200) {
                    setReferenceData(JSON?.parse(res?.data));
                } else {
                    setReferenceData([]);
                }
                setLoader(false);
            })
            ?.catch((err) => {
                setLoader(false);
                setReferenceData([]);
            });
    };

    return (
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", py: 3, gap: 3 }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        background: "linear-gradient(to right, #fff, #E3F4FF)",
                        width: "75%",
                        borderRadius: "19px",
                        px: 2,
                        py: 0.5,
                        border: "1px solid #E2E2E2",
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        <IconButton onClick={() => navigate(contextStore?.claims?.state?.prevRoute || "/claims")}>
                            <ArrowBackIos sx={{ fontSize: "0.8rem" }} />
                        </IconButton>
                        <Typography sx={{ color: "#007BFF", fontSize: "0.8rem" }}>Back</Typography>
                    </Box>
                    <Typography sx={{ color: "#007BFF", fontSize: "0.9rem" }}>{pdfName}</Typography>
                </Box>
                <Box
                    sx={{
                        width: "75%",
                        borderRadius: "13px",
                        px: 2,
                        border: "1px solid #E2E2E2",
                        position: "relative",
                    }}
                >
                    <Typography
                        sx={{
                            color: "#007BFF",
                            py: 1.5,
                            position: "sticky",
                            top: "0px",
                            background: "#fff",
                        }}
                    >
                        Reference
                    </Typography>
                    {loader ? (
                        <Box sx={{ height: "15rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Bounce />
                        </Box>
                    ) : (
                        <Box sx={{ height: "70vh", overflowY: "scroll", pb: 1 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow sx={{}}>
                                        <TableCell
                                            sx={{
                                                border: "1px solid #D9D9D9",
                                                textAlign: "center",
                                                borderTopLeftRadius: "6px",
                                            }}
                                        >
                                            Reference
                                        </TableCell>
                                        <TableCell sx={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                            Starting date
                                        </TableCell>
                                        <TableCell sx={{ border: "1px solid #D9D9D9", textAlign: "center" }}>
                                            Planned Ending date
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                border: "1px solid #D9D9D9",
                                                textAlign: "center",
                                                borderTopRightRadius: "6px",
                                            }}
                                        >
                                            Documents Mentioned in Details
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {referenceData?.map((item: any) => (
                                        <TableRow>
                                            <TableCell
                                                sx={{ border: "1px solid #D9D9D9", borderBottomLeftRadius: "6px" }}
                                            >
                                                {item["Reference"]}
                                            </TableCell>
                                            <TableCell sx={{ border: "1px solid #D9D9D9" }}>
                                                {item["Starting Date"]}
                                            </TableCell>
                                            <TableCell sx={{ border: "1px solid #D9D9D9" }}>
                                                {item["Planned Ending Date"]}
                                            </TableCell>
                                            <TableCell
                                                sx={{ border: "1px solid #D9D9D9", borderBottomRightRadius: "6px" }}
                                            >
                                                {item["Documents Mentioned in"]}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    )}
                    {/* <Typography
                        sx={{
                            color: "#007BFF",
                            py: 1.5,
                            position: "sticky",
                            top: "0px",
                            background: "#fff",
                        }}
                    >
                        Problem Statement
                    </Typography>
                    {loader ? (
                        <Box sx={{ height: "15rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Bounce />
                        </Box>
                    ) : (
                        <Typography sx={{ fontSize: "0.96rem", fontFamily: "Outfit" }}>
                            {problemStatement?.split("\n")?.map((msg) => (
                                <>
                                    {msg} <br />
                                </>
                            ))}
                        </Typography>
                    )} */}
                </Box>
            </Box>
            {filteredFiles?.length > 0 && (
                <>
                    <Box
                        sx={{
                            width: "16px",
                            height: "100%",
                            background: "#E3F4FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            borderWidth: "0px 2px 0px 2px",
                            borderStyle: "solid",
                            borderColor: "#E3F4FF",
                        }}
                    >
                        <Box
                            sx={{
                                width: "3px",
                                height: "15px",
                                background: "#0000001C",
                                borderRadius: "10px",
                            }}
                        ></Box>
                        <Box
                            sx={{
                                width: "3px",
                                height: "15px",
                                background: "#0000001C",
                                borderRadius: "10px",
                            }}
                        ></Box>
                    </Box>
                    <Box sx={{ width: "20rem", display: "felx", flexDirection: "column", gap: 1 }}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 1 }}>
                            <Typography>Claim Docs ({filteredFiles?.length})</Typography>
                        </Box>
                        <Divider sx={{ borderColor: "#F3F3F4" }} />
                        <Box sx={{ p: 1 }}>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
                                <Typography sx={{ color: "#6B7582", fontSize: "0.9rem" }}>
                                    {filteredFiles?.length} Documents
                                </Typography>
                            </Box>
                            {filteredFiles?.map((pdf) => (
                                <Box
                                    sx={{
                                        p: 1,
                                        pb: 1.5,
                                        cursor: "pointer",
                                        transition: "200ms ease-out",
                                        borderRadius: "6px",
                                        "&:hover": {
                                            boxShadow: "0px 2px 4px 1px #00000010",
                                            px: 2,
                                        },
                                        my: 0.4,
                                    }}
                                    onClick={() => {
                                        setPdfName(pdf?.pdf_name);
                                        const filterDocs = filteredFiles?.reduce(
                                            (filter: any, item: any) => {
                                                filter?.pdf_ids?.push(item?.pdf_id);
                                                return filter;
                                            },
                                            { pdf_ids: [], filter_pdf_id: pdf?.pdf_id }
                                        );
                                        handleReferences(filterDocs);
                                    }}
                                >
                                    <Tooltip title={pdf?.pdf_name || pdf?.name}>
                                        <Typography
                                            noWrap
                                            sx={{
                                                fontSize: "0.86rem",
                                                color:
                                                    pdfName === pdf?.pdf_name || pdfName === pdf?.name
                                                        ? "#007BFF"
                                                        : "inherit",
                                            }}
                                        >
                                            {pdf?.pdf_name || pdf?.name}
                                        </Typography>
                                    </Tooltip>
                                    <Divider sx={{ borderColor: "#F3F3F4", pt: 0.1 }} />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default References;
