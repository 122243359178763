import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { useContext, useState } from "react";
import UploadBoxIcon from "../../components/Common/svgLogs/UploadBoxIcon";
import { Api } from "../../apis";
import { Context } from "../../context/setting";
import DocumentLoadingModal from "../Chat/DocumentLoading/DocumentLoadingModal";
import Car1 from "../../components/Common/svgLogs/Damage_Detection/Car1";
import Car2 from "../../components/Common/svgLogs/Damage_Detection/Car2";
import Car3 from "../../components/Common/svgLogs/Damage_Detection/Car3";
import Car4 from "../../components/Common/svgLogs/Damage_Detection/Car4";
import Car5 from "../../components/Common/svgLogs/Damage_Detection/Car5";
import Car6 from "../../components/Common/svgLogs/Damage_Detection/Car6";
import Car7 from "../../components/Common/svgLogs/Damage_Detection/Car7";
import Car8 from "../../components/Common/svgLogs/Damage_Detection/Car8";
import Car9 from "../../components/Common/svgLogs/Damage_Detection/Car9";
import Car10 from "../../components/Common/svgLogs/Damage_Detection/Car10";
import Car11 from "../../components/Common/svgLogs/Damage_Detection/Car11";
import Car12 from "../../components/Common/svgLogs/Damage_Detection/Car12";

const ImageUploadBox = ({ setIsImageUploaded, setImageBytes, selectedFiles, setSelectedFiles }: any) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [loading, setLoading] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);
    const data = [
        { icon: <Car1 />, index: 0 },
        { icon: <Car2 />, index: 1 },
        { icon: <Car3 />, index: 2 },
        { icon: <Car4 />, index: 3 },
        { icon: <Car5 />, index: 4 },
        { icon: <Car6 />, index: 5 },
        { icon: <Car7 />, index: 6 },
        { icon: <Car8 />, index: 7 },
        { icon: <Car9 />, index: 8 },
        { icon: <Car10 />, index: 9 },
        { icon: <Car11 />, index: 10 },
        { icon: <Car12 />, index: 11 },
    ];
    const uploadFiles = () => {
        if (selectedFiles.length === 0) {
            setContext({
                ...contextStore,
                snackState: {
                    open: true,
                    errorType: "error",
                    message: "Please select atleast one image, then try again",
                },
            });
        } else {
            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                formData?.append("files", selectedFiles[i]);
            }

            setLoading(true);
            Api?.uploadDamageDetectionFiles(formData)?.then((res: any) => {
                if (res?.status === 200) {
                    setImageBytes(res?.data);
                    setIsImageUploaded(true);
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Server issue! Please try after some time",
                        },
                    });
                }
                setLoading(false);
            });
        }
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    width: "70%",
                    height: "80%",
                    border: "1px solid #E0E0E0",
                    borderRadius: "19px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "70%",
                        height: "14%",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6" sx={{ marginTop: "1.75%" }}>
                        Auto AI Inspection
                    </Typography>
                    <Typography sx={{ color: "#484848" }}>
                        Upload vehicle photos/videos from various angles for analysis
                    </Typography>
                </Box>
                <Box sx={{ width: "80%", mt: "3%" }}>
                    <Typography>Current data source in use</Typography>
                    <Select
                        value={"image"}
                        size="small"
                        sx={{
                            width: "8rem",
                            height: "2rem",
                            borderRadius: "10px",
                            borderColor: "#EBEBEB",
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#EBEBEB",
                            },
                            fontSize: "0.87rem",
                        }}
                    >
                        <MenuItem value="image">Image</MenuItem>
                        <MenuItem value="video" disabled>
                            Video
                        </MenuItem>
                    </Select>
                </Box>
                <Box sx={{ width: "80%", display: "flex", justifyContent: "space-between", mt: "1%" }}>
                    {step > 0 ? (
                        <Button
                            sx={{ color: "#007BFF", fontWeight: 600, fontSize: "1rem" }}
                            onClick={() => setStep(step - 1)}
                        >
                            Previous
                        </Button>
                    ) : (
                        <Button sx={{ width: "1px", height: "1px" }}></Button>
                    )}

                    {step < 11 ? (
                        <Button
                            sx={{ color: "#007BFF", fontWeight: 600, fontSize: "1rem" }}
                            onClick={() => setStep((step + 1) % 12)}
                        >
                            Next
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            sx={{
                                background: "#007BFF",
                                width: "12rem",
                                height: "2.3rem",
                                borderRadius: "10px",
                                "&:hover": {
                                    boxShadow: "0px -1px 6px 4px #d4d4d4",
                                },
                            }}
                            onClick={uploadFiles}
                        >
                            Continue
                        </Button>
                    )}
                </Box>
                <Box
                    sx={{
                        width: "80%",
                        height: "50%",
                        display: "flex",
                        border: "1.5px dashed #007BFF",
                        mt: "1%",
                        borderRadius: "6px",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                    onDrop={(e: any) => {
                        e.preventDefault();
                        setSelectedFiles([...selectedFiles, e?.target?.files?.[0]]);
                        setStep((step + 1) % 12);
                    }}
                    onDragOver={(e: any) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <Box
                        sx={{
                            height: "100%",
                        }}
                        component={"label"}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <>{data[step]?.icon}</>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 1,
                                    mt: "10%",
                                }}
                            >
                                <Box sx={{ transform: "scale(0.8)" }}>
                                    <UploadBoxIcon />
                                </Box>
                                {selectedFiles[step] !== undefined ? (
                                    selectedFiles?.[step]?.name
                                ) : (
                                    <Typography variant="h6">
                                        Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                                    </Typography>
                                )}
                            </Box>

                            <input
                                hidden
                                type="file"
                                accept=".jpg, .png, .jpeg"
                                onChange={(e: { target: { files: any; value: any } }) => {
                                    setSelectedFiles([...selectedFiles, e?.target?.files?.[0]]);
                                    if (step !== 11) {
                                        setStep(step + 1);
                                    }
                                    e.target.value = "";
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", mt: "2%", justifyContent: "center" }}>
                    {data?.map((value: any, index: number) => {
                        return (
                            <Button
                                onClick={() => setStep(index)}
                                sx={{ background: step === index ? "#3386D3" : "#F3F3F3", mr: "1%", maxWidth: "30px" }}
                            ></Button>
                        );
                    })}
                </Box>
            </Box>
            <DocumentLoadingModal loading={loading} />
        </Box>
    );
};

export default ImageUploadBox;
