// Generating All Constants for Sagas and Reducers.

const default_reducers = ["load", "success", "error", "unload", "set"];
const default_reducer_prefix = "REDUCERS";
const default_saga_prefix = "SAGAS";

function generateConsts(type: string): any {
  switch (type) {
    case "auth":
      return {
        reducers: default_reducers,
        actions: ["login", 'logout'],
        name: "AUTH",
      };
  
    case "some_other":
      return {
        reducers: default_reducers,
        actions: ["some_action"],
        name: "SOME_ACTION",
      };
  }
}

export type ConstantsType = {
  sagas: any;
  reducers: any;
};

export default function constants(type: string): ConstantsType {
  const consts: ConstantsType = {
    reducers: {},
    sagas: {},
  };
  const genConsts = generateConsts(type);

  for (const i in genConsts.actions) {
    if (!consts.sagas[genConsts.actions[i]]) {
      consts.sagas[genConsts.actions[i]] = {};
    }
    consts.sagas[genConsts.actions[i]] = `${type}/${default_saga_prefix}/${genConsts.actions[i]}`;
    for (const j in genConsts.reducers) {
      if (!consts.reducers[genConsts.actions[i]]) {
        consts.reducers[genConsts.actions[i]] = {};
      }
      consts.reducers[genConsts.actions[i]][genConsts.reducers[j]] = `${type}/${default_reducer_prefix}/${genConsts.actions[i]}/${genConsts.reducers[j]}`;
    }
  }

  return consts;
}
