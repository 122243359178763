import React from "react";

const SummarizeIcon = () => {
    return (
        <svg
        style={{ width: "1.063rem", height: "1.125rem" }}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_339_293"
                style={{ maskType: "alpha", width: "1.375rem", height: "1.375rem" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
            >
                <rect x="0.854492" y="0.853516" width="15.2915" height="15.2915" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_339_293)">
                <path
                    d="M5.95135 6.5885C6.13188 6.5885 6.2832 6.52744 6.40532 6.40532C6.52744 6.2832 6.5885 6.13188 6.5885 5.95135C6.5885 5.77083 6.52744 5.61951 6.40532 5.49739C6.2832 5.37527 6.13188 5.31421 5.95135 5.31421C5.77083 5.31421 5.61951 5.37527 5.49739 5.49739C5.37527 5.61951 5.31421 5.77083 5.31421 5.95135C5.31421 6.13188 5.37527 6.2832 5.49739 6.40532C5.61951 6.52744 5.77083 6.5885 5.95135 6.5885ZM5.95135 9.13708C6.13188 9.13708 6.2832 9.07603 6.40532 8.95391C6.52744 8.83179 6.5885 8.68046 6.5885 8.49994C6.5885 8.31941 6.52744 8.16809 6.40532 8.04597C6.2832 7.92385 6.13188 7.86279 5.95135 7.86279C5.77083 7.86279 5.61951 7.92385 5.49739 8.04597C5.37527 8.16809 5.31421 8.31941 5.31421 8.49994C5.31421 8.68046 5.37527 8.83179 5.49739 8.95391C5.61951 9.07603 5.77083 9.13708 5.95135 9.13708ZM5.95135 11.6857C6.13188 11.6857 6.2832 11.6246 6.40532 11.5025C6.52744 11.3804 6.5885 11.229 6.5885 11.0485C6.5885 10.868 6.52744 10.7167 6.40532 10.5946C6.2832 10.4724 6.13188 10.4114 5.95135 10.4114C5.77083 10.4114 5.61951 10.4724 5.49739 10.5946C5.37527 10.7167 5.31421 10.868 5.31421 11.0485C5.31421 11.229 5.37527 11.3804 5.49739 11.5025C5.61951 11.6246 5.77083 11.6857 5.95135 11.6857ZM4.03992 14.2343C3.68949 14.2343 3.3895 14.1095 3.13995 13.8599C2.8904 13.6104 2.76562 13.3104 2.76562 12.96V4.03992C2.76562 3.68949 2.8904 3.3895 3.13995 3.13995C3.3895 2.8904 3.68949 2.76562 4.03992 2.76562H11.0485L14.2343 5.95135V12.96C14.2343 13.3104 14.1095 13.6104 13.8599 13.8599C13.6104 14.1095 13.3104 14.2343 12.96 14.2343H4.03992ZM4.03992 12.96H12.96V6.5885H10.4114V4.03992H4.03992V12.96Z"
                    fill="#484848"
                />
            </g>
        </svg>
    );
};

export default SummarizeIcon;
