import { Box, Button, Checkbox, MenuItem, Select, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

import React, { useState } from "react";

interface CheckboxItem {
    label: string;
    id: string;
}

const checkboxItems: CheckboxItem[] = [
    { label: "Normal RunQuery", id: "Normal_RunQuery" },
    { label: "Detailed Answer", id: "Detailed_Answer" },
    { label: "RAG Fusion (Thumbs Down)", id: "RAG_Fusion" },
    { label: "Summary", id: "Summary" },
    { label: "Image_Generation", id: "Image_Generation" },
    { label: "ESG - English", id: "ESG_English" },
    { label: "ESG - Multilingual", id: "ESG_Multilingual" },
    { label: "Graph - QA", id: "Graph_QA" },
    { label: "Compare and Contrast", id: "Compare_Contrast" },
];

const Ingestion = () => {
    const [noOfPages, setNoOfPages] = useState(0);
    const [value, setValue] = useState(0);
    const [infrastructure_Instance, setInfrastructure_Instance] = useState("aws");
    const [time_reader, setTime_Reader] = useState("textract");
    const [total_Cost, setTotalCost] = useState(0.0);
    const [includeTableCost, setIncludeTableCost] = useState(false);
    const [includeSummaryEmbeddings, setIncludeSummaryEmbeddings] = useState(false);

    const textractCosts = {
        textract_without_table: 0.0015,
        textract_with_table: 0.015,
    };

    const awsInstanceCosts = {
        cpu: 0.25,
        gpu: 0.5,
    };

    const propulsionInstanceCosts = {
        cpu: 0.468,
        gpu: 3.96,
    };

    const visionCostPerTable = 0.026;
    const titanTextEmbeddings = 0.0001;
    const textractCostPerTable = 0.035;
    const costPerGbS3 = 0.023;
    const avgReportSizeInGb = 0.0005;
    const mongoCostPerReport = 0.019;
    const pineconeCostPerReport = 0.026;
    const tokensPerDocumentPage = 700;
    const avgTablesPerPage = 0.3;
    const averageReportsHourly = 0.5;
    let embeddingsCost;

    const calculateCosts = (
        pages: any,
        includeTableCosts = true,
        includeSummaryEmbeddings = true,
        instance = "aws",
        tableReader = "textract"
    ) => {
        const tokens = tokensPerDocumentPage * pages;
        const tables = avgTablesPerPage * pages;
        let tableCost = 0;
        let instanceCost = 0;

        if (includeTableCosts) {
            if (tableReader === "textract") {
                tableCost = textractCosts["textract_with_table"] * pages + textractCostPerTable * tables;
            } else if (tableReader === "vision") {
                tableCost = visionCostPerTable * tables;
            }
        } else {
            if (tableReader === "textract") {
                tableCost = textractCosts["textract_without_table"] * pages;
            }
        }

        if (includeSummaryEmbeddings) {
            instanceCost =
                (instance === "aws" ? awsInstanceCosts["gpu"] : propulsionInstanceCosts["gpu"]) / averageReportsHourly;
            embeddingsCost = (titanTextEmbeddings * tokens) / 3000;
        } else {
            instanceCost =
                (instance === "aws" ? awsInstanceCosts["cpu"] : propulsionInstanceCosts["cpu"]) / averageReportsHourly;
            embeddingsCost = (titanTextEmbeddings * tokens) / 1000;
        }

        const storageCost = costPerGbS3 * avgReportSizeInGb;
        const totalCost =
            tableCost + instanceCost + storageCost + embeddingsCost + mongoCostPerReport + pineconeCostPerReport;

        console.log("=".repeat(50));
        console.log(`Table Cost: $${tableCost}`);
        console.log(`Parsing Cost: $${instanceCost}`);
        console.log(`Storage Cost: $${storageCost}`);
        console.log(`Embeddings Cost: $${embeddingsCost}`);
        console.log(`Mongo Cost: $${mongoCostPerReport}`);
        console.log(`Pinecone Cost: $${pineconeCostPerReport}`);
        console.log("=".repeat(50));
        console.log(`Total Cost: $${totalCost}`);
        setTotalCost(Number(totalCost.toFixed(2)));
    };

    return (
        <Box sx={{ width: "100%", border: "1px solid #D4D4D4", height: "75vh" }}>
            <Box
                sx={{
                    width: "100%",
                    // border: "1px solid red",
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // paddingY: "20px",
                }}
            >
                <Box
                    sx={{
                        width: "50%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "50%",
                            height: "100%",
                            borderRight: "1px solid #D4D4D4",
                            paddingLeft: "15px",
                        }}
                    >
                        <Typography
                            sx={{ fontFamily: "Gilroy-Medium", fontSize: "1rem", marginTop: "15px", color: "#424F65" }}
                        >
                            No. Of Pages
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                // border: "1px solid red",
                                marginTop: "10px",
                                width: "100%",
                            }}
                        >
                            <TextField
                                type="number"
                                value={noOfPages}
                                onChange={(e) =>
                                    setNoOfPages(Math.min(Math.max(parseInt(e.target.value) || 0, 0), 100))
                                }
                                inputProps={{ min: 0, max: 100 }}
                                style={{ width: "180px" }} // Set the width using style prop
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                // border: "1px solid red",
                                marginLeft: "-10px",
                                marginTop: "15px",
                            }}
                        >
                            <Checkbox
                                name="Include Table Cost"
                                onChange={(event) => setIncludeTableCost(event.target.checked)}
                            />
                            <Typography sx={{ fontFamily: "Gilroy-Medium", fontSize: "1rem" }}>
                                Include Table Cost
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginLeft: "-10px",
                                marginTop: "5px",
                            }}
                        >
                            <Checkbox
                                name="Include Summary Embeddings"
                                onChange={(event) => setIncludeSummaryEmbeddings(event.target.checked)}
                            />
                            <Typography sx={{ fontFamily: "Gilroy-Medium", fontSize: "1rem" }}>
                                Include Summary Embeddings
                            </Typography>
                        </Box>
                        <Box sx={{ marginTop: "20px" }}>
                            <Typography
                                sx={{
                                    fontFamily: "Gilroy-Medium",
                                    fontSize: "1rem",
                                    marginBottom: "10px",
                                    color: "#424F65",
                                }}
                            >
                                Infrastructure Instance
                            </Typography>
                            <Select
                                value={infrastructure_Instance}
                                size="small"
                                onChange={(e: { target: { value: string } }) =>
                                    setInfrastructure_Instance(() => e?.target?.value)
                                }
                                sx={{
                                    width: "10rem",
                                    height: "3rem",
                                    borderRadius: "10px",
                                    borderColor: "#EBEBEB",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#7d7d7d",
                                    },
                                    fontSize: "0.87rem",
                                }}
                            >
                                {[
                                    { title: "AWS", value: "aws" },
                                    { title: "Propulsion", value: "propulsion" },
                                ]?.map((type) => <MenuItem value={type?.value}>{type?.title}</MenuItem>)}
                            </Select>
                        </Box>

                        <Box sx={{ marginTop: "12px" }}>
                            <Typography
                                sx={{
                                    fontFamily: "Gilroy-Medium",
                                    fontSize: "1rem",
                                    marginBottom: "10px",
                                    color: "#424F65",
                                }}
                            >
                                Time Reader
                            </Typography>
                            <Select
                                value={time_reader}
                                size="small"
                                onChange={(e: { target: { value: string } }) => setTime_Reader(() => e?.target?.value)}
                                sx={{
                                    width: "10rem",
                                    height: "3rem",
                                    borderRadius: "10px",
                                    borderColor: "#EBEBEB",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#7d7d7d",
                                    },
                                    fontSize: "0.87rem",
                                }}
                            >
                                {[
                                    { title: "Textract", value: "textract" },
                                    { title: "Vision", value: "vision" },
                                ]?.map((type) => <MenuItem value={type?.value}>{type?.title}</MenuItem>)}
                            </Select>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: "50%",
                        // border: "1px solid black",
                        height: "90%",
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "20px",
                        // marginTop: "20px",
                    }}
                >
                    <Box
                        sx={{
                            width: "350px",
                            height: "100px",
                            // border: "1px solid red",
                            backgroundColor: "#F7F7F7",
                            boxShadow: "0px 5px 5px 0px #D4D4D4",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box
                            sx={{
                                width: "40%",
                                // border: "1px solid red",
                                height: "100%",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "space-evenly",
                                flexDirection: "column",
                                paddingLeft: "10px",
                            }}
                        >
                            <Typography sx={{ fontFamily: "Gilroy-Medium", fontSize: "1rem", color: "#515151" }}>
                                Total Cost
                            </Typography>
                            <Typography sx={{ fontFamily: "Gilroy-Medium", fontSize: "1.5rem", color: "#515151" }}>
                                $ {total_Cost}
                            </Typography>
                            <Typography sx={{ fontFamily: "Gilroy-Medium", fontSize: "1rem", color: "#515151" }}>
                                or {(total_Cost * 85).toFixed(2)} Ruppes
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: "60%",
                                // border: "1px solid red",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    calculateCosts(
                                        noOfPages,
                                        includeTableCost,
                                        includeSummaryEmbeddings,
                                        infrastructure_Instance,
                                        time_reader
                                    );
                                }}
                                variant="contained"
                            >
                                Calculate Cost
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Ingestion;
