import { Close } from "@mui/icons-material";
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/setting";
import { useNavigate } from "react-router-dom";
import { Api } from "../../../apis";
import Bounce from "../../../components/Loaders/Bounce";
import Relevencies from "./Relevencies";

const Tables = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [tabSelect, setTabSelect] = useState<"chronology" | "clauses_result" | "de_period_result">("chronology");
    const [loader, setLoader] = useState<boolean>(false);
    const [tableData, setTableData] = useState<any>([]);
    const [filters, setFilters] = useState<any>({
        package: "C4",
        contractor: "L&T",
        eot: "EOT-3",
        party: "",
        delayEvent: "",
        responseTime: "",
    });
    const [filterStatus, setFilterStatus] = useState<boolean>(false);

    const delay_event = [
        "",
        "DE-01",
        "DE-1A",
        "DE-02",
        "DE-03",
        "DE-04",
        "DE-05",
        "DE-06",
        "DE-07",
        "DE-08",
        "DE-09",
        "DE-10",
        "DE-11",
        "DE-12",
        "DE-13",
        "DE-14",
        "DE-15",
        "DE-16",
        "DE-17",
        "DE-18",
        "DE-19",
        "DE-20",
        "DE-21",
        "DE-22",
        "DE-22A",
        "DE-23",
        "DE-24",
        "DE-24A",
        "DE-24B",
        "DE-25",
        "DE-26",
        "DE-27",
        "DE-28",
        "DE-29",
        "DE-30",
        "DE-31",
        "DE-32",
        "DE-33",
        "DE-34",
        "DE-35",
        "DE-36",
        "DE-37",
        "DE-38",
        "DE-39",
        "DE-40",
        "DE-41",
        "DE-42",
        "DE-43",
        "DE-44",
        "DE-45",
        "DE-46",
        "DE-47",
        "DE-48",
        "DE-49",
        "DE-50",
        "DE-51",
        "DE-52",
        "DE-53",
        "DE-54",
        "DE-55",
        "DE-56",
        "DE-57",
        "DE-58",
        "DE-59",
        "DE-60",
        "DE-61",
        "DE-62",
        "DE-63",
    ];

    const handleFilter = () => {
        setLoader(true);
        setFilterStatus(true);
        setTableData([]);
        let filterPayload = {};
        if (tabSelect === "chronology")
            filterPayload = {
                table_name: tabSelect,
                filter_dict: {
                    ...(filters?.party ? { Party: filters?.party } : {}),
                    ...(filters?.delayEvent ? { "Delay Event": filters?.delayEvent } : {}),
                    ...(filters?.responseTime ? { "Response Time": filters?.responseTime } : {}),
                },
            };
        if (tabSelect === "de_period_result")
            filterPayload = {
                table_name: tabSelect,
                filter_dict: {
                    ...(filters?.delayEvent ? { "Delay Event": filters?.delayEvent } : {}),
                },
            };
        if (tabSelect === "clauses_result")
            filterPayload = {
                table_name: tabSelect,
                filter_dict: {},
            };
        Api?.getClaimsExistingDelayEvent({ user_id: contextStore?.profile?.userId, ...filterPayload })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    if (tabSelect === "chronology") setTableData(JSON?.parse(res?.data?.chronology));
                    if (tabSelect === "de_period_result") setTableData(JSON?.parse(res?.data?.de_period_result));
                    if (tabSelect === "clauses_result") setTableData(JSON?.parse(res?.data?.clauses_result));
                } else {
                    setTableData([]);
                }
                setLoader(false);
            })
            ?.catch((err) => setLoader(false));
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", p: 2, background: "#FEFDFD", flex: 1 }}>
            <Box
                sx={{
                    width: "78vw",
                    background: "#fff",
                    border: "1px solid #E0E0E0",
                    borderRadius: "7px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    pt: 2,
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 2 }}>
                    <Typography sx={{ color: "#007BFF" }}>Information Table</Typography>
                    <IconButton onClick={() => navigate(contextStore?.claims?.state?.prevRoute || "/claims")}>
                        <Close sx={{ fontSize: "0.8rem", color: "#007BFF" }} />
                    </IconButton>
                </Box>
                <Box sx={{ px: 2 }}>
                    <Tabs
                        value={tabSelect}
                        onChange={(e: any, value: "chronology" | "clauses_result" | "de_period_result") => {
                            setTableData([]);
                            setFilterStatus(false);
                            setTabSelect(value);
                        }}
                    >
                        <Tab
                            sx={{ minWidth: "33%", p: 0.3, textTransform: "capitalize" }}
                            label={"Chronology (as per the Contract)"}
                            value={"chronology"}
                        />
                        <Tab
                            sx={{ minWidth: "33%", p: 0.3, textTransform: "capitalize" }}
                            label={"Delay Period Claimed"}
                            value={"de_period_result"}
                        />
                        <Tab
                            sx={{ minWidth: "33%", p: 0.3, textTransform: "capitalize" }}
                            label={"Clauses Refered by Contractor"}
                            value={"clauses_result"}
                        />
                    </Tabs>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 1.5,
                        flexWrap: "wrap",
                        pt: 1.5,
                        px: 2,
                    }}
                >
                    <FormControl>
                        <InputLabel>Package</InputLabel>
                        <Select
                            value={filters?.package}
                            onChange={(e: { target: { value: string } }) =>
                                setFilters((prev: any) => ({ ...prev, package: e?.target?.value }))
                            }
                            label={"Package"}
                            size="small"
                            sx={{ minWidth: "8rem" }}
                        >
                            <MenuItem value={"C4"}>C4</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel>Contractor</InputLabel>
                        <Select
                            value={filters?.contractor}
                            onChange={(e: { target: { value: string } }) =>
                                setFilters((prev: any) => ({ ...prev, contractor: e?.target?.value }))
                            }
                            size="small"
                            label={"Contractor"}
                            sx={{ minWidth: "8rem" }}
                        >
                            <MenuItem value={"L&T"}>L&T</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel>EOT</InputLabel>
                        <Select
                            value={filters?.eot}
                            onChange={(e: { target: { value: string } }) =>
                                setFilters((prev: any) => ({ ...prev, eot: e?.target?.value }))
                            }
                            size="small"
                            label="EOT"
                            sx={{ minWidth: "8rem" }}
                        >
                            <MenuItem value={"EOT-3"}>EOT-3</MenuItem>
                        </Select>
                    </FormControl>
                    {["chronology", "de_period_result"]?.includes(tabSelect) && (
                        <FormControl>
                            <InputLabel>Delay Event</InputLabel>
                            <Select
                                value={filters?.delayEvent}
                                onChange={(e: { target: { value: string } }) =>
                                    setFilters((prev: any) => ({ ...prev, delayEvent: e?.target?.value }))
                                }
                                size="small"
                                label="Delay Event"
                                sx={{ minWidth: "8rem" }}
                            >
                                {delay_event?.map((item) =>
                                    item === "" ? (
                                        <MenuItem value={""} sx={{ fontStyle: "italic" }}>
                                            N/A
                                        </MenuItem>
                                    ) : (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    )}
                    {["chronology"]?.includes(tabSelect) && (
                        <>
                            <FormControl>
                                <InputLabel>Party</InputLabel>
                                <Select
                                    value={filters?.party}
                                    onChange={(e: { target: { value: string } }) =>
                                        setFilters((prev: any) => ({ ...prev, party: e?.target?.value }))
                                    }
                                    size="small"
                                    label="Party"
                                    sx={{ minWidth: "8rem" }}
                                >
                                    {["", "NHSRCL", "LTC", "TCAP"]?.map((item) =>
                                        item === "" ? (
                                            <MenuItem value={""} sx={{ fontStyle: "italic" }}>
                                                N/A
                                            </MenuItem>
                                        ) : (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel>Response Time</InputLabel>
                                <Select
                                    value={filters?.responseTime}
                                    onChange={(e: { target: { value: string } }) =>
                                        setFilters((prev: any) => ({ ...prev, responseTime: e?.target?.value }))
                                    }
                                    size="small"
                                    label="Response Time"
                                    sx={{ minWidth: "8rem" }}
                                >
                                    {["", ">=21", "<21"]?.map((item) =>
                                        item === "" ? (
                                            <MenuItem value={""} sx={{ fontStyle: "italic" }}>
                                                N/A
                                            </MenuItem>
                                        ) : (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </>
                    )}
                    <Button
                        sx={{
                            minWidth: "8rem",
                            height: "2.5rem",
                            background: "#007BFF",
                            color: "#fff",
                            transition: "ease-in-out 80ms",
                            letterSpacing: "0.5px",
                            "&:hover": {
                                background: "#007BFFaa",
                                color: "#fff",
                                fontWeight: 600,
                                letterSpacing: "3px",
                            },
                        }}
                        onClick={handleFilter}
                        disabled={loader}
                    >
                        Filter
                    </Button>
                </Box>
                {loader ? (
                    <Box sx={{ height: "20rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Bounce />
                    </Box>
                ) : (
                    <>
                        {filterStatus ? (
                            <>
                                {tabSelect === "chronology" && (
                                    <Box sx={{ flex: "1 0 10vh", overflow: "scroll" }}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "3rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                    >
                                                        Sn.
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                        }}
                                                        size="small"
                                                        colSpan={2}
                                                    >
                                                        Delay Event
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                    >
                                                        Party
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                    >
                                                        Department
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                    >
                                                        Letter Reference No.
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                    >
                                                        Date
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                    >
                                                        Referred In Claims
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "8rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                    >
                                                        Response Time
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "5rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                    >
                                                        Missing in Claims
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "5rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                    >
                                                        Reason for letter
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "17rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                    >
                                                        Gist
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "5rem",
                                                        }}
                                                        size="small"
                                                        colSpan={2}
                                                    >
                                                        Contradictions
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "17rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                    >
                                                        Clauses/Sub-Clauses in letter
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            minWidth: "10rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                    >
                                                        Rule Books
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                        }}
                                                        size="small"
                                                        rowSpan={2}
                                                    >
                                                        Relevancies
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            top: "30px",
                                                        }}
                                                        size="small"
                                                    >
                                                        DE No.
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            top: "30px",
                                                        }}
                                                        size="small"
                                                    >
                                                        Sub Delay Event
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            top: "30px",
                                                        }}
                                                        size="small"
                                                    >
                                                        Contrasts : Contract-Contrasts
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            px: 0.3,
                                                            fontSize: "0.8rem",
                                                            top: "30px",
                                                        }}
                                                        size="small"
                                                    >
                                                        Letter Contrasts(Party Communication)
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableData?.map((row: any, index: number) => (
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {row["Delay Event"]}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {row["Sub Delay Event"]?.toString()}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {row["Party"]?.toString()}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {row["Department"]?.toString()}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {/* {row["Letter Reference No."]
                                                        ?.toString()
                                                        ?.split("(")
                                                        ?.map((str: string) =>
                                                            str?.startsWith("http") ? (
                                                                <>
                                                                    {"("}
                                                                    <a href={str?.replace(")", "")} target="_blank">
                                                                        {str?.replace(")", "")}
                                                                    </a>
                                                                    {")"}
                                                                </>
                                                            ) : (
                                                                str
                                                            )
                                                        )} */}
                                                            {row["Letter Reference No."]
                                                                ?.toString()
                                                                ?.includes("http") ? (
                                                                <>
                                                                    {"["}
                                                                    <a
                                                                        href={row["Letter Reference No."]
                                                                            ?.split("(")[1]
                                                                            ?.replace(")", "")}
                                                                        target="_blank"
                                                                    >
                                                                        {row["Letter Reference No."]
                                                                            ?.split("[")[1]
                                                                            ?.split("(")[0]
                                                                            ?.replace("]", "")}
                                                                    </a>
                                                                    {"]"}
                                                                </>
                                                            ) : (
                                                                row["Letter Reference No."]
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {row["Date"]?.toString()}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {row["Referred"]?.toString()}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {row["New_response_time"]?.toString() || row["Response Time"]?.toString()}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {row["Missing in Claims"]?.toString()}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {row["Reason for letter"]?.toString()}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                            dangerouslySetInnerHTML={{
                                                                __html: row["Gist"]
                                                                    ?.toString()
                                                                    ?.replace(
                                                                        row["Gist Contrasts"]?.toString(),
                                                                        `<span style="background:yellow; color:#000;">${row["Gist Contrasts"]}</span>`
                                                                    ),
                                                            }}
                                                        ></TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {row["Contract-Contrasts"]?.toString()}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {row["Letter Contrasts"]?.toString()}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {row["Clauses/Sub-Clauses in letter"]?.toString()}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {row["Rule Books"]?.toString()}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                            size="small"
                                                        >
                                                            {/* {row["Relevancies"]?.toString()} */}
                                                            <Relevencies value={row["Relevancies"]} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                )}
                                {tabSelect === "de_period_result" && (
                                    <Box sx={{ flex: "1 0 10vh", overflow: "scroll" }}>
                                        <Table stickyHeader sx={{ mt: 2 }} size="small">
                                            <TableHead>
                                                <TableRow>
                                                    {[
                                                        "Delay Event",
                                                        "Delay Start Date",
                                                        "Delay End Date",
                                                        "Submission Status under 28 Days",
                                                        "Submission Status (Otherwise)",
                                                        "Other Notices/Supporting Particulars",
                                                        "Under Clauses",
                                                    ]?.map((head: string) => (
                                                        <TableCell
                                                            sx={{
                                                                textAlign: "center",
                                                                border: "1px solid",
                                                                background: "#c9d9ff",
                                                                p: 0.3,
                                                                minWidth: "6rem",
                                                            }}
                                                        >
                                                            {head}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableData?.map((row: any, index: number) => (
                                                    <TableRow>
                                                        {[
                                                            "Delay Event",
                                                            "Delay Start Date",
                                                            "Delay End Date",
                                                            "Submission Status under 28 Days",
                                                            "Submission Status (Otherwise)",
                                                            "Other Notices/Supporting Particulars",
                                                            "Under Clauses",
                                                        ]?.map((item: string) => (
                                                            <TableCell
                                                                sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                                size="small"
                                                            >
                                                                {row[item]}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                )}
                                {tabSelect === "clauses_result" && (
                                    <Box sx={{ flex: "1 0 10vh", overflow: "scroll" }}>
                                        <Table stickyHeader sx={{ mt: 2 }} size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                            border: "1px solid",
                                                            background: "#c9d9ff",
                                                            p: 0.3,
                                                            minWidth: "4rem",
                                                        }}
                                                    >
                                                        Sn No.
                                                    </TableCell>
                                                    {Object?.keys(tableData[0])?.map((head: string) => (
                                                        <TableCell
                                                            sx={{
                                                                textAlign: "center",
                                                                border: "1px solid",
                                                                background: "#c9d9ff",
                                                                p: 0.3,
                                                                minWidth: "4rem",
                                                            }}
                                                        >
                                                            {head}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableData?.map((row: any, index: number) => (
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{
                                                                border: "1px solid #D9D9D9",
                                                                p: 0.5,
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </TableCell>
                                                        {Object?.keys(row)?.map((item: string) => (
                                                            <TableCell
                                                                sx={{ border: "1px solid #D9D9D9", p: 0.5 }}
                                                                size="small"
                                                            >
                                                                {row[item]?.toString()}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                )}
                            </>
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "10rem",
                                }}
                            >
                                <Typography sx={{ fontSize: "1.08rem", fontWeight: 600 }}>
                                    Please Filter The Conditions For Data
                                </Typography>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default Tables;
