import React, { useContext, useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    CircularProgress,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import LogoXIcon from "../../../components/Common/svgLogs/LogoXIcon";
import { Context } from "../../../context/setting";
import ChatBox from "./ChatBox";
import { ExpandMore, SkipNext, SkipPrevious } from "@mui/icons-material";
import { Api } from "../../../apis";

const Parameters = ({ selectedFile }: { selectedFile: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [description, setDescription] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [type, setType] = useState<string>("");
    const [data, setData] = useState<any[]>([]);
    const [limit, setLimit] = useState<number>(1);
    const [offsets, setOffsets] = useState<{ page: number; size: number }>({ page: 1, size: 100 });
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        handleFinanceResponse(contextStore?.anamoly?.selectedType, offsets);
    }, [contextStore?.anamoly?.selectedType]);

    const handleFinanceResponse = async (type: string, pages: any) => {
        setLoader(true);
        if (type === "Detect Anomalies") {
            const result = await response_generator(Api?.getDetectAnomalies());
            if (result) {
                setDescription(result?.description);
                setType(type);
                setData(JSON?.parse(result?.resultant_data));
                setLimit(result?.entries_count);
            }
        }
        if (type === "Abnormal Entries (Outliers)") {
            const result = await response_generator(Api?.getDetectOutliers());
            if (result) {
                setDescription(result?.description);
                setType(type);
                setData(JSON?.parse(result?.resultant_data));
                setLimit(result?.entries_count);
            }
        }
        if (type === "Rounded Amount") {
            const result = await response_generator(Api?.getDetectRounded(pages));
            // console.log({ result });
            if (result) {
                setDescription(result?.description);
                setType(type);
                setData(JSON?.parse(result?.resultant_data));
                setLimit(result?.entries_count);
            }
        }
        if (type === "Unexpected Credits & Debits") {
            const result = await response_generator(Api?.getDetectCreditsDebits());
            if (result) {
                setDescription(result?.description);
                setType(type);
                setData(JSON?.parse(result?.resultant_data));
                setLimit(result?.entries_count);
            }
        }
        if (type === "Weekday Gap Anomaly") {
            const result = await response_generator(Api?.getDetectWeeklyGap());
            if (result) {
                setDescription(result?.description);
                setType(type);
                setData(JSON?.parse(result?.resultant_data));
                setLimit(result?.entries_count);
            }
        }
        if (type === "Exceptional Entry") {
            const result = await response_generator(Api?.getDetecExceptionalEntries());
            if (result) {
                setDescription(result?.description);
                setType(type);
                setData(JSON?.parse(result?.resultant_data));
                setLimit(result?.entries_count);
            }
        }
        setLoader(false);
    };

    const response_generator = async (promise: any) => {
        const result = await promise;
        if (result?.status === 200) {
            return result?.data;
        } else {
            if (result?.status === 500) {
                setContext({
                    ...contextStore,
                    source_file: undefined,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: result?.message || "Something Went Wrong",
                    },
                });
            }
            return undefined;
        }
    };

    useEffect(() => {
        if (selectedFile) {
            handleFinanceResponse("Detect Anomalies", offsets);
        }
    }, [selectedFile]);

    return (
        <Box
            sx={{
                p: 3,
                flex: 1,
                display: "flex",
                flexDirection: "column",
                width: "79vw",
                height: "100%",
            }}
        >
            {loading ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "25vh" }}>
                    <CircularProgress style={{ width: "25px", height: "25px", animationDuration: "600ms" }} />
                    <Typography>Processing Data Please Wait...</Typography>
                </Box>
            ) : (
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, py: 1 }}>
                            <LogoXIcon />
                            <Typography>{description}</Typography>
                        </Box>
                    </Box>
                    {loader ? (
                        <Box sx={{ height: "40vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress style={{ width: "25px", height: "25px", animationDuration: "600ms" }} />
                        </Box>
                    ) : (
                        <Accordion
                            defaultExpanded
                            sx={{
                                "&::before": {
                                    content: "''",
                                    opacity: 0,
                                },
                                borderRadius: "8px",
                                boxShadow: "0px 0px 4px -2px",
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                sx={{
                                    "& .MuiAccordionSummary-content": { my: 1, minHeight: "20px" },
                                    "& .Mui-expanded .MuiAccordionSummary-root": {
                                        minHeight: "42px",
                                    },
                                    minHeight: "42px",
                                }}
                            >
                                <Typography sx={{ fontWeight: 500, fontFamily: "Gilroy-SemiBold" }}>{type}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: 0 }}>
                                <Box
                                    sx={{
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box sx={{ height: "39vh", overflow: "auto", width: "100%" }}>
                                        {data?.length > 0 && (
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        {Object?.keys(data[0])?.map((item: string) => (
                                                            <TableCell
                                                                key={item}
                                                                sx={{
                                                                    background: "#F6F6F6",
                                                                    border: "1px solid #E4E4E4",
                                                                    py: 1.2,
                                                                    whiteSpace: "nowrap",
                                                                }}
                                                            >
                                                                {item
                                                                    ?.split("_")
                                                                    ?.map(
                                                                        (head) =>
                                                                            head[0]?.toUpperCase() + head?.slice(1)
                                                                    )
                                                                    ?.join(" ")}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data?.map((item: any) => (
                                                        <TableRow>
                                                            {Object?.keys(item)?.map((subItem) => (
                                                                <TableCell
                                                                    sx={{ border: "1px solid #E4E4E4" }}
                                                                    size="small"
                                                                >
                                                                    {item[subItem]?.toString()}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            p: 1,
                                        }}
                                    >
                                        {data?.length > 0 && (
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        if (offsets?.page > 1) {
                                                            setOffsets((prev: any) => {
                                                                handleFinanceResponse(
                                                                    contextStore?.anamoly?.selectedType,
                                                                    {
                                                                        ...prev,
                                                                        page: prev?.page - 1,
                                                                    }
                                                                );
                                                                return { ...prev, page: prev?.page - 1 };
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <SkipPrevious />
                                                </IconButton>
                                                <Typography sx={{ px: 2, border: "1px solid #f2f2f2" }}>
                                                    {offsets?.page}
                                                </Typography>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        if (offsets?.page < limit / 100) {
                                                            setOffsets((prev: any) => {
                                                                handleFinanceResponse(
                                                                    contextStore?.anamoly?.selectedType,
                                                                    {
                                                                        ...prev,
                                                                        page: prev?.page + 1,
                                                                    }
                                                                );
                                                                return { ...prev, page: prev?.page + 1 };
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <SkipNext />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )}
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", flex: 1 }}>
                        <ChatBox />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Parameters;
