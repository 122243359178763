// PdfViewer.tsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import { Box, Typography } from "@mui/material";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// import { pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();

const PdfViewer = ({ pdfName }: { pdfName: string }) => {
    const [pdfData, setPdfData] = useState<ArrayBuffer | null>(null);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [name, setName] = useState<string>("");

    const fetchPdf = async () => {
        try {
            const response = await axios.get<ArrayBuffer>(
                "https://softxapi-demo.softsensor.ai/bond_llm/get_pdf_for_aurigo",
                {
                    responseType: "arraybuffer",
                }
            );

            setPdfData(response.data);
        } catch (error) {
            console.error("Error fetching PDF:", error);
        }
    };
    useEffect(() => {
        if (pdfName?.length > 0) {
            fetchPdf();
            setName(pdfName);
        }
    }, [pdfName]);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    return (
        <Box sx={{ flex: "1 0 10vh", overflowY: "scroll" }}>
            <Box sx={{ py: 1, pl: 3, mb: 1 }}>
                <Typography>{pdfName}</Typography>
            </Box>
            {pdfData ? (
                <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(new Array(numPages), (_, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                </Document>
            ) : (
                <p>Loading PDF...</p>
            )}
        </Box>
    );
};

export default PdfViewer;
