import { Box, Button } from "@mui/material";
import { useState } from "react";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import RightPanelTopBars from "./RightPanelTopBars";
import ImagePanel from "./ImagePanel";
import ImageUploadBox from "./ImageUploadBox";
import DamageDescriptionBox from "./DamageDescriptionBox";

const DamageDetectionBox = () => {
    const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false);
    const [imageBytes, setImageBytes] = useState<any>(null);
    const [imageIndex, setImageIndex] = useState<any>(0);
    const [selectedFiles, setSelectedFiles] = useState<any>([]);

    return isImageUploaded ? (
        <Box
            sx={{
                width: "100%",
                height: "95vh",
                display: "flex",
                flexDirection: "row",
                overflow: "none",
            }}
        >
            <ReflexContainer orientation="vertical">
                <Button
                    sx={{ height: "50px" }}
                    onClick={() => {
                        setImageIndex(-1);
                        setImageBytes(null);
                        setIsImageUploaded(false);
                    }}
                >
                    {"< Back"}
                </Button>
                <ReflexElement minSize={400} flex={1}>
                    {imageIndex !== -1 ? (
                        <Box sx={{ border: "1px solid #ECECEC", borderRadius: "10px", width: "98%", mt: "2%" }}>
                            <DamageDescriptionBox data={imageBytes} imageIndex={imageIndex} />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid #ECECEC",
                                borderRadius: "10px",
                                fontSize: "32px",
                                textAlign: "center",
                                opacity: "0.5",
                            }}
                        >
                            Please select an image to view it's damage report
                        </Box>
                    )}
                </ReflexElement>
                <ReflexSplitter style={{ border: "none" }}>
                    <Box
                        sx={{
                            width: "10px",
                            height: "100%",
                            background: "#E3F4FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            borderWidth: "0px 2px 0px 2px",
                            borderStyle: "solid",
                            borderColor: "#E3F4FF",
                        }}
                    >
                        <Box
                            sx={{
                                width: "3px",
                                height: "15px",
                                background: "#0000001C",
                                borderRadius: "10px",
                            }}
                        ></Box>
                        <Box
                            sx={{
                                width: "3px",
                                height: "15px",
                                background: "#0000001C",
                                borderRadius: "10px",
                            }}
                        ></Box>
                    </Box>
                </ReflexSplitter>
                <ReflexElement minSize={400} flex={1}>
                    <RightPanelTopBars
                        imageBytes={imageBytes}
                        setImageIndex={setImageIndex}
                        imageIndex={imageIndex}
                        setImageBytes={setImageBytes}
                        setIsImageUploaded={setIsImageUploaded}
                        setSelectedFiles={setSelectedFiles}
                        selectedFiles={selectedFiles}
                    />
                    <ImagePanel imageBytes={imageBytes} imageIndex={imageIndex} setImageIndex={setImageIndex} />
                </ReflexElement>
            </ReflexContainer>
        </Box>
    ) : (
        <ImageUploadBox
            setIsImageUploaded={setIsImageUploaded}
            setImageBytes={setImageBytes}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
        />
    );
};

export default DamageDetectionBox;
