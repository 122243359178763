import { Add, Reply } from "@mui/icons-material";
import { Box, Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import DeleteIcon from "../../components/Common/svgLogs/DeleteIcon";

interface AnamolyFilesProps {
    setSelectedTab: (tab: string) => void;
}

const AnamolyFiles: React.FC<AnamolyFilesProps> = ({ setSelectedTab }) => {
    return (
        <Box
            sx={{
                width: "80vw",
                height: "100%",
                // border: "1px solid red",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                px: "15px",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    height: "10%",
                    // border: "1px solid red",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography sx={{ fontSize: "1.5rem" }}>My Files</Typography>
                <Box sx={{ display: "flex", height: "60px", alignItems: "center", gap: 2 }}>
                    <OutlinedInput
                        sx={{
                            pr: "1.8px",
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "7.6px",
                                borderColor: "#CFCFCF",
                                overflow: "hidden",
                            },
                            "& .MuiOutlinedInput-input": {
                                height: "0.5rem",
                                padding: "14px 12px",
                            },
                        }}
                        placeholder="Search"
                        endAdornment={
                            <IconButton sx={{ borderRadius: "4px" }}>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.91732 15.4245C10.3225 15.4242 11.6872 14.9538 12.7941 14.0881L16.2743 17.5683L17.3937 16.4489L13.9135 12.9687C14.7796 11.8617 15.2503 10.4967 15.2507 9.09115C15.2507 5.5991 12.4094 2.75781 8.91732 2.75781C5.42528 2.75781 2.58398 5.5991 2.58398 9.09115C2.58398 12.5832 5.42528 15.4245 8.91732 15.4245ZM8.91732 4.34115C11.5369 4.34115 13.6673 6.47152 13.6673 9.09115C13.6673 11.7108 11.5369 13.8411 8.91732 13.8411C6.29769 13.8411 4.16732 11.7108 4.16732 9.09115C4.16732 6.47152 6.29769 4.34115 8.91732 4.34115Z"
                                        fill="#484848"
                                    />
                                </svg>
                            </IconButton>
                        }
                    />
                    <Button
                        startIcon={<Add />}
                        sx={{
                            textTransform: "none",
                            background: "#fff",
                            color: "#007BFF",
                            px: 2,
                            borderRadius: "8px",
                            border: "1px solid #007BFF",
                            "&:hover": { background: "#fffc3" },
                        }}
                        // onClick={() => navigate("/data_source/add_source")}
                    >
                        New
                    </Button>
                    <Button
                        sx={{
                            textTransform: "none",
                            background: "#007BFF",
                            color: "#fff",
                            px: 2,
                            borderRadius: "7.31px",
                            "&:hover": { background: "#007BFFc3" },
                        }}
                        startIcon={<Reply sx={{ transform: "rotateY(180deg)" }} />}
                        // onClick={() => {
                        //     if (tabSelect === "pdf" && Object?.keys(Selected)?.length > 0) {
                        //         navigate("/chat");
                        //     } else if (tabSelect === "excel") {
                        //         navigate("/chat");
                        //     } else if (tabSelect === "youtube" && Object?.keys(Selected)?.length > 0) {
                        //         navigate("/chat");
                        //         setContext({
                        //             ...contextStore,
                        //             bucketName: Selected?.file_id,
                        //         });
                        //     } else {
                        //         setContext({
                        //             ...contextStore,
                        //             snackState: {
                        //                 open: true,
                        //                 errorType: "warning",
                        //                 message: "Please Select a Document first",
                        //             },
                        //         });
                        //     }
                        // }}
                    >
                        Start Chat
                    </Button>
                </Box>
            </Box>
            <Box>
                <Typography sx={{ color: "#929292" }}>Uploaded Files</Typography>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    height: "10%",
                    // border: "1px solid red",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid #E1E1E1",
                }}
            >
                <Typography sx={{ fontSize: "1.2rem" }}>File Name</Typography>
                <Box
                    sx={{
                        width: "8%",
                        height: "10%",
                        // border: "1px solid red",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Button
                        sx={{
                            background: "#E3F4FF",
                            borderRadius: "20px",
                            "&:hover": {
                                background: "#E3F4FF", // Set the hover background color to be the same as the normal background color
                            },
                        }}
                        onClick={() => {
                            setSelectedTab("chat");
                        }}
                    >
                        Ask
                    </Button>
                    <DeleteIcon />
                </Box>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    height: "10%",
                    // border: "1px solid red",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid #E1E1E1",
                }}
            >
                <Typography sx={{ fontSize: "1.2rem" }}>File Name</Typography>
                <Box
                    sx={{
                        width: "8%",
                        height: "10%",
                        // border: "1px solid red",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Button
                        sx={{
                            background: "#E3F4FF",
                            borderRadius: "20px",
                            "&:hover": {
                                background: "#E3F4FF", // Set the hover background color to be the same as the normal background color
                            },
                        }}
                        onClick={() => {
                            setSelectedTab("chat");
                        }}
                    >
                        Ask
                    </Button>
                    <DeleteIcon />
                </Box>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    height: "10%",
                    // border: "1px solid red",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid #E1E1E1",
                }}
            >
                <Typography sx={{ fontSize: "1.2rem" }}>File Name</Typography>
                <Box
                    sx={{
                        width: "8%",
                        height: "10%",
                        // border: "1px solid red",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Button
                        sx={{
                            background: "#E3F4FF",
                            borderRadius: "20px",
                            "&:hover": {
                                background: "#E3F4FF", // Set the hover background color to be the same as the normal background color
                            },
                        }}
                        onClick={() => {
                            setSelectedTab("chat");
                        }}
                    >
                        Ask
                    </Button>
                    <DeleteIcon />
                </Box>
            </Box>
        </Box>
    );
};

export default AnamolyFiles;
