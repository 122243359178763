import { Box, Typography } from "@mui/material";
import UploadBoxIcon from "../../components/Common/svgLogs/UploadBoxIcon";

const UploadModal = ({ setSelectedFiles, selectedType }: any) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
            onDrop={(e: any) => {
                e.preventDefault();
                setSelectedFiles(Array.from(e?.dataTransfer?.files));
            }}
            onDragOver={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            <Box
                sx={{
                    p: 3,
                    minHeight: "50px",
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center",
                }}
                component={"label"}
            >
                <Typography variant="h4" sx={{ mb: 3 }}>
                    Drag & Drop your file
                </Typography>
                <Box sx={{ transform: "scale(0.8)" }}>
                    <UploadBoxIcon />
                </Box>
                <Box sx={{ width: "100%", height: "100%" }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 1,
                        }}
                    >
                        <Typography variant="h6">
                            Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: "#424F65" }}>
                            {selectedType === "pdf" ? <em>Supported .pdf</em> : <em>Supported .jpg, .jpeg, .png</em>}
                        </Typography>
                    </Box>

                    <input
                        hidden
                        multiple={selectedType === "pdf"}
                        type="file"
                        accept={selectedType === "pdf" ? ".pdf" : ".jpg, .jpeg, .png"}
                        onChange={(e: { target: { files: any; value: any } }) => {
                            setSelectedFiles(Array.from(e?.target?.files));
                            e.target.value = "";
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default UploadModal;
