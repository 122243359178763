import React from "react";

const SupplyChainIcon = () => {
    return (
        <svg width="58" height="54" viewBox="0 0 58 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M31.0833 53.8346C16.2415 53.8346 4.16602 41.7591 4.16602 26.9173C4.16602 26.3682 4.38416 25.8415 4.77247 25.4532C5.16078 25.0649 5.68743 24.8467 6.23658 24.8467C6.78572 24.8467 7.31238 25.0649 7.70069 25.4532C8.08899 25.8415 8.30714 26.3682 8.30714 26.9173C8.30714 39.4753 18.5254 49.6935 31.0833 49.6935C43.6413 49.6935 53.8595 39.4753 53.8595 26.9173C53.8595 14.3593 43.6413 4.14112 31.0833 4.14112C27.8787 4.14337 24.7106 4.82065 21.7852 6.12887C18.8598 7.43709 16.2428 9.34689 14.1047 11.7339C13.7369 12.1371 13.2247 12.3788 12.6796 12.4064C12.1344 12.434 11.6005 12.2452 11.1938 11.8811C10.7872 11.5171 10.5407 11.0071 10.5081 10.4623C10.4755 9.91745 10.6593 9.38176 11.0196 8.97175C13.5461 6.15092 16.6386 3.89406 20.0956 2.34823C23.5526 0.802398 27.2965 0.00227602 31.0833 0C45.9251 0 58.0006 12.0755 58.0006 26.9173C58.0006 41.7591 45.9251 53.8346 31.0833 53.8346Z"
                fill="black"
            />
            <path
                d="M10.3783 28.9876C10.1063 28.9879 9.837 28.9344 9.58578 28.8303C9.33456 28.7262 9.10639 28.5735 8.91437 28.3809L6.23713 25.7037L3.55989 28.3809C3.36889 28.5787 3.14041 28.7364 2.8878 28.8449C2.63518 28.9534 2.36348 29.0106 2.08855 29.0129C1.81363 29.0153 1.54098 28.9629 1.28651 28.8588C1.03205 28.7547 0.800864 28.601 0.606454 28.4066C0.412043 28.2122 0.258297 27.981 0.154188 27.7265C0.0500779 27.472 -0.00231087 27.1994 7.81776e-05 26.9245C0.00246723 26.6495 0.0595863 26.3778 0.168102 26.1252C0.276619 25.8726 0.434358 25.6441 0.632118 25.4531L4.77324 21.312C5.16153 20.9238 5.68809 20.7058 6.23713 20.7058C6.78617 20.7058 7.31273 20.9238 7.70102 21.312L11.8421 25.4531C12.1316 25.7427 12.3288 26.1116 12.4086 26.5132C12.4885 26.9148 12.4475 27.331 12.2908 27.7093C12.1341 28.0876 11.8688 28.411 11.5284 28.6385C11.1879 28.866 10.7877 28.9875 10.3783 28.9876ZM24.8722 33.1287H16.5899C16.0408 33.1287 15.5141 32.9106 15.1258 32.5222C14.7375 32.1339 14.5194 31.6073 14.5194 31.0581V26.917C14.5195 26.3679 14.7377 25.8413 15.1261 25.4531L19.2672 21.312C19.6554 20.9237 20.182 20.7054 20.7311 20.7053H24.8722C25.4213 20.7053 25.948 20.9235 26.3363 21.3118C26.7246 21.7001 26.9427 22.2267 26.9427 22.7759V31.0581C26.9427 31.6073 26.7246 32.1339 26.3363 32.5222C25.948 32.9106 25.4213 33.1287 24.8722 33.1287ZM18.6605 28.9876H22.8016V24.8465H21.5883L18.6605 27.7742V28.9876ZM47.6484 33.1287H29.0133C28.4642 33.1287 27.9375 32.9106 27.5492 32.5222C27.1609 32.1339 26.9427 31.6073 26.9427 31.0581V20.7053C26.9427 20.1562 27.1609 19.6295 27.5492 19.2412C27.9375 18.8529 28.4642 18.6348 29.0133 18.6348H47.6484C48.1975 18.6348 48.7242 18.8529 49.1125 19.2412C49.5008 19.6295 49.7189 20.1562 49.7189 20.7053V31.0581C49.7189 31.6073 49.5008 32.1339 49.1125 32.5222C48.7242 32.9106 48.1975 33.1287 47.6484 33.1287ZM31.0839 28.9876H45.5778V22.7759H31.0839V28.9876Z"
                fill="black"
            />
            <path
                d="M21.766 37.2703C23.4813 37.2703 24.8718 35.8797 24.8718 34.1644C24.8718 32.4491 23.4813 31.0586 21.766 31.0586C20.0507 31.0586 18.6602 32.4491 18.6602 34.1644C18.6602 35.8797 20.0507 37.2703 21.766 37.2703Z"
                fill="black"
            />
            <path
                d="M34.1879 37.2703C35.9032 37.2703 37.2937 35.8797 37.2937 34.1644C37.2937 32.4491 35.9032 31.0586 34.1879 31.0586C32.4726 31.0586 31.082 32.4491 31.082 34.1644C31.082 35.8797 32.4726 37.2703 34.1879 37.2703Z"
                fill="black"
            />
            <path
                d="M44.5414 37.2703C46.2567 37.2703 47.6472 35.8797 47.6472 34.1644C47.6472 32.4491 46.2567 31.0586 44.5414 31.0586C42.8261 31.0586 41.4355 32.4491 41.4355 34.1644C41.4355 35.8797 42.8261 37.2703 44.5414 37.2703Z"
                fill="black"
            />
        </svg>
    );
};

export default SupplyChainIcon;
