import useWebSocket from "react-use-websocket";
import { Some } from "./Some";
import { Api } from "../../../apis";

import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../../context/setting";

export const SofieSocket: any = () => {
    const [msg, setMsg] = useState<string>("");
    const msgRef: any = useRef();

    const { getWebSocket, lastJsonMessage, lastMessage, readyState, sendJsonMessage, sendMessage } = useWebSocket(
        Api?.sofieUrl,
        {
            onOpen: () => console.log("WebSocket connection established."),
            onMessage: (resp: any) => {
                const dataString = Some.String(resp?.data);
                if (
                    dataString.includes("Connection established") 
                ) {
                    return;
                }

              

                if (dataString?.startsWith("Error occurred")) {
                    setMsg((prev) => prev + "<EOD>");
                    msgRef.load = false;
                    return;
                }

                msgRef.load = true;
                msgRef.relavent_search = false;
                if (dataString?.toString()?.trim()?.length === 0) {
                    msgRef.typing = true;
                } else {
                    msgRef.typing = false;
                }

                setMsg((prev: string) => {
                    try {
                        const json = JSON.parse(dataString);
                        if (typeof json === "object" && json?.hasOwnProperty("text") && !Array?.isArray(json)) {
                            msgRef.msg = json;
                        } else if (
                            typeof json === "object" &&
                            json?.hasOwnProperty("summary") &&
                            !Array?.isArray(json)
                        ) {
                            msgRef.summary = json?.summary;
                        } else if (typeof json === "object" && !Array?.isArray(json)) {
                            msgRef.json = json;
                        } else {
                            msgRef.msg = msgRef?.msg?.length > 0 ? msgRef?.msg + dataString : dataString;
                        }
                    } catch (error) {
                        if (dataString === "<EOD>") {
                            msgRef.load = false;
                            // msgRef.msg = "";
                        } else if (dataString === "<Not Found>") {
                            msgRef.load = false;
                            msgRef.typing = false;
                            msgRef.relavent_search = true;
                        } else {
                            msgRef.msg = msgRef?.msg?.length > 0 ? msgRef?.msg + dataString : dataString;
                        }
                    }
                    return prev + dataString;
                });
            },
            onError: (event: any) => {
                console.log(event);
            },
        }
    );
    return [msg, { getWebSocket, lastJsonMessage, lastMessage, readyState, sendJsonMessage, sendMessage }, msgRef];
};

export default SofieSocket;
