import { Box, Typography, Button, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setContext } from "redux-saga/effects";
import { Context } from "../../context/setting";

const UploadProgress = ({
    item,
    progressStatus,
    msgRef,
    socketHooks,
}: {
    item: any;
    progressStatus: any;
    msgRef: any;
    socketHooks: any;
}) => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [progress, setProgress] = useState<number>(0);
    const [prosessedFile, setProsessedFile] = useState<any>(item);

    useEffect(() => {
        setProsessedFile(item);
    }, [item]);

    useEffect(() => {
        if (prosessedFile?.file_id === msgRef?.msg?.file_id) {
            setProgress(msgRef?.msg?.progress);
        }
    }, [msgRef?.msg?.file_id]);

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        if (!prosessedFile?.is_processed) {
            interval = setInterval(() => {
                socketHooks?.sendJsonMessage({ upload_status: { file_id: prosessedFile?.file_id } });
            }, 3000);
        }
        return () => clearInterval(interval);
    }, [prosessedFile]);

    return (
        <Box
            sx={{
                px: 2.2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                py: 1,
            }}
        >
            <Typography sx={{ width: "70%" }} noWrap>
                {item?.file_name}
            </Typography>
            <Box>
                {item?.is_processed || progress === 100 ? (
                    <Button
                        sx={{
                            color: "#007BFF",
                            border: "1px solid #007BFF",
                            background: "#fff",
                            borderRadius: "19px",
                        }}
                        onClick={() => {
                            setContext({
                                ...contextStore,
                                care: { selectedFile: item },
                            });
                            navigate("detail");
                        }}
                    >
                        View
                    </Button>
                ) : (
                    <Box
                        sx={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress
                            variant="determinate"
                            value={progress || 0}
                            sx={{ border: "1px solid #f2f2f2", borderRadius: "50%" }}
                        />
                        <Typography
                            sx={{
                                position: "absolute",
                            }}
                            variant="caption"
                        >
                            {progress || 0}%
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default UploadProgress;
