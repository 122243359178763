import React from "react";

const Typing = () => {
    return (
        <svg height="40" width="40">
            <circle className="dot" cx="10" cy="20" r="3" fill={"grey"} />
            <circle className="dot" cx="20" cy="20" r="3" fill={"grey"} />
            <circle className="dot" cx="30" cy="20" r="3" fill={"grey"} />
        </svg>
    );
};

export default Typing;
