import React from "react";

const PdfIcon = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_314_2898"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="17"
                height="17"
            >
                <rect width="17" height="17" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_314_2898)">
                <path
                    d="M6.37533 8.85352H7.08366V7.43685H7.79199C7.99269 7.43685 8.16092 7.36897 8.29668 7.2332C8.43244 7.09744 8.50033 6.92921 8.50033 6.72852V6.02018C8.50033 5.81949 8.43244 5.65126 8.29668 5.51549C8.16092 5.37973 7.99269 5.31185 7.79199 5.31185H6.37533V8.85352ZM7.08366 6.72852V6.02018H7.79199V6.72852H7.08366ZM9.20866 8.85352H10.6253C10.826 8.85352 10.9942 8.78563 11.13 8.64987C11.2658 8.51411 11.3337 8.34588 11.3337 8.14518V6.02018C11.3337 5.81949 11.2658 5.65126 11.13 5.51549C10.9942 5.37973 10.826 5.31185 10.6253 5.31185H9.20866V8.85352ZM9.91699 8.14518V6.02018H10.6253V8.14518H9.91699ZM12.042 8.85352H12.7503V7.43685H13.4587V6.72852H12.7503V6.02018H13.4587V5.31185H12.042V8.85352ZM5.66699 12.7493C5.27741 12.7493 4.9439 12.6106 4.66647 12.3332C4.38904 12.0558 4.25033 11.7223 4.25033 11.3327V2.83268C4.25033 2.4431 4.38904 2.10959 4.66647 1.83216C4.9439 1.55473 5.27741 1.41602 5.66699 1.41602H14.167C14.5566 1.41602 14.8901 1.55473 15.1675 1.83216C15.4449 2.10959 15.5837 2.4431 15.5837 2.83268V11.3327C15.5837 11.7223 15.4449 12.0558 15.1675 12.3332C14.8901 12.6106 14.5566 12.7493 14.167 12.7493H5.66699ZM5.66699 11.3327H14.167V2.83268H5.66699V11.3327ZM2.83366 15.5827C2.44408 15.5827 2.11057 15.444 1.83314 15.1665C1.55571 14.8891 1.41699 14.5556 1.41699 14.166V4.24935H2.83366V14.166H12.7503V15.5827H2.83366Z"
                    fill="#007BFF"
                />
            </g>
        </svg>
    );
};

export default PdfIcon;
