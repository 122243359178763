import { call, put } from "redux-saga/effects";
import constants from "../../utils/constants";
import {Auth} from "../../services";
import Environment from "../../enviroment";
const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

export function* login(action: any) {
  yield load("login");
  const response: ResponseGenerator = yield call(Auth.login, action.payload);
  if (response.status === 200) {
    yield put({
      type: constants("auth").reducers.login.success,
      payload: { ...response.data, VERSION: Environment.RELEASE },
    });

    yield delay(200);
    yield unload("login");   
  } else {
    if (response?.data?.message) {
        // Do Something here acc. to your app
    } else
      // Do Something here acc. to your app

    yield error("login", response, null);
    yield unload("login");
  }
}

export function* logout(action: any) {
    yield load("logout");
    const response: ResponseGenerator = yield call(Auth.logOut, action.payload);
    if (response.status === 200) {
      yield put({
        type: constants("auth").reducers.login.success,
        payload: { ...response.data, VERSION: Environment.RELEASE },
      });
  
      yield delay(200);
      yield unload("logout");   
    } else {
      if (response?.data?.message) {
        // Do Something here acc. to your app
      } else
        // Do Something here acc. to your app
  
      yield error("logout", response, null);
      yield unload("logout");
    }
  }

function* error(type: string, response: any, message: any) {
  let status = 0;
  if (response) {
    status = response.status || 0;
  }
  yield put({
    type: constants("auth").reducers[type].error,
    payload: {
      status: status,
      message: message || "We ran into some issues and are looking into it.",
    },
  });
}

function* load(type: any) {
  yield put({
    type: constants("auth").reducers[type].load,
  });
}

function* unload(type: any) {
  yield put({
    type: constants("auth").reducers[type].unload,
  });
}
