import React from "react";

const GoogleDriveIcon = () => {
    return (
        <svg width="34" height="31" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_320_3471)">
                <path
                    d="M23.2385 20.5684H10.7993L5.47266 29.7025C6.11346 30.0223 6.84116 30.2274 7.31181 30.2274C12.2051 30.2274 22.1915 30.1563 27.1489 30.1563C27.6587 30.1563 28.1358 29.9857 28.5638 29.7184L23.2385 20.5684Z"
                    fill="#4185F3"
                />
                <path
                    d="M5.4721 29.7025L10.7988 20.5684H0.00497696C-0.000453594 21.2031 0.22944 21.7839 0.589667 22.4464C1.59323 24.2895 3.29119 27.2359 4.2886 28.7748C4.53442 29.1541 5.51048 29.7216 5.4721 29.7025Z"
                    fill="#1767D1"
                />
                <path
                    d="M23.2383 20.5691L28.5653 29.7177C29.2557 29.287 29.8183 28.6046 30.1836 27.9762C31.0083 26.5592 32.4029 24.1996 33.3648 22.602C33.8119 21.8592 33.9857 21.1921 33.9749 20.5684L23.2383 20.5691Z"
                    fill="#E94235"
                />
                <path
                    d="M10.8005 20.5696L17.0138 9.96556L11.4608 0.876953C10.8602 1.26471 10.3465 1.79107 10.1133 2.19198C7.6895 6.36523 2.80671 14.9182 0.351369 19.1455C0.0997744 19.578 -0.0206852 20.0721 0.00417521 20.5696H10.8005Z"
                    fill="#30A753"
                />
                <path
                    d="M23.2382 20.5691L17.0137 9.96502L22.4142 0.886719C23.0148 1.27483 23.6741 1.79053 23.9072 2.19179C26.3311 6.36469 31.2139 14.9177 33.6703 19.145C33.9237 19.5796 34.0001 20.0715 33.9755 20.5691H23.2382Z"
                    fill="#F9BC00"
                />
                <path
                    d="M11.46 0.877762L17.0136 9.96494L22.4141 0.886648C21.8566 0.566776 21.067 0.377342 20.3024 0.353529C18.175 0.283868 14.7263 0.255791 12.8701 0.335048C12.4125 0.354595 11.4658 0.873853 11.46 0.877762Z"
                    fill="#0F8038"
                />
            </g>
            <defs>
                <clipPath id="clip0_320_3471">
                    <rect width="34" height="30" fill="white" transform="translate(0 0.242188)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default GoogleDriveIcon;
