import React from "react";

const ArraowUp = () => {
    return (
        <svg width="13" height="9" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.5 5.5L5.5 1.5L1.5 5.5"
                stroke="#007BFF"
                stroke-width="1.59324"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default ArraowUp;
