import { OutlinedInput, Box, IconButton } from "@mui/material";
import { useContext, useState } from "react";
import SendWhiteIcon from "../../../components/Common/svgLogs/SendWhiteIcon";
import { Context } from "../../../context/setting";

const InputField = ({ handleSend }: { handleSend: (value: string) => void }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [query, setQuery] = useState<string>("");
    return (
        <OutlinedInput
            value={query}
            onChange={(e: { target: { value: string } }) => {
                setQuery(e.target.value);
            }}
            onKeyDown={(e: any) => {
                if (e?.key === "Enter") {
                    if (e?.target?.value?.length === 0) {
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "warning",
                                message: "Please Ask A Question",
                            },
                        });
                    } else {
                        setQuery(() => "");
                        handleSend(e?.target?.value);
                    }
                }
                return;
            }}
            fullWidth
            endAdornment={
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <IconButton
                        sx={{ background: "#007BFF", "&:hover": { background: "#007BFFaa" } }}
                        onClick={() => {
                            if (query?.length === 0) {
                                setContext({
                                    ...contextStore,
                                    snackState: {
                                        open: true,
                                        errorType: "warning",
                                        message: "Please Ask A Question",
                                    },
                                });
                            } else {
                                setQuery(() => "");
                                handleSend(query);
                            }
                        }}
                    >
                        <SendWhiteIcon />
                    </IconButton>
                </Box>
            }
            sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "80px",
                    border: "1px solid #E0E0E0",
                },
                "& .MuiOutlinedInput-input": {
                    padding: "13px 20px",
                },
                width: "100%",
            }}
            placeholder="Ask Me Anything"
        />
    );
};

export default InputField;
