import React from "react";

const FileIcon = () => {
    return (
        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.21653 14.5126C1.81028 14.5126 1.46251 14.368 1.17321 14.0787C0.883907 13.7894 0.739258 13.4416 0.739258 13.0353V3.43306H2.21653V13.0353H14.7733V14.5126H2.21653ZM5.17108 11.5581C4.76483 11.5581 4.41705 11.4134 4.12775 11.1241C3.83845 10.8348 3.6938 10.487 3.6938 10.0808V1.95579C3.6938 1.54954 3.83845 1.20176 4.12775 0.912465C4.41705 0.623165 4.76483 0.478516 5.17108 0.478516H8.86426L10.3415 1.95579H15.512C15.9182 1.95579 16.266 2.10044 16.5553 2.38974C16.8446 2.67904 16.9893 3.02681 16.9893 3.43306V10.0808C16.9893 10.487 16.8446 10.8348 16.5553 11.1241C16.266 11.4134 15.9182 11.5581 15.512 11.5581H5.17108ZM5.17108 10.0808H15.512V3.43306H9.73216L8.25488 1.95579H5.17108V10.0808Z"
                fill="#1C1B1F"
            />
        </svg>
    );
};

export default FileIcon;
