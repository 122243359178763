import React from "react";

const GoogleMailIcon = () => {
    return (
        <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.8728 29.8943H9.20824V14.5067L0.155273 7.70898V27.1768C0.155273 28.6834 1.37474 29.8943 2.8728 29.8943Z"
                fill="#4285F4"
            />
            <path
                d="M30.9473 29.8943H37.2827C38.7808 29.8943 40.0002 28.6749 40.0002 27.1768V7.70898L30.9473 14.4981V29.8943Z"
                fill="#34A853"
            />
            <path
                d="M30.9473 2.727V14.4967L40.0002 7.70758V4.08187C40.0002 0.726992 36.1675 -1.19364 33.4819 0.822712L30.9473 2.727Z"
                fill="#FBBC04"
            />
            <path
                d="M9.20801 14.5048V2.72656L20.0773 10.8791L30.9466 2.72656V14.5048L20.0773 22.6489L9.20801 14.5048Z"
                fill="#EA4335"
            />
            <path
                d="M0.155273 4.08969V7.71539L9.20824 14.5045V2.72703L6.6736 0.830525C3.98798 -1.18583 0.155273 0.734804 0.155273 4.08969Z"
                fill="#C5221F"
            />
        </svg>
    );
};

export default GoogleMailIcon;
