import { Box, Typography } from "@mui/material";
import React from "react";

const Loading = () => {
    return (
        <Box
            sx={{
                background: "#fff",
                borderRadius: "18px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                boxShadow: "0px 4px 34.4px 0px rgba(0, 0, 0, 0.09)",
                height: "18.5rem",
                width: "35rem",
                p: 6,
                zIndex: "5",
            }}
        >
            <Box sx={{ textAlign: "center" }}>
                <div className="dot-spin"></div>
                <Typography variant="h4">Working on your invoices...</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "center" }}>
                <Typography sx={{ fontStyle: "italic", color: "#717171", fontSize: "0.8rem" }}>
                    Sit back and relax this wont take long
                </Typography>
            </Box>
        </Box>
    );
};

export default Loading;
