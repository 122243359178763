
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  persistStore,
  persistReducer,
} from "redux-persist";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";
import sagas from "./sagas";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage: storage,
  debug: false,
  whitelist: ["auth"],
};

const persistedReducers = persistReducer(persistConfig, rootReducer);

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

if (process.env.NODE_ENV !== "production") {
  middlewares.push(createLogger());
}



// apply the middleware
const middleware = composeWithDevTools(applyMiddleware(...middlewares));


export default () => {
  const store = createStore(persistedReducers, undefined, middleware);
  const persistor = persistStore(store);

  sagaMiddleware.run(sagas);
  return { store, persistor };
};
