const DetectionVisibilityIcon = () => {
    return (
        <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.9974 11.667C12.1432 11.667 13.1173 11.2661 13.9197 10.4643C14.7221 9.66255 15.123 8.68844 15.1224 7.54199C15.1218 6.39555 14.7209 5.42174 13.9197 4.62058C13.1186 3.81941 12.1445 3.41821 10.9974 3.41699C9.85034 3.41577 8.87653 3.81696 8.07598 4.62058C7.27542 5.42419 6.87423 6.39799 6.8724 7.54199C6.87056 8.68599 7.27176 9.6601 8.07598 10.4643C8.8802 11.2685 9.85401 11.6694 10.9974 11.667ZM10.9974 10.017C10.3099 10.017 9.72567 9.77622 9.24473 9.29466C8.76379 8.8131 8.52301 8.22888 8.5224 7.54199C8.52179 6.8551 8.76256 6.27088 9.24473 5.78933C9.7269 5.30777 10.3111 5.06699 10.9974 5.06699C11.6837 5.06699 12.2682 5.30777 12.751 5.78933C13.2338 6.27088 13.4742 6.8551 13.4724 7.54199C13.4706 8.22888 13.2301 8.81341 12.751 9.29558C12.2719 9.77774 11.6873 10.0182 10.9974 10.017ZM10.9974 14.417C8.76684 14.417 6.7349 13.7946 4.90156 12.5497C3.06823 11.3049 1.73906 9.63566 0.914062 7.54199C1.73906 5.44894 3.06823 3.77999 4.90156 2.53516C6.7349 1.29033 8.76684 0.667604 10.9974 0.666993C13.228 0.666382 15.2599 1.2891 17.0932 2.53516C18.9266 3.78121 20.2557 5.45016 21.0807 7.54199C20.2557 9.63505 18.9266 11.3043 17.0932 12.5497C15.2599 13.7952 13.228 14.4176 10.9974 14.417ZM10.9974 12.5837C12.7238 12.5837 14.309 12.1293 15.7531 11.2206C17.1971 10.3119 18.3008 9.08566 19.0641 7.54199C18.3002 5.99894 17.1962 4.77305 15.7521 3.86433C14.3081 2.9556 12.7232 2.50094 10.9974 2.50033C9.27162 2.49971 7.6867 2.95438 6.24265 3.86433C4.79859 4.77427 3.69462 6.00016 2.93073 7.54199C3.69462 9.08505 4.79859 10.3112 6.24265 11.2206C7.6867 12.1299 9.27162 12.5843 10.9974 12.5837Z"
                fill="#007BFF"
            />
        </svg>
    );
};

export default DetectionVisibilityIcon;
