import constants from "../../utils/constants";

const initial_state = {
    logged_in: false,
    loading: false,
    user: <any>{},
};

export default function auth(state = initial_state, action: any): any {
    switch (action.type) {


        case constants("auth").reducers.login.load:
        case constants("auth").reducers.logout.load:
            return {
                ...state,
                loading: true,
            };
        case constants("auth").reducers.login.success:
            return {
                ...state,
                loading: false,
                logged_in: true,
                user: action.payload.data,
                APP_VERSION: action.payload.VERSION,
            };

        case constants("auth").reducers.login.unload:
        case constants("auth").reducers.login.error:
        case constants("auth").reducers.logout.unload:
            return {
                ...state,
                loading: false,
            };


        case constants("auth").reducers.logout.success:
            return {
                ...state,
                ...initial_state,
            };
        case constants("auth").reducers.logout.error:
            return {
                ...state,
                logged_in: false,
                loading: false,
                user: {},
                signup: {},
                setInspectionDate: "",
            };
        default:
            return state;
    }
}
