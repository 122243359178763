import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    IconButton,
    TableContainer,
    Modal,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import * as ExcelJS from "exceljs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import exportFromJSON from "export-from-json";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AddIcCallOutlined } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import ShareIcon from "@mui/icons-material/Share";
import ChatIcon from "../../components/Common/svgLogs/ChatIcon";
import InputField from "../Chat/ChatBox/InputField";
import InputHandler from "../Restaurent_Bot/InputHandler";
import LogoXIcon from "../../components/Common/svgLogs/LogoXIcon";
import axios from "axios";
import Environment from "../../enviroment";
interface InvoiceMatchingProps {
    invoiceResponse: {
        summary: string;
        retrieved_invoice_data: [
            {
                invoice_number: string;
                invoice_date: string;
                vendor: string;
                transactional_amount: number;
                description: string | null;
            },
        ];
        missing_invoice_number: [
            {
                invoice_number: string;
                invoice_date: string;
                vendor: string;
                transactional_amount: number;
                description: string | null;
            },
        ];
        je_dump_data: [
            {
                period: string;
                account: string;
                accounting_date: number;
                business_unit: string;
                contract_type_reinsurance: string;
                gl_posted_date: number;
                gl_journal_category: string;
                internal_external_account: string;
                management_product: string;
                obu: string;
                producing_office_code: string | null;
                producing_office_description: string | null;
                reversal_flag: string;
                source: string;
                sub_region_xl_loc_: string | null;
                underwriting_year: number | null;
                web_adi_manual_adjustment_id: number | null;
                web_adi_adjustment_description: string;
                web_adi_adjuster: string | null;
                web_adi_approver: string | null;
                functional_currency_code: string;
                transactional_currency_code: string;
                bu_consolidation_flag: string;
                functional_amount: number;
                transactional_amount: number;
                vendor: string;
                invoice_number: number;
                invoice_date: number;
                authorised_from: string | null;
            },
        ];
        possibility_of_anomalies: [
            {
                invoice_number: string;
                invoice_date: string;
                vendor: string;
                transactional_amount: number;
                description: string | null;
                comment: string;
            },
        ];
        matched_entries: [
            {
                invoice_number: string;
                invoice_date: string;
                vendor: string;
                transactional_amount: number | null;
                transaction_amount: number | null;
                description: string | null;
            },
        ];
    };
    setTableGenerate: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvoiceMatching: React.FC<InvoiceMatchingProps> = ({ invoiceResponse, setTableGenerate }) => {
    const [isvalid, setIsValid] = useState(true);
    const [isChat_selected, setIsChat_selected] = useState(false);
    const [loadingType, setLoadingType] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState("");
    const inputRef = useRef<any>();
    const [messages, setMessages] = useState([
        {
            response: "Welcome to SoftsensorX! Your go-to for seamless assistance",
            isReceived: true,
            withAvatar: true,
            responseType: "text",
        },
    ]);
    const [open, setOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const handleOpen = (payload: any) => {
        setOpen(true);
        setModalImage(payload);
    };
    const handleClose = () => {
        setOpen(false);
        setModalImage("");
    };

    const handleSendClick = async (value: any, type: any) => {
        // console.log({ value });
        // console.log({ type });
        const outMessage = {
            response: value,
            isReceived: false,
            withAvatar: false,
            responseType: "text",
        };
        // console.log({ value });
        // console.log({ type });
        setMessages((prevMessages) => [...prevMessages, outMessage]);
        const STRUCTURED_URL = Environment.STRUCTURED_URL;
        // Replace 'your-api-endpoint' with the actual endpoint URL
        const apiEndpoint = `${STRUCTURED_URL}/invoice_chat_bot`;
        setLoadingType(true);
        const chat_with_invoice = await axios.post(apiEndpoint, {
            user_query: value,
        });

        if (chat_with_invoice && chat_with_invoice.status === 200) {
            console.log({ chat_with_invoice });
            const testMessage = {
                response: null as any,
                isReceived: true,
                withAvatar: true,
                responseType: "table",
            };

            const response = chat_with_invoice?.data;
            console.log(typeof response?.table);

            if (response.text !== null) {
                testMessage.response = response.text;
                testMessage.responseType = "text";
            }
            // Check if there's a value in 'visual' and set responseType accordingly
            else if (response.visual !== null) {
                testMessage.response = response.visual;
                testMessage.responseType = "visual";
            }
            // If neither 'text' nor 'visual' have values, use 'table'
            else {
                testMessage.response = JSON.parse(response.table);
                testMessage.responseType = "table";
            }

            setLoadingType(false);
            setMessages((prevMessages) => [...prevMessages, testMessage]);
        }
        // socketHooks?.sendJsonMessage({
        //     message: textFieldValue.trim().toLowerCase(),
        // });
        setTextFieldValue("");
    };

    const lastMessageRef = useRef<HTMLDivElement>(null);

    // useEffect(() => {
    //     if (lastMessageRef.current) {
    //         lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    //     }
    // }, [messages]);

    const res = typeof invoiceResponse === "string" ? JSON.parse(invoiceResponse) : invoiceResponse;
    // console.log({ res });
    const valid_invoice_data_entries = res.retrieved_invoice_data || [];
    const valid_invoice_data_headers: (keyof (typeof invoiceResponse.retrieved_invoice_data)[0])[] = Array.from(
        new Set(valid_invoice_data_entries.flatMap((obj: any) => Object.keys(obj) as (keyof typeof obj)[]))
    ); // Extract headers dynamically

    const invalid_invoice_data_entries = res.missing_invoice_number || [];
    const invalid_invoice_data_headers: (keyof (typeof invoiceResponse.missing_invoice_number)[0])[] = Array.from(
        new Set(valid_invoice_data_entries.flatMap((obj: object) => Object.keys(obj) as (keyof typeof obj)[]))
    ); // Extract headers dynamically

    const je_dump_data_entries = res.je_dump_data || [];
    const je_dump_data_headers: (keyof (typeof invoiceResponse.je_dump_data)[0])[] = Array.from(
        new Set(je_dump_data_entries.flatMap((obj: object) => Object.keys(obj) as (keyof typeof obj)[]))
    ); // Extract headers dynamically

    // console.log({ je_dump_data_headers });
    // console.log({ je_dump_data_entries });

    const matched_entries = (res.matched_entries as typeof invoiceResponse.matched_entries) || []; // If null, return empty array
    const matched_entries_headers: (keyof (typeof matched_entries)[0])[] =
        matched_entries.length > 0
            ? Array.from(new Set(matched_entries.flatMap((obj: object) => Object.keys(obj) as (keyof typeof obj)[])))
            : []; // Extract headers dynamically

    const possibility_entries = (res.possibility_of_anomalies as typeof invoiceResponse.possibility_of_anomalies) || [];
    const possibility_entries_headers: (keyof (typeof invoiceResponse.possibility_of_anomalies)[0])[] = Array.from(
        new Set(possibility_entries.flatMap((obj: object) => Object.keys(obj) as (keyof typeof obj)[]))
    ); // Extract headers dynamically

    const dataArray: any[] = [
        { invalid_invoice_data_entries },
        { valid_invoice_data_entries },
        { possibility_entries },
        { matched_entries },
        { je_dump_data_entries },
    ];

    const handleDownload = async () => {
        const fileName = "download";
        const exportType = "xlsx"; // Adjust this based on your export type (xls, xlsx, etc.)

        const workbook = new ExcelJS.Workbook();

        dataArray.forEach((data, index) => {
            const sheetName = Object.keys(data)[0];
            const worksheet = workbook.addWorksheet(sheetName);

            // Add headers
            const headers: any = Object.keys(data[Object.keys(data)[0]][0] || {});
            worksheet.addRow(headers);

            // Add data
            data[Object.keys(data)[0]].forEach((entry: any) => {
                const values = Object.values(entry);
                worksheet.addRow(values);
            });
        });

        // Create a buffer from the workbook
        const excelBuffer = await workbook.xlsx.writeBuffer();

        // Create a Blob from the buffer
        const blob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a link and trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = `${fileName}.${exportType}`;
        document.body.appendChild(a);
        a.click();

        // Remove the link
        document.body.removeChild(a);
    };

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "720px",
        height: "fit-content",
        bgcolor: "background.paper",
        // border: "2px solid #000",
        borderRadius: "12px",
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box sx={{ px: "20px", position: "relative", height: "100%" }}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img
                        src={modalImage}
                        alt="graph"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            cursor: "pointer",
                            // border:"2px solid red"
                        }}
                    />
                </Box>
            </Modal>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "8%" }}>
                <Box
                    sx={{ my: "20px", display: "flex", cursor: "pointer" }}
                    onClick={() => {
                        if (!isChat_selected) {
                            setTableGenerate(false);
                        } else {
                            setIsChat_selected(false);
                        }
                    }}
                >
                    <ArrowBackIosIcon sx={{ color: "#007BFF" }} />
                    <Typography sx={{ color: "#007BFF" }}>Back</Typography>
                </Box>

                <IconButton
                    onClick={() => {
                        handleDownload();
                    }}
                    // sx={{ background: "#007BFF", "&:hover": { background: "#007BFFaa" } }}
                >
                    {/* <SendWhiteIcon /> */}
                    {!isChat_selected && <DownloadIcon />}
                </IconButton>
            </Box>
            {!isChat_selected ? (
                <Box>
                    {/* <Button onClick={handleExportToExcel}>Export to Excel</Button> */}
                    <Typography variant="h6" sx={{ mb: "20px", color: "#007BFF", width: "80%" }}>
                        Summary: <span style={{ color: "black" }}>{res?.summary}</span>
                    </Typography>

                    <Typography variant="h6">Invoice Data </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            borderBottom: "1px solid #E4E4E4",
                            mb: "20px",
                            // border: "1px solid red",
                        }}
                    >
                        <Button
                            sx={{
                                width: "fit-content",
                                borderBottom: isvalid ? "2px solid  #007BFF" : "",
                                borderRadius: "0px",
                                fontSize: "1.3rem",
                                marginRight: "12px",
                                color: isvalid ? "#007BFF" : "black",
                                // border: "1px solid red",
                            }}
                            onClick={() => {
                                setIsValid(true);
                            }}
                        >
                            Retrieved Invoice Data
                        </Button>
                        <Button
                            sx={{
                                width: "fit-content",
                                borderBottom: !isvalid ? "2px solid  #007BFF" : "",
                                borderRadius: "0px",
                                fontSize: "1.3rem",
                                marginRight: "12px",
                                color: !isvalid ? "#007BFF" : "black",
                            }}
                            onClick={() => {
                                setIsValid(false);
                            }}
                        >
                            Missing Invoice Data
                        </Button>
                    </Box>
                    {isvalid ? (
                        <Box
                            sx={{
                                height: valid_invoice_data_entries?.length > 0 ? "300px" : "fit-content",
                                overflow: "scroll",
                                width: "100%",
                                border: "1px solid grey",
                                margin: "0 auto",
                                marginBottom: "15px",
                            }}
                        >
                            {valid_invoice_data_entries?.length > 0 && (
                                <Table
                                    sx={{
                                        "& thead th": { textAlign: "center" },
                                        "& tbody tr:nth-of-type(even)": { backgroundColor: "#f0f8ff" },
                                    }}
                                    stickyHeader
                                >
                                    <TableHead>
                                        <TableRow>
                                            {valid_invoice_data_headers.map((header, index) => (
                                                <TableCell
                                                    key={index}
                                                    sx={{
                                                        fontWeight: "bold",
                                                        whiteSpace: "nowrap",
                                                        border: "1px solid #DCDCDC",
                                                    }}
                                                >
                                                    {header === "transactional_amount"
                                                        ? "Invoice Amount"
                                                        : header === "invoice_number"
                                                          ? "Invoice Number"
                                                          : header === "invoice_date"
                                                            ? "Invoice Date"
                                                            : header.charAt(0).toUpperCase() +
                                                              header.slice(1).replace(/_/g, " ")}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {valid_invoice_data_entries.map((entry: any, entryIndex: any) => (
                                            <TableRow key={entryIndex} sx={{ whiteSpace: "nowrap" }}>
                                                {valid_invoice_data_headers.map((header, colIndex) => (
                                                    <TableCell
                                                        key={colIndex}
                                                        align="center"
                                                        sx={{ border: "1px solid #DCDCDC" }}
                                                    >
                                                        {header === "transactional_amount" &&
                                                        entry[header] != null &&
                                                        entry[header] !== ""
                                                            ? Number(entry[header]).toLocaleString() // Format number with commas
                                                            : header === "description" && Array.isArray(entry[header])
                                                              ? entry[header].join(", ")
                                                              : entry[header] !== null
                                                                ? entry[header]
                                                                : "-"}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                            {valid_invoice_data_entries?.length <= 0 && (
                                <Typography sx={{ width: "100%", textAlign: "center", my: "12px" }}>
                                    No Data Found
                                </Typography>
                            )}
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                height: valid_invoice_data_entries?.length > 0 ? "300px" : "fit-content",
                                overflow: "scroll",
                                width: "100%",
                                border: "1px solid grey",
                                margin: "0 auto",
                                marginBottom: "15px",
                            }}
                        >
                            {valid_invoice_data_entries?.length > 0 && (
                                <Table
                                    sx={{
                                        "& thead th": { textAlign: "center" },
                                        "& tbody tr:nth-of-type(even)": { backgroundColor: "#f0f8ff" },
                                    }}
                                    stickyHeader
                                >
                                    <TableHead>
                                        <TableRow>
                                            {invalid_invoice_data_headers.map((header: any, index: any) => (
                                                <TableCell
                                                    key={index}
                                                    sx={{
                                                        fontWeight: "bold",
                                                        whiteSpace: "nowrap",
                                                        border: "1px solid #DCDCDC",
                                                    }}
                                                >
                                                    {header === "transactional_amount"
                                                        ? "Invoice Amount"
                                                        : header
                                                              .split("_")
                                                              .map(
                                                                  (word: any) =>
                                                                      word.charAt(0).toUpperCase() + word.slice(1)
                                                              )
                                                              .join(" ")}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {invalid_invoice_data_entries.map((entry: any, entryIndex: number) => (
                                            <TableRow
                                                key={entryIndex}
                                                sx={{
                                                    whitespace: "nowrap",
                                                }}
                                            >
                                                {invalid_invoice_data_headers.map((header, colIndex) => (
                                                    <TableCell
                                                        key={colIndex}
                                                        align="center"
                                                        sx={{
                                                            border: "1px solid #DCDCDC",
                                                        }}
                                                    >
                                                        {header === "transactional_amount" &&
                                                        entry[header] != null &&
                                                        entry[header] !== ""
                                                            ? Number(entry[header]).toLocaleString() // Format number with commas
                                                            : entry[header] !== null
                                                              ? entry[header]
                                                              : "-"}{" "}
                                                        {/* Display "-" for null values */}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                            {valid_invoice_data_entries?.length <= 0 && (
                                <Typography sx={{ width: "100%", textAlign: "center", my: "12px" }}>
                                    No Data Found
                                </Typography>
                            )}
                        </Box>
                    )}

                    {je_dump_data_entries?.length > 0 && (
                        <Box sx={{ mb: "15px" }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        backgroundColor: "#E3F4FF", // Replace 'yourColor' with your desired color code
                                        color: "#007BFF", // Text color (optional)
                                    }}
                                >
                                    {`JE Dump Entries (${je_dump_data_entries?.length})`}{" "}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box
                                        sx={{
                                            height: "300px",
                                            overflow: "scroll",
                                            width: "100%",
                                            border: "1px solid grey",
                                            marginBottom: "25px",
                                        }}
                                    >
                                        <Table
                                            sx={{
                                                "& thead th": { textAlign: "center" },
                                                "& tbody tr:nth-of-type(odd)": { backgroundColor: "#f0f8ff" },
                                            }}
                                            stickyHeader
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    {je_dump_data_headers.map((header: any, index: any) => (
                                                        <TableCell
                                                            key={index}
                                                            sx={{
                                                                fontWeight: "bold",
                                                                whiteSpace: "nowrap",
                                                                border: "1px solid #DCDCDC",
                                                            }}
                                                        >
                                                            {
                                                                header
                                                                    .split("_")
                                                                    .map(
                                                                        (word: any) =>
                                                                            word.charAt(0).toUpperCase() + word.slice(1)
                                                                    )
                                                                    .join(" ")
                                                                    .replace(/\//g, " ") // Use regex to globally replace "/"
                                                            }
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {je_dump_data_entries.map((entry: any, entryIndex: number) => (
                                                    <TableRow key={entryIndex} sx={{ whiteSpace: "nowrap" }}>
                                                        {je_dump_data_headers.map((header: any, colIndex: any) => (
                                                            <TableCell
                                                                key={colIndex}
                                                                align="center"
                                                                sx={{ border: "1px solid #DCDCDC" }}
                                                            >
                                                                {header === "transactional_amount" ||
                                                                (header === "functional_amount" &&
                                                                    entry[header] != null &&
                                                                    entry[header] !== "")
                                                                    ? Number(entry[header]).toLocaleString() // Format number with commas
                                                                    : entry[header] !== null
                                                                      ? entry[header]
                                                                      : "-"}{" "}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    )}

                    {matched_entries.length > 0 && (
                        <Box sx={{ mb: "15px" }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        backgroundColor: "#E3F4FF", // Replace 'yourColor' with your desired color code
                                        color: "#007BFF", // Text color (optional)
                                    }}
                                >
                                    {` Matched Entries (${matched_entries.length})`}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box
                                        sx={{
                                            height: "300px",
                                            overflow: "scroll",
                                            width: "100%",
                                            border: "1px solid grey",
                                            marginBottom: "25px",
                                        }}
                                    >
                                        <Table
                                            sx={{
                                                "& thead th": { textAlign: "center" },
                                                "& tbody tr:nth-of-type(odd)": { backgroundColor: "#f0f8ff" },
                                            }}
                                            stickyHeader
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    {matched_entries_headers.map((header: any, index: any) => (
                                                        <TableCell
                                                            key={index}
                                                            sx={{
                                                                fontWeight: "bold",
                                                                whiteSpace: "nowrap",
                                                                border: "1px solid #DCDCDC",
                                                            }}
                                                        >
                                                            {header
                                                                .split("_")
                                                                .map(
                                                                    (word: any) =>
                                                                        word.charAt(0).toUpperCase() + word.slice(1)
                                                                )
                                                                .join(" ")}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {matched_entries.map((entry: any, entryIndex: number) => (
                                                    <TableRow key={entryIndex} sx={{ whiteSpace: "nowrap" }}>
                                                        {matched_entries_headers.map((header: any, colIndex: any) => (
                                                            <TableCell
                                                                key={colIndex}
                                                                align="center"
                                                                sx={{ border: "1px solid #DCDCDC" }}
                                                            >
                                                                {header === "transactional_amount" &&
                                                                entry[header] != null &&
                                                                entry[header] !== ""
                                                                    ? Number(entry[header]).toLocaleString() // Format number with commas
                                                                    : entry[header] != null && entry[header] !== "" // If not transactional_amount, just display
                                                                      ? header === "description" &&
                                                                        /[']|\[|\]/g.test(entry[header])
                                                                          ? entry[header].replace(/[']|\[|\]/g, "") // Remove single quotes, [, and ] for description column
                                                                          : entry[header]
                                                                      : "-"}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    )}

                    {possibility_entries?.length > 0 && (
                        <Box sx={{ mb: "15px" }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={{
                                        backgroundColor: "#E3F4FF", // Replace 'yourColor' with your desired color code
                                        color: "#007BFF", // Text color (optional)
                                        border: "2px dashed red",
                                    }}
                                >
                                    {`  Possibility of Anomalies (${possibility_entries.length})`}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box
                                        sx={{
                                            height: "300px",
                                            overflow: "scroll",
                                            width: "100%",
                                            border: "1px solid grey",
                                            marginBottom: "25px",
                                        }}
                                    >
                                        <Table
                                            sx={{
                                                "& thead th": { textAlign: "center" },
                                                "& tbody tr:nth-of-type(odd)": { backgroundColor: "#FF474C" },
                                            }}
                                            stickyHeader
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    {possibility_entries_headers.map((header: any, index: any) => (
                                                        <TableCell
                                                            key={index}
                                                            sx={{
                                                                fontWeight: "bold",
                                                                whiteSpace: "nowrap",
                                                                border: "1px solid #DCDCDC",
                                                            }}
                                                        >
                                                            {header
                                                                .split("_")
                                                                .map(
                                                                    (word: any) =>
                                                                        word.charAt(0).toUpperCase() + word.slice(1)
                                                                )
                                                                .join(" ")}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {possibility_entries.map((entry: any, entryIndex: number) => (
                                                    <TableRow key={entryIndex} sx={{ whiteSpace: "nowrap" }}>
                                                        {possibility_entries_headers.map(
                                                            (header: any, colIndex: any) => (
                                                                <TableCell
                                                                    key={colIndex}
                                                                    align={header === "comment" ? "left" : "center"}
                                                                    sx={{ border: "1px solid #DCDCDC" }}
                                                                >
                                                                    {header === "transactional_amount" &&
                                                                    entry[header] != null &&
                                                                    entry[header] !== ""
                                                                        ? Number(entry[header]).toLocaleString() // Format number with commas
                                                                        : entry[header] != null && entry[header] !== "" // If not transactional_amount, just display
                                                                          ? header === "description" &&
                                                                            /[']|\[|\]/g.test(entry[header])
                                                                              ? entry[header].replace(/[']|\[|\]/g, "") // Remove single quotes, [, and ] for description column
                                                                              : entry[header]
                                                                          : "-"}
                                                                </TableCell>
                                                            )
                                                        )}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    )}

                    {/* Floating button */}
                    <Button
                        variant="contained"
                        color="primary" // Set the primary color for the border
                        sx={{
                            position: "fixed",
                            bottom: "20px",
                            right: "20px",
                            zIndex: "999",
                            fontWeight: "bold",
                            borderRadius: "20px",
                            backgroundColor: "white", // Set the background color to white
                            color: "#007BFF", // Set the text color to blue
                            "&:hover": {
                                backgroundColor: "white", // Adjust hover background color if needed
                            },
                            "& .MuiButton-startIcon": {
                                color: "blue", // Set the icon color to blue
                            },
                            border: "2px solid", // Add a border
                            borderColor: "primary.main", // Set the border color to primary color
                        }}
                        startIcon={<ChatIcon fill={"#007BFF"} />}
                        onClick={() => {
                            // Handle button click
                            setIsChat_selected(true);
                        }}
                    >
                        Chat
                    </Button>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "90%", // Set the parent container to 100% height
                        width: "97.5%", // Set the parent container to 100% height
                        position: "absolute",
                        // border: "2px solid black",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            height: "fit-content",
                            // border: "2px solid red",
                        }}
                    >
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{
                                    backgroundColor: "#E3F4FF", // Replace 'yourColor' with your desired color code
                                    color: "#007BFF", // Text color (optional)
                                    border: "2px dashed #007BFF",
                                }}
                            >
                                {`  Possibility of Anomalies (${possibility_entries.length})`}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box
                                    sx={{
                                        height: "300px",
                                        overflow: "scroll",
                                        width: "100%",
                                        border: "1px solid grey",
                                        marginBottom: "25px",
                                    }}
                                >
                                    <Table
                                        sx={{
                                            "& thead th": { textAlign: "center" },
                                            "& tbody tr:nth-of-type(odd)": { backgroundColor: "#f0f8ff" },
                                        }}
                                        stickyHeader
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {possibility_entries_headers.map((header: any, index: any) => (
                                                    <TableCell
                                                        key={index}
                                                        sx={{
                                                            fontWeight: "bold",
                                                            whiteSpace: "nowrap",
                                                            border: "1px solid #DCDCDC",
                                                        }}
                                                    >
                                                        {header
                                                            .split("_")
                                                            .map(
                                                                (word: any) =>
                                                                    word.charAt(0).toUpperCase() + word.slice(1)
                                                            )
                                                            .join(" ")}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {possibility_entries.map((entry: any, entryIndex: number) => (
                                                <TableRow key={entryIndex} sx={{ whiteSpace: "nowrap" }}>
                                                    {possibility_entries_headers.map((header: any, colIndex: any) => (
                                                        <TableCell
                                                            key={colIndex}
                                                            align={header === "comment" ? "left" : "center"}
                                                            sx={{ border: "1px solid #DCDCDC" }}
                                                        >
                                                            {header === "transactional_amount" &&
                                                            entry[header] != null &&
                                                            entry[header] !== ""
                                                                ? Number(entry[header]).toLocaleString() // Format number with commas
                                                                : entry[header] != null && entry[header] !== "" // If not transactional_amount, just display
                                                                  ? header === "description" &&
                                                                    /[']|\[|\]/g.test(entry[header])
                                                                      ? entry[header].replace(/[']|\[|\]/g, "") // Remove single quotes, [, and ] for description column
                                                                      : entry[header]
                                                                  : "-"}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            // border: "2px solid green",
                            height: "70%",
                            minHeight: "20%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ height: "100%", width: "100%", overflowY: "scroll" }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%",
                                    width: "100%",
                                    overflowY: "scroll",
                                    // border:"1px solid red",
                                    // padding: "10px",
                                }}
                            >
                                {messages.map((message, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            justifyContent: message.isReceived ? "flex-start" : "flex-end",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: message.isReceived
                                                    ? message.responseType !== "visual"
                                                        ? "#E1E7ED"
                                                        : ""
                                                    : "#e3f4ff",
                                                color: "black",
                                                padding: "10px",
                                                borderRadius: "10px",
                                                maxWidth: "75%",
                                                wordWrap: "break-word",
                                            }}
                                        >
                                            {/* {loadingType && (
                                                <div
                                                >
                                                    Loading...
                                                </div>)} */}
                                            {message.responseType === "text" && (
                                                <div dangerouslySetInnerHTML={{ __html: message.response }} />
                                            )}
                                            {message.responseType === "table" && (
                                                <Table sx={{ border: "1px solid #DCDCDC" }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            {Object.keys(message.response[0]).map((header, index) => (
                                                                <TableCell
                                                                    key={index}
                                                                    sx={{
                                                                        fontWeight: "bold",
                                                                        whiteSpace: "nowrap",
                                                                        border: "1px solid #DCDCDC",
                                                                    }}
                                                                >
                                                                    {header
                                                                        .split("_")
                                                                        .map(
                                                                            (word) =>
                                                                                word.charAt(0).toUpperCase() +
                                                                                word.slice(1)
                                                                        )
                                                                        .join(" ")}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {Array.isArray(message.response) &&
                                                            message.response.map((entry, entryIndex) => (
                                                                <TableRow
                                                                    key={entryIndex}
                                                                    // sx={{ whiteSpace: "nowrap" }}
                                                                >
                                                                    {possibility_entries_headers.map(
                                                                        (header: any, colIndex: any) => (
                                                                            <TableCell
                                                                                key={colIndex}
                                                                                align={
                                                                                    header === "comment"
                                                                                        ? "left"
                                                                                        : "center"
                                                                                }
                                                                                sx={{ border: "1px solid #DCDCDC" }}
                                                                            >
                                                                                {header === "transactional_amount" &&
                                                                                entry[header] != null &&
                                                                                entry[header] !== ""
                                                                                    ? Number(
                                                                                          entry[header]
                                                                                      ).toLocaleString() // Format number with commas
                                                                                    : entry[header] != null &&
                                                                                        entry[header] !== "" // If not transactional_amount, just display
                                                                                      ? header === "description" &&
                                                                                        /[']|\[|\]/g.test(entry[header])
                                                                                          ? entry[header].replace(
                                                                                                /[']|\[|\]/g,
                                                                                                ""
                                                                                            ) // Remove single quotes, [, and ] for description column
                                                                                          : entry[header]
                                                                                      : "-"}
                                                                            </TableCell>
                                                                        )
                                                                    )}
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            )}
                                            {message.responseType === "visual" && (
                                                <Box
                                                    sx={{
                                                        maxWidth: "60%",
                                                        maxHeight: "50%",
                                                        padding: "5px",
                                                    }}
                                                >
                                                    <img
                                                        src={`data:image/jpeg;base64,${message?.response}`}
                                                        alt="graph"
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            objectFit: "contain",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            handleOpen(`data:image/jpeg;base64,${message?.response}`)
                                                        }
                                                    />
                                                </Box>
                                            )}
                                            {/* Add similar logic for other response types (e.g., visual) */}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div ref={lastMessageRef} />
                        </Box>
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "sticky",
                                bottom: 0,
                                bg: "#fff",
                                borderTop: "1px solid #DCDCDC",
                                px: "15px",
                                py: "10px",
                            }}
                        >
                            <Box sx={{ width: "100%", borderRadius: "30px" }}>
                                <InputField
                                    handleSend={handleSendClick}
                                    inputRef={inputRef}
                                    invoice_reconciliation={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default InvoiceMatching;
