import React from "react";

const ESGIcon = () => {
    return (
        <svg width="53" height="54" viewBox="0 0 53 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M50.8206 20.1459C49.1581 9.91346 40.4668 2.30109 30.1045 2.00143C29.6118 1.98211 29.1317 2.1595 28.7699 2.49449C28.4081 2.82948 28.1944 3.29458 28.1758 3.7873V3.95398L29.3425 21.4079C29.4191 22.5828 30.4292 23.476 31.6047 23.4081L49.1062 22.2413C49.5993 22.2048 50.0576 21.9732 50.3794 21.5978C50.7012 21.2223 50.86 20.7341 50.8206 20.2411V20.1459Z"
                stroke="#200E32"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17.9861 12.3838C19.0994 12.1263 20.2387 12.6959 20.7007 13.7411C20.8217 13.9869 20.8944 14.2536 20.915 14.5268C21.1531 17.9081 21.6531 25.3135 21.9389 29.3139C21.9877 30.0345 22.3216 30.7059 22.8668 31.1796C23.412 31.6533 24.1235 31.8902 24.8439 31.8379L39.5357 30.933C40.1916 30.8936 40.8346 31.127 41.3125 31.5779C41.7905 32.0288 42.0609 32.6571 42.0597 33.3142C41.4643 42.186 35.0902 49.6028 26.4088 51.5252C17.7273 53.4477 8.81788 49.4155 4.53258 41.6245C3.25333 39.3821 2.44321 36.9032 2.15142 34.3381C2.02667 33.5509 1.97882 32.7534 2.00855 31.9569C2.03382 22.4826 8.68771 14.3191 17.9623 12.3838"
                stroke="#200E32"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default ESGIcon;
