import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/setting";
import SoftSensorXLogo from "../../components/Common/svgLogs/SoftSensorXLogo";
import AllignmentIcon1 from "../../components/Common/svgLogs/DamageDetectionTest/CarAlignmentIcons/AllignmentIcon1";
import AlignmentIcon2 from "../../components/Common/svgLogs/DamageDetectionTest/CarAlignmentIcons/AlignmentIcon2";
import AlignmentIcon3 from "../../components/Common/svgLogs/DamageDetectionTest/CarAlignmentIcons/AlignmentIcon3";
import AlignmentIcon4 from "../../components/Common/svgLogs/DamageDetectionTest/CarAlignmentIcons/AlignmentIcon4";
import AlignmentIcon5 from "../../components/Common/svgLogs/DamageDetectionTest/CarAlignmentIcons/AlignmentIcon5";
import AlignmentIcon6 from "../../components/Common/svgLogs/DamageDetectionTest/CarAlignmentIcons/AlignmentIcon6";
import AlignmentIcon7 from "../../components/Common/svgLogs/DamageDetectionTest/CarAlignmentIcons/AlignmentIcon7";
import AlignementIcon8 from "../../components/Common/svgLogs/DamageDetectionTest/CarAlignmentIcons/AlignmentIcon8";

const InfoAndCaptureButton = ({ imageIndex, setImageIndex, captureImage, loadingTask }: any) => {
    const { contextStore, setContext } = useContext<any>(Context);

    const matches = useMediaQuery("(min-width:1000px)");
    useEffect(() => {
        if (imageIndex === 8) {
            loadingTask();
        }
    }, [imageIndex]);

    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                zIndex: 5,
                boxSizing: "border-box",
                position: "absolute",
                fontSize: !matches ? "12px" : "16px",
            }}
        >
            {/* top part of the screen */}
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                    boxSizing: "border-box",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1", color: "#fff" }}>
                    <SoftSensorXLogo />
                    <Typography variant="body2" sx={{ mt: "7%" }}>{`Captured ${imageIndex}/8`}</Typography>
                    <Typography variant="body2">
                        {imageIndex === 0 && "DRIVER SIDE"}
                        {imageIndex === 1 && "FRONT-RIGHT"}
                        {imageIndex === 2 && "FRONT"}
                        {imageIndex === 3 && "FRONT-LEFT"}
                        {imageIndex === 4 && "PASSENGER SIDE"}
                        {imageIndex === 5 && "REAR-RIGHT"}
                        {imageIndex === 6 && "REAR"}
                        {imageIndex === 7 && "REAR-LEFT"}
                    </Typography>
                </Box>
                <Box>
                    <Typography sx={{ color: "#fff" }}>{contextStore?.profile?.userId}</Typography>
                </Box>
            </Box>

            {/* bottom part info and icons */}
            <Box
                sx={{
                    position: "absolute",
                    bottom: "0",
                    width: "100%",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    height: "60px",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        background: "rgba(44, 44, 44, 0.76)",
                        width: "60%",
                        textAlign: "center",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50px 50px 0 0",
                        p: 1,
                    }}
                >
                    Stand at a safe distance of 10 feet from the driver’s door and align the vehicle with the lines to
                    capture its profile.
                </Box>
            </Box>

            {/* Car alignment icon */}
            <Box
                sx={{
                    position: "absolute",
                    bottom: "6vh",
                    p: 2,
                    left: matches ? "2vw" : null,
                    transform: matches ? "scale(1.5)" : "scale(1)",
                }}
            >
                {imageIndex === 0 && <AllignmentIcon1 />}
                {imageIndex === 1 && <AlignmentIcon2 />}
                {imageIndex === 2 && <AlignmentIcon3 />}
                {imageIndex === 3 && <AlignmentIcon4 />}
                {imageIndex === 4 && <AlignmentIcon5 />}
                {imageIndex === 5 && <AlignmentIcon6 />}
                {imageIndex === 6 && <AlignmentIcon7 />}
                {imageIndex === 7 && <AlignementIcon8 />}
            </Box>
            <Button
                sx={{ position: "absolute", right: "5%", bottom: "50%", top: "50%" }}
                onClick={() => captureImage(imageIndex)}
            >
                <Box sx={{ width: "64px", height: "64px", background: "#E8E8E8", borderRadius: "50%", padding: "8px" }}>
                    <Box sx={{ background: "#B4B4B4", width: "100%", height: "100%", borderRadius: "50%" }} />
                </Box>
            </Button>
        </Box>
    );
};

export default InfoAndCaptureButton;

//
