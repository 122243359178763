import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../context/setting";
import UploadBoxIcon from "../../components/Common/svgLogs/UploadBoxIcon";
import { Api } from "../../apis";
import InvoiceMatching from "./InvoiceMatching";
import axios from "axios";
import Loading from "./Loading";
import Environment from "../../enviroment";

interface Report_reconcilieationProps {}
interface InvoiceResponseState {
    summary: string;
    retrieved_invoice_data: [
        {
            invoice_number: string;
            invoice_date: string;
            vendor: string;
            transactional_amount: number;
            description: string | null;
        },
    ];
    missing_invoice_number: [
        {
            invoice_number: string;
            invoice_date: string;
            vendor: string;
            transactional_amount: number;
            description: string | null;
        },
    ];
    je_dump_data: [
        {
            period: string;
            account: string;
            accounting_date: number;
            business_unit: string;
            contract_type_reinsurance: string;
            gl_posted_date: number;
            gl_journal_category: string;
            internal_external_account: string;
            management_product: string;
            obu: string;
            producing_office_code: string | null;
            producing_office_description: string | null;
            reversal_flag: string;
            source: string;
            sub_region_xl_loc_: string | null;
            underwriting_year: number | null;
            web_adi_manual_adjustment_id: number | null;
            web_adi_adjustment_description: string;
            web_adi_adjuster: string | null;
            web_adi_approver: string | null;
            functional_currency_code: string;
            transactional_currency_code: string;
            bu_consolidation_flag: string;
            functional_amount: number;
            transactional_amount: number;
            vendor: string;
            invoice_number: number;
            invoice_date: number;
            authorised_from: string | null;
        },
    ];
    possibility_of_anomalies: [
        {
            invoice_number: string;
            invoice_date: string;
            vendor: string;
            transactional_amount: number;
            description: string | null;
            comment: string;
        },
    ];
    matched_entries: [
        {
            invoice_number: string;
            invoice_date: string;
            vendor: string;
            transactional_amount: number | null;
            transaction_amount: number | null;
            description: string | null;
        },
    ];
}

const Report_reconcilieation: React.FC<Report_reconcilieationProps> = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [loader, setLoader] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState<File[] | null>(null);
    const [tableGenerate, setTableGenerate] = useState(false);
    const [invoiceResponse, setInvoiceResponse] = useState<InvoiceResponseState>({
        summary: "",
        retrieved_invoice_data: [
            {
                invoice_number: "",
                invoice_date: "",
                vendor: "",
                transactional_amount: 0,
                description: "",
            },
        ],
        missing_invoice_number: [
            {
                invoice_number: "",
                invoice_date: "",
                vendor: "",
                transactional_amount: 0,
                description: "",
            },
        ],
        je_dump_data: [
            {
                period: "",
                account: "",
                accounting_date: 0,
                business_unit: "",
                contract_type_reinsurance: "",
                gl_posted_date: 0,
                gl_journal_category: "",
                internal_external_account: "",
                management_product: "",
                obu: "",
                producing_office_code: "",
                producing_office_description: "",
                reversal_flag: "",
                source: "",
                sub_region_xl_loc_: "",
                underwriting_year: 0,
                web_adi_manual_adjustment_id: 0,
                web_adi_adjustment_description: "",
                web_adi_adjuster: "",
                web_adi_approver: "",
                functional_currency_code: "",
                transactional_currency_code: "",
                bu_consolidation_flag: "",
                functional_amount: 0,
                transactional_amount: 0,
                vendor: "",
                invoice_number: 0,
                invoice_date: 0,
                authorised_from: "",
            },
        ],
        possibility_of_anomalies: [
            {
                invoice_number: "",
                invoice_date: "",
                vendor: "",
                transactional_amount: 0,
                description: "",
                comment: "",
            },
        ],
        matched_entries: [
            {
                invoice_number: "",
                invoice_date: "",
                vendor: "",
                transactional_amount: 0,
                transaction_amount: 0,
                description: "",
            },
        ],
    });

    // console.log({ invoiceResponse });

    const handleUpload = (files: FileList, fileType: any) => {
        const fileList = Array.from(files);

        if (fileType === "invoice") {
            setSelectedInvoice(fileList);
        }
    };

    function removeBackslashesAndQuotes(obj: any) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];

                if (typeof value === "string") {
                    obj[key] = value.replace(/\\/g, "").replace(/"/g, "");
                } else if (typeof value === "object") {
                    removeBackslashesAndQuotes(value);
                }
            }
        }
    }

    const handleCheck = async () => {
        try {
            if (!selectedInvoice) {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Failed To Upload File Please Retry",
                    },
                });
                return; // exit the function if files are not selected
            }
            setLoader(true);

            const invoiceFormData = new FormData();
            // invoiceFormData.append("files", selectedInvoice);
            selectedInvoice.forEach((file, index) => {
                invoiceFormData.append(`files`, file);
            });

            // console.log({ invoiceFormData });

            // Upload bank statement
            // console.log({ selectedInvoice });

            // Invoice matching
            const STRUCTURED_URL = Environment.STRUCTURED_URL;
            // Replace 'your-api-endpoint' with the actual endpoint URL
            const apiEndpoint = `${STRUCTURED_URL}/invoice_reconciliation`;
            const invoiceMatching2 = await axios.post(apiEndpoint, invoiceFormData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (invoiceMatching2?.data && isValidInvoiceResponse(invoiceMatching2)) {
                setLoader(false);
                setTableGenerate(true);
                const result = {
                    summary: invoiceMatching2?.data.summary,
                    missing_invoice_number: JSON.parse(invoiceMatching2?.data.missing_invoice_number),
                    retrieved_invoice_data: JSON.parse(invoiceMatching2?.data.retrieved_invoice_data),
                    je_dump_data: JSON.parse(invoiceMatching2?.data.je_dump_data),
                    matched_entries: JSON.parse(invoiceMatching2?.data.matched_entries),
                    possibility_of_anomalies: JSON.parse(invoiceMatching2?.data.possibility_of_anomalies),
                };
                setInvoiceResponse(result);
                // const aa = JSON.parse(invoiceMatching.data);
            } else {
                setLoader(false);
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Something went wrong",
                    },
                });
                // Handle the case when the response doesn't match the expected structure
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    function isValidInvoiceResponse(data: any): data is InvoiceResponseState {
        return (
            typeof data === "object" &&
            "retrieved_invoice_data" in data.data &&
            "summary" in data.data &&
            "missing_invoice_number" in data.data &&
            "je_dump_data" in data.data &&
            "possibility_of_anomalies" in data.data &&
            "matched_entries" in data.data
        );
    }

    // console.log({ selectedFileStatement });

    useEffect(() => {
        if (!tableGenerate) {
            // setSelectedFileStatement(null);
            setSelectedInvoice(null);
            setInvoiceResponse({
                summary: "",
                retrieved_invoice_data: [
                    {
                        invoice_number: "",
                        invoice_date: "",
                        vendor: "",
                        transactional_amount: 0,
                        description: "",
                    },
                ],
                missing_invoice_number: [
                    {
                        invoice_number: "",
                        invoice_date: "",
                        vendor: "",
                        transactional_amount: 0,
                        description: "",
                    },
                ],
                je_dump_data: [
                    {
                        period: "",
                        account: "",
                        accounting_date: 0,
                        business_unit: "",
                        contract_type_reinsurance: "",
                        gl_posted_date: 0,
                        gl_journal_category: "",
                        internal_external_account: "",
                        management_product: "",
                        obu: "",
                        producing_office_code: "",
                        producing_office_description: "",
                        reversal_flag: "",
                        source: "",
                        sub_region_xl_loc_: "",
                        underwriting_year: 0,
                        web_adi_manual_adjustment_id: 0,
                        web_adi_adjustment_description: "",
                        web_adi_adjuster: "",
                        web_adi_approver: "",
                        functional_currency_code: "",
                        transactional_currency_code: "",
                        bu_consolidation_flag: "",
                        functional_amount: 0,
                        transactional_amount: 0,
                        vendor: "",
                        invoice_number: 0,
                        invoice_date: 0,
                        authorised_from: "",
                    },
                ],
                possibility_of_anomalies: [
                    {
                        invoice_number: "",
                        invoice_date: "",
                        vendor: "",
                        transactional_amount: 0,
                        description: "",
                        comment: "",
                    },
                ],
                matched_entries: [
                    {
                        invoice_number: "",
                        invoice_date: "",
                        vendor: "",
                        transactional_amount: 0,
                        transaction_amount: 0,
                        description: "",
                    },
                ],
            });
        }
    }, [tableGenerate]);

    return !tableGenerate && !loader ? (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                // border:"1px solid red"
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                <Box
                    sx={{
                        minWidth: "600px",
                        width: "100%",
                        minHeight: "300px",
                        height: "100%",
                        backgroundColor: "white", // You can adjust the background color
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        flexDirection: "column",
                        boxShadow: "0px 4px 9.8px 0px rgba(0, 0, 0, 0.06)",
                        borderRadius: "0.68rem",
                    }}
                >
                    <Typography sx={{ fontWeight: "500", fontSize: "2rem" }}>Invoice Reconciliation</Typography>
                    <Typography sx={{ fontWeight: "500", fontSize: "1rem" }}>
                        Upload files to compare with Accounting Records
                    </Typography>
                    <Box sx={{ border: "1px solid #f2f2f2", borderRadius: "10px" }}>
                        {selectedInvoice ? (
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    // overflow: "scroll",
                                    p: "10px",
                                }}
                            >
                                {/* <CircularProgress style={{ width: "25px", height: "25px" }} /> */}
                                {selectedInvoice && selectedInvoice.length > 0 && (
                                    <Box sx={{ height: "100px", width: "100%", overflowY: "scroll" }}>
                                        {/* Use map to iterate over the first three elements of selectedInvoice and display names */}
                                        {selectedInvoice?.map((invoice, index) => (
                                            <React.Fragment key={index}>
                                                <Typography>{invoice.name}</Typography>
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "10vh",
                                        p: 1,
                                        pl: 4,
                                        minHeight: "50px",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                    component={"label"}
                                    onDrop={(e: any) => {
                                        e.preventDefault();
                                        setSelectedInvoice(e?.dataTransfer?.files);
                                        handleUpload(e?.dataTransfer?.files, "invoice");
                                    }}
                                    onDragOver={(e: any) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    <Box sx={{ transform: "scale(0.6)" }}>
                                        <UploadBoxIcon />
                                    </Box>
                                    <Box
                                        sx={{
                                            height: "100%",
                                            width: "100%",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "300px",
                                                height: "100%",
                                                justifyContent: "center",
                                                display: "flex",
                                                ml: 2,
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                            }}
                                        >
                                            <Typography variant="h5">
                                                Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                                            </Typography>
                                        </Box>

                                        <input
                                            hidden
                                            type="file"
                                            multiple
                                            accept=".pdf"
                                            onChange={(e: { target: { files: any; value: any } }) => {
                                                // console.log(e?.target?.files);
                                                handleUpload(e?.target?.files, "invoice");
                                                // setOpen(true);

                                                e.target.value = "";
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        maxHeight: "42vh",
                                        overflow: "auto",
                                        display: "flex",
                                        flexDirection: "column",
                                        p: 1,
                                        gap: 0.8,
                                    }}
                                ></Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <Button
                variant="contained"
                disableRipple
                sx={{ fontSize: "1rem", px: "40px", my: "15px" }}
                onClick={() => handleCheck()}
            >
                Check
            </Button>
        </Box>
    ) : loader ? (
        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Loading />
        </Box>
    ) : (
        <Box sx={{ width: "100%", height: "100%", overflowY: "scroll" }}>
            <InvoiceMatching invoiceResponse={invoiceResponse} setTableGenerate={setTableGenerate} />
        </Box>
    );
};

export default Report_reconcilieation;
