import React from "react";

const Loader = () => {
    return (
        <svg height="10" width="40">
            <circle className="dot_blick" cx="10" cy="5" r="3" fill={"grey"} />
            <circle className="dot_blick" cx="20" cy="5" r="3" fill={"grey"} />
            <circle className="dot_blick" cx="30" cy="5" r="3" fill={"grey"} />
        </svg>
    );
};

export default Loader;
