import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, OutlinedInput, Typography } from "@mui/material";
import ESGSearchIcon from "../../../components/Common/svgLogs/ESGSearchIcon";
import SendWhiteIcon from "../../../components/Common/svgLogs/SendWhiteIcon";
import { Context } from "../../../context/setting";
import { KeyboardVoice, Mic, PauseCircle, PlayArrow } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

var a: any;

const InputField = ({
    audioFile,
    setAudioFile,
    s3AudioFile,
    sets3AudioFile,
    handleSend,
    inputRef,
    disabled,
    handleSendFabricQuery,
    invoice_reconciliation,
}: any) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [query, setQuery] = useState<string>("");
    const [disable, setDisable] = useState(false);
    const [activeMic, setActiveMic] = useState<boolean>(false);
    const [buttonName, setButtonName] = useState<any>("Play");
    const [audio, setAudio] = useState<any>(null);

    useEffect(() => {
        setDisable(disabled);
    }, [disabled]);

    useEffect(() => {
        if (audioFile) {
            setAudio(URL.createObjectURL(audioFile));
        }
    }, [audioFile]);

    useEffect(() => {
        if (contextStore?.activeSource === "audio") {
            if (a) {
                a.pause();
                setAudio(null);
                setButtonName("Play");
            }
            if (audio) {
                a = new Audio(audio);
                a.onended = () => {
                    setButtonName("Play");
                };
            }
            if (s3AudioFile) {
                a = new Audio(s3AudioFile);
                a.onended = () => {
                    setButtonName("Play");
                };
            }
        }
        // if (contextStore?.activeSource !== "audio" && a) {
        //     a.pause();
        //     setButtonName("Pause");
        //     a = null;
        // }
    }, [audio, s3AudioFile, contextStore?.activeSource]);

    const handleClickPlay = () => {
        console.log("inside");
        if (contextStore?.activeSource === "audio") {
            if (buttonName === "Play") {
                a.play();
                setButtonName("Pause");
            } else {
                a.pause();
                setButtonName("Play");
            }
        }
    };

    const addAudioElement = (blob: Blob) => {
        const url = URL.createObjectURL(blob);
        const audiofile = new File([url], "audiofile.wav", {
            type: "audio/wav",
        });
        setAudioFile(audiofile);
    };

    const wind: any = window;
    const speechRecognization = wind?.SpeechRecognition || wind?.webkitSpeechRecognition;
    const rec = new speechRecognization();

    const handleMicorphone = () => {
        setActiveMic(true);
        rec.lang = "en-US";
        // rec.lang = "hi-IN";
        rec.continuous = true;
        rec.interimResults = true;
        rec?.start();
        rec.onresult = (res: any) => {
            let text: string = Array.from(res.results)
                .map((r: any) => r[0])
                .map((txt: any) => txt.transcript)
                .join("");
            setQuery(text);
        };
        setTimeout(() => {
            setActiveMic(false);
            rec?.stop();
            inputRef?.current?.click();
        }, 7000);
    };

    return (
        <OutlinedInput
            ref={inputRef}
            value={query}
            disabled={disable}
            onChange={(e: { target: { value: string }; preventDefault: any }) => {
                setQuery(e.target.value);
            }}
            onKeyDown={(e: any) => {
                if (e?.key === "Enter") {
                    if (e?.target?.value?.length === 0) {
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "warning",
                                message: "Please Ask A Question",
                            },
                        });
                    } else {
                        setQuery(() => "");
                        if (contextStore.activeSource === "Fabric") {
                            handleSendFabricQuery(e?.target?.value);
                            rec?.stop();
                        } else {
                            if (query?.length === 0) {
                                setContext({
                                    ...contextStore,
                                    snackState: {
                                        open: true,
                                        errorType: "warning",
                                        message: "Please Ask A Question",
                                    },
                                });
                            } else {
                                setQuery(() => "");
                                handleSend(e?.target?.value, "general");
                                rec?.stop();
                            }
                        }
                    }
                }
                return;
            }}
            fullWidth
            endAdornment={
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {/* {contextStore?.activeSource === "excel" && (
                        <IconButton onClick={() => handleSend(query, "sql")}>
                            <ESGSearchIcon />
                        </IconButton>
                    )} */}
                    <IconButton
                        sx={{ background: "#007BFF", "&:hover": { background: "#007BFFaa" } }}
                        onClick={() => {
                            if (contextStore.activeSource === "Fabric" && !invoice_reconciliation) {
                                handleSendFabricQuery(query);
                                setQuery(() => "");
                            } else if (invoice_reconciliation) {
                                setQuery(() => "");
                                handleSend(query, "general");
                            } else {
                                if (query?.length === 0) {
                                    setContext({
                                        ...contextStore,
                                        snackState: {
                                            open: true,
                                            errorType: "warning",
                                            message: "Please Ask A Question",
                                        },
                                    });
                                } else {
                                    if (
                                        ![
                                            "pdf",
                                            "excel",
                                            "pathalogy",
                                            "image",
                                            "agent-pipeline",
                                            "video",
                                            "audio",
                                        ]?.includes(contextStore?.activeSource)
                                    ) {
                                        setContext({
                                            ...contextStore,
                                            snackState: {
                                                open: true,
                                                errorType: "warning",
                                                message: "Please Select Data Source First",
                                            },
                                        });
                                    } else if (query?.length === 0) {
                                        setContext({
                                            ...contextStore,
                                            snackState: {
                                                open: true,
                                                errorType: "warning",
                                                message: "Please Ask A Question",
                                            },
                                        });
                                    } else {
                                        setQuery(() => "");
                                        handleSend(query, "general");
                                        rec?.stop();
                                    }
                                }
                            }
                        }}
                    >
                        <SendWhiteIcon />
                    </IconButton>
                </Box>
            }
            startAdornment={
                <Box sx={{ display: "flex", height: "50px", alignItems: "center" }}>
                    <AudioRecorder
                        onRecordingComplete={addAudioElement}
                        audioTrackConstraints={{
                            noiseSuppression: true,
                            echoCancellation: true,
                        }}
                        onNotAllowedOrFound={(err) => console.table(err)}
                        // downloadOnSavePress={true}
                        // downloadFileExtension="mp3"
                        mediaRecorderOptions={{
                            audioBitsPerSecond: 128000,
                        }}
                    />
                </Box>
            }
            sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "80px",
                    border: "1px solid #E0E0E0",
                },
                "& .MuiOutlinedInput-input": {
                    padding: "10px 20px",
                },
                width: "100%",
            }}
            placeholder="Start inquiry about uploaded documents here."
        />
    );
};

export default InputField;
