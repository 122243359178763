import { Box } from "@mui/material";
import React from "react";

const Badge = ({ score, index, text }: any) => {
    const backgroundColor = ["#E4FCE0", "#FBE3FF", "#FCEFE0", "#FFE6DE"];
    const textColor = ["#036C07", "#8A0596", "#C85909", "#CC5555"];

    return (
        <Box
            sx={{
                height: "32px",
                width: "130px",
                background: backgroundColor[index],
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "11.5px",
                color: textColor[index],
            }}
        >
            {`${text}: ${score}`}
        </Box>
    );
};

export default Badge;
