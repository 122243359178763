import React, { useContext, useEffect, useState } from "react";
import { Box, IconButton, OutlinedInput } from "@mui/material";
import { Context } from "../../../context/setting";
import SendWhiteIcon from "../../../components/Common/svgLogs/SendWhiteIcon";

const InputField = ({ handleSend }: any) => {
    const [query, setQuery] = useState<string>("");

    return (
        <OutlinedInput
            value={query}
            onChange={(e: { target: { value: string }; preventDefault: any }) => {
                setQuery(e.target.value);
            }}
            onKeyDown={(e: any) => {
                if (e?.key === "Enter") {
                    if (e?.target?.value?.length > 0) {
                        setQuery(() => "");
                        handleSend(e?.target?.value, "general");
                    }
                }
                return;
            }}
            fullWidth
            endAdornment={
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <IconButton
                        sx={{ background: "#007BFF", "&:hover": { background: "#007BFFaa" } }}
                        onClick={() => {
                            if (query?.length > 0) {
                                handleSend(query, "general");
                                setQuery(() => "");
                            }
                        }}
                    >
                        <SendWhiteIcon />
                    </IconButton>
                </Box>
            }
            sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "80px",
                },
                "& .MuiOutlinedInput-input": {
                    padding: "12px 20px",
                    background: "#fff",
                },
                width: "100%",
            }}
            placeholder="Ask Anything"
        />
    );
};

export default InputField;
