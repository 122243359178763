import React from "react";

const GoogleSheetsIcon = () => {
    return (
        <svg width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.4996 0.00195312H1.87499C0.843479 0.00195312 0 0.850804 0 1.88178V25.6335C0 26.665 0.843479 27.5085 1.87499 27.5085H18.123C19.1545 27.5085 19.998 26.665 19.998 25.6335V7.50569L15.6205 4.37998L12.4996 0.00195312Z"
                fill="#0F9D58"
            />
            <path
                d="M5.00195 13.4375V22.5041H15.0034V13.4375H5.00195ZM9.37461 21.2528H6.24891V19.69H9.37461V21.2528ZM9.37461 18.7498H6.24891V17.187H9.37461V18.7498ZM9.37461 16.2521H6.24891V14.6893H9.37461V16.2521ZM13.7521 21.2528H10.6259V19.69H13.7516V21.2528H13.7521ZM13.7521 18.7498H10.6259V17.187H13.7516V18.7498H13.7521ZM13.7521 16.2521H10.6259V14.6893H13.7516V16.2521H13.7521Z"
                fill="#F1F1F1"
            />
            <path d="M13.0469 6.95703L19.9972 13.9074V7.50502L13.0469 6.95703Z" fill="url(#paint0_linear_877_350)" />
            <path d="M12.502 0V5.62391C12.502 6.66026 13.3401 7.4989 14.3769 7.4989H20.0003L12.502 0Z" fill="#87CEAC" />
            <path
                d="M1.87499 0.000537247C0.843479 0.000537247 0 0.849388 0 1.88037V2.03617C0 1.00465 0.843479 0.161174 1.87499 0.161174H12.4996V0H1.87499V0.000537247Z"
                fill="white"
                fill-opacity="0.2"
            />
            <path
                d="M18.1235 27.3477H1.87499C0.843479 27.3477 0 26.5042 0 25.4727V25.6285C0 26.66 0.843479 27.5035 1.87499 27.5035H18.123C19.1545 27.5035 19.998 26.66 19.998 25.6285V25.4727C19.998 26.5047 19.1545 27.3477 18.1235 27.3477Z"
                fill="#263238"
                fill-opacity="0.2"
            />
            <path
                d="M14.375 7.50585C13.3386 7.50585 12.5 6.66775 12.5 5.63086V5.78666C12.5 6.82301 13.3381 7.66166 14.375 7.66166H19.9984V7.50585H14.375Z"
                fill="#263238"
                fill-opacity="0.1"
            />
            <path
                d="M12.4996 0H1.87499C0.843479 0 0 0.848851 0 1.87983V25.6315C0 26.6631 0.843479 27.5065 1.87499 27.5065H18.123C19.1545 27.5065 19.998 26.6631 19.998 25.6315V7.50374L12.4996 0Z"
                fill="url(#paint1_radial_877_350)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_877_350"
                    x1="16.522"
                    y1="7.55292"
                    x2="16.522"
                    y2="13.9073"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#263238" stopOpacity="0.2" />
                    <stop offset="1" stopColor="#263238" stopOpacity="0.02" />
                </linearGradient>
                <radialGradient
                    id="paint1_radial_877_350"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0.633501 0.748358) scale(32.2523 32.2523)"
                >
                    <stop stopColor="white" stopOpacity="0.1" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </radialGradient>
            </defs>
        </svg>
    );
};

export default GoogleSheetsIcon;
