import React from "react";

const Submission = () => {
    return (
        <svg width="76" height="73" viewBox="0 0 76 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M54.0585 2.09561L52.3906 0.427734H17.8362C17.0574 0.428721 16.3107 0.738557 15.76 1.28929C15.2093 1.84002 14.8994 2.58669 14.8984 3.36555V69.5876C14.8994 70.3665 15.2093 71.1132 15.76 71.6639C16.3107 72.2146 17.0574 72.5245 17.8362 72.5254H66.9889C67.7678 72.5245 68.5144 72.2146 69.0652 71.6639C69.6159 71.1132 69.9257 70.3665 69.9267 69.5876V17.9639L68.2589 16.296L54.0585 2.09561ZM64.8119 17.086H53.2684V5.54256L64.8119 17.086ZM61.2289 63.7662L23.6555 63.8261L23.5956 9.18842L50.2721 9.18706V17.1445C50.2731 17.9233 50.5829 18.67 51.1336 19.2208C51.6843 19.7715 52.431 20.0814 53.2099 20.0824H61.17L61.2289 63.7662ZM17.8948 69.5876L17.8362 3.42406H50.3076C50.2913 3.50283 50.2794 3.58245 50.2721 3.66254V6.19215H23.5955C22.817 6.19297 22.0706 6.50261 21.5201 7.05311C20.9696 7.60362 20.66 8.35002 20.6591 9.12855V63.8261C20.66 64.6047 20.9696 65.3511 21.5201 65.9016C22.0706 66.4521 22.817 66.7617 23.5955 66.7625H61.2289C62.0075 66.7618 62.7539 66.4523 63.3045 65.9017C63.855 65.3512 64.1646 64.6047 64.1653 63.8261V20.0824H66.6905C66.7711 20.075 66.8512 20.0631 66.9304 20.0467V69.5291L17.8948 69.5876Z"
                fill="#007BFF"
                fill-opacity="0.68"
            />
            <path
                d="M46.1864 27.8861L41.2339 32.84L38.6393 30.2446C38.5002 30.1055 38.3351 29.9951 38.1533 29.9198C37.9716 29.8446 37.7768 29.8058 37.58 29.8058C37.3833 29.8058 37.1885 29.8446 37.0068 29.9198C36.825 29.9951 36.6599 30.1055 36.5208 30.2446C36.3817 30.3837 36.2713 30.5488 36.1961 30.7306C36.1208 30.9123 36.082 31.1071 36.082 31.3038C36.082 31.5005 36.1208 31.6953 36.1961 31.8771C36.2713 32.0588 36.3817 32.224 36.5208 32.3631L40.1748 36.0178C40.3139 36.1569 40.479 36.2673 40.6608 36.3426C40.8425 36.4179 41.0373 36.4567 41.2341 36.4567C41.4308 36.4567 41.6256 36.4179 41.8073 36.3426C41.9891 36.2673 42.1542 36.1569 42.2933 36.0178L48.305 30.0046C48.5851 29.7235 48.7422 29.3428 48.7419 28.9459C48.7416 28.5491 48.5838 28.1686 48.3031 27.888C48.0225 27.6074 47.642 27.4496 47.2452 27.4492C46.8483 27.4489 46.4675 27.606 46.1864 27.8861Z"
                fill="#007BFF"
                fill-opacity="0.68"
            />
            <path
                d="M42.4126 19.9648C40.0418 19.9648 37.7243 20.6679 35.7531 21.985C33.7819 23.3021 32.2455 25.1742 31.3382 27.3645C30.431 29.5548 30.1936 31.965 30.6561 34.2902C31.1186 36.6154 32.2603 38.7512 33.9366 40.4276C35.613 42.104 37.7489 43.2456 40.0741 43.7081C42.3993 44.1707 44.8095 43.9333 46.9998 43.026C49.1901 42.1188 51.0621 40.5824 52.3793 38.6112C53.6964 36.64 54.3994 34.3224 54.3994 31.9517C54.3959 28.7736 53.1319 25.7268 50.8847 23.4796C48.6375 21.2324 45.5906 19.9684 42.4126 19.9648ZM42.4126 40.9421C40.6345 40.9421 38.8962 40.4149 37.4178 39.427C35.9393 38.4391 34.7869 37.035 34.1065 35.3922C33.426 33.7494 33.248 31.9417 33.5949 30.1977C33.9418 28.4537 34.798 26.8518 36.0554 25.5944C37.3127 24.3371 38.9147 23.4808 40.6586 23.1339C42.4026 22.787 44.2103 22.9651 45.8531 23.6455C47.4959 24.326 48.9 25.4783 49.8879 26.9568C50.8758 28.4353 51.4031 30.1735 51.4031 31.9517C51.4003 34.3352 50.4522 36.6204 48.7668 38.3058C47.0813 39.9912 44.7962 40.9394 42.4126 40.9421Z"
                fill="#007BFF"
                fill-opacity="0.68"
            />
            <path
                d="M48.6289 49.2286C48.6288 49.4254 48.6675 49.6203 48.7427 49.8021C48.818 49.9839 48.9283 50.1491 49.0675 50.2882C49.2066 50.4274 49.3718 50.5377 49.5536 50.613C49.7354 50.6882 49.9303 50.7269 50.1271 50.7268H55.4745C55.8719 50.7268 56.2529 50.569 56.5339 50.288C56.8149 50.007 56.9727 49.626 56.9727 49.2286C56.9727 48.8313 56.8149 48.4502 56.5339 48.1693C56.2529 47.8883 55.8719 47.7305 55.4745 47.7305H50.1271C49.9303 47.7304 49.7354 47.769 49.5536 47.8443C49.3718 47.9195 49.2066 48.0299 49.0675 48.169C48.9283 48.3082 48.818 48.4734 48.7427 48.6552C48.6675 48.837 48.6288 49.0319 48.6289 49.2286Z"
                fill="#007BFF"
                fill-opacity="0.68"
            />
            <path
                d="M29.3536 50.7268H44.8817C45.2791 50.7268 45.6601 50.569 45.9411 50.288C46.2221 50.007 46.3799 49.626 46.3799 49.2286C46.3799 48.8313 46.2221 48.4502 45.9411 48.1693C45.6601 47.8883 45.2791 47.7305 44.8817 47.7305H29.3536C28.9563 47.7305 28.5752 47.8883 28.2943 48.1693C28.0133 48.4502 27.8555 48.8313 27.8555 49.2286C27.8555 49.626 28.0133 50.007 28.2943 50.288C28.5752 50.569 28.9563 50.7268 29.3536 50.7268Z"
                fill="#007BFF"
                fill-opacity="0.68"
            />
            <path
                d="M55.4739 54.9277H39.8185C39.4211 54.9277 39.0401 55.0856 38.7591 55.3665C38.4782 55.6475 38.3203 56.0286 38.3203 56.4259C38.3203 56.8232 38.4782 57.2043 38.7591 57.4853C39.0401 57.7662 39.4211 57.9241 39.8185 57.9241H55.4739C55.8712 57.9241 56.2523 57.7662 56.5332 57.4853C56.8142 57.2043 56.972 56.8232 56.972 56.4259C56.972 56.0286 56.8142 55.6475 56.5332 55.3665C56.2523 55.0856 55.8712 54.9277 55.4739 54.9277Z"
                fill="#007BFF"
                fill-opacity="0.68"
            />
            <path
                d="M34.6499 54.9277H29.3536C28.9563 54.9277 28.5752 55.0856 28.2943 55.3665C28.0133 55.6475 27.8555 56.0286 27.8555 56.4259C27.8555 56.8232 28.0133 57.2043 28.2943 57.4853C28.5752 57.7662 28.9563 57.9241 29.3536 57.9241H34.6499C35.0472 57.9241 35.4283 57.7662 35.7092 57.4853C35.9902 57.2043 36.1481 56.8232 36.1481 56.4259C36.1481 56.0286 35.9902 55.6475 35.7092 55.3665C35.4283 55.0856 35.0472 54.9277 34.6499 54.9277Z"
                fill="#007BFF"
                fill-opacity="0.68"
            />
            <path
                d="M75.2802 4.87883L72.268 4.06784C72.1446 4.03458 72.032 3.96948 71.9416 3.87906C71.8511 3.78863 71.786 3.67605 71.7528 3.55258L70.9418 0.540425C70.9 0.385334 70.8083 0.248337 70.6808 0.150641C70.5533 0.0529459 70.3972 0 70.2366 0C70.0759 0 69.9198 0.0529459 69.7923 0.150641C69.6648 0.248337 69.5731 0.385334 69.5313 0.540425L68.7202 3.55258C68.687 3.67608 68.6219 3.78867 68.5314 3.8791C68.441 3.96953 68.3284 4.03461 68.2049 4.06784L65.1928 4.87883C65.0377 4.92059 64.9007 5.01231 64.803 5.1398C64.7053 5.2673 64.6523 5.42344 64.6523 5.58406C64.6523 5.74469 64.7053 5.90083 64.803 6.02832C64.9007 6.15581 65.0377 6.24754 65.1928 6.2893L68.2049 7.10038C68.3284 7.13359 68.441 7.19867 68.5315 7.2891C68.6219 7.37953 68.687 7.49214 68.7202 7.61564L69.5313 10.6278C69.5731 10.7829 69.6648 10.9199 69.7923 11.0176C69.9198 11.1153 70.0759 11.1682 70.2366 11.1682C70.3972 11.1682 70.5533 11.1153 70.6808 11.0176C70.8083 10.9199 70.9 10.7829 70.9418 10.6278L71.7528 7.61564C71.786 7.49216 71.8511 7.37957 71.9415 7.28914C72.032 7.19872 72.1446 7.13362 72.268 7.10038L75.2802 6.2893C75.4353 6.24753 75.5723 6.1558 75.67 6.02831C75.7677 5.90082 75.8206 5.74468 75.8206 5.58406C75.8206 5.42345 75.7677 5.26731 75.67 5.13982C75.5723 5.01233 75.4353 4.9206 75.2802 4.87883Z"
                fill="#007BFF"
                fill-opacity="0.68"
            />
            <path
                d="M10.6278 66.664L7.6157 65.8531C7.4922 65.8198 7.3796 65.7547 7.28917 65.6643C7.19874 65.5738 7.13366 65.4612 7.10044 65.3377L6.28936 62.3256C6.24761 62.1705 6.15589 62.0335 6.02841 61.9358C5.90093 61.8381 5.74479 61.7852 5.58417 61.7852C5.42355 61.7852 5.26741 61.8381 5.13992 61.9358C5.01244 62.0335 4.92072 62.1705 4.87897 62.3256L4.0679 65.3377C4.03466 65.4612 3.96956 65.5738 3.87912 65.6643C3.78867 65.7547 3.67605 65.8198 3.55253 65.8531L0.540482 66.664C0.385379 66.7058 0.248368 66.7975 0.15066 66.925C0.052953 67.0525 0 67.2086 0 67.3693C0 67.5299 0.052953 67.686 0.15066 67.8135C0.248368 67.941 0.385379 68.0327 0.540482 68.0745L3.55253 68.8855C3.67604 68.9187 3.78865 68.9838 3.8791 69.0742C3.96954 69.1647 4.03465 69.2773 4.0679 69.4008L4.87897 72.4129C4.92074 72.568 5.01247 72.705 5.13995 72.8027C5.26743 72.9004 5.42356 72.9533 5.58417 72.9533C5.74477 72.9533 5.9009 72.9004 6.02838 72.8027C6.15586 72.705 6.24759 72.568 6.28936 72.4129L7.10044 69.4008C7.13367 69.2773 7.19876 69.1647 7.28919 69.0743C7.37962 68.9838 7.49221 68.9187 7.6157 68.8855L10.6278 68.0745C10.7829 68.0327 10.9199 67.941 11.0176 67.8135C11.1153 67.686 11.1682 67.5299 11.1682 67.3693C11.1682 67.2087 11.1153 67.0525 11.0176 66.925C10.9199 66.7976 10.7829 66.7058 10.6278 66.664Z"
                fill="#007BFF"
                fill-opacity="0.68"
            />
        </svg>
    );
};

export default Submission;
