import React from "react";

const FMCGIcon = () => {
    return (
        <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.3529 48.7061L27.7647 43.5002V34.9864L17.353 39.4331L17.3529 48.7061ZM15.6176 36.3963L26.5717 31.7056L15.6176 27.0149L4.6636 31.7056L15.6176 36.3963ZM45.1177 48.7061L55.5294 43.5002V34.9864L45.1177 39.4331V48.7061ZM43.3824 36.3963L54.3364 31.7056L43.3824 27.0149L32.4283 31.7056L43.3824 36.3963ZM31.2353 28.4519L41.647 23.9781V16.7658L31.2353 21.2125V28.4519ZM29.5 18.1757L41.4573 13.0512L29.5 7.92665L17.5428 13.0512L29.5 18.1757ZM59 32.2208V43.5002C59 44.1509 58.8283 44.7564 58.4848 45.3168C58.1414 45.8772 57.6714 46.302 57.0749 46.5912L44.9279 52.6647C44.476 52.9178 43.9608 53.0443 43.3824 53.0443C42.8039 53.0443 42.2888 52.9178 41.8369 52.6647L29.6898 46.5912C29.5994 46.555 29.5362 46.5189 29.5 46.4827C29.4639 46.5189 29.4006 46.555 29.3102 46.5912L17.1632 52.6647C16.7112 52.9178 16.1961 53.0443 15.6176 53.0443C15.0392 53.0443 14.5241 52.9178 14.0722 52.6647L1.92507 46.5912C1.32855 46.3019 0.858573 45.8772 0.515131 45.3168C0.17171 44.7564 0 44.1509 0 43.5002V32.2208C0 31.5339 0.194318 30.9012 0.582954 30.3228C0.97159 29.7443 1.48224 29.3105 2.11491 29.0213L13.8823 23.9781V13.1325C13.8823 12.4456 14.0766 11.813 14.4653 11.2345C14.8539 10.6561 15.3646 10.2223 15.9972 9.93305L28.1443 4.72718C28.56 4.54643 29.0119 4.45605 29.5 4.45605C29.988 4.45605 30.4399 4.54643 30.8556 4.72718L43.0027 9.93308C43.6354 10.2223 44.146 10.6561 44.5347 11.2346C44.9233 11.813 45.1176 12.4456 45.1176 13.1325V23.9781L56.8851 29.0214C57.5358 29.3106 58.051 29.7444 58.4305 30.3228C58.8102 30.9012 59 31.5338 59 32.2207V32.2208Z"
                fill="black"
            />
        </svg>
    );
};

export default FMCGIcon;
