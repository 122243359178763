import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Ingestion from "./Ingestion";
import PathologyReport from "./PathologyReport";
import Query from "./Query";

const Calculator = () => {
    const [selectedTab, setSelectedTab] = useState("Ingestion");
    return (
        <Box
            sx={{
                background: "#fff",
                width: "100vw",
                height: "95vh",
                // border: "1px solid #F5F5F5",
                // borderRadius: "11px",
                // border: "1px solid red",
                p: 4.6,
                zIndex: 1,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    // border: "1px solid red",
                    height: "7vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "flex-start",
                }}
            >
                <Typography fontFamily="Gilroy-Medium" fontSize="24px">
                    Calculate Costs
                </Typography>
                <Typography fontFamily="Gilroy-Medium" fontSize="14px" color="#424F65">
                    Explore costs applicable in various instances and use cases
                </Typography>
            </Box>
            <Box sx={{ width: "100%", marginTop: "30px", height: "5vh" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "20px",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            height: "100%",
                            paddingX: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: selectedTab === "Ingestion" ? "#E3F4FF" : "transparent",
                            color: selectedTab === "Ingestion" ? "#007BFF" : "black",
                            cursor: "pointer",
                        }}
                        onClick={() => setSelectedTab("Ingestion")}
                    >
                        <Typography fontFamily=" Gilroy-Medium" fontSize="1.5rem">
                            Ingestion
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            height: "100%",
                            paddingX: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: selectedTab === "Pathology_Report" ? "#E3F4FF" : "transparent",
                            color: selectedTab === "Pathology_Report" ? "#007BFF" : "black",
                            cursor: "pointer",
                        }}
                        onClick={() => setSelectedTab("Pathology_Report")}
                    >
                        <Typography fontFamily=" Gilroy-Medium" fontSize="1.5rem">
                            Pathology Report
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            height: "100%",
                            paddingX: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: selectedTab === "Query" ? "#E3F4FF" : "transparent",
                            color: selectedTab === "Query" ? "#007BFF" : "black",
                            cursor: "pointer",
                        }}
                        onClick={() => setSelectedTab("Query")}
                    >
                        <Typography fontFamily=" Gilroy-Medium" fontSize="1.5rem">
                            Query
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {selectedTab === "Ingestion" ? (
                <Ingestion />
            ) : selectedTab === "Pathology_Report" ? (
                <PathologyReport />
            ) : (
                <Query />
            )}
        </Box>
    );
};

export default Calculator;
