import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import PageNotFound from "../screens/Auth/PageNotFound";
import Loading from "../screens/Auth/Loading";
import VerifyEmail from "../screens/Auth/VerifyEmail";
import { Api } from "../apis";

const RestrictedRoute = ({ children }: any) => {
    const { isAuthenticated, isLoading: isAuthLoading, user } = useAuth0();

    /* for setting the error page call the api from AI team and check the error status 
    const [error, setError] = useState<boolean>(false);
    const handleFetchExcel = () => {
        Api?.getTables()?.then((res: any) => {
            if (res?.status === 503) setError(true);
        });
    };

    useEffect(() => {handleFetchExcel()}, []); */

    if (isAuthLoading) return <Loading />;

    if (!isAuthenticated) return <Navigate to="/login" />;

    if (isAuthenticated && !user?.email_verified)
        return (
            <>
                <Navigate to="/verify" />
                <VerifyEmail />
            </>
        );
    else if (isAuthenticated && user?.email_verified) return children;

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <PageNotFound />
        </Box>
    );
};

export default RestrictedRoute;
