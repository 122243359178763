import { Box, Button, CircularProgress, IconButton, Slider, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { Context } from "../../../context/setting";
import SoftSensorXLogo from "../../../components/Common/svgLogs/SoftSensorXLogo";
import DetectionVisibilityIcon from "../../../components/Common/svgLogs/Damage_Detection/DetectionVisibilityIcon";
import DetectionVisibilityOffIcon from "../../../components/Common/svgLogs/Damage_Detection/DetectionVisibilityOffIcon";
import {
    ArrowBackIos,
    ArrowForwardIos,
    ChevronLeftSharp,
    Close,
    DoneOutlined,
    InfoOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const DamageReports = ({ setSelectedReport, data }: any) => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [visibilityView, setVisibilityView] = useState<boolean>(false);
    const [coordinate, setCoordinate] = useState<any>(null);
    const [imageIndex, setImageIndex] = useState<number>(0);
    const [showParameters, setShowParameters] = useState<boolean>(false);

    return (
        <Box sx={{ width: "100%", maxHeight: "100vh", height: "100%", overflowY: "scroll", boxSizing: "border-box" }}>
            {/* appbar */}
            <Box
                sx={{
                    width: "100%",
                    height: "7%",
                    background: "#f3f3f3",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingInline: "8% 8%",
                }}
            >
                <Button sx={{ position: "absolute", marginLeft: "-6%" }} onClick={() => setSelectedReport(null)}>
                    <ChevronLeftSharp />
                </Button>
                <Box sx={{ display: "flex", gap: 3 }}>
                    <Typography sx={{ color: "#007BFF" }}>
                        {data?.image_results?.[imageIndex]?.other_details?.Category.toUpperCase()}
                    </Typography>
                    <Typography>{`VIN NO ${contextStore?.profile?.userId}`}</Typography>
                </Box>
                <Box>
                    <SoftSensorXLogo />
                </Box>
            </Box>

            {/* images box */}
            <Box
                sx={{
                    width: "100%",
                    height: "fit-content",
                    display: "flex",
                    flexDirection: "column",
                    paddingInline: "8% 8%",
                    mt: "2%",
                    borderBottom: "1px solid #d9d9d9",
                    pb: 2,
                }}
            >
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <Typography>360 View</Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                        <Button onClick={() => setVisibilityView(!visibilityView)}>
                            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                                <Typography
                                    sx={{
                                        fontFamily: "Gilroy-Regular",
                                        fontWeight: "600",
                                        color: !visibilityView ? "#007BFF" : "#000",
                                    }}
                                >
                                    Detection Visibility
                                </Typography>{" "}
                                {!visibilityView ? <DetectionVisibilityIcon /> : <DetectionVisibilityOffIcon />}
                            </Box>
                        </Button>
                        <IconButton
                            size="small"
                            onMouseOver={() => {
                                setShowParameters(true);
                            }}
                            onMouseOut={() => {
                                setShowParameters(false);
                            }}
                        >
                            <InfoOutlined sx={{ color: "#007BFF" }} />
                        </IconButton>
                        {showParameters && (
                            <Box
                                sx={{
                                    position: "absolute",
                                    right: "8%",
                                    mt: "45px",
                                    background: "#fff",
                                    p: 1,
                                    borderRadius: "10px",
                                    border: "1px solid #B6B6B6",
                                }}
                            >
                                <Box sx={{ display: "flex" }}>
                                    {data?.image_results?.[imageIndex]?.detailed_reports?.[0]?.[3]?.too_blurry ? (
                                        <DoneOutlined sx={{ color: "green", transform: "scale(0.8)" }} />
                                    ) : (
                                        <Close sx={{ color: "red", transform: "scale(0.8)" }} />
                                    )}
                                    <Typography>Too blurry</Typography>
                                </Box>
                                <Box sx={{ display: "flex" }}>
                                    {data?.image_results?.[imageIndex]?.detailed_reports?.[0]?.[3]?.is_cropped ? (
                                        <DoneOutlined sx={{ color: "green", transform: "scale(0.8)" }} />
                                    ) : (
                                        <Close sx={{ color: "red", transform: "scale(0.8)" }} />
                                    )}
                                    <Typography>Cropped</Typography>
                                </Box>
                                <Box sx={{ display: "flex" }}>
                                    {data?.image_results?.[imageIndex]?.detailed_reports?.[0]?.[3]?.too_far ? (
                                        <DoneOutlined sx={{ color: "green", transform: "scale(0.8)" }} />
                                    ) : (
                                        <Close sx={{ color: "red", transform: "scale(0.8)" }} />
                                    )}
                                    <Typography>Too Far</Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "space-between",
                        height: "100%",
                    }}
                >
                    <ArrowBackIos
                        style={{
                            color: "#B6B6B6",
                            transform: "scale(2)",
                            marginLeft: "2%",
                            cursor: imageIndex > 0 ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                            if (imageIndex > 0) setImageIndex((imageIndex - 1) % data?.image_results?.length);
                        }}
                    />
                    <img
                        style={{ width: "80%", maxHeight: "500px", maxWidth: "800px", cursor: "grab" }}
                        alt="graph"
                        src={
                            visibilityView
                                ? `data:image/png;base64,${data?.image_results?.[imageIndex]?.detailed_reports?.[0]?.[1]?.original_base64}`
                                : `data:image/png;base64,${data?.image_results?.[imageIndex]?.detailed_reports?.[0]?.[1]?.modified_image}`
                        }
                        onMouseMove={(e: any) => {
                            if (coordinate !== null && e.buttons === 1) {
                                if (e.clientX - coordinate >= 20) {
                                    setImageIndex((imageIndex + 1) % data?.image_results?.length);
                                    setCoordinate(e.clientX);
                                }
                                if (coordinate - e.clientX >= 20) {
                                    if (imageIndex === 0) {
                                        setImageIndex(data?.image_results?.length - 1);
                                    } else setImageIndex(Math.abs(imageIndex - 1) % data?.image_results?.length);
                                    setCoordinate(e.clientX);
                                }
                            }
                        }}
                        onMouseDown={(e: any) => {
                            setCoordinate(e.clientX);
                        }}
                        onMouseUp={() => setCoordinate(null)}
                        draggable={false}
                    />
                    <ArrowForwardIos
                        style={{
                            color: "#B6B6B6",
                            transform: "scale(2)",
                            marginLeft: "2%",
                            cursor: imageIndex < data?.image_results?.length ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                            if (imageIndex < data?.image_results?.length)
                                setImageIndex((imageIndex + 1) % data?.image_results?.length);
                        }}
                    />{" "}
                </Box>
            </Box>

            {/* image slideshow */}
            <Box
                sx={{
                    width: "100%",
                    height: "fit-content",
                    display: "flex",
                    paddingInline: "8% 8%",
                    mt: "3%",
                    gap: 2,
                    overflowX: "scroll",
                }}
            >
                {data?.image_results.map((item: any, index: any) => {
                    return (
                        <>
                            <Box
                                sx={{
                                    width: "12.5%",
                                    height: "fit-content",
                                    // background: imageIndex === index ? "" : "rgba(0, 0, 0, 0.4)",
                                    cursor: "pointer",
                                    position: "relative",
                                }}
                                onClick={() => setImageIndex(index)}
                            >
                                <img
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "15px",
                                        // position: "relative",
                                    }}
                                    alt="graph"
                                    src={`data:image/png;base64,${item?.detailed_reports?.[0]?.[1]?.original_base64}`}
                                />
                                <Box
                                    sx={{
                                        position: "absolute",
                                        background: imageIndex === index ? "" : "rgba(0,0,0, 0.5)",
                                        width: "100%",
                                        height: "95%",
                                        zIndex: 5,
                                        borderRadius: "15px",
                                        top: 0,
                                    }}
                                ></Box>
                            </Box>
                        </>
                    );
                })}
            </Box>

            {/* damage report box */}
            <Box
                sx={{
                    width: "100%",
                    height: "fit-content",
                    paddingInline: "8% 8%",
                    display: "flex",
                    mt: "4%",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ width: "45%", display: "flex", flexDirection: "column" }}>
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            height: "20%",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <Typography sx={{ color: "#007BFF", fontSize: "1rem" }}>
                                {data?.image_results?.[imageIndex]?.other_details?.Vehicle_Model}
                            </Typography>
                            <Typography sx={{ color: "#484848", fontSize: "0.875rem" }}>
                                {`Category: ${data?.image_results?.[imageIndex]?.other_details?.Category}`}
                            </Typography>
                            <Typography sx={{ color: "#484848", fontSize: "0.875rem" }}>
                                {`Confidence Score: ${data?.image_results?.[imageIndex]?.other_details?.Confidence_Score}%`}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                height: "100px",
                                width: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                transform: "rotateY(180deg)",
                            }}
                        >
                            <CircularProgress
                                variant="determinate"
                                value={data?.image_results?.[imageIndex]?.other_details?.Damage_Intensity}
                                sx={{
                                    height: "150px",
                                    width: "150px",
                                    background: "#D9D9D9",
                                    borderRadius: "50%",
                                    color: "#E98B35",
                                }}
                                size={80}
                                thickness={7}
                            />

                            <Typography
                                sx={{
                                    position: "absolute",
                                    transform: "rotateY(180deg)",
                                    mt: "100px",
                                    width: "163px",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                variant="caption"
                            >
                                Damage Intensity
                            </Typography>
                            <Box
                                sx={{
                                    zIndex: "10",
                                    position: "absolute",
                                    background: "#fae683",
                                    height: "55px",
                                    width: "55px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    transform: "rotateY(180deg)",
                                }}
                            >
                                <Typography variant="caption" sx={{ color: "#E98B35" }}>
                                    {data?.image_results?.[imageIndex]?.other_details?.Damage_Intensity}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", mt: "4%" }}>
                        <Typography sx={{ fontFamily: "Gilroy-SemiBold" }}>Damage Report:</Typography>
                        <Box sx={{ width: "100%", display: "flex" }}>
                            <Box sx={{ width: "50%" }} />
                            <Box sx={{ width: "50%", display: "flex", justifyContent: "space-between" }}>
                                <Typography variant="caption">Minor</Typography>
                                <Typography variant="caption">Moderate</Typography>
                                <Typography variant="caption">Severe</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                            {data?.image_results?.[imageIndex]?.processed_report_summary?.map((item: any) => {
                                return (
                                    <Box sx={{ width: "100%", display: "flex", mb: "0.8%" }}>
                                        <Box sx={{ width: "50%" }}>{item?.["Car Part"]}</Box>
                                        <Slider
                                            size="small"
                                            value={
                                                item?.["Damage Type"]?.includes("minor")
                                                    ? 25
                                                    : item?.["Damage Type"]?.includes("severe")
                                                      ? 90
                                                      : 50
                                            }
                                            sx={{
                                                color: item?.["Damage Type"]?.includes("minor")
                                                    ? "#F9E000"
                                                    : item?.["Damage Type"]?.includes("severe")
                                                      ? "#CC3838"
                                                      : "#E66036",
                                                width: "50%",
                                            }}
                                        />
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: "45%" }}>
                    <img
                        style={{
                            width: "85%",
                            height: "75%",
                            borderRadius: "15px",
                        }}
                        alt="graph"
                        src={`data:image/png;base64,${data?.image_results?.[imageIndex]?.detailed_reports?.[0]?.[1]?.modified_image}`}
                    />
                </Box>
            </Box>

            <Stack alignItems={"center"} justifyContent={"center"} sx={{ mb: "3vh" }}>
                <Button
                    onClick={() => navigate("/report_detail_2")}
                    variant="contained"
                    sx={{ borderRadius: "19px", px: 3 }}
                >
                    View Report
                </Button>
            </Stack>
        </Box>
    );
};

export default DamageReports;
