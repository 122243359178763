import useWebSocket from "react-use-websocket";
import React, { useRef, useState } from "react";
import { Api } from "../../apis";
import { Some } from "../Chat/utils/Some";

export const PdfSocket: any = () => {
    const [msg, setMsg] = useState<string>("");
    const msgRef: any = useRef();
    const { getWebSocket, lastJsonMessage, lastMessage, readyState, sendJsonMessage, sendMessage } = useWebSocket(
        Api?.socketUrl,
        {
            onOpen: () => console.log("WebSocket connection established."),
            onMessage: (resp: any) => {
                const dataString = Some.String(resp?.data);
                if (
                    dataString.includes("Connection established") ||
                    dataString.includes("Provide pdf_name first") ||
                    dataString.includes("is_function_calling_model")
                ) {
                    return;
                }

                if (dataString.includes("index loaded for pdf")) {
                    console.log("loaded successfully");
                    return;
                }

                if (dataString?.startsWith("Error occurred")) {
                    setMsg((prev) => prev + "<EOD>");
                    msgRef.load = false;
                    return;
                }

                msgRef.load = true;
                if (dataString === "<EOD>") {
                    msgRef.load = false;
                    
                } else {
                    msgRef.summary = msgRef?.summary?.length > 0 ? dataString : dataString;
                    console.log({ msgRef });
                }
            },
            onError: (event: any) => {
                console.log(event);
            },
        }
    );
    return [msg, { getWebSocket, lastJsonMessage, lastMessage, readyState, sendJsonMessage, sendMessage }, msgRef];
};

export default PdfSocket;
