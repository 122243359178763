import React from "react";

const DownloadIconLogo = () => {
    return (
        <svg
            style={{ width: "1.5rem", height: "1.5rem" }}
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.5" y="0.5" width="35" height="35" rx="10.587" fill="white" />
            <rect x="0.5" y="0.5" width="35" height="35" rx="10.587" stroke="#F5F5F5" />
            <path
                d="M18.0003 21.6667L13.417 17.0834L14.7003 15.7542L17.0837 18.1376V10.6667H18.917V18.1376L21.3003 15.7542L22.5837 17.0834L18.0003 21.6667ZM12.5003 25.3334C11.9962 25.3334 11.5647 25.1541 11.206 24.7953C10.8473 24.4366 10.6676 24.0049 10.667 23.5001V20.7501H12.5003V23.5001H23.5003V20.7501H25.3337V23.5001C25.3337 24.0042 25.1543 24.436 24.7956 24.7953C24.4369 25.1547 24.0051 25.334 23.5003 25.3334H12.5003Z"
                fill="#007BFF"
            />
        </svg>
    );
};

export default DownloadIconLogo;
