import React from "react";

const Calculator = ({ fill }: { fill?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <mask
                id="mask0_3167_513"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="22"
                height="22"
            >
                <rect y="-1.90735e-05" width="21.75" height="21.75" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3167_513)">
                <path
                    d="M1.8125 18.125V3.62498H19.9375V18.125H1.8125ZM3.625 16.3125H18.125V5.43748H3.625V16.3125ZM7.25 15.4062H9.0625V14.5H9.96875C10.2255 14.5 10.4408 14.4131 10.6145 14.2394C10.7882 14.0657 10.875 13.8505 10.875 13.5937V10.875C10.875 10.6182 10.7882 10.403 10.6145 10.2293C10.4408 10.0556 10.2255 9.96873 9.96875 9.96873H7.25V9.06248H10.875V7.24998H9.0625V6.34373H7.25V7.24998H6.34375C6.08698 7.24998 5.87174 7.33683 5.69805 7.51053C5.52435 7.68423 5.4375 7.89946 5.4375 8.15623V10.875C5.4375 11.1318 5.52435 11.347 5.69805 11.5207C5.87174 11.6944 6.08698 11.7812 6.34375 11.7812H9.0625V12.6875H5.4375V14.5H7.25V15.4062ZM14.5 14.7265L16.3125 12.914H12.6875L14.5 14.7265ZM12.6875 9.06248H16.3125L14.5 7.24998L12.6875 9.06248Z"
                    fill="#007BFF"
                />
            </g>
        </svg>
    );
};

export default Calculator;
