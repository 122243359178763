import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import DamageReports from "./DamageReports";
import { Api } from "../../../apis";
import { Context } from "../../../context/setting";
import moment from "moment";

const ReportsList = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [selectedReport, setSelectedReport] = useState<any>(null);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        Api?.getDamageDetectionReports("3ddf28da-ca5e-4615-ab05-50d30c888307")?.then((res: any) => {
            if (res?.status === 200) {
                setData(res?.data);
            } else {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Server issue! Please try after some time",
                    },
                });
            }
        });
    }, []);

    const calcDamage = (data: any) => {
        let count = 0;
        for (let i = 0; i < data?.image_results?.length; i++) {
            count += data?.image_results?.[i]?.file_damage_counts?.[0]?.["Damage Count"];
        }
        return count;
    };

    return selectedReport !== null ? (
        <DamageReports
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
            data={data[selectedReport]}
        />
    ) : (
        <Box
            sx={{
                width: "100%",
                height: "100vh",
                display: "flex",
                p: 3,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "scroll",
                gap: 3,
            }}
        >
            <Typography variant="h5" sx={{ color: "#007bff", mt: "2%" }}>
                Damage Reports
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "80%",
                    height: "95vh",
                    gap: 3,
                    justifyContent: "space-evenly",
                }}
            >
                {data !== null &&
                    data?.map((item: any, index: any) => {
                        return (
                            <Card
                                sx={{
                                    maxWidth: "350px",
                                    maxHeight: "320px",
                                    width: "24%",
                                    height: "45%",
                                    minHeight: "300px",
                                    minWidth: "320px",
                                    borderRadius: "15px",
                                    "&:hover": {
                                        transform: "scale(1.03)",
                                    },
                                }}
                            >
                                <CardMedia
                                    sx={{ height: 150 }}
                                    image={`data:image/png;base64,${item?.image_results?.[0]?.detailed_reports?.[0]?.[1]?.original_base64}`}
                                    title={`${item?.report_number}`}
                                />
                                <CardContent sx={{ height: 110 }}>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {`Report: ${index + 1}`}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                        {`Created ${moment(item?.report_number).fromNow()}`}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                        {`Total damages detected: ${calcDamage(item)}`}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                        {`Created by: ${contextStore?.profile?.name}`}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ height: 60 }}>
                                    <Button size="small" onClick={() => setSelectedReport(index)}>
                                        View Now
                                    </Button>
                                </CardActions>
                            </Card>
                        );
                    })}
            </Box>
        </Box>
    );
};

export default ReportsList;
