const Stencil1 = ({ stroke }: any) => {
    return (
        <svg width="70vw" height="80vh" viewBox="0 0 672 284" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M36.795 244.5C36.795 265.763 54.0321 283 75.295 283C96.558 283 113.795 265.763 113.795 244.5C113.795 223.237 96.558 206 75.295 206C54.0321 206 36.795 223.237 36.795 244.5Z"
                stroke={stroke}
                stroke-width="2"
            />
            <path
                d="M508.795 244.5C508.795 265.763 526.032 283 547.295 283C568.558 283 585.795 265.763 585.795 244.5C585.795 240.637 585.193 237.898 584.081 236C576.295 236 563.695 236 546.295 236C528.895 236 516.295 236 510.335 236C509.331 237.898 508.795 240.637 508.795 244.5Z"
                stroke={stroke}
                stroke-width="2"
            />
            <path
                d="M2.29535 230C4.6955 238.8 20.6287 243.667 28.2953 245C28.2953 223 42.7943 197.3 74.7943 196.5C114.794 195.5 122.295 232 123.295 242.5C124.095 250.9 128.962 252.333 131.295 252C253.795 252 496.094 254.5 499.294 254.5C503.294 254.5 505.294 251 505.294 247C505.294 243.8 503.794 232.5 510.294 232.5H586.294C591.294 232.5 590.294 236.5 589.294 246C588.494 253.6 594.279 253.323 597.294 253C611.295 251.5 640.795 248.5 654.295 246C668.848 243.305 671.129 226.667 670.295 217V17C670.295 4.6 655.295 1.16667 647.795 1H192.295C152.295 4.6 125.629 27.1667 117.295 38L41.2953 147.5C16.4954 156.7 6.29535 170.667 4.29535 176.5C2.6286 190.667 -0.104797 221.2 2.29535 230Z"
                stroke={stroke}
                stroke-width="2"
            />
        </svg>
    );
};

export default Stencil1;
