import { Button, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CompleteState from "../../../components/Common/svgLogs/CompleteState";
import ErrorState from "../../../components/Common/svgLogs/ErrorState";
import LoadingState from "../../../components/Common/svgLogs/LoadingState";
import index from "../../Auth";
import { Api } from "../../../apis";
import { Context } from "../../../context/setting";

const ListItemUpload = ({ img, index, handleCapture }: { img: any; index: number; handleCapture: any }) => {
    const { contextStore, setContext } = useContext(Context);
    const [loadingState, setLoadingState] = useState<"init" | "loading" | "complete" | "error">("init");
    const handleIndexText = (index: number) => {
        switch (index) {
            case 0:
                return "DRIVER SIDE";
            case 1:
                return "FRONT-RIGHT";
            case 2:
                return "FRONT";
            case 3:
                return "FRONT-LEFT";
            case 4:
                return "PASSENGER SIDE";
            case 5:
                return "REAR-RIGHT";
            case 6:
                return "REAR";
            case 7:
                return "REAR-LEFT";
            default:
                return "DRIVER SIDE";
        }
    };
    const uploadImage = (image: any) => {
        setLoadingState("loading");
        if (image !== null) {
            Api?.uploadDamageDetectionFilesRTS({
                user_id: "3ddf28da-ca5e-4615-ab05-50d30c888307",
                image_base64: image.split(",")[1],
                filename: "image.jpg",
                report_number: localStorage.getItem("startTime"),
            })
                ?.then((res: any) => {
                    if (res?.status === 200) {
                        setLoadingState("complete");
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "success",
                                message: "Image captured successfully!!",
                            },
                        });
                    } else {
                        setLoadingState("error");
                        setContext({
                            ...contextStore,
                            snackState: {
                                open: true,
                                errorType: "error",
                                message: "Server issue! Please try after some time",
                            },
                        });
                    }
                })
                ?.catch((err) => {
                    setLoadingState("error");
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Server issue! Please try after some time",
                        },
                    });
                });
        }
    };
    useEffect(() => {
        uploadImage(img);
    }, []);

    return (
        <Stack
            sx={{
                background: "#fff",
                p: 1,
                borderRadius: "8px",
                gap: 1,
                border: "1px solid #BEDFF1",
                justifyContent: "space-between",
            }}
            direction={"row"}
        >
            <Stack gap={1} direction={"row"}>
                <img src={img} style={{ width: "7rem", height: "4.5rem", borderRadius: "6px" }} />
                <Stack>
                    <Typography>{handleIndexText(index)}</Typography>
                    {loadingState === "error" && (
                        <Typography color={"#CC5555"} variant="caption">
                            Error Uploading
                        </Typography>
                    )}
                    {["complete", "error"]?.includes(loadingState) && (
                        <Button onClick={() => handleCapture(index)} variant="contained" sx={{ width: "6rem" }}>
                            Capture
                        </Button>
                    )}
                </Stack>
            </Stack>
            {loadingState === "init" && (
                <Stack>
                    <LoadingState fill="#e7e7e7" />
                </Stack>
            )}
            {loadingState === "complete" && (
                <Stack>
                    <CompleteState />
                </Stack>
            )}
            {loadingState === "error" && (
                <Stack>
                    <ErrorState />
                </Stack>
            )}
            {loadingState === "loading" && (
                <Stack className="loading">
                    <LoadingState fill="#F9BC00" />
                </Stack>
            )}
        </Stack>
    );
};

export default ListItemUpload;
