import { Box } from "@mui/material";
import { useState } from "react";
import AnamolyLeftBar from "./AnamolyLeftBar";
import AnamolyDetectionUpload from "./AnamolyDetectionUpload";
import InitialChat from "./InitialChat";
import AnamolyFiles from "./AnamolyFiles";
import Parameters from "./Parameters/Parameters";
import Report_reconcilieation from "./Report_reconciliation";

const AnamolyDetection = () => {
    const [selectedFile, setSelectedFile] = useState(false);
    const [selectedTab, setSelectedTab] = useState("parameter");
    const [fileToQuery, setFileToQuery] = useState(true);
    const [invoiceReconciliation, setInvoiceReconciliation] = useState(false);
    return (
        <Box
            sx={{
                display: "flex",
                flex: 1,
                // border: "3px solid red",
                width: "80px",
                overflow: "hidden",
            }}
        >
            {selectedFile && (
                <AnamolyLeftBar
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    setInvoiceReconciliation={setInvoiceReconciliation}
                    invoiceReconciliation={invoiceReconciliation}
                />
            )}
            {/* Right side AnamolyDetection component */}
            {!selectedFile && <AnamolyDetectionUpload setSelectedFile={setSelectedFile} />}
            {selectedTab === "chat" && fileToQuery && !invoiceReconciliation && (
                <InitialChat setSelectedTab={setSelectedTab} />
            )}
            {/* {selectedTab === "chat" && fileToQuery && invoiceReconciliation && <Report_reconcilieation />} */}
            {selectedTab === "my_files" && <AnamolyFiles setSelectedTab={setSelectedTab} />}
            {selectedTab === "parameter" && <Parameters selectedFile={selectedFile} />}
        </Box>
    );
};

export default AnamolyDetection;
