import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip,
    Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Api } from "../../apis";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/setting";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import InputHandler from "./InputFinance";
import Typing from "../AI_Images/Typing";
import * as ExcelJS from "exceljs";
import DownloadIcon from "../../components/Common/svgLogs/Finance_CFO/DownloadIcon";
import IncomeStatementIcon from "../../components/Common/svgLogs/Finance_CFO/IncomeStatementIcon";
import ScenarioAnalysisShortIcon from "../../components/Common/svgLogs/Finance_CFO/ScenarioAnalysisShortIcon";
import ShowPPTIcon from "../../components/Common/svgLogs/Finance_CFO/ShowPPtIcon";
import ShowPPT from "./ShowPPT";

const TrialBalance = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [trialBalanceData, setTrialBalanceData] = useState<any>(null);
    const [incomeStatementData, setIncomeStatementData] = useState<any>(null);
    const [headers, setHeaders] = useState<any>(null);
    const [incomeStatement_headers, setIncomeStatement_headers] = useState<any>(null);
    const [showTrialBalance, setShowTrialBalance] = useState<boolean>(true);
    const [openModal, setOpenModal] = useState(false);
    const [showPPT, setShowPPT] = useState<boolean>(false);
    const [dropdownValue1, setDropdownValue1] = useState(["--"]);
    const [dropdownValue2, setDropdownValue2] = useState(["--"]);
    const [dropdownValue3, setDropdownValue3] = useState(["--"]);
    const [dropdownValue4, setDropdownValue4] = useState(["--"]);
    const [dropdownValue5, setDropdownValue5] = useState(["--"]);
    const [chats, setChats] = useState<any>([]);
    const [scenarioDropdownOptions, setScenarioDropdownOptions] = useState<any>({
        unique_recurring_revenue_rows: [],
        unique_non_recurring_revenue_rows: [],
        unique_payroll_rows: [],
        unique_opex_values: [],
        unique_non_operating_expense_rows: [],
    });
    const [updateStatement, setUpdateStatement] = useState<boolean>(false);
    const [updateStatement_table, setUpdateStatement_table] = useState<any>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (contextStore?.income_statement_table) {
            setUpdateStatement(true);
            setUpdateStatement_table(contextStore?.income_statement_table[0]);
            const dummy: any = Object.keys(contextStore?.income_statement_table[0][0]);
            setIncomeStatement_headers(dummy);
        }
    }, [contextStore?.income_statement_table]);

    useEffect(() => {
        if (location.pathname === "/finance/trial_balance") {
            setShowTrialBalance(true);
            setUpdateStatement(false);
            const { income_statement_table, ...restContext } = contextStore;
            setContext({
                ...restContext,
            });
        } else if (location.pathname === "/finance/income_statement") {
            setShowTrialBalance(false);
        }
    }, [location.pathname]);

    const getTable = () => {
        Api?.getTrailBalanceData()?.then((res: any) => {
            if (res?.data) {
                setTrialBalanceData(JSON.parse(res?.data));
            } else {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Server issue! Please try after some time",
                    },
                });
            }
        });
    };

    const get_scenario_analysis_dropdown = () => {
        Api?.get_scenario_analysis_dropdown_data()?.then((res: any) => {
            if (res?.data) {
                setScenarioDropdownOptions(res?.data);
                setDropdownValue1(res?.data?.unique_recurring_revenue_rows);
                setDropdownValue2(res?.data?.unique_non_recurring_revenue_rows);
                setDropdownValue3(res?.data?.unique_payroll_rows);
                setDropdownValue4(res?.data?.unique_opex_values);
                setDropdownValue5(res?.data?.unique_non_operating_expense_rows);
            } else {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Server issue! Please try after some time",
                    },
                });
            }
        });
    };

    const dataProcessing = () => {
        if (trialBalanceData !== null) {
            const dummy: any = Object.entries(trialBalanceData[0]);
            const data = dummy?.map((arr: any) => arr[0]);
            setHeaders(data);
        }
    };

    const incomeStatement_header = () => {
        if (incomeStatementData !== null) {
            const dummy: any = Object.entries(incomeStatementData[0]);
            const data = dummy?.map((arr: any) => arr[0]);
            setIncomeStatement_headers(data);
        }
    };

    useEffect(() => {
        getTable();
        get_scenario_analysis_dropdown();
        navigate("/finance/trial_balance");
    }, []);

    useEffect(() => {
        dataProcessing();
    }, [trialBalanceData]);

    useEffect(() => {
        incomeStatement_header();
    }, [incomeStatementData]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason !== "backdropClick") {
            setOpenModal(false);
        }
    };

    const parsedHeading = (heading: any) => {
        const date: any = new Date(heading);
        if (Object.prototype.toString.call(date) === "[object Date]") {
            // it is a date
            if (isNaN(date)) {
                // d.getTime() or d.valueOf() will also work
                // date object is not valid
                return heading;
            } else {
                // date object is valid
                return date.toLocaleString("default", { month: "short", year: "numeric" });
            }
        } else {
            // not a date object
            return heading;
        }
    };

    const handleGenerate = () => {
        const { income_statement_table, ...restContext } = contextStore;
        setContext({
            ...restContext,
        });
        setChats([]);
        setOpenModal(false);
        const final_payload_statement = {
            unique_recurring_revenue_rows:
                dropdownValue1[0] !== "--" ? dropdownValue1 : scenarioDropdownOptions.unique_recurring_revenue_rows,
            unique_non_recurring_revenue_rows:
                dropdownValue2[0] !== "--" ? dropdownValue2 : scenarioDropdownOptions.unique_non_recurring_revenue_rows,
            unique_payroll_rows:
                dropdownValue3[0] !== "--" ? dropdownValue3 : scenarioDropdownOptions.unique_payroll_rows,
            unique_opex_values:
                dropdownValue4[0] !== "--" ? dropdownValue4 : scenarioDropdownOptions.unique_opex_values,
            unique_non_operating_expense_rows:
                dropdownValue5[0] !== "--" ? dropdownValue5 : scenarioDropdownOptions.unique_non_operating_expense_rows,
        };

        axios
            .post(
                "https://struct-api-ssx.softsensor.ai/structured_data/get_final_income_statement",
                final_payload_statement
            )
            .then((response) => {
                setIncomeStatementData(JSON.parse(response?.data));
                navigate("/finance/income_statement");
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    };
    const handleChange = (event: any, stateName: any) => {
        const { value } = event.target;
        if (value.length === 0) {
            if (stateName === "dropdownValue1") {
                setDropdownValue1(["--"]);
            } else if (stateName === "dropdownValue2") {
                setDropdownValue2(["--"]);
            } else if (stateName === "dropdownValue3") {
                setDropdownValue3(["--"]);
            } else if (stateName === "dropdownValue4") {
                setDropdownValue4(["--"]);
            } else if (stateName === "dropdownValue5") {
                setDropdownValue5(["--"]);
            }
        } else {
            const valueArr = value.filter((elem: any) => elem !== "--");
            if (stateName === "dropdownValue1") {
                setDropdownValue1(valueArr);
            } else if (stateName === "dropdownValue2") {
                setDropdownValue2(valueArr);
            } else if (stateName === "dropdownValue3") {
                setDropdownValue3(valueArr);
            } else if (stateName === "dropdownValue4") {
                setDropdownValue4(valueArr);
            } else if (stateName === "dropdownValue5") {
                setDropdownValue5(valueArr);
            }
        }
    };

    const handleDownload = async () => {
        const fileName = "download";
        const exportType = "xlsx"; // Adjust this based on your export type (xls, xlsx, etc.)

        const workbook = new ExcelJS.Workbook();

        const sheetName = "CFO";
        const worksheet = workbook.addWorksheet(sheetName);

        if (!showTrialBalance && !updateStatement) {
            // Add headers
            const headers = incomeStatement_headers.map((heading: any) => parsedHeading(heading));
            worksheet.addRow(headers);

            incomeStatementData?.forEach((data: any, index: number) => {
                let arr: any = [];
                Object.entries(data)?.map((entry: any, idx: number) => {
                    arr.push(entry[1]);
                });
                worksheet.addRow(arr);
                arr = [];
            });
        } else if (!showTrialBalance && updateStatement) {
            // Add headers
            const headers = incomeStatement_headers.map((heading: any) => parsedHeading(heading));
            worksheet.addRow(headers);

            updateStatement_table?.forEach((data: any, index: number) => {
                let arr: any = [];
                Object.entries(data)?.map((entry: any, idx: number) => {
                    arr.push(entry[1]?.value);
                });
                worksheet.addRow(arr);
                arr = [];
            });
        }

        // Create a buffer from the workbook
        const excelBuffer = await workbook.xlsx.writeBuffer();

        // Create a Blob from the buffer
        const blob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a link and trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = `${fileName}.${exportType}`;
        document.body.appendChild(a);
        a.click();

        // Remove the link
        document.body.removeChild(a);
    };

    return (
        <Box
            sx={{
                width: "96vw",
                height: "92vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    width: "95%",
                    height: "95%",
                    borderRadius: "10px",
                    border: "2px solid #EEEEEE",
                    display: "flex",
                    flexDirection: "column",
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        height: "13%",
                        mb: "1%",
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        <Button
                            onClick={() => {
                                if (showTrialBalance) {
                                    navigate("/finance");
                                } else if (!showTrialBalance && !updateStatement) {
                                    navigate("/finance/trial_balance");
                                }
                            }}
                        >
                            {"< Back"}
                        </Button>
                        {showTrialBalance ? (
                            <Typography sx={{ fontFamily: "Gilroy-SemiBold" }}>Scenario Analysis</Typography>
                        ) : null}
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", gap: 2 }}>
                            <Tooltip title={!showTrialBalance ? "View Income Statement" : null}>
                                <Button
                                    sx={{
                                        textTransform: "none",
                                        background: "#fff",
                                        color: !showTrialBalance ? "#007BFF" : "#D9D9D9",
                                        height: "50px",
                                        borderRadius: "7.31px",
                                        border: !showTrialBalance ? "1px solid #007BFF" : "1px solid #D9D9D9",
                                        "&:hover": { background: "#fff" },
                                    }}
                                    disabled={showTrialBalance}
                                    onClick={() => {
                                        // navigate("/finance/trial_balance");
                                        if (showTrialBalance) {
                                            navigate("/finance/income_statement");
                                        } else if (!showTrialBalance && updateStatement) {
                                            setUpdateStatement(false);
                                        }
                                    }}
                                >
                                    {showTrialBalance ? "View trial balance" : <IncomeStatementIcon />}
                                </Button>
                            </Tooltip>

                            <Tooltip title={!showTrialBalance ? "Scenario Analysis" : null}>
                                <Button
                                    sx={{
                                        textTransform: "none",
                                        background: "#fff",
                                        color: "#007BFF",
                                        height: "50px",
                                        borderRadius: "7.31px",
                                        border: "1px solid #007BFF",
                                        "&:hover": { background: "#fff" },
                                    }}
                                    onClick={() => {
                                        if (updateStatement_table === null) handleOpenModal();
                                        else {
                                            setUpdateStatement(true);
                                        }
                                    }}
                                >
                                    {showTrialBalance ? "Income Statement" : <ScenarioAnalysisShortIcon />}
                                </Button>
                            </Tooltip>
                            {!showTrialBalance ? (
                                <Tooltip title="Show PPT">
                                    <Button
                                        sx={{
                                            textTransform: "none",
                                            background: "#fff",
                                            color: "#007BFF",
                                            height: "50px",
                                            borderRadius: "7.31px",
                                            border: "1px solid #007BFF",
                                            "&:hover": { background: "#fff" },
                                        }}
                                        onClick={() => setShowPPT(true)}
                                    >
                                        <ShowPPTIcon />
                                    </Button>
                                </Tooltip>
                            ) : null}
                        </Box>

                        {!showTrialBalance ? (
                            <Button
                                onClick={handleDownload}
                                sx={{ background: "#E3F4FF", borderRadius: "10px", height: "50px" }}
                            >
                                <DownloadIcon />
                            </Button>
                        ) : null}
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        overflow: "scroll",
                        flexDirection: "column",
                    }}
                >
                    {showTrialBalance && (
                        <>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                {headers?.map((heading: any) => (
                                    <Box
                                        key={heading}
                                        sx={{
                                            background: "#2f3b1c",
                                            color: "#fff",
                                            p: 1,
                                            height: "60px",
                                            minWidth: "230px",
                                            border: "1px solid #fff",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        {parsedHeading(heading)}
                                    </Box>
                                ))}
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {trialBalanceData?.map((data: any, index: number) => (
                                    <Box sx={{ display: "flex" }} key={index}>
                                        {Object.entries(data)?.map((entry: any, idx: number) => (
                                            <Box
                                                sx={{
                                                    background: index % 2 === 0 ? "#fff" : "#dcf2e9",
                                                    color: "#000",
                                                    p: 1,
                                                    height: "60px",
                                                    minWidth: "230px",
                                                    border: "1px solid #2f3b1c",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    textAlign: "center",
                                                }}
                                                key={idx}
                                            >
                                                {entry[1] === null ? "-" : entry[1]}
                                            </Box>
                                        ))}
                                    </Box>
                                ))}
                            </Box>
                        </>
                    )}
                    {!showTrialBalance && !updateStatement && (
                        <>
                            <Box sx={{ width: "100%", height: "50%", overflow: "scroll" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        position: "sticky",
                                        top: 0,
                                        zIndex: 1,
                                    }}
                                >
                                    {incomeStatement_headers?.map((heading: any) => {
                                        return (
                                            <Box
                                                sx={{
                                                    background: "#2f3b1c",
                                                    color: "#fff",
                                                    p: 1,
                                                    height: "60px",
                                                    minWidth: "230px",
                                                    border: "1px solid #fff",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {parsedHeading(heading)}
                                            </Box>
                                        );
                                    })}
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    {incomeStatementData?.map((data: any, index: number) => (
                                        <Box sx={{ display: "flex" }} key={index}>
                                            {Object.entries(data)?.map((entry: any, idx: number) => (
                                                <Box
                                                    sx={{
                                                        background: index % 2 === 0 ? "#fff" : "#dcf2e9",
                                                        color: "#000",
                                                        p: 1,
                                                        height: "60px",
                                                        minWidth: "230px",
                                                        border: "1px solid #2f3b1c",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        textAlign: "center",
                                                    }}
                                                    key={idx}
                                                >
                                                    {entry[1] === null ? "-" : entry[1]}
                                                </Box>
                                            ))}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "50%",
                                    overflow: "scroll",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "90%",
                                        padding: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                        overflow: "scroll",
                                    }}
                                >
                                    {chats?.map((message: any, index: any) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                alignSelf: message.self ? "flex-end" : "flex-start",
                                                backgroundColor: message.self ? "#e0f7fa" : "#f1f1f1",
                                                padding: 1,
                                                borderRadius: 2,
                                                maxWidth: "80%",
                                                textAlign: message.self ? "right" : "left",
                                            }}
                                        >
                                            <Typography>{message.msg}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // border: "1px solid red",
                                        background: "#fff",
                                    }}
                                >
                                    <InputHandler setChats={setChats} chats={chats} />
                                </Box>
                            </Box>
                        </>
                    )}
                    {!showTrialBalance && updateStatement && (
                        <>
                            <Box sx={{ width: "100%", height: "50%", overflow: "scroll" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        position: "sticky",
                                        top: 0,
                                        zIndex: 1,
                                    }}
                                >
                                    {incomeStatement_headers?.map((heading: any) => (
                                        <Box
                                            sx={{
                                                background: "#2f3b1c",
                                                color: "#fff",
                                                p: 1,
                                                height: "60px",
                                                minWidth: "230px",
                                                border: "1px solid #fff",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            {parsedHeading(heading)}
                                        </Box>
                                    ))}
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    {updateStatement_table?.map((data: any, index: number) => {
                                        return (
                                            <Box sx={{ display: "flex" }} key={index}>
                                                {Object.entries(data)?.map((entry: any, idx: number) => {
                                                    return (
                                                        <Box
                                                            sx={{
                                                                background:
                                                                    index % 2 === 0
                                                                        ? entry?.[1]?.color === "null"
                                                                            ? "#fff"
                                                                            : entry?.[1]?.color
                                                                        : entry?.[1]?.color === "null"
                                                                          ? "#dcf2e9"
                                                                          : entry?.[1]?.color,
                                                                color: "#000",
                                                                p: 1,
                                                                height: "60px",
                                                                minWidth: "230px",
                                                                border: "1px solid #2f3b1c",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                textAlign: "center",
                                                            }}
                                                            key={idx}
                                                        >
                                                            {entry[1]?.value === null ? "-" : entry[1]?.value}
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "50%",
                                    overflow: "scroll",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "90%",
                                        padding: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                        overflow: "scroll",
                                    }}
                                >
                                    {chats?.map((message: any, index: any) => {
                                        return (
                                            <Box
                                                key={index}
                                                sx={{
                                                    alignSelf: message.self ? "flex-end" : "flex-start",
                                                    backgroundColor: message.self ? "#e0f7fa" : "#f1f1f1",
                                                    padding: 1,
                                                    borderRadius: 2,
                                                    maxWidth: "80%",
                                                    textAlign: message.self ? "right" : "left",
                                                }}
                                            >
                                                <Typography>{message.load ? <Typing /> : message.msg}</Typography>
                                            </Box>
                                        );
                                    })}
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <InputHandler setChats={setChats} chats={chats} />
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>

            <Dialog
                sx={{
                    "& .MuiDialog-paper": {
                        height: "825px",
                        width: "700px",
                    },
                }}
                open={openModal}
                onClose={handleCloseModal}
            >
                <DialogTitle>
                    FSLI Mappings
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenModal(false)}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 5,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "space-between" }}>
                            <Typography sx={{ width: "45%" }}>Unique Recurring Revenue</Typography>
                            <FormControl sx={{ width: "45%" }}>
                                <Select
                                    labelId="dropdown1-label"
                                    value={dropdownValue1}
                                    multiple
                                    onChange={(event) => handleChange(event, "dropdownValue1")}
                                >
                                    <MenuItem value="--">--</MenuItem>
                                    {scenarioDropdownOptions.unique_recurring_revenue_rows.map(
                                        (value: any, index: any) => (
                                            <MenuItem key={index} value={value}>
                                                {value}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "space-between" }}>
                            <Typography sx={{ width: "45%" }}>Unique Non Recurring Revenue</Typography>
                            <FormControl sx={{ width: "45%" }}>
                                {/* <InputLabel id="dropdown2-label">Select Value</InputLabel> */}
                                <Select
                                    labelId="dropdown2-label"
                                    value={dropdownValue2}
                                    multiple
                                    onChange={(event) => handleChange(event, "dropdownValue2")}
                                >
                                    <MenuItem value="--">--</MenuItem>
                                    {scenarioDropdownOptions.unique_non_recurring_revenue_rows.map(
                                        (value: string, index: number) => (
                                            <MenuItem key={index} value={value}>
                                                {value}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "space-between" }}>
                            <Typography sx={{ width: "45%" }}>Unique Payroll</Typography>
                            <FormControl sx={{ width: "45%" }}>
                                {/* <InputLabel id="dropdown2-label">Select Value</InputLabel> */}
                                <Select
                                    labelId="dropdown3-label"
                                    value={dropdownValue3}
                                    multiple
                                    onChange={(event) => handleChange(event, "dropdownValue3")}
                                >
                                    <MenuItem value="--">--</MenuItem>
                                    {scenarioDropdownOptions.unique_payroll_rows.map((value: string, index: number) => (
                                        <MenuItem key={index} value={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "space-between" }}>
                            <Typography sx={{ width: "45%" }}>Unique Opex Values</Typography>
                            <FormControl sx={{ width: "45%" }}>
                                {/* <InputLabel id="dropdown2-label">Select Value</InputLabel> */}
                                <Select
                                    labelId="dropdown2-label"
                                    value={dropdownValue4}
                                    multiple
                                    onChange={(event) => handleChange(event, "dropdownValue4")}
                                >
                                    <MenuItem value="--">--</MenuItem>
                                    {scenarioDropdownOptions.unique_opex_values.map((value: string, index: number) => (
                                        <MenuItem key={index} value={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "space-between" }}>
                            <Typography sx={{ width: "45%" }}>Unique Non Operating Expense</Typography>
                            <FormControl sx={{ width: "45%" }}>
                                {/* <InputLabel id="dropdown2-label">Select Value</InputLabel> */}
                                <Select
                                    labelId="dropdown2-label"
                                    value={dropdownValue5}
                                    multiple
                                    onChange={(event) => handleChange(event, "dropdownValue5")}
                                >
                                    <MenuItem value="--">--</MenuItem>
                                    {scenarioDropdownOptions.unique_non_operating_expense_rows.map(
                                        (value: string, index: number) => (
                                            <MenuItem key={index} value={value}>
                                                {value}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button variant="contained" onClick={() => handleGenerate()} color="primary">
                        Generate
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal open={showPPT} onClose={() => setShowPPT(false)}>
                <ShowPPT onClose={setShowPPT} report="scenario" />
            </Modal>
        </Box>
    );
};

export default TrialBalance;
