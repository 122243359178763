import React from "react";

const Upcoming1Icon = () => {
    return (
        <svg width="32" height="38" viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.57324 6.63086V31.3704L16.1305 28.1048V9.79753" fill="#E05243" />
            <path
                d="M0 7.91667V30.0833L2.57292 31.3698V6.63021M9.99479 2.86979V10.9844H15.7344V14.25L9.99479 14.9427V23.0573L15.7344 23.75V27.0156H9.99479V35.1302L15.7344 38L16.1302 28.3021L28.8958 31.3698V6.63021L16.1302 9.69792L15.7344 0"
                fill="#8C3123"
            />
            <path
                d="M15.7344 38V0L21.474 2.86979V10.9844H15.7344V14.25L21.474 14.9427V23.0573L15.7344 23.75V27.0156H21.474V35.1302M28.8958 31.3698V6.63021L31.4688 7.81771V30.0833"
                fill="#E05243"
            />
            <path d="M21.4743 10.985L15.7347 12.0736L9.99512 10.985L15.7347 9.59961" fill="#5E1F18" />
            <path d="M21.4743 27.0143L15.7347 25.9258L9.99512 27.0143L15.7347 28.3997" fill="#F2B0A9" />
        </svg>
    );
};

export default Upcoming1Icon;
