import { Box, IconButton, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import InputField from "./InputField";
import axios from "axios";
import Loader from "./Loader";
import CopyIcon from "../../components/Common/svgLogs/CopyIcon";
import DownloadIconLogo from "../../components/Common/svgLogs/DownloadIconLogo";
import { Context } from "../../context/setting";
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
const pdfStyles = StyleSheet.create({
    page: {
        backgroundColor: "#FFFFFF",
        padding: 20,
    },
    text: {
        fontSize: 13,
    },
});

const MedicalSummarizer = () => {
    const containerRef: any = useRef<any>();
    const { contextStore, setContext } = useContext<any>(Context);
    const [msgList, setMsgList] = useState<any[]>([
        {
            in: true,
            init: true,
            element: (
                <>
                    <Typography sx={{ fontSize: "0.9rem", fontFamily: "Gilroy-SemiBold" }}>
                        To inquire about a query, please provide the Patient ID or registered mobile number associated
                        with it.
                    </Typography>
                </>
            ),
        },
    ]);

    const handleSend = (value: string) => {
        setMsgList((prev: any[]) => [...prev, { out: true, message: value }, { in: true, msg: "loading", load: true }]);
        setTimeout(() => {
            containerRef?.current?.lastElementChild?.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "end",
            });
        }, 100);
        axios
            .post("https://struct-api-ssx.softsensor.ai/structured_data/medical_summarizer_bot", {
                query: value
              //  file_id: "d6ae5710-fe8f-404a-82c9-f9d85b828e04",
            })
            ?.then((res) => {
                if (res?.status == 200) {
                    setMsgList((prev: any[]) => {
                        return prev?.map((items) => {
                            if (items?.load) {
                                items.load = false;
                                items.msg = res?.data;
                            }
                            return items;
                        });
                    });
                    setTimeout(() => {
                        containerRef?.current?.lastElementChild?.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                            inline: "end",
                        });
                    }, 100);
                }
            })
            ?.catch((err) => {
                setMsgList((prev: any[]) => {
                    return prev?.map((items) => {
                        if (items?.load) {
                            items.load = false;
                            items.msg = err?.response?.data?.detail || err?.message;
                            items.err = true;
                        }
                        return items;
                    });
                });
                setTimeout(() => {
                    containerRef?.current?.lastElementChild?.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "end",
                    });
                }, 100);
            });
    };

    const handleCopy = (msg: string) => {
        navigator.clipboard
            ?.writeText(msg)
            ?.then(() =>
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message: "Message Copied",
                    },
                })
            )
            ?.catch((err) =>
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: err?.message || "Please try again later",
                    },
                })
            );
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", flex: 1, p: 1.5 }}>
            <Box sx={{ width: "60rem", display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Box
                    sx={{
                        border: "1px solid #EEEEEE",
                        background: "#fff",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Gilroy-SemiBold",
                        p: 1,
                        borderRadius: "8px",
                        gap: 1,
                    }}
                >
                    <Typography sx={{ color: "#007BFF" }}>Hospital Name Database</Typography>
                    <Typography>Welcome to SoftsensorX! Your go-to for seamless assistance</Typography>
                </Box>
                <Box
                    sx={{
                        flex: "1 0 10vh",
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        fontSize: "0.9rem",
                        fontFamily: "Gilroy-SemiBold",
                        mt: 0.5,
                        pb: 1,
                        px: 0.5,
                    }}
                    ref={containerRef}
                >
                    {msgList?.map((item: any, index: number) => (
                        <>
                            {item?.in && (
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Box
                                        sx={{
                                            p: 1,
                                            background: "rgba(217, 217, 217, 0.3)",
                                            borderRadius: "13px",
                                            width: "60%",
                                            alignSelf: "flex-start",
                                            color: item?.err ? "#fe6767" : "inherit",
                                            boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.3)",
                                            minHeight: "3rem",
                                        }}
                                    >
                                        {item?.init && item?.element}
                                        {item?.load ? (
                                            <Loader />
                                        ) : (
                                            item?.msg?.split("\n")?.map((msg: string) => (
                                                <>
                                                    {msg}
                                                    <br />
                                                </>
                                            ))
                                        )}
                                    </Box>
                                    {item?.hasOwnProperty("load") && !item?.load && (
                                        <>
                                            <Box>
                                                <IconButton
                                                    sx={{
                                                        transition: "ease-in-out 200ms",
                                                        transform: "scale(1)",
                                                        "&:hover": {
                                                            background: "#fff",
                                                            transform: "scale(1.1)",
                                                        },
                                                    }}
                                                    onClick={() => handleCopy(item?.msg)}
                                                >
                                                    <CopyIcon />
                                                </IconButton>
                                            </Box>
                                            <Box>
                                                <PDFDownloadLink
                                                    document={
                                                        <Document>
                                                            <Page size="A4" style={pdfStyles?.page}>
                                                                {item?.msg
                                                                    ?.split("\n")
                                                                    ?.map((msg: string) => (
                                                                        <Text style={pdfStyles?.text}>{msg}</Text>
                                                                    ))}
                                                            </Page>
                                                        </Document>
                                                    }
                                                    fileName={`Medical-Summarizer response - ${msgList[index - 1]
                                                        ?.message}...`}
                                                >
                                                    {({ blob, url, loading, error }) =>
                                                        loading ? (
                                                            <Loader />
                                                        ) : (
                                                            <IconButton
                                                                sx={{
                                                                    transition: "ease-in-out 200ms",
                                                                    transform: "scale(1)",
                                                                    "&:hover": {
                                                                        background: "#fff",
                                                                        transform: "scale(1.1)",
                                                                    },
                                                                }}
                                                            >
                                                                <DownloadIconLogo />
                                                            </IconButton>
                                                        )
                                                    }
                                                </PDFDownloadLink>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            )}
                            {item?.out && (
                                <Box
                                    sx={{
                                        p: 1,
                                        background: "#D2E8FF",
                                        borderRadius: "13px",
                                        width: "60%",
                                        alignSelf: "flex-end",
                                    }}
                                >
                                    {item?.message}
                                </Box>
                            )}
                        </>
                    ))}
                </Box>
                <Box>
                    <InputField handleSend={handleSend} />
                </Box>
            </Box>
        </Box>
    );
};

export default MedicalSummarizer;
