import { Box, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import SortIcon from "../../../components/Common/svgLogs/SortIcon";
import { Check } from "@mui/icons-material";
import moment from "moment";

const SortSection = ({ setPdfList }: { setPdfList: any }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [sortOrder, setSortOrder] = useState<string>("Ascending");
    const [sortType, setSortType] = useState<string>("Date");

    useEffect(() => {
        if (sortType === "Date") {
            setPdfList((prev: any) => {
                if (Array?.isArray(prev)) {
                    prev?.sort((a: any, b: any) => {
                        if (a?.pdf?.Date > b?.pdf?.Date) return 1;
                        if (a?.pdf?.Date < b?.pdf?.Date) return -1;
                        return 0;
                    });
                    return [...prev];
                } else {
                    prev?.pdfs?.sort((a: any, b: any) => {
                        if (a?.publish_date > b?.publish_date) return 1;
                        if (a?.publish_date < b?.publish_date) return -1;
                        return 0;
                    });
                    return { ...prev };
                }
            });
        }
        if (sortType === "A-Z") {
            setPdfList((prev: any) => {
                if (Array?.isArray(prev)) {
                    prev?.sort((a: any, b: any) => {
                        if (a?.pdf?.pdf_name > b?.pdf?.pdf_name) return 1;
                        if (a?.pdf?.pdf_name < b?.pdf?.pdf_name) return -1;
                        return 0;
                    });
                    return [...prev];
                } else {
                    prev?.pdfs?.sort((a: any, b: any) => {
                        if (a?.pdf_name > b?.pdf_name) return 1;
                        if (a?.pdf_name < b?.pdf_name) return -1;
                        return 0;
                    });
                    return { ...prev };
                }
            });
        }
    }, [sortType]);

    useEffect(() => {
        if (sortOrder === "Ascending") {
            setPdfList((prev: any) => {
                if (Array?.isArray(prev)) {
                    prev?.sort((a: any, b: any) => {
                        if (a?.pdf?.pdf_name > b?.pdf?.pdf_name) return 1;
                        if (a?.pdf?.pdf_name < b?.pdf?.pdf_name) return -1;
                        return 0;
                    });
                    return [...prev];
                } else {
                    prev?.pdfs?.sort((a: any, b: any) => {
                        if (a?.pdf_name > b?.pdf_name) return 1;
                        if (a?.pdf_name < b?.pdf_name) return -1;
                        return 0;
                    });
                    return { ...prev };
                }
            });
        }
        if (sortOrder === "Descending") {
            setPdfList((prev: any) => {
                if (Array?.isArray(prev)) {
                    prev?.sort((a: any, b: any) => {
                        if (b?.pdf?.pdf_name > a?.pdf?.pdf_name) return 1;
                        if (b?.pdf?.pdf_name < a?.pdf?.pdf_name) return -1;
                        return 0;
                    });
                    return [...prev];
                } else {
                    prev?.pdfs?.sort((a: any, b: any) => {
                        if (b?.pdf_name > a?.pdf_name) return 1;
                        if (b?.pdf_name < a?.pdf_name) return -1;
                        return 0;
                    });
                    return { ...prev };
                }
            });
        }
    }, [sortOrder]);

    return (
        <Box>
            <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}>
                <SortIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        padding: "0px 5px",
                        width: "12rem",
                    },
                }}
            >
                {["Date", "A-Z"]?.map((item: string) => (
                    <MenuItem
                        key={item}
                        sx={{
                            background: sortType === item ? "#E3F4FF" : "inherit",
                            borderRadius: "7px",
                        }}
                        onClick={() => setSortType(item)}
                    >
                        {item}
                    </MenuItem>
                ))}
                <Divider />
                {["Ascending", "Descending"]?.map((item: string) => (
                    <MenuItem
                        key={item}
                        sx={{
                            borderRadius: "7px",
                            justifyContent: "space-between",
                        }}
                        onClick={() => setSortOrder(item)}
                    >
                        {item}
                        {sortOrder === item && <Check fontSize="small" sx={{ color: "#007BFF" }} />}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default SortSection;
