import { Box, Button, CircularProgress, Divider, LinearProgress, OutlinedInput, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/setting";
import SearchMidiumIcon from "../../components/Common/svgLogs/SearchMidiumIcon";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import UploadSocket from "../Data_Sources/utils/UploadSocket";
import UploadProgress from "./UploadProgress";

const Care = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    const [careList, setCareList] = useState<any[]>([]);
    const [progressStatus, setProgressStatus] = useState<any[]>([]);
    const [msg, socketHooks, msgRef] = UploadSocket();

    useEffect(() => {
        if (contextStore?.pdfList) {
            setCareList(contextStore?.pdfList?.filter((file: { file_type: string }) => file?.file_type === "care"));
            setProgressStatus(
                contextStore?.pdfList?.filter(
                    (file: { file: { is_processed: boolean }; file_type: string }) =>
                        file?.file_type === "care" && !file?.file?.is_processed
                )
            );
        }
    }, [contextStore?.pdfList]);

    const handleFilter = (value: string) => {
        console.log(value);
        if (value?.trim()?.length === 0) {
            setCareList(contextStore?.pdfList?.filter((file: { file_type: string }) => file?.file_type === "care"));
        } else {
            setCareList(
                contextStore?.pdfList?.filter(
                    (file: { file_type: string; file_name: string }) =>
                        file?.file_type === "care" &&
                        file?.file_name
                            ?.toLowerCase()
                            ?.trim()
                            ?.includes(value?.toLowerCase()?.trim())
                )
            );
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5, flex: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 2, py: 1.5 }}>
                <Typography variant="h5">Source Documents</Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <OutlinedInput
                        size="small"
                        sx={{ "& .MuiOutlinedInput-input": { height: "1.1rem" } }}
                        endAdornment={<SearchMidiumIcon />}
                        placeholder="Search..."
                        onChange={(e: { target: { value: string } }) => handleFilter(e?.target?.value)}
                    />
                    <Button variant="outlined" onClick={() => navigate("upload")} startIcon={<Add fontSize="small" />}>
                        Upload new
                    </Button>
                </Box>
            </Box>
            <Divider />
            <Box sx={{ flex: "1 0 10vh", overflow: "auto" }}>
                {careList?.length > 0 ? (
                    careList?.map((item) => (
                        <>
                            <UploadProgress
                                item={item}
                                progressStatus={progressStatus}
                                msgRef={msgRef}
                                socketHooks={socketHooks}
                            />
                            <Divider />
                        </>
                    ))
                ) : (
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "10rem" }}>
                        <Typography fontWeight={600}>Please Upload A Document</Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default Care;
