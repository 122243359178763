import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";

const ImageClassification = ({ image, setImage, selectedFiles }: any) => {
    return (
        <Box sx={{ width: "100%", height: "100%", maxHeight: "95vh", display: "flex", p: 2, alignItems: "flex-start" }}>
            <Box
                sx={{
                    width: "15%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                }}
                onClick={() => {
                    setImage(null);
                }}
            >
                <Button sx={{ ml: "-50%" }}>Back</Button>
            </Box>
            <Box
                sx={{
                    width: "70%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    p: 3,
                    border: "1px solid #EDEDED",
                    borderRadius: "8px",
                }}
            >
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", mb: "3%" }}>
                    <Typography variant="h5">{selectedFiles?.[0]?.name}</Typography>
                    {/* <Menu>
                        <MenuItem>Anomaly detection</MenuItem>
                    </Menu> */}
                </Box>

                <img src={image} alt="classification image" style={{ width: "100%", height: "90%" }} />
            </Box>
        </Box>
    );
};

export default ImageClassification;
