import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, CircularProgress } from "@mui/material";

const Login = () => {
    const { isLoading, loginWithRedirect } = useAuth0();

    useEffect(() => {
        (async function login() {
            if (!isLoading) {
                await loginWithRedirect();
            }
        })();
    }, [isLoading, loginWithRedirect]);

    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                background: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default Login;
