import React from 'react';
import { Button } from '@mui/material';

interface RightArrowIconProps {
  size?: number;
  handleNext: () => void;
  currentStep: number;
  steps: any[];
}

const RightArrowIcon: React.FC<RightArrowIconProps> = ({ 
  size = 33, 
  handleNext, 
  currentStep, 
  steps 
}) => {
  const isLastStep = currentStep === steps.length - 1;
  
  if (!isLastStep) {
    return (
      <div 
        onClick={handleNext}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          backgroundColor: '#007AFF',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        role="button"
        aria-label="Next"
      >
        <svg
          width={size * 0.5}
          height={size * 0.5}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 12H19M19 12L12 5M19 12L12 19"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  return (
        <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    sx={{ mt: 2, backgorund: "#007AFF", borderRadius: '10px' }}
                >

      Start Chat
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginLeft: '8px' }}
      >
        <path
          d="M5 12H19M19 12L12 5M19 12L12 19"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Button>
  );
};

export default RightArrowIcon;