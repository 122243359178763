const RotatePhoneImage = () => {
    return (
        <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61.7 14C68.8 14 74.6 19.3 75.5 26.1L71.6 23.4C71.1 23.1 70.5 23.2 70.2 23.7C69.9 24.2 70 24.8 70.5 25.1L76.2 29C76.4 29.1 76.6 29.2 76.8 29.2C77.1 29.2 77.4 29 77.6 28.8L81.5 23.1C81.8 22.6 81.7 22 81.2 21.7C80.7 21.4 80.1 21.5 79.8 22L77.4 25.5C76.2 17.8 69.6 12 61.7 12C61.1 12 60.7 12.4 60.7 13C60.7 13.6 61.1 14 61.7 14ZM20 10H30C30.6 10 31 9.6 31 9C31 8.4 30.6 8 30 8H20C19.4 8 19 8.4 19 9C19 9.6 19.4 10 20 10ZM13.1 40H11C10.3 40 9.6 40.1 9 40.2C8.5 40.3 8.1 40.8 8.2 41.4C8.3 41.9 8.7 42.2 9.2 42.2C9.3 42.2 9.3 42.2 9.4 42.2C9.9 42.1 10.5 42 11.1 42H13.2C13.8 42 14.2 41.6 14.2 41C14.2 40.4 13.7 40 13.1 40ZM23.1 40H19.1C18.5 40 18.1 40.4 18.1 41C18.1 41.6 18.5 42 19.1 42H23.1C23.7 42 24.1 41.6 24.1 41C24.1 40.4 23.7 40 23.1 40ZM79 40H50V11C50 4.9 45.1 0 39 0H11C4.9 0 0 4.9 0 11V79C0 85.1 4.9 90 11 90H79C85.1 90 90 85.1 90 79V51C90 44.9 85.1 40 79 40ZM11 88C6 88 2 84 2 79V48.2C2.3 48.1 2.6 47.9 2.7 47.6C3.2 46.5 3.8 45.5 4.7 44.6C5.1 44.2 5.1 43.6 4.7 43.2C4.3 42.8 3.7 42.8 3.3 43.2C2.8 43.7 2.4 44.2 2.1 44.7V11C2.1 6 6.1 2 11.1 2H39.1C44.1 2 48.1 6 48.1 11V79C48.1 84 44.1 88 39.1 88H11ZM88 79C88 84 84 88 79 88H45.3C48.1 86 50 82.7 50 79V42H79C84 42 88 46 88 51V79ZM33.1 40H29.1C28.5 40 28.1 40.4 28.1 41C28.1 41.6 28.5 42 29.1 42H33.1C33.7 42 34.1 41.6 34.1 41C34.1 40.4 33.6 40 33.1 40ZM43 40H39C38.4 40 38 40.4 38 41C38 41.6 38.4 42 39 42H43C43.6 42 44 41.6 44 41C44 40.4 43.6 40 43 40ZM81 59C80.4 59 80 59.4 80 60V70C80 70.6 80.4 71 81 71C81.6 71 82 70.6 82 70V60C82 59.4 81.6 59 81 59Z"
                fill="black"
            />
        </svg>
    );
};

export default RotatePhoneImage;
