import { Box, MenuItem, Select, Typography } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FilesIcon from "../../components/Common/svgLogs/FilesIcon";
import ReportIcon from "../../components/Common/svgLogs/ReportIcon";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/setting";
import { ArrowForwardIos } from "@mui/icons-material";

interface AnamolyLeftBarProps {
    setSelectedTab: (tab: string) => void;
    selectedTab: string;
    setInvoiceReconciliation: React.Dispatch<React.SetStateAction<boolean>>;
    invoiceReconciliation: boolean;
}

const AnamolyLeftBar: React.FC<AnamolyLeftBarProps> = ({
    setSelectedTab,
    selectedTab,
    setInvoiceReconciliation,
    invoiceReconciliation,
}) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [selectedType, setSelectedType] = useState<string>("Detect Anomalies");
    const [loader, setLoader] = useState<boolean>(false);
    const [expand, setExpand] = useState<boolean>(true);

    // const handleFinanceResponse = async (type: string) => {
    //     setLoader(true);
    //     setContext({ ...contextStore, anamoly: { load: true } });
    //     if (type === "Detect Anomalies") {
    //         const result = await response_generator(Api?.getDetectAnomalies());
    //         if (result) {
    //             setContext({
    //                 ...contextStore,
    //                 anamoly: {
    //                     load: false,
    //                     msg: {
    //                         description: result?.description,
    //                         data: result?.resultant_data,
    //                     },
    //                     type: "Detect Anomalies",
    //                 },
    //             });
    //         }
    //     }
    //     if (type === "Abnormal Entries (Outliars)") {
    //         const result = await response_generator(Api?.getDetectOutliers());
    //         if (result) {
    //             setContext({
    //                 ...contextStore,
    //                 anamoly: {
    //                     load: false,
    //                     msg: {
    //                         description: result?.description,
    //                         data: result?.resultant_data,
    //                     },
    //                     type: "Abnormal Entries (Outliars)",
    //                 },
    //             });
    //         }
    //     }
    //     if (type === "Rounded Amount") {
    //         const result = await response_generator(Api?.getDetectRounded({ page: 1, size: 100 }));
    //         // console.log({ result });
    //         if (result) {
    //             setContext({
    //                 ...contextStore,
    //                 anamoly: {
    //                     load: false,
    //                     msg: {
    //                         description: result?.description,
    //                         data: result?.resultant_data,
    //                         count: result?.entries_count,
    //                     },
    //                     type: "Rounded Amount",
    //                 },
    //             });
    //         }
    //     }
    //     if (type === "Unexpected Credits & Debits") {
    //         const result = await response_generator(Api?.getDetectCreditsDebits());
    //         if (result) {
    //             setContext({
    //                 ...contextStore,
    //                 anamoly: {
    //                     load: false,
    //                     msg: { description: result?.description, data: result?.resultant_data },
    //                     type: "Unexpected Credits & Debits",
    //                 },
    //             });
    //         }
    //     }
    //     if (type === "Weekday Gap Anamoly") {
    //         const result = await response_generator(Api?.getDetectWeeklyGap());
    //         if (result) {
    //             setContext({
    //                 ...contextStore,
    //                 anamoly: {
    //                     load: false,
    //                     msg: { description: result?.description, data: result?.resultant_data },
    //                     type: "Weekday Gap Anamoly",
    //                 },
    //             });
    //         }
    //     }
    //     if (type === "Exceptional Entry") {
    //         const result = await response_generator(Api?.getDetecExceptionalEntries());
    //         if (result) {
    //             setContext({
    //                 ...contextStore,
    //                 anamoly: {
    //                     load: false,
    //                     msg: { description: result?.description, data: result?.resultant_data },
    //                     type: "Exceptional Entry",
    //                 },
    //             });
    //         }
    //     }
    //     setLoader(false);
    // };

    // const response_generator = async (promise: any) => {
    //     const result = await promise;
    //     if (result?.status === 200) {
    //         return result?.data;
    //     } else {
    //         return undefined;
    //     }
    // };

    return (
        <Box
            sx={{
                width: "17vw",
                height: "100%",
                borderRight: "1px solid #E1E1E1",
                px: 2,
                py: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
                userSelect: "none",
            }}
        >
            {/* <Box
                sx={{
                    width: "90%",
                    border: "red",
                    background: selectedTab === "chat" ? "#E3F4FF" : "white",
                    p: "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "20px",
                    cursor: "pointer",
                }}
                onClick={() => {
                    setSelectedTab("chat");
                    setSelectedType("");
                }}
            >
                <Box
                    sx={{
                        width: "50%",
                        border: "red",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <ChatIcon sx={{ fontSize: "20px", marginRight: "15px" }} />
                    <Typography sx={{ fontSize: "1rem" }}>Chat</Typography>
                </Box>
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
            </Box>
            {[
                { label: "my_files", title: "My Files", icon: FilesIcon },
                { label: "my_reports", title: "My Reports", icon: ReportIcon },
            ]?.map((item) => (
                <Box
                    sx={{
                        width: "90%",
                        border: "red",
                        background: selectedTab === item?.label ? "#E3F4FF" : "white",
                        p: "15px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: "20px",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        setSelectedTab(item?.label);
                        setSelectedType("");
                    }}
                >
                    <Box
                        sx={{
                            width: "fit-content",
                            border: "red",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Box sx={{ fontSize: "20px", marginRight: "15px" }}>
                            <item.icon />
                        </Box>
                        <Typography sx={{ fontSize: "1rem" }}>{item?.title}</Typography>
                    </Box>
                </Box>
            ))} */}

            <Box
                sx={{
                    width: "100%",
                    flexDirection: "column",
                    display: "flex",
                }}
            >
                <Typography
                    sx={{
                        color: "grey",
                        py: 1,
                        px: 2,
                        background: selectedTab === "chat" ? "#f8f8f8" : "#E3F4FF",
                        borderRadius: "19px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        cursor: "pointer",
                    }}
                    onClick={() => setExpand(!expand)}
                >
                    Audit Anomaly{" "}
                    {
                        <ArrowForwardIos
                            sx={{
                                fontSize: "0.8rem",
                                fontWeight: "bold",
                                transform: expand ? "rotate(90deg)" : "rotate(0deg)",
                            }}
                        />
                    }
                </Typography>

                {expand && (
                    <Box>
                        {[
                            "Detect Anomalies",
                            "Abnormal Entries (Outliers)",
                            "Rounded Amount",
                            "Unexpected Credits & Debits",
                            "Weekday Gap Anomaly",
                            "Exceptional Entry",
                        ]?.map((item: string) => (
                            <Typography
                                key={item}
                                sx={{
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                    color: !invoiceReconciliation && selectedType === item ? "#688bde" : "#888",
                                    pr: 2,
                                    pl: 2.5,
                                    py: 0.7,
                                }}
                                onClick={() => {
                                    if (loader) {
                                        setContext({
                                            ...contextStore,
                                            snackState: {
                                                open: true,
                                                errorType: "warning",
                                                message: "Please Wait For Response",
                                            },
                                        });
                                    } else {
                                        setSelectedType(item);
                                        setContext({
                                            ...contextStore,
                                            anamoly: { ...contextStore?.anamoly, selectedType: item },
                                        });
                                        setInvoiceReconciliation(false);
                                        setSelectedTab("parameter");
                                    }
                                }}
                            >
                                {item}
                            </Typography>
                        ))}
                    </Box>
                )}

                {/* <Typography
                    sx={{
                        color: "grey",
                        py: 1,
                        px: 2,
                        background: selectedTab === "parameter" ? "#f8f8f8" : "#E3F4FF",
                        borderRadius: "19px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mt: 1,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        setInvoiceReconciliation(true);
                        setSelectedTab("chat");
                    }} // Use the setInvoiceReconciliation function provided by useState
                >
                    Invoice Reconciliation
                </Typography> */}
            </Box>
        </Box>
    );
};

export default AnamolyLeftBar;
