const BinIcon = () => {
    return (
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_2650_591" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="19">
                <rect width="20" height="19" fill="grey" />
            </mask>
            <g mask="url(#mask0_2650_591)">
                <path
                    d="M5.83203 16.625C5.3737 16.625 4.98134 16.47 4.65495 16.1599C4.32856 15.8498 4.16536 15.4771 4.16536 15.0417V4.75H3.33203V3.16667H7.4987V2.375H12.4987V3.16667H16.6654V4.75H15.832V15.0417C15.832 15.4771 15.6688 15.8498 15.3424 16.1599C15.0161 16.47 14.6237 16.625 14.1654 16.625H5.83203ZM14.1654 4.75H5.83203V15.0417H14.1654V4.75ZM7.4987 13.4583H9.16536V6.33333H7.4987V13.4583ZM10.832 13.4583H12.4987V6.33333H10.832V13.4583Z"
                    fill="grey"
                />
            </g>
        </svg>
    );
};

export default BinIcon;
