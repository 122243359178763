const Car1 = () => {
    return (
        <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="140" height="140" fill="white" />
            <path
                d="M86.9908 8.1529C75.8949 5.17976 64.6961 5.38746 54.3624 8.21702L70.4267 69.9712L86.9908 8.1529Z"
                fill="#BEDFF1"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M82.3251 76.2178C82.407 79.5449 83.3185 82.6373 85.4714 86.3909V77.5401L82.3251 76.2178ZM85.4703 62.327C83.4409 65.9639 82.278 69.6512 82.214 74.3006L85.4703 75.6745V62.327ZM86.3941 57.9021C86.8734 57.9201 87.2596 58.2939 87.2596 58.7496V89.9279C87.2719 90.7765 86.109 91.1143 85.6174 90.4027C81.0051 83.8608 80.4135 80.142 80.3832 74.2377C80.3551 68.3334 81.8458 63.399 85.6275 58.2591C85.8048 58.0245 86.0899 57.8898 86.3941 57.9021Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M82.7566 33.8155V36.1312C82.7566 37.0842 83.1607 38.4222 85.1363 39.8264V36.6105C85.1363 35.873 84.0665 34.1668 82.7566 33.8155ZM81.7228 30.6467C81.8799 30.6523 82.0382 30.6927 82.1796 30.7634C85.3664 32.4067 87.2589 34.535 87.2589 36.9248V41.7021C87.2589 42.4788 86.4462 42.9997 85.7447 42.6596C85.125 42.3677 84.547 42.0635 84.0385 41.7178C81.3726 39.9229 80.6407 37.6903 80.6407 36.1289V31.7029C80.634 31.1136 81.1267 30.6355 81.7228 30.6467Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M57.3617 33.8155V36.1312C57.3617 37.0842 56.9576 38.4222 54.982 39.8264V36.6105C54.982 35.873 56.0517 34.1668 57.3617 33.8155ZM58.3955 30.6467C58.2383 30.6523 58.0801 30.6927 57.9386 30.7634C54.7519 32.4067 52.8594 34.535 52.8594 36.9248V41.7021C52.8594 42.4788 53.6721 42.9997 54.3736 42.6596C54.9932 42.3677 55.5713 42.0635 56.0798 41.7178C58.7457 39.9229 59.4832 37.6903 59.4832 36.1289V31.7029C59.4843 31.1136 58.9915 30.6355 58.3955 30.6467Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M80.8267 107.246C81.388 107.251 82.1075 107.035 82.7619 106.596C83.4354 106.151 83.9147 105.578 84.1302 105.105C84.3468 104.637 84.3109 104.374 84.2301 104.252C84.1481 104.13 83.9326 103.988 83.4174 104.006C82.9022 104.016 82.195 104.234 81.5226 104.678C80.8447 105.123 80.3654 105.697 80.1431 106.169C79.9276 106.636 79.9736 106.9 80.0567 107.022C80.1207 107.118 80.2015 107.234 80.8267 107.246ZM80.793 109.368C79.7469 109.35 78.7871 108.952 78.2787 108.182C77.695 107.287 77.8061 106.183 78.2203 105.276C78.6412 104.364 79.3787 103.546 80.3429 102.902C81.3139 102.265 82.36 101.914 83.3602 101.886C84.3603 101.855 85.4177 102.19 86.0014 103.089C86.5929 103.99 86.4818 105.088 86.0597 105.995C85.6388 106.901 84.9025 107.725 83.9326 108.362C82.9796 108.994 81.8976 109.385 80.793 109.368Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M70.0744 26.5492C75.4298 26.5492 80.2475 27.7828 83.6206 29.6877C86.9982 31.588 88.8289 34.044 88.8289 36.5629V50.2561C88.8289 50.7051 89.068 51.1272 89.4665 51.3551L92.0157 52.8278C92.5365 53.1275 92.7644 53.8795 92.541 54.2724C92.2727 54.7293 91.7878 54.8112 90.9639 54.5059L90.5654 54.3543C89.7303 54.0266 88.8289 54.6406 88.8289 55.5408V103.339C88.8289 105.01 88.2037 107.338 85.6018 109.355C82.9987 111.371 78.2933 113.096 70.0744 113.096C61.8589 113.096 57.1478 111.371 54.547 109.355C51.944 107.338 51.3243 105.01 51.3243 103.339V55.5408C51.3243 54.6395 50.4185 54.0266 49.5822 54.3543L49.186 54.5059C48.3677 54.8112 47.8761 54.7293 47.6134 54.2724C47.39 53.8807 47.619 53.1275 48.1342 52.8278L50.689 51.3551C51.0796 51.1272 51.3255 50.7051 51.3255 50.2561V36.5629C51.3255 34.044 53.1495 31.588 56.5293 29.6877C59.908 27.7817 64.719 26.5492 70.0744 26.5492ZM70.0744 24C64.3329 24 59.1414 25.2853 55.2766 27.4662C51.4119 29.6405 48.7696 32.8329 48.7696 36.5629V49.5119L46.8703 50.6243C45.1989 51.5885 44.6433 53.5473 45.2214 55.1423C45.5087 55.9371 46.1508 56.6813 47.0522 57.0315C47.555 57.2245 48.187 56.9956 48.7707 56.9372V103.339C48.7707 105.69 49.7708 108.876 52.9811 111.371C56.1959 113.863 61.5042 115.64 70.0755 115.64C78.6513 115.64 83.954 113.863 87.1699 111.371C90.3847 108.876 91.3781 105.69 91.3781 103.339V56.9372C91.9697 56.9956 92.5949 57.2245 93.1023 57.0315C94.0036 56.6813 94.6468 55.9371 94.933 55.1423C95.5122 53.5473 94.951 51.5874 93.2852 50.6243L91.3781 49.5119V36.5629C91.3781 32.8329 88.7347 29.6405 84.8767 27.4662C81.0119 25.2853 75.8159 24 70.0744 24Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M70.0734 49.5822C74.1592 49.5946 80.1118 50.9797 82.9764 53.2067L78.3338 61.9352C75.1897 61.4267 72.0153 61.2224 70.0801 61.2224C68.1449 61.2224 64.9705 61.4256 61.8242 61.9352L57.1827 53.2067C60.0473 50.9797 65.9853 49.6002 70.0734 49.5822ZM54.9894 52.0135C54.6493 52.3413 54.5673 52.8498 54.784 53.2651L60.2561 63.5482C60.4795 63.9647 60.9464 64.1858 61.4089 64.0994C64.6697 63.4898 68.0551 63.345 70.079 63.345C72.1073 63.345 75.4928 63.4898 78.7547 64.0994C79.2161 64.1858 79.6841 63.9647 79.8997 63.5482L85.3718 53.2651C85.5952 52.8509 85.5065 52.3413 85.1686 52.0135C81.6664 48.664 74.8271 47.4775 70.079 47.4775C65.332 47.4775 58.4859 48.664 54.9894 52.0135Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M56.6912 104.007C57.258 103.995 57.9787 104.217 58.632 104.649C59.3043 105.094 59.778 105.673 60.0003 106.14C60.2169 106.615 60.181 106.872 60.1002 106.994C60.0182 107.116 59.8005 107.255 59.2875 107.245C58.7723 107.227 58.0584 107.016 57.3871 106.572C56.7147 106.129 56.2298 105.556 56.0132 105.081C55.7977 104.609 55.8437 104.35 55.9268 104.228C55.9851 104.129 56.0716 104.013 56.6912 104.007ZM56.6631 101.884C55.617 101.894 54.6572 102.293 54.1487 103.065C53.5639 103.964 53.6694 105.071 54.0904 105.976C54.5113 106.882 55.2432 107.706 56.213 108.343C57.1828 108.986 58.2301 109.337 59.2302 109.367C60.2304 109.395 61.2878 109.063 61.8715 108.156C62.463 107.254 62.3519 106.162 61.9298 105.256C61.5089 104.344 60.7725 103.524 59.8016 102.882C58.8497 102.259 57.7676 101.866 56.6631 101.884Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M61.2379 86.7373C64.2552 87.3738 67.1433 87.8542 70.0663 87.8542C72.9893 87.8542 75.8887 87.3738 78.8992 86.7373L83.0569 94.4073C82.4541 95.5399 80.7939 96.5703 78.2975 97.1596C75.6361 97.7849 72.4561 97.9903 70.0719 97.9903C67.6922 97.9903 64.5122 97.7849 61.8519 97.1596C59.3555 96.5703 57.6819 95.5399 57.0802 94.4073L61.2379 86.7373ZM60.6666 84.5327C60.2569 84.5204 59.882 84.7438 59.6889 85.094L54.8184 94.0975C54.6792 94.359 54.6557 94.6812 54.76 94.9629C55.6794 97.4054 58.4317 98.5335 61.3558 99.2239C64.2844 99.9142 67.5699 100.107 70.0719 100.107C72.5739 100.107 75.8595 99.9142 78.788 99.2239C81.7166 98.5335 84.4712 97.4054 85.3827 94.9629C85.4893 94.6756 85.4646 94.3601 85.3187 94.0975L80.4482 85.094C80.2203 84.6731 79.7354 84.4508 79.2673 84.5563C76.0402 85.2803 73.0656 85.7316 70.0843 85.7652C67.0906 85.7304 64.1205 85.2859 60.881 84.5563C60.8114 84.544 60.7351 84.5327 60.6666 84.5327Z"
                fill="black"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M57.7939 76.2178C57.712 79.5449 56.8005 82.6373 54.6476 86.3909V77.5401L57.7939 76.2178ZM54.6487 62.327C56.6782 65.9639 57.8411 69.6512 57.9051 74.3006L54.6487 75.6745V62.327ZM53.7249 57.9021C53.2456 57.9201 52.8595 58.2939 52.8595 58.7496V89.9279C52.8471 90.7765 54.01 91.1143 54.5017 90.4027C59.1162 83.8608 59.7055 80.142 59.7358 74.2377C59.7639 68.3334 58.2732 63.399 54.4916 58.2591C54.3142 58.0245 54.0291 57.8898 53.7249 57.9021Z"
                fill="black"
            />
            <circle cx="70" cy="70" r="61" stroke="#3386D3" stroke-opacity="0.35" stroke-width="6" />
        </svg>
    );
};

export default Car1;
