import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { IconChevronDown } from "@tabler/icons-react";
import { useContext, useEffect, useRef, useState } from "react";
import { Api } from "../../apis";
import { Context } from "../../context/setting";
import DocumentLoadingModal from "../Chat/DocumentLoading/DocumentLoadingModal";

const RightPanelTopBars = ({
    imageBytes,
    setImageIndex,
    imageIndex,
    setImageBytes,
    setIsImageUploaded,
    setSelectedFiles,
    selectedFiles,
}: any) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const hiddenFileInput = useRef<any>(null);
    const [closeModal, setCloseModal] = useState<boolean>(true);
    const [additionalFiles, setAdditionalFiles] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const uploadFiles = () => {
        if (additionalFiles !== null) {
            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                formData?.append("files", selectedFiles[i]);
            }
            for (let i = 0; i < additionalFiles.length; i++) {
                formData.append("files", additionalFiles[i]);
            }

            setLoading(true);
            Api?.uploadDamageDetectionFiles(formData)?.then((res: any) => {
                if (res?.status === 200) {
                    setImageBytes(res?.data);
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Server issue! Please try after some time",
                        },
                    });
                }
                setLoading(false);
                setAdditionalFiles(null);
            });
        }
    };
    useEffect(() => {
        uploadFiles();
    }, additionalFiles);
    return (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", mb: "2%", mt: "2%" }}>
            <Box
                sx={{
                    width: "95%",
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #ECECEC",
                    borderRadius: "10px",
                    p: 1,
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: !closeModal ? null : "0.2%",
                    }}
                >
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <Typography sx={{ fontFamily: "Gilroy-Regular", fontWeight: "600" }}>Select Image</Typography>
                        <Box
                            sx={{
                                color: "#007BFF",
                                fontFamily: "Gilroy-Regular",
                                fontWeight: "600",
                                cursor: "pointer",
                            }}
                            onClick={() => hiddenFileInput.current.click()}
                        >
                            + Upload New
                        </Box>
                        <input
                            hidden
                            multiple
                            ref={hiddenFileInput}
                            type="file"
                            accept=".jpg, .png, .jpeg"
                            onChange={(e: { target: { files: any; value: any } }) => {
                                setAdditionalFiles(Array.from(e?.target?.files));
                                e.target.value = "";
                            }}
                        />
                    </Box>

                    <Button sx={{ marginLeft: "1%" }} onClick={() => setCloseModal(!closeModal)}>
                        {!closeModal ? (
                            <IconChevronDown />
                        ) : (
                            <Close style={{ color: "#9F9B9B", transform: "scale(0.7)" }} />
                        )}
                    </Button>
                </Box>

                {closeModal ? (
                    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", width: "100%" }}>
                        {imageBytes?.image_bytes_list?.detailed_reports?.map((img: any, index: any) => {
                            return (
                                <Box
                                    sx={{
                                        width: "100px",
                                        height: "100px",
                                        border:
                                            imageBytes?.image_bytes_list?.detailed_reports?.[imageIndex]?.[0] ===
                                            img?.[0]
                                                ? "3.5px solid #CAE4FF"
                                                : "2.5px solid #E4E4E4",
                                        borderRadius: "10px",
                                        mr: "2%",
                                        mb: "2%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setImageIndex(index);
                                    }}
                                >
                                    {/* <img src={img.imgSrc} /> */}
                                    <img
                                        style={{ width: "100%" }}
                                        alt="graph"
                                        src={`data:image/png;base64,${img?.[1]?.modified_image}`}
                                    />
                                </Box>
                            );
                        })}
                    </Box>
                ) : null}
            </Box>
            <DocumentLoadingModal loading={loading} />
        </Box>
    );
};

export default RightPanelTopBars;
