import { Box, Button, Checkbox, Typography, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";

import React, { useState } from "react";

interface CheckboxItem {
    label: string;
    id: string;
}

const checkboxItems: CheckboxItem[] = [
    { label: "Normal RunQuery", id: "Normal RunQuery" },
    { label: "Detailed Answer", id: "Detailed Answer" },
    { label: "RAG Fusion (Thumbs Down)", id: "RAG Fusion (Thumbs Down)" },
    { label: "Summary", id: "Summary" },
    { label: "Image_Generation", id: "Image Generation" },
    { label: "ESG - English", id: "ESG - English" },
    { label: "ESG - Multilingual", id: "ESG - MultiLingual" },
    { label: "Graph - QA", id: "Graph - QA" },
];

const Query = () => {
    const [total_Cost, setTotalCost] = useState(0.0);
    const [instance, setInstance] = useState("openai");
    const [compareAndContrast, setCompareAndContrast] = useState(false);
    const [numberofDocuments, setNumberofDocuments] = useState(0);
    const [queryTypes, setQueryTypes] = useState<string[]>([]);

    const instanceRatesOpenAI = {
        "Normal RunQuery": 0.0086,
        "Detailed Answer": 0.015,
        "RAG Fusion (Thumbs Down)": 0.03,
        Summary: 0.055,
        "Image Generation": 0.02,
        "ESG - English": 0.01585,
        "ESG - MultiLingual": 0.01617 + 0.000127,
        "Graph - QA": 0.0005,
    };

    const instanceRatesBedrock = {
        "Normal RunQuery": 0.0068,
        "Detailed Answer": 0.0013,
        "RAG Fusion (Thumbs Down)": 0.086,
        Summary: 0.0156,
        "Image Generation": 0.02,
        "ESG - English": 0.01585,
        "ESG - MultiLingual": 0.01617 + 0.000127,
        "Graph - QA": 0.0005,
    };

    const handleOptionChange = (event: any) => {
        setInstance(event.target.value);
    };

    const calculateCosts = () => {
        let cost = 0;
        const instanceRates = instance === "openai" ? instanceRatesOpenAI : instanceRatesBedrock;

        if (!compareAndContrast) {
            for (const queryType of queryTypes) {
                if (!instanceRates.hasOwnProperty(queryType)) {
                    throw new Error(`${queryType} is not a valid query type`);
                }
                cost += instanceRates[queryType as keyof typeof instanceRates];
            }
        }

        if (compareAndContrast) {
            cost +=
                instanceRates["Normal RunQuery" as keyof typeof instanceRates] * numberofDocuments +
                0.5 * instanceRates["Normal RunQuery" as keyof typeof instanceRates];
        }

        setTotalCost(Number(cost.toFixed(2)));
    };

    const handleCheckboxChange = (event: any) => {
        setCompareAndContrast(event.target.checked);
    };

    const handleQueryTypes = (event: any) => {
        const { checked, id } = event.target;
        setQueryTypes((prevQueryTypes) => {
            if (checked) {
                return [...prevQueryTypes, id];
            } else {
                return prevQueryTypes.filter((type) => type !== id);
            }
        });
    };

    return (
        <Box sx={{ width: "100%", border: "1px solid #D4D4D4", height: "75vh" }}>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // paddingY: "20px",
                }}
            >
                <Box
                    sx={{
                        width: "30%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        borderRight: "1px solid #D4D4D4",
                    }}
                >
                    <Box sx={{ width: "100%", paddingLeft: "15px", paddingTop: "10px" }}>
                        <Typography sx={{ fontFamily: "Gilroy-Medium", fontSize: "1rem", color: "#424F65" }}>
                            Select Instace Type
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <RadioGroup
                                row
                                aria-label="select-option"
                                name="select-option"
                                value={instance}
                                onChange={handleOptionChange}
                            >
                                <FormControlLabel
                                    value="openai"
                                    control={<Radio sx={{ fontSize: "small" }} />}
                                    label={<Box sx={{ fontSize: "small" }}>OpenAI</Box>}
                                />
                                <FormControlLabel
                                    value="bedrock"
                                    control={<Radio sx={{ fontSize: "small" }} />}
                                    label={<Box sx={{ fontSize: "small" }}>Bedrock</Box>}
                                />
                            </RadioGroup>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: "50%",
                            height: "100%",
                            paddingLeft: "15px",
                        }}
                    >
                        <Typography
                            sx={{ fontFamily: "Gilroy-Medium", fontSize: "1rem", marginTop: "15px", color: "#424F65" }}
                        >
                            Select Query Types
                        </Typography>
                        <FormGroup sx={{ marginTop: "5px" }}>
                            {checkboxItems.map((item) => (
                                <FormControlLabel
                                    key={item.id}
                                    control={
                                        <Checkbox
                                            checked={queryTypes.includes(item.id)}
                                            onChange={handleQueryTypes}
                                            id={item.id}
                                            disabled={compareAndContrast === true}
                                        />
                                    }
                                    label={item.label}
                                    labelPlacement="end"
                                />
                            ))}
                        </FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={compareAndContrast}
                                    disabled={queryTypes?.length > 0}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Compare and Contrast"
                        />
                        <Typography
                            sx={{
                                fontFamily: "Gilroy-Medium",
                                fontSize: "1rem",
                                marginTop: "15px",
                                color: queryTypes.length > 0 ? "grey" : "#424F65",
                            }}
                        >
                            No. Of Documents
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                // border: "1px solid red",
                                marginTop: "10px",
                                width: "100%",
                            }}
                        >
                            <TextField
                                type="number"
                                value={numberofDocuments}
                                onChange={(e) =>
                                    setNumberofDocuments(Math.min(Math.max(parseInt(e.target.value) || 0, 0), 100))
                                }
                                inputProps={{ min: 0, max: 100 }}
                                disabled={queryTypes?.length > 0}
                                style={{ width: "180px" }} // Set the width using style prop
                            />
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: "70%",
                        // border: "1px solid black",
                        height: "90%",
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "20px",
                        // marginTop: "20px",
                    }}
                >
                    <Box
                        sx={{
                            width: "350px",
                            height: "100px",
                            // border: "1px solid red",
                            backgroundColor: "#F7F7F7",
                            boxShadow: "0px 5px 5px 0px #D4D4D4",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box
                            sx={{
                                width: "40%",
                                // border: "1px solid red",
                                height: "100%",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "space-evenly",
                                flexDirection: "column",
                                paddingLeft: "10px",
                            }}
                        >
                            <Typography sx={{ fontFamily: "Gilroy-Medium", fontSize: "1rem", color: "#515151" }}>
                                Total Cost
                            </Typography>
                            <Typography sx={{ fontFamily: "Gilroy-Medium", fontSize: "1.5rem", color: "#515151" }}>
                                $ {total_Cost}
                            </Typography>
                            <Typography sx={{ fontFamily: "Gilroy-Medium", fontSize: "1rem", color: "#515151" }}>
                                or {(total_Cost * 85).toFixed(2)} Ruppes
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: "60%",
                                // border: "1px solid red",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Button onClick={() => calculateCosts()} variant="contained">
                                Calculate Cost
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Query;
