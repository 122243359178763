const report: any = {
    part_avg_confidence_confident_scroe: 0.4570915997028351,
    damage_avg_confidence_damage_intensity: 0.34963515400886536,
    image1: {
        Posture: "Side",
        "Damage Report": [
            { Component: "Side bumper", Severity: "Medium", "Type of Damage": "Dent", "Repair Method": "Replace" },

            { Component: "Door", Severity: "Medium", "Type of Damage": "Scrape", "Repair Method": "Replace" },

            { Component: "Side bumper", Severity: "Medium", "Type of Damage": "Scratch", "Repair Method": "Repair" },

            { Component: "Side bumper", Severity: "Medium", "Type of Damage": "Dent", "Repair Method": "Replace" },
            { Component: "Door", Severity: "Medium", "Type of Damage": "Scrape", "Repair Method": "Replace" },
            { Component: "Front bumper", Severity: "Minor", "Type of Damage": "Scratch", "Repair Method": "Repair" },
            { Component: "Door", Severity: "Medium", "Type of Damage": "Scrape", "Repair Method": "Replace" },
            { Component: "Door", Severity: "Medium", "Type of Damage": "Scratch", "Repair Method": "Repair" },
            { Component: "Side bumper", Severity: "Medium", "Type of Damage": "Scratch", "Repair Method": "Repair" },
            { Component: "Door", Severity: "Medium", "Type of Damage": "Scratch", "Repair Method": "Repair" },
            {
                Component: "Passenger-s door",
                Severity: "Medium",
                "Type of Damage": "Scratch",
                "Repair Method": "Repair",
            },
            {
                Component: "Passenger-s door",
                Severity: "Minor",
                "Type of Damage": "Scratch",
                "Repair Method": "Repair",
            },
        ],
        "Number of damages detected": 12,
    },

    image2: {
        Posture: "Front-Left",
        "Damage Report": [
            { Component: "Front bumper", Severity: "Minor", "Type of Damage": "Scratch", "Repair Method": "Repair" },
            { Component: "Front bumper", Severity: "Minor", "Type of Damage": "Dent", "Repair Method": "Replace" },
            { Component: "Front bumper", Severity: "Minor", "Type of Damage": "Scratch", "Repair Method": "Repair" },
            { Component: "Front bumper", Severity: "Minor", "Type of Damage": "Scratch", "Repair Method": "Repair" },
            { Component: "Front bumper", Severity: "Minor", "Type of Damage": "Scratch", "Repair Method": "Repair" },
            {
                Component: "Passenger-s door",
                Severity: "Medium",
                "Type of Damage": "Scratch",
                "Repair Method": "Repair",
            },
            { Component: "Front bumper", Severity: "Minor", "Type of Damage": "Scratch", "Repair Method": "Repair" },
            { Component: "Front bumper", Severity: "Minor", "Type of Damage": "Scratch", "Repair Method": "Repair" },
        ],
        "Number of damages detected": 8,
    },

    image3: {
        Posture: "Front",

        "Damage Report": [
            { Component: "Front bumper", Severity: "Medium", "Type of Damage": "Scratch", "Repair Method": "Repair" },
            { Component: "Front bumper", Severity: "Minor", "Type of Damage": "Dent", "Repair Method": "Replace" },
        ],
        "Number of damages detected": 2,
    },

    image4: {
        Posture: "Side",

        "Damage Report": [
            { Component: "Door", Severity: "Major", "Type of Damage": "Dent", "Repair Method": "Replace" },
        ],
        "Number of damages detected": 1,
    },

    image5: {
        Posture: "Side",

        "Damage Report": [
            { Component: "Door", Severity: "Medium", "Type of Damage": "Dent", "Repair Method": "Replace" },
            { Component: "Door", Severity: "Major", "Type of Damage": "Dent", "Repair Method": "Replace" },
            { Component: "Door", Severity: "Major", "Type of Damage": "Dent", "Repair Method": "Replace" },
        ],
        "Number of damages detected": 3,
    },

    image6: {
        Posture: "Rear-Right",

        "Damage Report": [
            { Component: "Door", Severity: "Major", "Type of Damage": "Scratch", "Repair Method": "Replace" },
            { Component: "Door", Severity: "Major", "Type of Damage": "Dent", "Repair Method": "Replace" },
            { Component: "Door", Severity: "Minor", "Type of Damage": "Scratch", "Repair Method": "Repair" },
            { Component: "Door", Severity: "Medium", "Type of Damage": "Dent", "Repair Method": "Replace" },
        ],
        "Number of damages detected": 4,
    },

    image7: {
        Posture: "Rear",

        "Damage Report": [
            { Component: "Door", Severity: "Medium", "Type of Damage": "Dent", "Repair Method": "Replace" },
            { Component: "Door", Severity: "Medium", "Type of Damage": "Dent", "Repair Method": "Replace" },
        ],
        "Number of damages detected": 2,
    },

    image8: {
        Posture: "Rear-Left",

        "Damage Report": [
            { Component: "Door", Severity: "Medium", "Type of Damage": "Scratch", "Repair Method": "Repair" },
        ],
        "Number of damages detected": 1,
    },
};

export default report;
