import { SaveAlt } from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/setting";
import { Api } from "../../../apis";
import ReactJson from "react-json-view";
import moment from "moment";

const DetailSection = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [highlightStatus, setHighlightStatus] = useState<"fail" | "pass">("fail");
    const [tableData, setTableData] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        setLoader(true);
        Api?.getCareDetail(contextStore?.care?.selectedFile?.file_id)
            ?.then((res: any) => {
                if (res?.status === 202) {
                    setTableData(res?.data);
                } else {
                    setTableData([]);
                }
                setLoader(false);
            })
            ?.catch((err) => {
                setLoader(false);
                setTableData([]);
            });
    }, [contextStore?.care?.selectedFile]);

    return (
        <Box sx={{ ml: "14px", display: "flex", flexDirection: "column", gap: 1, p: 2, height: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box>
                    <Typography sx={{ color: "#007BFF" }}>Extract & Match Result</Typography>
                    <Typography variant="caption">
                        Created {moment(contextStore?.care?.selectedFile?.created_at)?.format("DD MMM, YYYY")}
                    </Typography>
                </Box>
                {/* <Button sx={{ background: "#007BFF33", minWidth: "auto", borderRadius: "10px", minHeight: "auto" }}>
                    <SaveAlt fontSize="small" sx={{ color: "#007BFF" }} />
                </Button> */}
            </Box>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1.5,
                    p: 1.5,
                    boxShadow: "0px 0px 7px 0px rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    flex: 1,
                }}
            >
                <Box sx={{ display: "flex", gap: 1, alignSelf: "flex-end" }}>
                    {/* <Typography>Highlight</Typography> */}
                    {/* <Select
                        size="small"
                        sx={{ height: "1.8rem" }}
                        value={highlightStatus}
                        onChange={(e: { target: { value: string } }) =>
                            setHighlightStatus(e?.target?.value as "fail" | "pass")
                        }
                        disabled
                    >
                        <MenuItem value={"fail"}>Fail</MenuItem>
                    </Select> */}
                </Box>
                {loader ? (
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "10rem" }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box sx={{ flex: "1 0 10vh", overflow: "auto" }}>
                        {tableData?.length > 0 ? (
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {["Sr. no", "Checklist", "Response", "Match Result"]?.map((head) => (
                                            <TableCell
                                                sx={{
                                                    border: "none",
                                                    background: "#e2f0ff",
                                                    color: "#007BFF",
                                                    "&:first-child": {
                                                        borderTopLeftRadius: "6px",
                                                    },
                                                    "&:last-child": {
                                                        borderTopRightRadius: "6px",
                                                    },
                                                }}
                                            >
                                                {head}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData?.map((item: any, index: number) => (
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    background:
                                                        highlightStatus === item?.check?.toString()?.toLowerCase()
                                                            ? "rgba(226, 87, 76, 0.16)"
                                                            : "inherit",
                                                }}
                                            >
                                                {index + 1}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    width: "22rem",
                                                    background:
                                                        highlightStatus === item?.check?.toString()?.toLowerCase()
                                                            ? "rgba(226, 87, 76, 0.16)"
                                                            : "inherit",
                                                }}
                                            >
                                                {item?.heading}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    width: "22rem",
                                                    background:
                                                        highlightStatus === item?.check?.toString()?.toLowerCase()
                                                            ? "rgba(226, 87, 76, 0.16)"
                                                            : "inherit",
                                                }}
                                            >
                                                {typeof item?.response === "object" ? (
                                                    <ReactJson src={item?.response} collapsed />
                                                ) : (
                                                    item?.response?.toString()
                                                )}
                                                {/* {JSON?.stringify(item?.response)?.toString()} */}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    background:
                                                        highlightStatus === item?.check?.toString()?.toLowerCase()
                                                            ? "rgba(226, 87, 76, 0.16)"
                                                            : "inherit",
                                                }}
                                            >
                                                {item?.check}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "10rem",
                                }}
                            >
                                <Typography fontWeight={600}>No Data Found</Typography>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default DetailSection;
