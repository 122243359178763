import { Box, Button, Checkbox, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";

const PathologyReport = () => {
    const [value, setValue] = useState(0);
    const [fullBodyReport, setFullBodyReport] = useState(false);
    const [price, setPrice] = useState(0);

    

    const handleCost = () => {
        if (value > 0 && !fullBodyReport) {
            const cost = parseFloat((value * 0.02 + 0.02).toFixed(2));
            // const roundedCost = Math.round(cost / 10) * 10;
            // console.log({ cost });
            setPrice(cost);
        } else if (fullBodyReport) {
            const cost = parseFloat((0.02 * 12).toFixed(2));
            setPrice(cost);
        }
    };

    // console.log({ price });
    // console.log({ value });
    return (
        <Box
            sx={{
                width: "100%",
                border: "1px solid #D4D4D4",
                height: "75vh",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Box sx={{ width: "50%", height: "100%", paddingLeft: "20px" }}>
                <Typography sx={{ fontFamily: "Gilroy-Medium", fontSize: "1rem", marginY: "15px", color: "#424F65" }}>
                    No. Of Pages
                </Typography>
                <TextField
                    type="number"
                    value={value}
                    onChange={(e) => setValue(Math.min(Math.max(parseInt(e.target.value) || 0, 0), 100))}
                    inputProps={{ min: 0, max: 100 }}
                    style={{ width: "180px" }} // Set the width using style prop
                />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        // border: "1px solid red",
                        marginLeft: "-10px",
                        marginTop: "15px",
                    }}
                >
                    <Checkbox
                        name="Include Table Cost"
                        disabled={value > 0}
                        onChange={() => setFullBodyReport(!fullBodyReport)}
                    />
                    <Typography
                        sx={{ fontFamily: "Gilroy-Medium", fontSize: "1rem", color: value > 0 ? "grey" : "black" }}
                    >
                        Full Body Report
                    </Typography>
                </Box>
            </Box>

            <Box
                sx={{
                    width: "50%",
                    height: "90%",
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "20px",
                }}
            >
                <Box
                    sx={{
                        width: "350px",
                        height: "100px",
                        // border: "1px solid red",
                        backgroundColor: "#F7F7F7",
                        boxShadow: "0px 5px 5px 0px #D4D4D4",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            width: "40%",
                            // border: "1px solid red",
                            height: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-evenly",
                            flexDirection: "column",
                            paddingLeft: "10px",
                        }}
                    >
                        <Typography sx={{ fontFamily: "Gilroy-Medium", fontSize: "1rem", color: "#515151" }}>
                            Total Cost
                        </Typography>
                        <Typography sx={{ fontFamily: "Gilroy-Medium", fontSize: "1.5rem", color: "#515151" }}>
                            {price === 0 ? `$ 0.00` : `$ ${price}`}
                        </Typography>
                        <Typography sx={{ fontFamily: "Gilroy-Medium", fontSize: "1rem", color: "#515151" }}>
                            {price === 0 ? `or 0.00 rupees` : `or ${(price * 85).toFixed(2)} rupees`}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: "60%",
                            // border: "1px solid red",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Button onClick={() => handleCost()} variant="contained">
                            Calculate Cost
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default PathologyReport;
