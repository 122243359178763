const Stencil3 = ({ stroke }: any) => {
    return (
        <svg width="70vw" height="80vh" viewBox="0 0 299 292" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M156.001 1.99947H82.0008C72.8341 0.666133 53.1008 2.69947 47.5008 21.4995C42.6986 37.621 31.1801 96.0751 23.7916 135M156.001 290C121.834 290.167 51.0019 290.4 41.0019 290C28.5019 289.5 16.5019 284 12.0019 277C7.50187 270 6.50028 246 6.00028 239.5C5.60028 234.3 8.5006 230.667 10.0008 229.5C9.50076 222.833 8.80076 205.3 10.0008 188.5C11.2008 171.7 17.5008 157.5 20.5008 152.5C21.1483 149.019 21.8721 145.156 22.6561 141M23.7916 135C23.401 137.058 23.022 139.061 22.6561 141M23.7916 135C20.8249 134.6 20.3616 132.833 20.5008 132V105C20.5008 100.2 17.1669 98.6667 15.5 98.5C14.6667 98.3333 11.9 98.1 7.5 98.5C3.1 98.9 2.00124 103 2.00187 105C1.3352 112.5 0.501866 128.7 2.50187 133.5C4.50187 138.3 16.7714 140.5 22.6561 141M143 1.99947H217C226.167 0.666137 245.9 2.69947 251.5 21.4995C256.302 37.6211 267.821 96.0751 275.209 135M143 290C177.166 290.167 247.999 290.4 257.999 290C270.499 289.5 282.499 284 286.999 277C291.499 270 292.5 246 293 239.5C293.4 234.3 290.5 230.667 289 229.5C289.5 222.833 290.2 205.3 289 188.5C287.8 171.7 281.5 157.5 278.5 152.5C277.852 149.019 277.129 145.156 276.345 141M275.209 135C275.6 137.058 275.979 139.061 276.345 141M275.209 135C278.176 134.6 278.639 132.833 278.5 132V105C278.5 100.2 281.834 98.6667 283.501 98.5C284.334 98.3333 287.101 98.1 291.501 98.5C295.901 98.9 296.999 103 296.999 105C297.666 112.5 298.499 128.7 296.499 133.5C294.499 138.3 282.229 140.5 276.345 141"
                stroke={stroke}
                stroke-width="2"
            />
        </svg>
    );
};

export default Stencil3;
