import React from "react";

const AlertGenIcon = () => {
    return (
        <svg
            style={{ width: "1.063rem", height: "1.125rem" }}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_4388_2089"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="22"
                height="22"
            >
                <rect width="22" height="22" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4388_2089)">
                <path
                    d="M1.83398 19.25V17.4167H20.1673V19.25H1.83398ZM2.75065 16.5V10.0833H5.50065V16.5H2.75065ZM7.33398 16.5V5.5H10.084V16.5H7.33398ZM11.9173 16.5V8.25H14.6673V16.5H11.9173ZM16.5007 16.5V2.75H19.2507V16.5H16.5007Z"
                    fill="#007BFF"
                />
            </g>
        </svg>
    );
};

export default AlertGenIcon;
