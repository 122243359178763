import {
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import UploadBoxIconSmall from "../../../components/Common/svgLogs/UploadBoxIconSmall";
import { ArrowBackIos, Close } from "@mui/icons-material";
import { Api } from "../../../apis";
import { Context } from "../../../context/setting";
import { useNavigate } from "react-router-dom";

const Upload = () => {
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    const [files, setFiles] = useState<any>([]);
    const [Type, setType] = useState<"CE" | "INC" | "Provisional" | "Others">("CE");
    const [loading, setLoading] = useState<boolean>(false);

    const handleUpload = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("user_id", contextStore?.profile?.userId);
        files?.forEach(async (file: any) => {
            formData.append("pdf_file", file?.file);
            formData.append("FE_input", file?.type);
            Api?.careUpload(formData)
                ?.then((res: any) => {
                    if (res?.status === 202) {
                        setFiles((prev: any) => {
                            const temp = prev?.filter((prevFile: any) => prevFile?.file?.name !== file?.file?.name);
                            if (temp?.length === 0) {
                                Api?.getPdfs({
                                    user_id: contextStore?.profile?.userId,
                                })
                                    .then((res1: any) => {
                                        if (res1?.status === 200) {
                                            setContext({
                                                ...contextStore,
                                                pdf: { pdfList: res1?.data?.sort() },
                                            });
                                            navigate("/care");
                                        }
                                        setLoading(false);
                                    })
                                    ?.catch((err) => {
                                        setLoading(false);
                                    });
                            }
                            return temp;
                        });
                    }
                })
                ?.catch((err) => {
                    setLoading(false);
                    console.log(err);
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: err?.message || `Something went wrong`,
                        },
                    });
                });
        });
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                flex: 1,
                p: 2,
                fontFamily: "Gilroy-Medium !important",
                position: "relative",
            }}
        >
            <Box sx={{ position: "absolute", top: "1rem", left: "1rem" }}>
                <IconButton onClick={() => navigate("/care")}>
                    <ArrowBackIos sx={{ fontSize: "0.9rem", color: "#007BFF" }} />
                </IconButton>
            </Box>
            <Box
                sx={{
                    width: "40vw",
                    display: "flex",
                    flexDirection: "column",
                    background: "#fff",
                    borderRadius: "10px",
                    border: "1px solid #f5f5f5",
                    alignItems: "center",
                    py: 2,
                    gap: 2,
                    pt: 4,
                    boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.1)",
                }}
            >
                <Typography variant="h6">Extract & Match</Typography>
                <Typography sx={{ color: "#424F65" }}>
                    Analyse, Compare and get Insights about your Documents
                </Typography>
                <Divider sx={{ width: "100%" }} />
                <Box
                    sx={{
                        px: 3,
                        border: "1px dashed #007BFF",
                        width: "80%",
                        borderRadius: "7px",
                        py: 1.5,
                        display: "flex",
                        alignItems: "center",
                        gap: 6,
                        cursor: "pointer",
                    }}
                    component={"label"}
                >
                    <input
                        hidden
                        type="file"
                        multiple
                        accept=".pdf"
                        onChange={(e: { target: { files: any } }) => {
                            const selectedFiles = Array?.from(e?.target?.files)?.map((item) => ({
                                type: "CE",
                                file: item,
                            }));
                            setFiles([...files, ...selectedFiles]);
                        }}
                    />
                    <UploadBoxIconSmall />
                    <Box>
                        <Typography sx={{ color: "#424F65" }}>
                            Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                        </Typography>
                        <Typography variant="caption" fontStyle={"italic"}>
                            Supported .pdf
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ width: "86%", flex: "1 0 10vh", overflowY: "auto" }}>
                    <Typography variant="caption" fontStyle={"italic"}>
                        Ready to upload
                    </Typography>
                    {files?.length > 0 &&
                        files?.map((file: any) => (
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", py: 1 }}>
                                <Tooltip title={file?.file?.name}>
                                    <Typography noWrap sx={{ width: "60%" }}>
                                        {file?.file?.name}
                                    </Typography>
                                </Tooltip>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                                    <Select
                                        size="small"
                                        value={file?.type || "CE"}
                                        onChange={(e: { target: { value: string } }) =>
                                            setFiles(
                                                (prev: any) =>
                                                    prev?.map((item: any) => {
                                                        if (item?.file?.name === file?.file?.name)
                                                            item.type = e?.target?.value;
                                                        return item;
                                                    })
                                            )
                                        }
                                        sx={{ height: "1.8rem" }}
                                    >
                                        {["CE", "INC", "Provisional", "Others"]?.map((items: string) => (
                                            <MenuItem value={items}>{items}</MenuItem>
                                        ))}
                                    </Select>
                                    <IconButton
                                        onClick={() =>
                                            setFiles(
                                                (prev: any) =>
                                                    prev?.filter(
                                                        (prevFile: any) => prevFile?.file?.name !== file?.file?.name
                                                    )
                                            )
                                        }
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                </Box>
                <Box>
                    <Button
                        sx={{
                            background: "#007BFF",
                            color: "#fff",
                            minWidth: "8rem",
                            "&:hover": {
                                background: "#007BFF",
                                color: "#fff",
                            },
                            "&:disabled": {
                                background: "#007BFF",
                                color: "#ffffffaa",
                            },
                        }}
                        disabled={files?.length === 0}
                        onClick={handleUpload}
                    >
                        {loading ? (
                            <CircularProgress style={{ width: "20px", height: "20px", color: "#fff" }} />
                        ) : (
                            "Upload"
                        )}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Upload;
