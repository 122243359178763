import { ArrowBackIos, Close } from "@mui/icons-material";
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Api } from "../../../apis";
import { useNavigate } from "react-router-dom";
import UploadBoxIcon from "../../../components/Common/svgLogs/UploadBoxIcon";
import { Context } from "../../../context/setting";

const Variance = () => {
    const { contextStore, setContext } = useContext<any>(Context);
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [currentMonth, setCurrentMonth] = useState<string>("Feb_23");
    const [previousMonth, setPreviousMonth] = useState<string>("Jan_23");
    const [loading, setLoading] = useState(false);

    const handleGenerate = () => {
        if (selectedFile !== null) {
            setLoading(true);
            const formData = new FormData();
            formData?.append("file", selectedFile);
            Api?.uploadVariaceReport(formData)?.then((res: any) => {
                if (res?.status === 200) {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "success",
                            message: "Excel file ingested successfully!!",
                        },
                    });
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "Server issue! Please try after some time",
                        },
                    });
                }
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        handleGenerate();
    }, [selectedFile]);

    return (
        <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
            <Button
                sx={{ position: "absolute", left: "20px", top: "20px" }}
                onClick={() => {
                    if (selectedFile === null) navigate("/finance");
                    else if (selectedFile !== null) setSelectedFile(null);
                }}
            >
                <ArrowBackIos sx={{ transform: "scale(0.6)" }} /> Back{" "}
            </Button>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {selectedFile !== null ? (
                    <>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 1,
                            }}
                        >
                            <Typography variant="h6">Variance Analysis Report Generation</Typography>
                            <Box
                                sx={{
                                    width: "45%",
                                    minWidth: "600px",
                                    p: 4,
                                    border: "1px solid #F5F5F5",
                                    borderRadius: "11px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Box sx={{ borderBottom: "1px solid #F5F5F5", width: "100%", pb: 4 }}>
                                    <Typography sx={{ color: "#424F65" }}>
                                        Your Uploaded file is ready to use, select time frame and click on proceed to
                                        generate report
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        borderBottom: "1px solid #F5F5F5",
                                        width: "100%",
                                        pb: 2,
                                        pt: 2,
                                        pl: 4,
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        size="small"
                                        sx={{
                                            "&:hover": {
                                                background: "inherit",
                                            },
                                            background: "inherit",
                                            mr: 1,
                                        }}
                                        onClick={() => setSelectedFile(null)}
                                    >
                                        <Close sx={{ color: "#6B6B6B" }} />
                                    </Button>
                                    <Typography>{selectedFile?.name}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        pl: 7,
                                        pt: 3,
                                        alignItems: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            width: "40%",
                                        }}
                                    >
                                        <Typography sx={{ mb: 0.5 }}>Current Month</Typography>
                                        <Select
                                            sx={{ height: "2.2rem", width: "90%" }}
                                            value={currentMonth}
                                            onChange={(e) => setCurrentMonth(e?.target?.value)}
                                        >
                                            {[
                                                { id: "Jan_23", name: "January 2023" },
                                                { id: "Feb_23", name: "February 2023" },
                                                { id: "Mar_23", name: "March 2023" },
                                                { id: "Apr_23", name: "April 2023" },
                                                { id: "May_23", name: "May 2023" },
                                                { id: "Jun_23", name: "June 2023" },
                                                { id: "Jul_23", name: "July 2023" },
                                                { id: "Aug_23", name: "August 2023" },
                                                { id: "Sep_23", name: "September 2023" },
                                                { id: "Oct_23", name: "October 2023" },
                                                { id: "Nov_23", name: "November 2023" },
                                                { id: "Dec_23", name: "December 2023" },
                                            ]?.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
                                        </Select>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            width: "40%",
                                        }}
                                    >
                                        <Typography sx={{ mb: 0.5 }}>Previous Month</Typography>
                                        <Select
                                            sx={{ height: "2.2rem", width: "90%" }}
                                            value={previousMonth}
                                            onChange={(e) => setPreviousMonth(e?.target?.value)}
                                        >
                                            {[
                                                { id: "Jan_23", name: "January 2023" },
                                                { id: "Feb_23", name: "February 2023" },
                                                { id: "Mar_23", name: "March 2023" },
                                                { id: "Apr_23", name: "April 2023" },
                                                { id: "May_23", name: "May 2023" },
                                                { id: "Jun_23", name: "June 2023" },
                                                { id: "Jul_23", name: "July 2023" },
                                                { id: "Aug_23", name: "August 2023" },
                                                { id: "Sep_23", name: "September 2023" },
                                                { id: "Oct_23", name: "October 2023" },
                                                { id: "Nov_23", name: "November 2023" },
                                                { id: "Dec_23", name: "December 2023" },
                                            ]?.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
                                        </Select>
                                    </Box>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            background: "#007BFF",
                                            width: "20%",
                                            height: "2.1rem",
                                            borderRadius: "5px",
                                            "&:hover": {
                                                boxShadow: "0px -1px 6px 4px #d4d4d4",
                                            },
                                            mt: 3.1,
                                        }}
                                        onClick={() =>
                                            navigate("/variance_generation", {
                                                state: {
                                                    currentMonth: currentMonth,
                                                    previousMonth: previousMonth,
                                                    fileName: selectedFile?.name,
                                                },
                                            })
                                        }
                                    >
                                        Proceed
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <Box
                        sx={{
                            width: "45%",
                            minWidth: "600px",
                            p: 5,
                            border: "1px solid #F5F5F5",
                            borderRadius: "11px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                                gap: 1,
                            }}
                        >
                            <Typography variant="h6">Variance Analysis Report Generation</Typography>
                            <Typography sx={{ color: "#424F65" }}>
                                Upload your excel files and get detailed Insights through an intuitive report
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                height: "10vh",
                                minHeight: "50px",
                                display: "flex",
                                border: "1px dashed #007BFF",
                                borderRadius: "6px",
                                mt: "5vh",
                                p: 2,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            component={"label"}
                            onDrop={(e: any) => {
                                e.preventDefault();
                                setSelectedFile(e?.dataTransfer?.files[0]);
                            }}
                            onDragOver={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            <Box sx={{ transform: "scale(0.6)" }}>
                                <UploadBoxIcon />
                            </Box>
                            <Box
                                sx={{
                                    height: "100%",
                                    width: "320px",
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "300px",
                                        height: "100%",
                                        justifyContent: "center",
                                        display: "flex",
                                        ml: 2,
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <Typography variant="h5">
                                        Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: "#424F65" }}>
                                        <em>Supported .xlsx, .csv</em>
                                    </Typography>
                                </Box>

                                <input
                                    hidden
                                    type="file"
                                    accept=".pdf, .xls, .xlsx, .csv"
                                    onChange={(e: { target: { files: any; value: any } }) => {
                                        // setSelectedFile(e?.target?.files[0]);
                                        setSelectedFile(e?.target?.files[0]);
                                        // setOpen(true);
                                        e.target.value = "";
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default Variance;
