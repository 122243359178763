import { Box } from "@mui/material";
import React from "react";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import PdfSection from "./PdfSection";
import DetailSection from "./DetailSection";

const Detail = () => {
    return (
        <ReflexContainer orientation="vertical">
            <ReflexElement flex={1} minSize={400} size={540}>
                <PdfSection />
            </ReflexElement>
            <ReflexSplitter>
                <Box
                    sx={{
                        width: "16px",
                        height: "100%",
                        background: "#E3F4FF",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        borderWidth: "0px 2px 0px 2px",
                        borderStyle: "solid",
                        borderColor: "#E3F4FF",
                    }}
                >
                    <Box
                        sx={{
                            width: "3px",
                            height: "15px",
                            background: "#0000001C",
                            borderRadius: "10px",
                        }}
                    ></Box>
                    <Box
                        sx={{
                            width: "3px",
                            height: "15px",
                            background: "#0000001C",
                            borderRadius: "10px",
                        }}
                    ></Box>
                </Box>
            </ReflexSplitter>
            <ReflexElement flex={1}>
                <DetailSection />
            </ReflexElement>
        </ReflexContainer>
    );
};

export default Detail;
