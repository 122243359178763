import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const UploadProgress = ({ data, selectedFiles }: any) => {
    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {
        // console.log(data, progress);
        if (data !== null) {
            setProgress(JSON.parse(data)?.progress);
        }
    }, [data]);

    // console.log("progress:", progress);

    return (
        <Box
            sx={{
                width: "100%",
                height: "70%",
                mt: "2%",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            {selectedFiles && data !== null && (
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        Uploading
                    </Typography>
                    {selectedFiles?.map((file: any) => {
                        return <Typography variant="h6">{file}</Typography>;
                    })}
                </Box>
            )}
            <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ mt: 3, height: "20px", borderRadius: "10px" }}
            />
            <Typography sx={{ width: "100%", display: "flex", justifyContent: "center", mt: 2 }} variant="h5">
                {progress !== 100 ? "Uploading your files. Please wait" : "Files uploaded successfully"}
            </Typography>
        </Box>
    );
};

export default UploadProgress;
