import React, { useRef, useState } from "react";
import useWebSocket from "react-use-websocket";
import { Some } from "../../../utils copy";
import { Api } from "../../../apis";
const UploadSocket: any = () => {
    const [msg, setMsg] = useState<string>("");
    const msgRef: any = useRef();

    const { getWebSocket, lastJsonMessage, lastMessage, readyState, sendJsonMessage, sendMessage } = useWebSocket(
        Api?.uploadPdfSocket,
        {
            onOpen: () => console.log("WebSocket connection established."),
            onMessage: (resp: any) => {
                const dataString = Some.String(resp?.data);

                if (
                    dataString.includes("Connection established") ||
                    dataString.includes("Provide pdf_name first") ||
                    dataString.includes("is_function_calling_model")
                ) {
                    return;
                }

                if (dataString.includes("index loaded for pdf")) {
                    return setTimeout(() => {
                        console.log("loaded successfully");
                    }, 800);
                }

                if (dataString?.startsWith("Error occurred")) {
                    setMsg((prev) => prev + "<EOD>");
                    msgRef.load = false;
                    return;
                }

                try {
                    const json = JSON.parse(dataString);

                    if (typeof json === "object" && !Array?.isArray(json)) {
                        msgRef.msg = json;
                    }
                } catch (error) {}
            },
            onError: (event: any) => {
                // console.log(event);
            },
        }
    );
    return [msg, { getWebSocket, lastJsonMessage, lastMessage, readyState, sendJsonMessage, sendMessage }, msgRef];
};

export default UploadSocket;
