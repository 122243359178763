import React from "react";

const LoadingState = ({ fill }: { fill: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5Z"
                fill={fill || "#F9BC00"}
            />
            <path
                d="M18.3 11.301C18.1143 11.301 17.9363 11.3748 17.805 11.506C17.6737 11.6373 17.6 11.8154 17.6 12.001C17.6083 13.3121 17.1592 14.5851 16.33 15.6007C15.5007 16.6163 14.3433 17.311 13.057 17.565C11.7707 17.8191 10.436 17.6166 9.28295 16.9926C8.12986 16.3685 7.23048 15.3618 6.73973 14.146C6.24897 12.9302 6.19756 11.5812 6.59433 10.3316C6.99111 9.0819 7.81125 8.00972 8.91349 7.29967C10.0157 6.58962 11.3311 6.28616 12.633 6.44156C13.9349 6.59696 15.1419 7.20149 16.046 8.15102H14.366C14.1803 8.15102 14.0023 8.22477 13.871 8.35605C13.7398 8.48732 13.666 8.66537 13.666 8.85102C13.666 9.03667 13.7398 9.21472 13.871 9.34599C14.0023 9.47727 14.1803 9.55102 14.366 9.55102H17.537C17.7227 9.55102 17.9007 9.47727 18.032 9.34599C18.1633 9.21472 18.237 9.03667 18.237 8.85102V5.70102C18.237 5.51537 18.1633 5.33733 18.032 5.20605C17.9007 5.07477 17.7227 5.00103 17.537 5.00103C17.3513 5.00103 17.1733 5.07477 17.042 5.20605C16.9107 5.33733 16.837 5.51537 16.837 5.70102V6.94002C15.6713 5.82566 14.1565 5.14799 12.5488 5.02156C10.9411 4.89512 9.33904 5.32768 8.01345 6.24611C6.68785 7.16454 5.72003 8.51251 5.27358 10.0622C4.82714 11.6118 4.92945 13.2681 5.56323 14.751C6.19701 16.2339 7.32337 17.4525 8.75193 18.2008C10.1805 18.9491 11.8236 19.1812 13.4035 18.8578C14.9835 18.5345 16.4033 17.6755 17.423 16.4262C18.4427 15.1768 18.9998 13.6137 19 12.001C19 11.8154 18.9263 11.6373 18.795 11.506C18.6637 11.3748 18.4857 11.301 18.3 11.301Z"
                fill="white"
            />
        </svg>
    );
};

export default LoadingState;
