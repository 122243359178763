import { Add, Close } from "@mui/icons-material";
import { Box, Button, Drawer, IconButton, OutlinedInput, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Api } from "../../apis";
import { Context } from "../../context/setting";
import UploadSocket from "../Data_Sources/utils/UploadSocket";
import UploadedPathFiles from "./UploadedPathFiles";
import UploadBoxIcon from "../../components/Common/svgLogs/UploadBoxIcon";

const FileView = ({
    containsFiles,
    setContainsFile,
    selectedFile,
    setSelectedFile,
    pdfList,
    setPdfList,
    fetchPathologyList,
}: any) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [anchor, setAnchor] = useState<any>(false);

    const handleUploadPathology = () => {
        const userId: string = contextStore?.profile?.userId;
        const formData = new FormData();
        const azure_provider: any = contextStore?.isAzure ? 1 : 0;
        formData.append("azure_provider", azure_provider);
        formData?.append("pdf_file", selectedFile);
        formData.append("user_id", userId);
        Api?.uploadPathology(formData)?.then((res: any) => {
            if (res?.status === 202) {
                setSelectedFile(null);
                setContainsFile(true);
                setAnchor(false);
                fetchPathologyList();
                setContext({
                    ...contextStore,
                    source_file: undefined,
                    snackState: {
                        open: true,
                        errorType: "success",
                        message: `Upload Process Started For ${selectedFile?.name}`,
                    },
                });
            }
        });
    };

    const handleDeleteFile = (name: string) => {
        Api?.deleteSourceESG({ table_name: name })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "success",
                            message: "File Deleted Successfully",
                        },
                    });
                    fetchPathologyList();
                } else {
                    setContext({
                        ...contextStore,
                        snackState: {
                            open: true,
                            errorType: "error",
                            message: "something went wrong",
                        },
                    });
                }
            })
            .catch((err) =>
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "something went wrong",
                    },
                })
            );
    };
    useEffect(() => {
        setPdfList(contextStore?.pathalogyList?.filter((pdf: any) => !pdf?.is_deleted));
        if (contextStore?.pathalogyList?.length > 0) {
            setContainsFile(true);
        }
    }, [contextStore?.pathalogyList]);

    const [msg, socketHooks, msgRef] = UploadSocket();

    return (
        <Box sx={{ width: "100%", height: "100%", p: "3vh 2vw 0px 2vw" }}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", mb: "4vh" }}>
                <Typography variant="h4" fontWeight={500}>
                    Medical Reports
                </Typography>
                <Box sx={{ gap: 3, display: "flex" }}>
                    <OutlinedInput
                        sx={{
                            pr: "1.8px",
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "7.6px",
                                borderColor: "#CFCFCF",
                                overflow: "hidden",
                            },
                            "& .MuiOutlinedInput-input": {
                                height: "0.5rem",
                                padding: "14px 12px",
                            },
                        }}
                        onChange={(e: { target: { value: string } }) =>
                            setPdfList(
                                contextStore?.pathalogyList?.filter(
                                    (item: any) =>
                                        item?.file_name
                                            ?.toLowerCase()
                                            ?.includes(e?.target?.value?.trim()?.toLowerCase())
                                )
                            )
                        }
                        placeholder="Search"
                        endAdornment={
                            <IconButton sx={{ borderRadius: "4px" }}>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.91732 15.4245C10.3225 15.4242 11.6872 14.9538 12.7941 14.0881L16.2743 17.5683L17.3937 16.4489L13.9135 12.9687C14.7796 11.8617 15.2503 10.4967 15.2507 9.09115C15.2507 5.5991 12.4094 2.75781 8.91732 2.75781C5.42528 2.75781 2.58398 5.5991 2.58398 9.09115C2.58398 12.5832 5.42528 15.4245 8.91732 15.4245ZM8.91732 4.34115C11.5369 4.34115 13.6673 6.47152 13.6673 9.09115C13.6673 11.7108 11.5369 13.8411 8.91732 13.8411C6.29769 13.8411 4.16732 11.7108 4.16732 9.09115C4.16732 6.47152 6.29769 4.34115 8.91732 4.34115Z"
                                        fill="#484848"
                                    />
                                </svg>
                            </IconButton>
                        }
                    />
                    <Button
                        startIcon={<Add />}
                        sx={{
                            textTransform: "none",
                            background: "#fff",
                            color: "#007BFF",
                            px: 2,
                            borderRadius: "8px",
                            border: "1px solid #007BFF",
                            "&:hover": { background: "#fffc3" },
                        }}
                        onClick={() => setAnchor(true)}
                    >
                        Upload Report
                    </Button>
                </Box>
            </Box>
            {pdfList?.map((file: any) => {
                return (
                    !file?.is_deleted && (
                        <Box
                            sx={{
                                height: "70px",
                                borderTop: "1px solid #F3F3F4",
                                p: "15px 0px",
                            }}
                        >
                            <UploadedPathFiles
                                key={file?.file_id}
                                file={file}
                                socketHooks={socketHooks}
                                msgRef={msgRef}
                                handleDeleteFile={handleDeleteFile}
                            />
                        </Box>
                    )
                );
            })}
            <Drawer anchor="right" open={anchor} onClose={() => setAnchor(false)}>
                <Box sx={{ width: "30vw", p: "3vh 2vw", minWidth: "350px", height: "100%" }}>
                    <Box
                        sx={{
                            width: "100%",
                            height: "15%",
                            mt: "4vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px dashed #007BFF",
                            borderRadius: "8px",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                p: 1,
                                pl: 4,
                                minHeight: "50px",
                                display: "flex",
                                flexDirection: "row",
                                justifyItems: "center",
                                alignItems: "center",
                            }}
                            component={"label"}
                            onDrop={(e: any) => {
                                e.preventDefault();
                                setSelectedFile(e?.dataTransfer?.files[0]);
                            }}
                            onDragOver={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            <Box sx={{ transform: "scale(0.8)", ml: "3%", mr: "5%" }}>
                                <UploadBoxIcon />
                            </Box>
                            <Box
                                sx={{
                                    height: "100%",
                                    width: "100%",
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        justifyContent: "center",
                                        display: "flex",
                                        ml: 2,
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <Typography variant="h5">
                                        {" "}
                                        Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: "#424F65" }}>
                                        <em>Supported .pdf</em>
                                    </Typography>
                                </Box>

                                <input
                                    hidden
                                    type="file"
                                    accept=".pdf"
                                    onChange={(e: { target: { files: any; value: any } }) => {
                                        setSelectedFile(e?.target?.files[0]);
                                        e.target.value = "";
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ width: "100%", mt: "4vh" }}>
                        {selectedFile ? (
                            <Box>
                                <Typography sx={{ color: "#424F65", mb: "3vh" }}>
                                    <em>Report ready to upload</em>
                                </Typography>
                                <Box
                                    sx={{
                                        width: "100%",

                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        height: "50px",
                                    }}
                                >
                                    <Typography variant="subtitle1">{selectedFile?.name}</Typography>
                                    <Button onClick={() => setSelectedFile(null)}>
                                        <Close sx={{ color: "#000" }} />
                                    </Button>
                                </Box>
                            </Box>
                        ) : null}
                    </Box>
                    {selectedFile && (
                        <Box sx={{ width: "100%", mt: "35vh", display: "flex", justifyContent: "center" }}>
                            <Button
                                variant="contained"
                                sx={{
                                    background: "#007BFF",
                                    width: "15rem",
                                    height: "2.8rem",
                                    borderRadius: "10px",
                                    mt: "20%",
                                    position: "relative",
                                    bottom: 0,
                                }}
                                onClick={handleUploadPathology}
                            >
                                Upload
                            </Button>
                        </Box>
                    )}
                </Box>
            </Drawer>
        </Box>
    );
};

export default FileView;
