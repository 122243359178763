import React from "react";

const EditIcon = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_320_3300"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="17"
                height="17"
            >
                <rect width="17" height="17" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_320_3300)">
                <path
                    d="M3.54167 13.4596H4.53333L10.6427 7.35026L9.65104 6.35859L3.54167 12.468V13.4596ZM13.6708 6.32318L10.6604 3.34818L11.6521 2.35651C11.9236 2.08498 12.2571 1.94922 12.6526 1.94922C13.0481 1.94922 13.3816 2.08498 13.6531 2.35651L14.6448 3.34818C14.9163 3.6197 15.058 3.94731 15.0698 4.33099C15.0816 4.71467 14.9517 5.04227 14.6802 5.3138L13.6708 6.32318ZM12.6437 7.36797L5.13542 14.8763H2.125V11.8659L9.63333 4.35755L12.6437 7.36797Z"
                    fill="#007BFF"
                />
            </g>
        </svg>
    );
};

export default EditIcon;
