import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
type Props = {
    open: boolean;
    handleClose: any;
    selectedFile: any;
    handlePdfSave: any;
    handleInputDetails: any;
};
function PdfModal({ open, handleClose, selectedFile, handlePdfSave, handleInputDetails }: Props): any {
    const [pdfUrl, setPdfUrl] = useState(URL.createObjectURL(selectedFile));
    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        borderRadius: "19px",
        boxShadow: 24,
        display: "flex",
        direction: "column",
        height: "35.9375rem",
        outline: "none",
    };
    useEffect(() => {
        setPdfUrl(URL.createObjectURL(selectedFile));
    }, [selectedFile]);
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...style, width: "37.687rem" }}>
                <Box
                    p={4}
                    maxWidth="15.0625rem"
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    <Box>
                        <Typography>{selectedFile?.name}</Typography>
                        <Typography color="#7A7A7A" m="1rem 0 2rem 0">
                            Specify Page range you want to upload
                        </Typography>
                        <Typography color="#424F65" mb="0.5rem">
                            Start from Page
                        </Typography>
                        <TextField
                            name="start_page"
                            variant="outlined"
                            placeholder="Eg.04"
                            size="small"
                            type="number"
                            onChange={handleInputDetails}
                            sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none",
                                },
                                "& input[type=number]": {
                                    MozAppearance: "textfield",
                                },
                            }}
                        />
                        <Typography color="#424F65" m="1.5625rem 0 0.5rem 0">
                            End to Page
                        </Typography>
                        <TextField
                            name="end_page"
                            variant="outlined"
                            placeholder="Eg.14"
                            size="small"
                            type="number"
                            onChange={handleInputDetails}
                            sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none",
                                },
                                "& input[type=number]": {
                                    MozAppearance: "textfield",
                                },
                            }}
                        />
                    </Box>
                    <Button
                        style={{
                            backgroundColor: "#007BFF",
                            color: "#fff",
                            alignItems: "flex-end",
                            borderRadius: "7px",
                        }}
                        onClick={handlePdfSave}
                    >
                        Save
                    </Button>
                </Box>
                <Box flex="1">
                    <iframe width={"100%"} height={"100%"} key={pdfUrl} src={pdfUrl} title="pdf"></iframe>
                </Box>
            </Box>
        </Modal>
    );
}

export default PdfModal;
