import React, { useState, useCallback, useContext } from "react";
import { useDropzone, Accept } from "react-dropzone";
import { Box, Typography, Button, TextField, List, ListItem, ListItemText, IconButton } from "@mui/material";
import DeleteIcon from "../../components/Common/svgLogs/HR_Analysis/DeleteIcon";
import axios from "axios";
import UploadIcon from "../../components/Common/svgLogs/HR_Analysis/UploadIcon";
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined";
import { Context } from "../../context/setting";
import { useNavigate } from "react-router-dom";
import { Spinner } from "./components/Spinner";

interface UploadedFile extends File {
    preview: string;
}

interface AppState {
    files: UploadedFile[];
    jobDescription: string;
    currentStep: "upload" | "input";
    loading: boolean; // Add loading state
}

const HRAnalysis: React.FC = () => {
    const [state, setState] = useState<AppState>({
        files: [],
        jobDescription: "",
        currentStep: "upload",
        loading: false, // Initialize loading state
    });
    const navigate = useNavigate();
    const { contextStore, setContext } = useContext<any>(Context);
    // const userId = contextStore?.profile?.userId;
    console.log(contextStore);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setState((prevState) => ({
            ...prevState,
            files: [
                ...prevState.files,
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                ),
            ],
        }));
    }, []);

    const removeFile = (index: number) => {
        setState((prevState) => ({
            ...prevState,
            files: prevState.files.filter((_, i) => i !== index),
        }));
    };

    const accept: Accept = {
        "application/pdf": [".pdf"],
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept,
        multiple: true,
    });

    const handleNext = () => {
        setState((prevState) => ({ ...prevState, currentStep: "input" }));
    };

    const handleBack = () => {
        setState((prevState) => ({ ...prevState, currentStep: "upload" }));
    };

    const handleClearAllFiles = () => {
        setState((prevState) => ({
            ...prevState,
            files: [],
        }));
    };

    const handleJobDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prevState) => ({ ...prevState, jobDescription: e.target.value }));
    };

    const handleSubmit = async () => {
        setState((prevState) => ({ ...prevState, loading: true })); // Set loading state to true

        const formData = new FormData();
        state.files.forEach((file, index) => {
            formData.append(`files`, file);
        });

        formData.append("job_description", state.jobDescription);
        // formData.append("user_id", "ec0732c9-9e59-460d-b496-9830fd7d272b"); // Example user ID
        formData.append("user_id", contextStore?.profile?.userId);

        try {
            const response = await axios.post("https://softxapi-demo.softsensor.ai/bond_llm/upload_resume", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log("Server response:", response.data);
            navigate("/analysis_result");
        } catch (error) {
            // console.error("Error sending data to server:", error);
            setContext({
                ...contextStore,
                snackState: {
                    open: true,
                    errorType: "error",
                    message: "Server not responding. Please try after some time",
                },
            });
        } finally {
            setState((prevState) => ({ ...prevState, loading: false })); // Set loading state to false
        }
    };

    return (
        <Box sx={{ width: "100%", height: "100%", p: "5vh 10vw" }}>
            {state.loading && <Spinner />} {/* Render Spinner when loading */}
            {!state.loading && ( // Hide form when loading
                <Box
                    sx={{
                        border: "1px solid #e0e0e0",
                        borderRadius: "10px",
                        p: 5,
                        width: "100%",
                        maxWidth: "700px",
                        m: "auto",
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
                        <Typography fontSize={23}>HR Analysis</Typography>
                        {/* <FolderSharedOutlinedIcon fontSize="large" sx={{ color: "#007BFF" }} /> */}
                    </Box>

                    {state.currentStep === "upload" ? (
                        <>
                            <Typography fontSize={14} color="text.secondary" sx={{ mb: 3 }}>
                                Start by uploading PDFs to create directory.
                            </Typography>
                            <Box>
                                <Box
                                    {...getRootProps()}
                                    sx={{
                                        border: "1px solid #e0e0e0",
                                        borderRadius: "10px",
                                        p: 4,
                                        textAlign: "center",
                                        mb: 2,
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    <Typography fontSize={19} sx={{ fontWeight: "bold", mb: 1 }}>
                                        Drag & Drop your file
                                    </Typography>
                                    <Box sx={{ transform: "scale(0.8)" }}>
                                        <UploadIcon />
                                    </Box>
                                    <Typography fontSize={16}>
                                        Drag and Drop or <span style={{ color: "#007BFF" }}>Browse</span>
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: "#424F65" }}>
                                        {<em>Supported: .pdf</em>}
                                    </Typography>
                                </Box>
                                <List
                                    sx={{
                                        maxHeight: "300px",
                                        overflowY: "auto",
                                        borderRadius: "4px",
                                        "&::-webkit-scrollbar": {
                                            width: "8px",
                                        },
                                        "&::-webkit-scrollbar-track": {
                                            background: "#f1f1f1",
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            background: "#888",
                                            borderRadius: "4px",
                                        },
                                        "&::-webkit-scrollbar-thumb:hover": {
                                            background: "#555",
                                        },
                                    }}
                                >
                                    {state.files.map((file, index) => (
                                        <ListItem
                                            key={index}
                                            secondaryAction={
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() => removeFile(index)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemText primary={file.name} />
                                        </ListItem>
                                    ))}
                                </List>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <Button
                                        variant="contained"
                                        sx={{ mb: 1, px: 4 }}
                                        onClick={handleNext}
                                        disabled={state.files.length === 0}
                                    >
                                        Upload
                                    </Button>
                                    <Button sx={{ color: "error.main" }} onClick={handleClearAllFiles}>
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <Box>
                            <Typography fontSize={14} color="text.secondary" sx={{ mb: 3 }}>
                                Write your required job description to match with potential candidates.
                            </Typography>

                            <TextField
                                fullWidth
                                value={state.jobDescription}
                                onChange={handleJobDescriptionChange}
                                margin="normal"
                                placeholder="Job Description"
                                multiline
                                rows={8}
                            />

                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Button
                                    variant="contained"
                                    sx={{ mb: 1, px: 4 }}
                                    onClick={handleSubmit}
                                    disabled={!state.jobDescription}
                                >
                                    Submit
                                </Button>
                                <Button sx={{ color: "error.main" }} onClick={handleBack}>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default HRAnalysis;
