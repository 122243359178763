import React from 'react'

const SSXLogo = () => {
  return (
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 14.2649C2.65299 11.958 8.39728 7.18261 8.76639 6.99806L15.6872 1H23.0694L14.3031 7.3441L7.24693 13.4038C6.59988 13.9594 5.7752 14.2649 4.9223 14.2649H0Z" fill="#BEDFF1"/>
    <path d="M3.80469 1.00012C6.88061 5.38332 13.0786 14.1958 13.2632 14.3804H19.3766L9.6874 1.00012H3.80469Z" fill="white" stroke="url(#paint0_linear_8066_1082)" stroke-width="1.78404"/>
    <path d="M23.0691 1.00012H15.6869L8.30469 7.37909L13.2262 14.3804H19.378L14.3027 7.37909L23.0691 1.00012Z" fill="#007BFF"/>
    <defs>
    <linearGradient id="paint0_linear_8066_1082" x1="9.11066" y1="9.18978" x2="15.6855" y2="3.65311" gradientUnits="userSpaceOnUse">
    <stop stop-color="#3386D3" stop-opacity="0.35"/>
    <stop offset="0.0001" stop-color="#3386D3" stop-opacity="0.35"/>
    <stop offset="1" stop-color="#0085FF" stop-opacity="0"/>
    </linearGradient>
    </defs>
    </svg>
    
  )
}

export default SSXLogo