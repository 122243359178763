import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import DetectionVisibilityIcon from "../../components/Common/svgLogs/Damage_Detection/DetectionVisibilityIcon";
import {
    ArrowBackIos,
    ArrowForwardIos,
    ArrowLeftSharp,
    ArrowRightSharp,
    Close,
    DoneOutlined,
    InfoOutlined,
    OpenInFull,
} from "@mui/icons-material";
import { useState } from "react";
import DetectionVisibilityOffIcon from "../../components/Common/svgLogs/Damage_Detection/DetectionVisibilityOffIcon";

interface ImagePanelProps {
    imageBytes: any;
    imageIndex: any;
    setImageIndex: any;
}

const ImagePanel: React.FC<ImagePanelProps> = ({ imageBytes, imageIndex, setImageIndex }) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [visibilityView, setVisibilityView] = useState<boolean>(false);
    const [showParameters, setShowParameters] = useState<boolean>(false);
    const [coordinate, setCoordinate] = useState<any>(null);

    const formatter = (str: any) => {
        return str.replace("_", " ").charAt(0).toUpperCase() + str.replace("_", " ").slice(1);
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "1050px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                overflow: "scroll",
            }}
        >
            <Box
                sx={{
                    width: "95%",
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #ECECEC",
                    borderRadius: "10px",
                    p: 1,
                    height: "500px",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography sx={{ fontFamily: "Gilroy-Regular", fontWeight: "600" }}>360 View</Typography>
                    <Box>
                        <Button onClick={() => setVisibilityView(!visibilityView)}>
                            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                                <Typography
                                    sx={{
                                        fontFamily: "Gilroy-Regular",
                                        fontWeight: "600",
                                        color: !visibilityView ? "#007BFF" : "#000",
                                    }}
                                >
                                    Detection Visibility
                                </Typography>{" "}
                                {!visibilityView ? <DetectionVisibilityIcon /> : <DetectionVisibilityOffIcon />}
                            </Box>
                        </Button>
                        <IconButton size="small" onClick={() => setOpenModal(true)}>
                            <OpenInFull sx={{ color: "#007BFF" }} />
                        </IconButton>
                        <IconButton
                            size="small"
                            onMouseOver={() => {
                                setShowParameters(true);
                            }}
                            onMouseOut={() => {
                                setShowParameters(false);
                            }}
                        >
                            <InfoOutlined sx={{ color: "#007BFF" }} />
                        </IconButton>
                    </Box>

                    {showParameters && (
                        <Box
                            sx={{
                                position: "absolute",
                                right: "7%",
                                mt: "110px",
                                background: "#fff",
                                p: 1,
                                borderRadius: "10px",
                                border: "1px solid #B6B6B6",
                            }}
                        >
                            <Box sx={{ display: "flex" }}>
                                {imageBytes?.image_bytes_list?.detailed_reports?.[imageIndex]?.[3]?.["Too Blurry"] ? (
                                    <DoneOutlined sx={{ color: "green", transform: "scale(0.8)" }} />
                                ) : (
                                    <Close sx={{ color: "red", transform: "scale(0.8)" }} />
                                )}
                                <Typography>Too blurry</Typography>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                                {imageBytes?.image_bytes_list?.detailed_reports?.[imageIndex]?.[3]?.["Cropped"] ? (
                                    <DoneOutlined sx={{ color: "green", transform: "scale(0.8)" }} />
                                ) : (
                                    <Close sx={{ color: "red", transform: "scale(0.8)" }} />
                                )}
                                <Typography>Cropped</Typography>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                                {imageBytes?.image_bytes_list?.detailed_reports?.[imageIndex]?.[3]?.["Too Far"] ? (
                                    <DoneOutlined sx={{ color: "green", transform: "scale(0.8)" }} />
                                ) : (
                                    <Close sx={{ color: "red", transform: "scale(0.8)" }} />
                                )}
                                <Typography>Too Far</Typography>
                            </Box>
                        </Box>
                    )}
                </Box>

                {imageIndex !== null ? (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                            height: "100%",
                        }}
                    >
                        <ArrowBackIos
                            style={{
                                color: "#B6B6B6",
                                transform: "scale(2)",
                                marginLeft: "2%",
                                cursor: imageIndex > 0 ? "pointer" : "not-allowed",
                            }}
                            onClick={() => {
                                if (imageIndex > 0)
                                    setImageIndex(
                                        (imageIndex - 1) % imageBytes?.image_bytes_list?.detailed_reports?.length
                                    );
                            }}
                        />
                        <img
                            style={{ width: "80%", maxHeight: "500px", maxWidth: "800px", cursor: "grab" }}
                            alt="graph"
                            src={
                                visibilityView
                                    ? `data:image/png;base64,${imageBytes?.image_bytes_list?.detailed_reports?.[imageIndex]?.[1]?.original_base64}`
                                    : `data:image/png;base64,${imageBytes?.image_bytes_list?.detailed_reports?.[imageIndex]?.[1]?.modified_image}`
                            }
                            onMouseMove={(e: any) => {
                                if (coordinate !== null && e.buttons === 1) {
                                    console.log(imageIndex);
                                    if (e.clientX - coordinate >= 20) {
                                        setImageIndex(
                                            (imageIndex + 1) % imageBytes?.image_bytes_list?.detailed_reports?.length
                                        );
                                        setCoordinate(e.clientX);
                                    }
                                    if (coordinate - e.clientX >= 20) {
                                        if (imageIndex === 0) {
                                            setImageIndex(imageBytes?.image_bytes_list?.detailed_reports?.length - 1);
                                        } else
                                            setImageIndex(
                                                Math.abs(imageIndex - 1) %
                                                    imageBytes?.image_bytes_list?.detailed_reports?.length
                                            );
                                        setCoordinate(e.clientX);
                                    }
                                }
                            }}
                            onMouseDown={(e: any) => {
                                setCoordinate(e.clientX);
                            }}
                            onMouseUp={() => setCoordinate(null)}
                            draggable={false}
                        />
                        <ArrowForwardIos
                            style={{
                                color: "#B6B6B6",
                                transform: "scale(2)",
                                marginLeft: "2%",
                                cursor:
                                    imageIndex < imageBytes?.image_bytes_list?.detailed_reports?.length
                                        ? "pointer"
                                        : "not-allowed",
                            }}
                            onClick={() => {
                                if (imageIndex < imageBytes?.image_bytes_list?.detailed_reports?.length)
                                    setImageIndex(
                                        (imageIndex + 1) % imageBytes?.image_bytes_list?.detailed_reports?.length
                                    );
                            }}
                        />{" "}
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h5" sx={{ opacity: 0.5 }}>
                            Please select an image to view it's damage report
                        </Typography>
                    </Box>
                )}
            </Box>
            <Box
                sx={{
                    width: "95%",
                    mt: "2%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "500px",
                    border: "1px solid #ECECEC",
                    borderRadius: "10px",
                    p: 2,
                }}
            >
                <Typography sx={{ fontFamily: "Gilroy-SemiBold", mb: "1%", width: "100%" }}>
                    Quality Metrics:
                </Typography>
                {Object.entries(imageBytes?.image_bytes_list?.detailed_reports?.[imageIndex]?.[3])?.map((item: any) => {
                    return (
                        item[0] !== "Too Blurry" &&
                        item[0] !== "Too Far" &&
                        item[0] !== "Cropped" && (
                            <Box sx={{ width: "100%", display: "flex" }}>
                                <Box sx={{ width: "50%", border: "1px solid #ECECEC", p: 1 }}>{formatter(item[0])}</Box>
                                <Box sx={{ width: "50%", border: "1px solid #ECECEC", p: 1 }}>{item[1]}</Box>
                            </Box>
                        )
                    );
                })}
            </Box>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: "10px",
                        width: "80%",
                        height: "90%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <img
                        style={{ width: "100%", height: "100%" }}
                        alt="graph"
                        src={`data:image/png;base64,${imageBytes?.image_bytes_list?.detailed_reports?.[imageIndex]?.[1]?.modified_image}`}
                    />
                    <Box sx={{ width: "10%", display: "flex", justifyContent: "space-between" }}>
                        <Button
                            sx={{ cursor: imageIndex > 0 ? "pointer" : "not-allowed" }}
                            onClick={() => {
                                if (imageIndex > 0)
                                    setImageIndex(
                                        (imageIndex - 1) % imageBytes?.image_bytes_list?.detailed_reports?.length
                                    );
                            }}
                        >
                            <ArrowLeftSharp />
                        </Button>
                        <Button
                            onClick={() => {
                                if (imageIndex < imageBytes?.image_bytes_list?.detailed_reports?.length)
                                    setImageIndex(
                                        (imageIndex + 1) % imageBytes?.image_bytes_list?.detailed_reports?.length
                                    );
                            }}
                            sx={{
                                cursor:
                                    imageIndex < imageBytes?.image_bytes_list?.detailed_reports?.length
                                        ? "pointer"
                                        : "not-allowed",
                            }}
                        >
                            <ArrowRightSharp />
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default ImagePanel;
