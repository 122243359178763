import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import InputField from "./InputField";
import XLogo from "../../../../components/Common/svgLogs/HR_Analysis/XLogo";
import ProfileAvatar from "../../../../components/Common/svgLogs/ProfileAvatar";
import FileMenu from "./FileMenu";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { Api } from "../../../../apis";
import { Context } from "../../../../context/setting";
import Typing from "../../../Chat/Effects/Typing";
import { ArrowBackIos } from "@mui/icons-material";
import remarkGfm from "remark-gfm";

const CFOChat = () => {
    const navigate = useNavigate();
    const scrollRef = useRef<any>();
    const { contextStore, setContext } = useContext<any>(Context);
    const [msgList, setMsgList] = useState<any[]>([
        {
            init: true,
            element: (
                <Stack gap={1}>
                    <Typography sx={{ fontSize: "0.95rem" }}>
                        Welcome to SoftsensorX! Your go-to for seamless assistance
                    </Typography>
                    <Typography variant="caption">Try Asking</Typography>
                    <Stack gap={1}>
                        {[
                            "What are the main topics and key points covered in the document discussing financial analysis concepts?",
                            "Can you provide an overview of the document's content, highlighting the main topics and key points related to financial analysis concepts?",
                            "What specific financial analysis concepts are explored in the document and what are the main takeaways from these discussions?",
                        ]?.map((item: string) => (
                            <Box
                                sx={{
                                    background: "rgba(217, 217, 217, 0.3)",
                                    p: 0.8,
                                    borderRadius: "6px",
                                    width: "90%",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    {item}
                                </Typography>
                            </Box>
                        ))}
                    </Stack>
                </Stack>
            ),
        },
    ]);
    const handleSend = (value: string) => {
        setMsgList((prev) => [
            ...prev,
            { out: true, msg: value },
            {
                in: true,
                msg: "",
                load: true,
            },
        ]);
        setTimeout(() => {
            scrollRef?.current?.lastElementChild?.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "end",
            });
        }, 100);
        Api?.chatCFO({ user_query: value })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setMsgList((prev: any) => {
                        return prev?.map((item: any) => {
                            if (item?.load) {
                                item.load = false;
                                item.msg = res?.data?.response;
                            }
                            return item;
                        });
                    });
                    setTimeout(() => {
                        scrollRef?.current?.lastElementChild?.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                            inline: "end",
                        });
                    }, 100);
                }
            })
            ?.catch((err) =>
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: "Something Went Wrong. Please Try again.",
                    },
                })
            );
    };

    return (
        <Stack flex={1} alignItems={"center"} sx={{ "& > div": { width: "75%" }, py: 1 }}>
            <Button
                sx={{
                    width: "fit-content",
                    gap: 1,
                    background: "#fff",
                    "&:hover": { background: "#fff" },
                    alignSelf: "flex-start",
                }}
                onClick={() => navigate("/cfo_doc_intel")}
            >
                <ArrowBackIos sx={{ fontSize: "0.85rem" }} /> Back
            </Button>
            <Stack ref={scrollRef} flex={"1 0 10vh"} overflow={"auto"} gap={1} p={1}>
                {msgList?.map((item: any) => (
                    <Stack
                        direction={"row"}
                        gap={2}
                        sx={{
                            background: item?.in ? "#fff" : "inherit",
                            border: item?.in ? "1px solid #EEEEEE" : "inherit",
                            p: 2,
                            borderRadius: "10px",
                        }}
                    >
                        <Stack>
                            {(item?.in || item?.init) && <XLogo />}
                            {item?.out && <ProfileAvatar />}
                        </Stack>
                        {item?.init && item?.element}
                        {item?.in && (
                            <>
                                {item?.load ? (
                                    <Typing
                                        onClick={() =>
                                            setMsgList((prev: any) => {
                                                return prev?.map((item: any) => {
                                                    if (item?.load) {
                                                        item.load = false;
                                                        item.msg = "Response Terminated!";
                                                        item.error = true;
                                                    }
                                                    return item;
                                                });
                                            })
                                        }
                                    />
                                ) : (
                                    <Typography
                                        sx={{
                                            fontSize: "0.9rem",
                                            pl: 1,
                                            color: item?.error ? "red" : "inherit",
                                            fontWeight: "Gilroy-SemiBold",
                                            "& td": {
                                                border: "1px solid #e7e7e7",
                                                px: 1,
                                            },
                                            "& table": {
                                                borderCollapse: "collapse",
                                            },
                                            "& th": {
                                                border: "1px solid #e7e7e7",
                                                background: "#e7e7e7",
                                                px: 1,
                                            },
                                            whiteSpace: "-moz-pre-wrap",
                                        }}
                                    >
                                        <Markdown remarkPlugins={[remarkGfm]}>{item?.msg}</Markdown>
                                    </Typography>
                                )}
                            </>
                        )}
                        {item?.out && <Typography sx={{ fontSize: "0.9rem" }}>{item?.msg}</Typography>}
                    </Stack>
                ))}
            </Stack>
            <Stack gap={1}>
                <FileMenu />
                <InputField handleSend={handleSend} handleStartClick={() => {}} />
            </Stack>
        </Stack>
    );
};

export default CFOChat;
