import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from "./reportWebVitals";
import Environment from "./enviroment";
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Auth0Provider
        domain={Environment.AUTH0_DOMAIN}
        clientId={Environment.AUTH0_CLIENT_ID}
        authorizationParams={{
            redirect_uri: `${window.location.origin}/`,
            audience: `${Environment.AUTH0_AUDIENCE}`,
            scope: "openid profile email offline_access",
            connection: `${Environment.AUTH0_CONNECTION}`,
        }}
        cacheLocation="localstorage"
    >
        <App />
    </Auth0Provider>
);
reportWebVitals();
