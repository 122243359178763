import { Box, Link, Typography, Checkbox, Button, useTheme, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SearchMidiumIcon from "../../components/Common/svgLogs/SearchMidiumIcon";
import { Api } from "../../apis";
import { Context } from "../../context/setting";

const EnterSource = ({ userSources }: { userSources: any }) => {
    const theme = useTheme();
    const { contextStore, setContext } = useContext<any>(Context);
    const [tabSelect, setTabSelect] = useState<string>("All");
    const [selectedPdf, setSelectedPdf] = useState<string>(contextStore.temparary?.source || "");
    const smd = useMediaQuery(theme?.breakpoints?.down("sm"));

    const styles = () => {
        if (smd) {
            return {
                main: {
                    px: 1,
                    mt: 5.3,
                },
                box: {
                    width: "90vw",
                    overflowX: "auto",
                    height: "50vh"
                },
            };
        }
        return {};
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, px: 4, mt: 4, ...styles()?.main }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="subtitle2">Select Data Source</Typography>
                <SearchMidiumIcon />
            </Box>
            <Box
                sx={{
                    border: "1px solid #CFCFCF",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                    height: "65vh",
                    py: 1,
                    ...styles()?.box,
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 2, gap: 1 }}>
                    {["All", "Document", "Datasets", "Website", "Databases"]?.map((elem) => (
                        <Link
                            sx={{
                                textDecoration: tabSelect === elem ? "underline" : "none",
                                color: tabSelect === elem ? "#007BFF" : "#7F8082",
                            }}
                        >
                            {elem}
                        </Link>
                    ))}
                </Box>
                <Box sx={{ flex: 1, overflowY: "auto", mt: 2, px: 2 }}>
                    {contextStore?.pdfList?.map((elem: string) => (
                        <Box
                            sx={{ display: "flex", alignItems: "center", userSelect: "none", cursor: "pointer" }}
                            onClick={() => {
                                if (selectedPdf === elem) {
                                    setSelectedPdf("");
                                    userSources.source = "";
                                } else {
                                    setSelectedPdf(elem);
                                    userSources.source = elem;
                                }
                            }}
                        >
                            <Checkbox checked={selectedPdf === elem} />
                            <Typography variant="subtitle2">{elem}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default EnterSource;
