import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import "./manLoader.css";

const DocumentLoadingModal = ({ loading }: any) => {
    return (
        <Modal open={loading}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        p: 3,
                        pb: 6,
                        border: "2px solid #f2f2f2",
                        borderRadius: "10px",
                        background: "#e2e2e2",
                        gap: 3,
                    }}
                >
                    <Typography>Please Wait, Documents Are Loading. . .</Typography>
                    <div className="running">
                        <div className="outer">
                            <div className="body">
                                <div className="arm behind"></div>
                                <div className="arm front"></div>
                                <div className="leg behind"></div>
                                <div className="leg front"></div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
        </Modal>
    );
};

export default DocumentLoadingModal;
