import React, { useContext, useRef, useState } from "react";
import { Box, Button, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import PDFLoadIcon from "../../../components/Common/svgLogs/PDFLoadIcon";
import MessageBox from "./MessageBox";
import InputField from "./InputField";
import PdfSocket from "../../Chat/utils/PdfSocket";
import SelectionModal from "./SelectionModal";
import LogoXIcon from "../../../components/Common/svgLogs/LogoXIcon";
import { Api } from "../../../apis";
import { Context } from "../../../context/setting";

const Chat = ({ visiblePdf, setVisiblePdf }: { setVisiblePdf: any; visiblePdf: boolean }) => {
    const theme = useTheme();
    const inputRef = useRef();
    const scrollRef = useRef<any>();
    const { contextStore, setContext } = useContext<any>(Context);
    const smd = useMediaQuery(theme?.breakpoints?.down("sm"));
    const [msgList, setMsgList] = useState<any>([
        {
            msg: (
                <Stack gap={1}>
                    <Typography>Let’s get started!</Typography>
                    <Stack direction={"row"} gap={3}>
                        <Stack gap={0.5} width={"50%"}>
                            <Typography variant="caption">Try Asking</Typography>
                            {[
                                "What are the main topics and key points covered in the document discussing financial analysis concepts?",
                                "Can you outline the document's content, emphasizing the primary topics and key aspects of financial analysis?",
                                "Which financial analysis concepts does the document cover, and what are the key insights from these discussions?",
                            ]?.map((msg) => (
                                <Typography
                                    key={msg}
                                    sx={{
                                        background: "rgba(217, 217, 217, 0.3)",
                                        p: 1,
                                        borderRadius: "4px",
                                        fontSize: "0.75rem",
                                    }}
                                >
                                    {msg}
                                </Typography>
                            ))}
                        </Stack>
                        <Stack gap={0.5}>
                            <Typography variant="caption">Limitations</Typography>
                            {[
                                "Optimal results with standardized documents; struggles with diverse formats.",
                                "May not handle highly complex or specialized IT graphs effectively.",
                                "May not guarantee improved results; requires user familiarity with IT tasks.",
                            ]?.map((msg) => (
                                <Typography
                                    key={msg}
                                    sx={{
                                        background: "rgba(217, 217, 217, 0.3)",
                                        p: 1,
                                        borderRadius: "4px",
                                        fontSize: "0.75rem",
                                    }}
                                >
                                    {msg}
                                </Typography>
                            ))}
                        </Stack>
                    </Stack>
                </Stack>
            ),
            in: true,
            time: new Date(),
            latest: false,
            generated: "auto",
            initial: true,
        },
    ]);
    const styles = (obj?: any) => {
        if (smd) {
            return {
                inputBox: {
                    width: "85%",
                },
                chatBox: {
                    width: "90%",
                },
                messageBox: {
                    justifyContent: obj?.in ? "flex-end" : "flex:start",
                },
            };
        }
        return {};
    };
    const handleSend = (value: string) => {
        setMsgList((prev: any) => [...prev, { out: true, msg: value }, { in: true, load: true, msg: "" }]);
        let data: any = {};
        contextStore?.aurigo?.selected?.forEach((pdf: any) => {
            const alaisName = contextStore?.pdfList?.find((item: any) => item?.file_id === pdf?.file_id)?.alais;
            data[pdf?.file_id] =
                (alaisName?.toString()?.length > 0
                    ? alaisName
                    : contextStore?.pdfList?.find((item: any) => item?.file_id === pdf?.file_id)?.file_name) || "";
        });
        Api?.chatBandhan({
            ids: [data],
            query: value,
        })
            ?.then((res: any) => {
                if (res?.status === 200) {
                    setMsgList(
                        (prev: any) =>
                            prev?.map((item: any) =>
                                item?.load ? { ...item, load: false, msg: res?.data?.response } : item
                            )
                    );
                } else {
                    setMsgList(
                        (prev: any) =>
                            prev?.map((item: any) => {
                                item.load = false;
                                item.msg = "Failed To Generate Response!";
                                item.error = true;
                                return item;
                            })
                    );
                }
            })
            ?.catch((err) => {
                setMsgList(
                    (prev: any) =>
                        prev?.map((item: any) => {
                            item.load = false;
                            item.msg = "Failed To Generate Response!";
                            item.error = true;
                            return item;
                        })
                );
            });
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                transition: "300ms ease-out",
                width: "100%",
                position: "relative",
            }}
        >
            {/* {!visiblePdf && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "5px",
                        right: "3rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        ml: "12px",
                        gap: 1,
                    }}
                >
                    <IconButton
                        sx={{
                            borderRadius: "10px",
                            background: "#fff",
                            border: "1px solid #F5F5F5",
                            width: "2.4rem",
                            height: "2.4rem",
                            transition: "ease-in-out 200ms",
                            p: 1.1,
                            "&:hover": {
                                boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.1)",
                                background: "#fff",
                                transform: "scale(1.05)",
                            },
                        }}
                        onClick={() => setVisiblePdf(true)}
                    >
                        <PDFLoadIcon />
                    </IconButton>
                </Box>
            )} */}

            <Box
                sx={{
                    flex: "1 0 10vh",
                    overflowY: "auto",
                    my: 1,
                    width: "75%",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    ...styles()?.chatBox,
                }}
                ref={scrollRef}
            >
                {msgList?.map((elem: any, index: number) => (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            pr: 1,
                            pb: 1,
                            ...styles(elem)?.messageBox,
                        }}
                    >
                        <MessageBox
                            elem={elem}
                            lastElement={msgList?.length - 1 === index}
                            msgList={msgList}
                            scrollRef={scrollRef}
                            setMsgList={setMsgList}
                        />
                    </Box>
                ))}
            </Box>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    pb: 1,
                }}
            >
                <Box
                    sx={{
                        width: "75%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                    }}
                >
                    <SelectionModal />
                    <InputField handleSend={(value: string) => handleSend(value)} inputRef={inputRef} />
                </Box>
            </Box>
        </Box>
    );
};

export default Chat;
